export class UserLection {

  id: string = null;
  active = true;

  constructor() {
  }

  static createWith(obj: any): UserLection {
    let newObj = new UserLection();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

}

<div class="modal-header">
  <h4 class="modal-title">{{'edit-card'|translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="module.type=='TWO_SIDE'">{{'front-side'|translate}}:</p>
  <app-editor
    [elementId]="'frontText'"
    [value]="frontText"
    (change)="onFrontTextChange($event)"
  >
  </app-editor>
  <p class="mt-3" *ngIf="module.type=='TWO_SIDE'">{{'back-side'|translate}}:</p>
  <app-editor *ngIf="module.type=='TWO_SIDE'"
              [elementId]="'backText'"
              [value]="backText"
              (change)="onBackTextChange($event)"
  >
  </app-editor>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-success" (click)="apply()">{{'apply'|translate}}</button>
  <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">{{'cancel'|translate}}</button>
</div>

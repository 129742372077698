import {Component, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../util/loading/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {Module} from '../../module/module.model';
import {ModulePickerComponent} from '../../module/module-picker/module-picker.component';
import {ModuleStat} from '../../stats/module-stat.model';
import {StatsService} from '../../stats/stats.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {KTracker} from '../ktracker.model';
import {TitleService} from '../../util/title/title.service';
import {KTrackerService} from '../ktracker.service';
import {KTrackerModule} from '../ktracker-module.model';
import {UserPickerComponent} from '../../user/user-picker/user-picker.component';
import {UserKTracker} from '../user-ktracker.model';
import {User} from '../../user/user.model';

@Component({
  selector: 'app-ktracker-detail',
  templateUrl: './ktracker-detail.component.html',
  styleUrls: ['./ktracker-detail.component.css']
})
export class KTrackerDetailComponent implements OnDestroy {

  dataChanged = false;
  isEditMode: boolean;

  errors: Map<string, string> = new Map();
  scheduleDays: boolean[] = [];
  auth: any;
  kTracker: KTracker;
  cardCount = 0;
  stats: Map<string, ModuleStat> = new Map();
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;
  private _ngbModalRef: NgbModalRef = null;

  constructor(private kTrackerService: KTrackerService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private statsService: StatsService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              public authService: AuthService) {

    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  private _init() {
    this.loadingService.open();


    let kTrackerId = this.route.snapshot.params['id'];
    if (kTrackerId && kTrackerId === 'new') {
      this.isEditMode = false;
      this._initNewKTracker();
      this.loadingService.dismiss();
    } else {
      this.isEditMode = true;
      this._sHelper.sub = this.kTrackerService.findByUserAndId(this.auth.user, kTrackerId).subscribe(
        kTracker => {
          this.kTracker = kTracker;
          this._initSchedule();
          this.getModuleStats();
        },
        e => console.error(e),
        () => this.loadingService.dismiss()
      );
    }

  }

  private _initNewKTracker() {
    this.kTracker = new KTracker();
    this._initSchedule();
  }

  private _initSchedule() {
    let days = this.kTracker.scheduleDays;
    for (let i = 0; i < 7; i++) {
      this.scheduleDays.push(days.includes('' + i));
    }
  }

  addModule() {
    this._ngbModalRef = this.modalService.open(ModulePickerComponent);
    this._ngbModalRef.componentInstance.moduleTypes.push('MULTIPLE_CHOICE');
    this.kTracker.ktrackerModules.forEach(
      am => this._ngbModalRef.componentInstance.excludedModules.set(am.module.id, am.module)
    );

    this._ngbModalRef.result.then((result: Module[]) => {
      if (result) {
        result.forEach(m => {
          let am = new KTrackerModule();
          am.module = m;
          this.kTracker.ktrackerModules.push(am);
          this.getModuleStats();
        });
        this.dataChanged = true;
      }
    }, (reason) => {
    });
  }

  addUser() {
    this._ngbModalRef = this.modalService.open(UserPickerComponent);
    this.kTracker.userKTrackers.forEach(
      uk => this._ngbModalRef.componentInstance.excludedUsers.set(uk.user.id, uk.user)
    );

    this._ngbModalRef.result.then((result: User[]) => {
      if (result) {
        result.forEach(user => {
          let uk = new UserKTracker();
          uk.user = user;
          this.kTracker.userKTrackers.push(uk);
        });
        this.dataChanged = true;
      }
    }, (reason) => {
    });
  }

  getModuleStats() {
    let modules: Module[] = [];
    this.kTracker.ktrackerModules.forEach(
      am => {
        if (!this.stats.get(am.module.id)) {
          modules.push(am.module);
        }
      });

    if (modules.length > 0) {
      this.statsService.findModuleStatsByUserAndModuleIds(this.auth.user, modules).subscribe(
        stats => {
          stats.forEach(stat => this.stats.set(stat.moduleId, stat));
          this.calcCardCount();
        });
    } else {
      this.calcCardCount();
    }
  }

  calcCardCount() {
    this.cardCount = 0;
    this.kTracker.ktrackerModules.forEach(
      am => {
        if (this.stats.get(am.module.id)) {
          this.cardCount += this.stats.get(am.module.id).cardCount;
        }
      });
  }

  remove(kTrackerModule: KTrackerModule, event) {
    event.stopPropagation();
    // delete item in local array
    let delFn = (item, i, arr) => item.module.id === kTrackerModule.module.id ? arr.splice(i, 1) : false;
    this.kTracker.ktrackerModules.some(delFn);
    this.dataChanged = true;
    this.calcCardCount();
  }

  removeUser(userKTracker: UserKTracker, event) {
    event.stopPropagation();
    // delete item in local array
    let delFn = (item, i, arr) => item.user.id === userKTracker.user.id ? arr.splice(i, 1) : false;
    this.kTracker.userKTrackers.some(delFn);
    this.dataChanged = true;
  }

  submit(event) {
    if (!this.dataChanged && this.isEditMode) {
      this._navigateNext();
      return;
    }
    this.loadingService.open();
    this.errors.clear();

    if (!this.kTracker.name || this.kTracker.name.trim() === '') {
      this.errors.set('name', '');
      this.errors.set('error', 'fields-required');
    } else {
      this.kTracker.name = this.kTracker.name.trim();
      if (this.kTracker.name.length < 2 || this.kTracker.name.length > 100) {
        this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('name', res);
          });
      }
    }

    // user has to select one day at least
    let oneChecked = false;
    this.scheduleDays.forEach((check) => {
      if (check) {
        oneChecked = true;
      }
    });

    if (!oneChecked) {
      this.errors.set('schedule-days', 'error-schedule-days');
    }

    if (this.errors.size == 0) {
      // serialize schedule
      this.kTracker.scheduleDays = '';
      this.scheduleDays.forEach((check, idx) => {
        if (check) {
          this.kTracker.scheduleDays += idx;
        }
      });

      if (this.isEditMode) {
        this._sHelper.sub = this.kTrackerService.update(this.auth.user, this.kTracker).subscribe(
          updated => {
            if (updated) {
              this.tooltipService.show({translateKey: 'update-success'});
              this._navigateNext();
            }
            this.loadingService.dismiss();
          },
          e => {
            console.error(e);
            this._processServerErrors(e);
            this.loadingService.dismiss();
          });
      } else {
        this._sHelper.sub =
          this.kTrackerService.persist(this.auth.user, this.kTracker).subscribe(
            newId => {
              if (newId) {
                this.tooltipService.show({translateKey: 'create-success'});
                this._navigateNext();
              }
              this.loadingService.dismiss();
            }, error => {
              this._processServerErrors(error);
              this.loadingService.dismiss();
            });

      }
    } else {
      window.scrollTo(0, 0);
      this.loadingService.dismiss();
    }
  }

  private _navigateNext() {
    this.router.navigate(['/manager/ktrackers']);
  }

  private _processServerErrors(error) {
    let errorJSON = null;
    try {
      errorJSON = error.json();
    } catch (e) {
      this.errors.set('error', error.statusText);
      console.error(e);
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set(k, errorJSON[k]);
        });
    }
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

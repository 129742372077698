import {Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener} from '@angular/core';
import {Location} from '@angular/common';
import {TitleService} from '../../util/title/title.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {LoadingService} from '../../util/loading/loading.service';
import {StatsService} from '../../stats/stats.service';
import {ModuleService} from '../../module/module.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {ModuleStat} from '../../stats/module-stat.model';
import {Module} from '../../module/module.model';
import {forkJoin} from 'rxjs';
import {Card} from '../../module/card.model';
import {PhaseIntervall} from '../../util/phase-intervall';
import {UserCard} from '../../module/user-card.model';
import {CardImage} from '../../module/card-image.model';
import {DeleteConfirmComponent} from '../../util/confirm-modal/delete-confirm.component';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import * as moment from 'moment';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {KTrackerStat} from '../../stats/ktracker-stat.model';
import {KTrackerService} from '../../ktracker/ktracker.service';
import {KTracker} from '../../ktracker/ktracker.model';
import {KTrackerCardAnswer} from '../../ktracker/ktracker-card-answer.model';
import {KTrackerCard} from '../../ktracker/ktracker-card.model';

enum ViewState {
  SHOW_QUESTION, SHOW_ANSWER, FINISHED
}

enum LearnModeState {
  DISABLED, ALL, PHASE, FAVORITE, KTRACKER
}

@Component({
  selector: 'app-learn-module',
  templateUrl: 'learn-module.component.html',
  styleUrls: ['learn-module.component.css'],
})
export class LearnModuleComponent implements OnInit, OnDestroy {

  private _learnCard;
  @ViewChild('learnCard')
  set learnCard(lc: ElementRef) {
    if (lc) {
      this._learnCard = lc.nativeElement;
    } else {
      this._learnCard = null;
    }
  }

  private _feedbackYes;
  @ViewChild('feedbackYes', { static: true })
  set feedbackYes(lc: ElementRef) {
    if (lc) {
      this._feedbackYes = lc.nativeElement;
    } else {
      this._feedbackYes = null;
    }
  }

  private _feedbackNo;
  @ViewChild('feedbackNo', { static: true })
  set feedbackNo(lc: ElementRef) {
    if (lc) {
      this._feedbackNo = lc.nativeElement;
    } else {
      this._feedbackNo = null;
    }
  }

  @ViewChild('yesBtn')
  set yesBtn(elRef: ElementRef) {
    if (elRef && (this.answerColor == '' || this.answerColor == 'green')) {
      elRef.nativeElement.focus();
    }
  }

  @ViewChild('noBtn')
  set noBtn(elRef: ElementRef) {
    if (elRef && this.answerColor == 'red') {
      elRef.nativeElement.focus();
    }
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.learnMode == LearnModeState.DISABLED) {
      return;
    }

    if (this.module.type == 'TWO_SIDE') {
      if (this.currentViewState == this.viewState.SHOW_QUESTION) {

        if (this.isWriteMode) {
          return;
        }
        if ('Enter' == event.key) {
          this.showAnswer();
          return;
        }
        if ('w' == event.key) {
          setTimeout(() => this.changeWriteMode(), 100);
          return;
        }
      } else if (this.currentViewState == this.viewState.SHOW_ANSWER) {
        if ('y' == event.key) {
          this.onAnswer(true);
        } else if ('n' == event.key) {
          this.onAnswer(false);
        }
      }


    } else if (this.module.type == 'ONE_SIDE') {
      if (this.currentViewState == this.viewState.SHOW_ANSWER) {
        if ('y' == event.key) {
          this.onAnswer(true);
        } else if ('n' == event.key) {
          this.onAnswer(false);
        }
      }
    } else if (this.module.type == 'MULTIPLE_CHOICE') {
      if (this.currentViewState == this.viewState.SHOW_QUESTION) {
        if ('Enter' == event.key) {
          this.showAnswer();
          return;
        }
        let choice = parseInt(event.key);
        if (choice > 0 && choice <= this.currentCard.cardMcChoices.length) {
          this.toggleMcc(this.currentCard.cardMcChoices[choice - 1]);
          return;
        }
      } else if (this.currentViewState == this.viewState.SHOW_ANSWER) {
        if ('Enter' == event.key) {
          this.initNextCard();
          return;
        }
      }
    }

    if (this.currentViewState == this.viewState.FINISHED) {
      if ('Enter' == event.key) {
        this.finish();
        return;
      }
    }

    if ('f' == event.key) {
      this.toggleFavorite();
    }

  }

  /*
   * Keypress event doesn't handle escape key so this is extra handling for keyup event.
   * */
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent2(event: KeyboardEvent) {
    if (this.learnMode == LearnModeState.DISABLED) {
      return;
    }

    if (this.module.type == 'TWO_SIDE') {
      if (this.currentViewState == this.viewState.SHOW_QUESTION) {
        if ('Escape' == event.key && this.isWriteMode) {
          this.answerText = '';
          this.changeWriteMode();
          return;
        }
      }
    }

    if ('Escape' == event.key) {
      this.finish();
      return;
    }
  }

  module: Module;
  cardId: string;
  moduleStat: ModuleStat;
  kTracker: KTracker;
  kTrackerStat: KTrackerStat;
  kTrackerCard: KTrackerCard;
  kTrackerCardAnswer: KTrackerCardAnswer;
  cardsLeft: Card[] = [];
  cardCount = 0;
  currentCard: Card;
  currentUserCard: UserCard;
  currentViewState: ViewState;
  yesClickCounter = 0;
  noClickCounter = 0;
  viewState = ViewState;
  learnModeState = LearnModeState;
  learnMode: LearnModeState = LearnModeState.ALL;
  isReadonly = true;
  isWriteMode = false;
  answerText = '';
  answerColor = '';
  answerTimeSec: number;
  answerTimeSecLeft: number;
  answerTimeProgress: number;
  answerTimeTimer;
  startAnswerTime: number;
  lastAnswerTime: number;

  private Math: any;
  auth: any;
  _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private moduleService: ModuleService,
              private statsService: StatsService,
              private kTrackerService: KTrackerService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private tooltipService: TooltipService,
              private titleService: TitleService,
              private location: Location) {
    this.Math = Math;
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  private _init() {
    this.loadingService.open();
    this._sHelper.sub = this.route.params.subscribe((params: Params) => {
        let module_id = parseInt(params['module_id']);
        let ktracker_id = parseInt(params['ktracker_id']);
        if (module_id) {
          this.cardId = params['card'];
          if (params['mode'] && LearnModeState[params['mode']] != undefined) {
            this.learnMode = (<any>LearnModeState)[params['mode']];
          }
          // Parallel
          this._sHelper.sub = forkJoin(
            [
              this.moduleService.findByUserAndId(this.auth.user, '' + module_id),
              this.statsService.findModuleStatsByUserAndModuleIds(this.auth.user, ['' + module_id])
            ])
            .subscribe(
              results => {
                this.module = results[0];
                this.titleService.title = this.module.name;
                this.isReadonly = this.module.permission(this.auth.user) == 'READ';

                let moduleStats = results[1];
                if (moduleStats.length > 0) {
                  this.moduleStat = moduleStats[0];
                }

                this.initCards();
                this.initNextCard();
              },
              e => console.error(e),
              () => this.loadingService.dismiss());
        } else if (ktracker_id) {
          this.learnMode = LearnModeState.KTRACKER;
          // Parallel
          this._sHelper.sub = forkJoin(
            [
              this.kTrackerService.findByUserAndId(this.auth.user, '' + ktracker_id),
              this.statsService.findKTrackerStatsByUserAndKTrackerIds(this.auth.user, ['' + ktracker_id])
            ])
            .subscribe(
              results => {
                this.kTracker = results[0];
                this.kTrackerCardAnswer = new KTrackerCardAnswer();
                this.kTrackerCardAnswer.userId = this.auth.user.id;
                this.kTrackerCardAnswer.ktrackerId = this.kTracker.id;

                this.titleService.title = this.kTracker.name;

                let kTrackerStats = results[1];
                if (kTrackerStats.length > 0) {
                  this.kTrackerStat = kTrackerStats[0];
                  this.cardsLeft = this.kTrackerStat.dueCardIds.map(cid => new Card('' + cid));
                  this.cardCount = this.cardsLeft.length;
                }

                this.initNextCard();
              },
              e => console.error(e),
              () => this.loadingService.dismiss());
        }
      },
      e => {
        console.error(e);
        this.loadingService.dismiss();
      });

  }

  initCards() {
    this.module.lections.forEach(
      l => {
        if (l.userLections[0].active) {
          l.cards.forEach(
            c => {
              if (this.cardId) {
                if (this.cardId == c.id) {
                  this.cardsLeft.push(c);
                }
                return;
              }
              switch (this.learnMode) {
                case LearnModeState.ALL:
                case LearnModeState.DISABLED:
                  this.cardsLeft.push(c);
                  break;
                case LearnModeState.PHASE:
                  if (PhaseIntervall.isDueCard(c.userCards[0], this.moduleStat.serverUnixTime)) {
                    this.cardsLeft.push(c);
                  }
                  break;
                case LearnModeState.FAVORITE:
                  if (c.userCards[0].favorite) {
                    this.cardsLeft.push(c);
                  }
                  break;
              }
            });
        }
      });
    this.cardCount = this.cardsLeft.length;
  }

  onAnswer(known: boolean, event?) {
    this.showFeedback(known);
    this._clearTimer();
    this.lastAnswerTime = moment().unix() - this.startAnswerTime;

    if (this.learnMode == LearnModeState.KTRACKER) {
      if (known) {
        this.yesClickCounter++;
      } else {
        this.noClickCounter++;
      }
      this.kTrackerCardAnswer.cardId = this.currentCard.id;
      this.kTrackerCardAnswer.answeredCorrect = known;
      this.kTrackerService.persistCardAnswer(this.kTrackerCardAnswer).subscribe(
        (res) => {
          console.log('Card answer sent!');
        });
    } else {
      if (known) {
        this.yesClickCounter++;
        if (PhaseIntervall.isDueCard(this.currentUserCard, this.moduleStat.serverUnixTime)) {
          if (this.currentUserCard.phase == 0) {
            this.currentUserCard.phase = 1;
          }
          this.currentUserCard.phase++;
        }
      } else {
        this.noClickCounter++;
        this.cardsLeft.push(this.currentCard);
        this.currentUserCard.phase = 1;
      }
      this.currentUserCard.lastKnown = known;
      this.currentUserCard.lastAnswerTime = this.lastAnswerTime;
      this._updateUserCard();

      if (known || this.module.type != 'MULTIPLE_CHOICE') {
        this.initNextCard();
      }
    }

    this.answerColor = '';
  }

  showFeedback(known: boolean) {
    let feedbackEl = this._feedbackNo;
    if (known) {
      feedbackEl = this._feedbackYes;
    }
    feedbackEl.classList.add('feedback-center-fade-in-out');
    setTimeout(() => feedbackEl.classList.remove('feedback-center-fade-in-out'), 1000);
  }

  toggleFavorite() {
    this.currentUserCard.favorite = !this.currentUserCard.favorite;
    this._updateUserCard();
  }

  changeWriteMode(event?) {
    if (event) {
      event.preventDefault();
    }
    this.isWriteMode = !this.isWriteMode;
  }

  getQuestText(): string {
    if (this.module.userModules && this.module.userModules[0].inversLearnDirection) {
      return this.currentCard.backText;
    } else {
      return this.currentCard.frontText;
    }
  }

  isQuestImage(cImg: CardImage): boolean {
    if (this.module.userModules && this.module.userModules[0].inversLearnDirection) {
      return !cImg.cardFrontImage;
    } else {
      return cImg.cardFrontImage;
    }
  }

  isAnswerImage(cImg: CardImage): boolean {
    if (this.module.userModules && this.module.userModules[0].inversLearnDirection) {
      return cImg.cardFrontImage;
    } else {
      return !cImg.cardFrontImage;
    }
  }

  getAnswerText(): string {
    if (this.module.userModules && this.module.userModules[0].inversLearnDirection) {
      return this.currentCard.frontText;
    } else {
      return this.currentCard.backText;
    }
  }

  showAnswer() {
    this.currentViewState = this.viewState.SHOW_ANSWER;
    if (this.module.type == 'MULTIPLE_CHOICE') {
      let answerKnown = true;
      this.currentCard.cardMcChoices.forEach(
        (mcc: any) => {

          mcc.wrong = mcc.selected && !mcc.correct;
          if (mcc.wrong || !mcc.selected && mcc.correct) {
            answerKnown = false;
          }
        });
      this.onAnswer(answerKnown);
    }
  }

  changeLearnDirection(event) {
    event.preventDefault();
    this.module.userModules[0].inversLearnDirection = !this.module.userModules[0].inversLearnDirection;
    this._updateUserModule();
  }

  deleteCard() {
    const modalRef = this.modalService.open(DeleteConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'delete-card-text';
    modalRef.result.then(
      (deleteItem) => {
        if (deleteItem) {
          this._sHelper.sub = this.moduleService.deleteCard(this.auth.user, this.module.id, this.currentCard.id).subscribe(
            deleted => {
              if (deleted) {
                this.initNextCard();
                this.tooltipService.show({translateKey: 'delete-success'});
              } else {
                this.tooltipService.show({
                  translateKey: 'delete-error',
                  type: 'danger',
                  stay: true
                });
              }
            });

        }
      });
  }

  submit(event) {
    this.currentViewState = this.viewState.SHOW_ANSWER
    let stripedAnswerText = this._htmlToPlaintext(this.getAnswerText());
    if (this.answerText.trim() == stripedAnswerText) {
      this.answerColor = 'green';
      this.onAnswer(true);
    } else {
      this.answerColor = 'red';
    }
  }

  initNextCard() {
    this.answerText = '';
    this.answerTimeSec = null;
    window.scrollTo(0, 0);

    if (this.cardsLeft.length == 0) {
      this.currentViewState = ViewState.FINISHED;
    } else if (this.learnMode == LearnModeState.KTRACKER) {
      this.loadingService.open();
      this.kTrackerService.requestKTrackerCard(this.auth.user, this.kTracker, this.cardsLeft.shift().id)
        .subscribe(
          kTrackerCard => {
            this.kTrackerCard = kTrackerCard;
            this.currentCard = kTrackerCard.card;
            this.module = kTrackerCard.module;
            if (kTrackerCard.answerTimeSec) {
              this.answerTimeSec = this.kTracker.answerTimeSec;
              this.answerTimeSecLeft = kTrackerCard.answerTimeSec;

              let timer = () => {
                this.answerTimeProgress = this.answerTimeSecLeft / this.answerTimeSec * 100;
                this.answerTimeSecLeft--;
                if (this.answerTimeSecLeft >= 0) {
                  this.answerTimeTimer = setTimeout(timer, 1000);
                } else {
                  this.showAnswer();
                }
              }
              this.answerTimeTimer = setTimeout(timer, 0);
            }
            this._initCardView();
            this.loadingService.dismiss();
          },
          e => this.loadingService.dismiss());
    } else {
      this.currentCard = this.cardsLeft.shift();
      this.currentUserCard = this.currentCard.userCards[0];
      this._initCardView();
    }

  }

  _clearTimer() {
    this.answerTimeSecLeft = -1;
    this.answerTimeSec = null;
    if (this.answerTimeTimer) {
      clearTimeout(this.answerTimeTimer);
      this.answerTimeTimer = null;
    }
  }

  _initCardView() {
    if (this._learnCard) {
      this._learnCard.classList.add('card-fade-in');
      const _learnCard = this._learnCard
      setTimeout(() => _learnCard.classList.remove('card-fade-in'), 1000);
    }

    if (this.module.type == 'TWO_SIDE') {
      this.currentViewState = ViewState.SHOW_QUESTION;
    } else if (this.module.type == 'MULTIPLE_CHOICE') {
      this.currentViewState = ViewState.SHOW_QUESTION;
      // shuffle mccs
      this.currentCard.cardMcChoices.sort((a, b) => 0.5 - Math.random());
      this.currentCard.cardMcChoices.forEach(
        (mcc: any) => {
          // remove view model data on mcc
          delete mcc.wrong;
          delete mcc.selected;
        });
    } else {
      this.currentViewState = ViewState.SHOW_ANSWER;
    }

    if (this.learnMode == LearnModeState.DISABLED) {
      this.currentViewState = ViewState.SHOW_ANSWER;
    }

    this.startAnswerTime = moment().unix();
  }

  shuffleCards(event) {
    event.preventDefault();
    if (this.module.type == 'ONE_SIDE'
      || (this.module.type == 'TWO_SIDE' && this.currentViewState == ViewState.SHOW_QUESTION)
      || (this.module.type == 'MULTIPLE_CHOICE' && this.currentViewState == ViewState.SHOW_QUESTION)) {
      this.cardsLeft.push(this.currentCard);
      this.initNextCard();
    }
    this.cardsLeft.sort(function (a, b) {
      return 0.5 - Math.random()
    });
    this._learnCard.classList.add('shake');
    setTimeout(() => this._learnCard.classList.remove('shake'), 1000);
  }

  toggleMcc(mcc) {
    if (this.currentViewState == ViewState.SHOW_QUESTION) {
      mcc.selected = !mcc.selected;
    }
  }

  finish() {
    if (this.learnMode == LearnModeState.KTRACKER) {
      this.router.navigate(['/dashboard']);
    } else {
      this.router.navigate(['/learn', this.module.id]);
    }
  }

  _updateUserCard() {
    let userCard = this.currentUserCard;
    this._sHelper.sub = this.moduleService.updateUserCard(this.auth.user, this.module, this.currentCard, this.currentUserCard).subscribe(
      success => {
        if (success) {
          userCard.id = success.id;
        }
      }
    );
  }

  _updateUserModule() {
    this._sHelper.sub = this.moduleService.updateUserModule(this.auth.user, this.module, this.module.userModules[0]).subscribe(
      success => {
        console.log('UserModule update ok');
      }
    );
  }

  _htmlToPlaintext(text) {
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
  }

  ngOnInit(): void {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('body-learn-mode');

  }

  ngOnDestroy() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('body-learn-mode');
    this._sHelper.unsubscribeAll();
  }
}

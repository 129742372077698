<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">{{'clients'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">

    <div class="col" style="margin-bottom: 15px">
      <button routerLink="/admin/clients/new" class="btn btn-outline-success"><i class="fa fa-plus" aria-hidden="true"></i> {{'new-client'|translate}}</button>
    </div>

    <div class="col col-sm-5 col-lg-4 text-sm-right" style="margin-bottom: 15px">
      <searchbar class="float-sm-right" [(query)]="searchQuery" (input)="doSearch()"
                 placeholder="{{'search'|translate}} {{'clients'|translate}}"></searchbar>
    </div>

  </div>
  <div class="row">
    <div class="col-12">

      <div class="card">
        <div class="table-responsive" *ngIf="filteredClients.length">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th class="border-top-0">{{'name'|translate}}</th>
              <th class="border-top-0"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let client of filteredClients; trackBy:trackByKey" (click)="edit(client)">
              <td>{{client.name}}</td>
              <td class="text-right mr-2 text-primary-2">
                {{stats.get(client.id)?.userCount}}
                <button class="btn btn-link btn-table text-primary-2"
                        (click)="showUsers(client,$event)">
                  <i class="fa fa-users" aria-hidden="true"></i>
                </button>
                <button class="btn btn-link btn-table text-primary-2"
                        *ngIf="client.id != auth.client.id"
                        (click)="delete(client,$event)">
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="!filteredClients.length">
          <h5 class="card-title text-muted text-center" style="margin-top: 12px">
            {{'empty-search-client'|translate}}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

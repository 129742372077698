import {Client} from '../client/client.model';
import {UserRole} from './user-role';

export class User {

  id: string = null;
  login: string = null;
  email: string = null;
  newEmail: string = null;
  name: string = null;
  forename: string = null;
  password: string = null;
  role: UserRole = UserRole.user;
  lang: string = null;
  img: string = null;
  client: Client = null;
  active = false;

  constructor() {
  }

  static createWith(obj: any): User {
    let newObj = new User();
    Object.keys(newObj).forEach(
      key => {
        if ('client' === key && obj[key]) {
          newObj.client = Client.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

  static sortByName(a: User, b: User): number {
    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}

import {Component, OnDestroy} from '@angular/core';
import {LoadingService} from '../../util/loading/loading.service';
import {DeleteConfirmComponent} from '../../util/confirm-modal/delete-confirm.component';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {AuthService} from '../../auth/auth.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {User} from '../user.model';
import {UserService} from '../user.service';
import {Client} from '../../client/client.model';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {TitleService} from '../../util/title/title.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {Log} from 'ng2-logger';
import {AppSettingsService} from '../../app.settings';

@Component({
  selector: 'user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnDestroy {

  log = Log.create('UserListComponent');

  public static readonly SELECTED_CLIENT_ID = 'user-list:selected-client-id';

  selectedClient: Client = null;
  clients: Client[] = [];
  users: User[] = [];
  filteredUsers: User[] = [];
  auth: any;
  searchQuery = '';
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private userService: UserService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              public authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private titleService: TitleService,
              private appSettings: AppSettingsService) {
    titleService.title = 'user';
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth) {
        this._init();
      }
    });
  }

  _init() {
    this.loadingService.open();
    // Observable concat not working on route.params. Don't know why?
    this._sHelper.sub = this.route.params
      .subscribe(
        parameter => {
          let clientId = parameter['clientId'];
          if (clientId) {
            this.appSettings.save(AppSettingsService.SELECTED_CLIENT_ID, clientId);
          } else {
            clientId = this.appSettings.load(AppSettingsService.SELECTED_CLIENT_ID);
          }

          this._sHelper.sub = this.userService.findAll().subscribe(
            users => {
              if (this.auth.user.role == 'manager') {
                //remove admins from list if user has role manager
                let delFn = (item, i, arr) => item.role === 'admin' ? arr.splice(i, 1) : false;
                users.filter(delFn);
              }
              this.users = users.sort(User.sortByName);
              let tmpMap: Map<string, Client> = new Map();
              this.users.forEach(user => {
                tmpMap.set(user.client.id, user.client);
                if (user.client.id == clientId) {
                  this.selectedClient = user.client;
                }
              });
              tmpMap.forEach(client => this.clients.push(client));
              this.clients = this.clients.sort(Client.sortByName);
              this.doSearch();
              this.loadingService.dismiss();
            },
            e => {
              this.loadingService.dismiss();
            }
          );
        },
        e => {
          this.loadingService.dismiss();
        },
        () => console.log('complete'));


  }

  doSearch() {
    this.filteredUsers = this.users.filter(
      (item: User) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.forename && !match) {
            match = item.forename.toLowerCase().indexOf(query) != -1;
          }
          if (item.email && !match) {
            match = item.email.toLowerCase().indexOf(query) != -1;
          }
        }

        let clientMatch = false;
        if (!this.selectedClient) {
          clientMatch = true;
        } else {
          clientMatch = item.client.id == this.selectedClient.id;
        }


        return match && clientMatch;
      });
  }

  edit(user) {
    this.router.navigate(['/manager/users', user.id]);
  }

  delete(user, event) {
    event.stopPropagation();
    this._sHelper.sub = this.translate.get(['delete-text', 'delete-success', 'delete-error'], {name: user.forename + ' ' + user.name})
      .subscribe((res: any) => {
        const modalRef = this.modalService.open(DeleteConfirmComponent, {size: 'sm'});
        modalRef.componentInstance.body = res['delete-text'];
        modalRef.result.then(
          (deleteItem) => {
            if (deleteItem) {
              this._sHelper.sub = this.userService.delete(user.id).subscribe(deleted => {
                if (deleted) {
                  // delete item in local array
                  let delFn = (item, i, arr) => item.id === user.id ? arr.splice(i, 1) : false;
                  this.users.some(delFn);
                  this.doSearch();
                  this.tooltipService.show({message: res['delete-success']});
                } else {
                  this.tooltipService.show({
                    message: res['delete-error'],
                    type: 'danger',
                    stay: true
                  });
                }
              });

            }
          });
      });
  }

  switchToUser(user, event) {
    event.stopPropagation();
    this._sHelper.sub = this.authService.switchToUser(user).subscribe(
      auth => {
        //this.router.navigateByUrl("/login/" + auth.token);
      });
  }

  showDelete(user) {
    if (this.auth) {
      if (user.role == 'admin' && this.auth.user.role == 'manager') {
        return false;
      } else if (user.id == this.auth.user.id) {
        return false;
      }
      return true;
    }
    return false;
  }

  openImport() {
  }

  selectedClientChanged(newObject) {
    if (newObject != null) {
      this.appSettings.save(AppSettingsService.SELECTED_CLIENT_ID, newObject.id);
    } else {
      this.appSettings.remove(AppSettingsService.SELECTED_CLIENT_ID);
    }
  }

  trackByKey(index: number, item: User) {
    return item.id;
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

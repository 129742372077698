<div class="d-flex justify-content-center align-items-center aeonik-regular"
     [style.background-color]="certWizard.tertiaryColor"
     [style.min-height]="windowHeight+'px'">

  <div [style.max-width]="wrapperMaxWidth + 'px'"
       [style.width]="wrapperMaxWidth + 'px'"
       [style.max-height]="wrapperMaxHeight+'px'"
       [style.background-color]="'white'"
       class="cert-wiz-wrapper">


    <nav #navbar class="navbar d-flex flex-row justify-content-between navbar-light bg-primary-4">
      <a #navRef class="navbar-brand mr-auto" style="padding-bottom: 0;padding-top: 2px">
        <img #navImageLeft src="" height="40" alt="">
      </a>

      <img #navImageRight src="" height="40" alt="">

    </nav>

    <div style="background-image: url('assets/img/cert-wizard/ok_mirrored_y.png');
    background-position: right 1.5rem bottom 8rem;
      background-repeat: no-repeat;
          -webkit-background-position: right 1.5rem bottom 8rem;
      -webkit-background-repeat: no-repeat;
          -moz-background-position: right 1.5rem bottom 8rem;
      -moz-background-repeat: no-repeat;
          -o-background-position: right 1.5rem bottom 8rem;
      -o-background-repeat: no-repeat;
    -webkit-background-size: 25rem;
    -moz-background-size: 25rem;
    -o-background-size: 25rem;
    background-size: 25rem;" class="d-flex flex-column align-items-center justify-content-between" [style.min-height]="contentMinHeight+'px'">

      <div class="mt-5">
        <svg class="circle-chart" viewbox="0 0 33.83098862 33.83098862" width="271" height="271" xmlns="http://www.w3.org/2000/svg">
          <circle class="circle-chart__background scale" stroke="#efefef" stroke-width="1.5" fill="none" cx="16.91549431" cy="16.91549431" r="15.91549431" />

          <circle transform="rotate(-90) scale(8,-8) translate(-33.83098862, -33.83098862)"
                  class="circle-chart__circle circle-chart__circle--negative" stroke="#E20613"
                  stroke-width="1.5"
                  style.stroke-dasharray="{{userStat.percentageWrong}}, 100"
                  stroke-linecap="round" fill="none"
                  cx="16.91549431" cy="16.91549431" r="15.91549431" />

          <circle transform="rotate(-90) scale(8) translate(-33.83098862, 0)"
                  class="circle-chart__circle" stroke="#57B760" stroke-width="2"
                  style.stroke-dasharray="{{userStat.percentageCorrect}}, 100"
                  stroke-linecap="round" fill="none"
                  cx="16.91549431" cy="16.91549431" r="15.91549431" />

<!--          <circle transform="rotate(-90) scale(8) translate(-33.83098862, 0)"-->
<!--                  class="circle-chart__circle" stroke="blue" stroke-width="2"-->
<!--                  stroke-dasharray="0,100" stroke-linecap="round" fill="none"-->
<!--                  cx="16.91549431" cy="16.91549431" r="15.91549431" />-->

          <g transform='' class="circle-chart__info">

            <text class="circle-chart__percent scale"
                  x="10"
                  y="14"
                  alignment-baseline="central"
                  text-anchor="middle"
                  font-size="5"
                  fill="#57B760">
              {{userStat.percentageCorrect}}
            </text>

            <text class="circle-chart__percent scale"
                  x="16.91549431"
                  y="14"
                  alignment-baseline="central"
                  text-anchor="middle"
                  font-size="5"
                  fill="#57B760">
              %
            </text>

            <text class="circle-chart__percent scale"
                  x="22.5"
                  y="14"
                  alignment-baseline="central"
                  text-anchor="middle"
                  font-size="5"
                  fill="#57B760">
              ✔
            </text>

            <text class="circle-chart__percent scale"
                  x="10"
                  y="21"
                  alignment-baseline="central"
                  text-anchor="middle"
                  font-size="5"
                  fill="#E20613">
              {{userStat.percentageWrong}}
            </text>

            <text class="circle-chart__percent scale"
                  x="16.91549431"
                  y="21"
                  alignment-baseline="central"
                  text-anchor="middle"
                  font-size="5"
                  fill="#E20613">
              %
            </text>

            <text class="circle-chart__percent scale"
                  x="22.5"
                  y="21"
                  alignment-baseline="central"
                  text-anchor="middle"
                  font-size="5"
                  fill="#E20613">
              ✖
            </text>


          </g>
        </svg>
      </div>


      <div class="w-100 mt-4 d-flex flex-column align-items-center" style="max-width: 80%;">

        <div *ngIf="certWizard.learnMode"
             class="w-100 pb-2 d-flex justify-content-end align-content-end align-items-end">
            <button (click)="navigateLearnView()" type="button" class="w-100 btn aeonik-regular overflow-break action-btn"
                    [style.background-color] = "certWizard.color"
                    [style.border-color] = "certWizard.color"
                    [style.color] = "certWizard.fontColor">
              <h3 class="m-0">
                {{'learn-mode'|translate}}
              </h3>
            </button>
        </div>


        <button *ngIf="userStat.prerequisiteLearnAcquired"
                (click)="getCertificateModal()"
                type="button" class="w-100 btn btn-block aeonik-regular fade-in-animation overflow-break action-btn"
                [style.background-color] = "certWizard.color"
                [style.border-color] = "certWizard.color"
                [style.color] = "certWizard.fontColor"
                [disabled]="certLoading || waitForStats">
          <h3 *ngIf="!certLoading" class="m-0">
            {{'to'|translate}} {{'learn-proof'|translate}}
          </h3>
          <div class="d-flex align-content-center align-items-center justify-content-center">
            <div *ngIf="certLoading" class="lds-dual-ring"></div>
          </div>
        </button>

        <button *ngIf="!userStat.prerequisiteLearnAcquired"
                type="button" class="w-100 btn btn-block aeonik-regular fade-in-animation overflow-break action-btn"
                placement="top" ngbTooltip="{{'requirements-not-reached'|translate}}" triggers="click"
                [style.background-color] = "'grey'"
                [style.border-color] = "'grey'"
                [style.color]="'white'">
          <h3 class="m-0">
            {{'learn-proof'|translate}}
          </h3>
        </button>



          <button *ngIf="userStat.prerequisiteLearnAcquired && certBlob && !certLoading" (click)="downloadCert()"
                  type="button" class="w-100 btn btn-block aeonik-regular fade-in-animation overflow-break action-btn"
                  [style.background-color] = "certWizard.color"
                  [style.border-color] = "certWizard.color"
                  [style.color] = "certWizard.fontColor">
            <h3 class="m-0">
              {{'download-learn-proof'|translate}}
            </h3>
          </button>

      </div>


      <div *ngIf="!userStat.prerequisiteLearnAcquired" class="mt-4 cert-progress-section">
        <p class="text-center">
            <span class="">{{userStat.cardsTowardsCertificate}}
              <span *ngIf="userStat.cardsTowardsCertificate == 1">
              {{'learn-card'|translate}}
              </span>
              <span *ngIf="userStat.cardsTowardsCertificate > 1">
              {{'learn-card-plural'|translate}}
              </span>
            </span>
          {{'till-cert'|translate}}
        </p>
      </div>


      <div class="mt-4">

      <div *ngIf="dummy" class="register-section d-flex flex-row justify-content-center align-items-center">
        <p class="mr-2">
          {{'register-hint'|translate}}
        </p>


          <button (click)="createCertWizUserModal()" type="button"
                  class="btn aeonik-regular overflow-break action-btn"
                  [style.background-color] = "'white'"
                  [style.border-color] = "certWizard.color"
                  [style.color]="certWizard.color">
            <p class="m-0">
              {{'register'|translate}}
            </p>
          </button>
      </div>


      <footer *ngIf="!sawFooter"
           [style.background-color]="certWizard.color"
           [style.width]="wrapperWidth + 'px'">

        <!-- Copyright -->
        <div class="text-center py-3"
             [style.color] = "certWizard.fontColor">
          <a href="https://easy-learner.de/"
             [class.marker]="isHexDark(certWizard.color)"
             class="underline"
          >easy-learner,</a> {{'web-app-of'|translate}}
          <br>
          <sup>©</sup>Little Helper Verlag 2006-{{currentYear}}
        </div>
        <!-- Copyright -->

      </footer>

      <footer *ngIf="sawFooter"
           class="d-flex flex-row align-items-center justify-content-between"
             [style.background-color] = "certWizard.color"
             [style.width]="wrapperWidth + 'px'">

          <div class="text-center py-3"
               [style.width]="wrapperWidth/2 + 'px'"
               [style.color] = "certWizard.fontColor">
            <a href="https://easy-learner.de/"
               class="underline"
            >EASY-LEARNER</a>
          </div>

          <div class="text-center py-3"
               [style.width]="wrapperWidth/2 + 'px'"
               [style.color] = "certWizard.fontColor">
            <a [href]="sawSelectedLink"
               class="underline"
            >SAFE AT WORK</a>
          </div>

        </footer>

    </div>


  </div>
</div>

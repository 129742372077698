import {Component, Input} from '@angular/core';
import {UserStat} from '../../stats/user-stat.model';
import * as moment from 'moment';
import {Router} from '@angular/router';

@Component({
  selector: 'user-stats-ktracker',
  templateUrl: './user-stats-ktracker.component.html',
  styleUrls: ['./user-stats-ktracker.component.css']
})
export class UserStatsKTrackerComponent {

  @Input() userStat: UserStat;

  constructor(private router: Router) {
  }

  startKTracker(kTrackerStat) {
    //http://localhost:4200/#/learn/ktracker/3
    this.router.navigate(['/learn/ktracker', kTrackerStat.ktrackerId]);
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment'
import {User} from '../user/user.model';
import {KTracker} from './ktracker.model';
import {KTrackerCard} from './ktracker-card.model';
import {KTrackerCardAnswer} from './ktracker-card-answer.model';
import {Log} from 'ng2-logger'
import {HttpClient} from '@angular/common/http';

@Injectable()
export class KTrackerService {
  log = Log.create('KTrackerService');

  resource: string = environment.backendApi + '/users/';

  constructor(private http: HttpClient) {
  }

  findAllByUser(user: User): Observable<KTracker[]> {
    return this.http.get<KTracker[]>(this.resource + user.id + '/ktrackers')
      .map(data => {
        this.log.info(<any>data);
        return data.map(item => KTracker.createWith(item));
      });
  }

  findByUserAndId(user: User, id: string): Observable<KTracker> {
    return this.http.get<KTracker>(this.resource + user.id + '/ktrackers/' + id)
      .map(data => {
        this.log.info('', data);
        return KTracker.createWith(data);
      });
  }

  persist(user: User, kTracker: KTracker): Observable<string> {
    return this.http.post<string>(this.resource + user.id + '/ktrackers', kTracker)
      .map(id => {
        this.log.info(id);
        return id;
      });
  }

  update(user: User, kTracker: KTracker): Observable<boolean> {
    return this.http.post<boolean>(this.resource + user.id + '/ktrackers/'
      + kTracker.id, kTracker)
      .map(res => {
        this.log.info('', res);
        return true;
      });
  }

  delete(user: User, id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.resource + user.id + '/ktrackers/' + id)
      .pipe(
        map(data => {
          return true;
        }),
        catchError((err, obs) => {
          return obs;
        })
      );
  }

  requestKTrackerCard(user: User, kTracker: KTracker, cardId: string): Observable<KTrackerCard> {
    return this.http.get<KTrackerCard>(this.resource + user.id + '/ktrackers/' + kTracker.id + '/cards/' + cardId)
      .map(data => {
        this.log.info('', data);
        return KTrackerCard.createWith(data);
      });
  }

  persistCardAnswer(kTrackerCardAnswer: KTrackerCardAnswer): Observable<boolean> {
    this.log.info(<any>'send ktracker card answer: ', kTrackerCardAnswer);
    return this.http.post<boolean>(this.resource + kTrackerCardAnswer.userId + '/ktrackers/'
      + kTrackerCardAnswer.ktrackerId + '/card-answers', kTrackerCardAnswer)
      .map(res => {
        this.log.info(<any>res);
        return true;
      });
  }

  testKTracker(user: User, id: string): Observable<KTracker> {
    return this.http.get<KTracker>(
      this.resource + user.id + '/ktrackers/' + id,
      {params: {'test': 'true'}})
      .map(data => {
        this.log.info('', data);
        return KTracker.createWith(data);
      });
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">{{'import-user'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">

  <form (ngSubmit)="submitCsvData($event)" autocomplete="off"
        autocomplete="false"
        *ngIf="auth && !loadingService.isOpen()"
        name="user-import">

    <div class="form-group row" *ngIf="auth.user.role == 'admin' && filteredUsers.length==0">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_client">{{'client'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <select id="user_client"
                [(ngModel)]="selectedClient"
                class="custom-select"
                style="width: initial"
                name="user[client]">
          <option *ngFor="let c of clients" [ngValue]="c">{{c.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row"
         [class.has-danger]="errorsCsvData.get('csvData') != undefined"
         *ngIf="filteredUsers.length==0">
      <div class="col-12">
        <textarea class="form-control form-control-danger" [(ngModel)]="csvData"
                  autocomplete="off"
                  placeholder="{{'csv-data'|translate}}"
                  rows="15" name="csv-data" id="csv-data">
        </textarea>
        <div class="form-control-feedback">{{errorsCsvData.get('csvData')|translate}}</div>
      </div>
    </div>

    <div class="form-group row"
         *ngIf="filteredUsers.length==0">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-outline-success" type="submit">
          <span>{{'csv-data-validate'|translate}}</span>
        </button>
      </div>
    </div>


  </form>

  <div class="row fade-in-1" *ngIf="filteredUsers.length && !loadingService.isOpen() && !userImported">
    <div class="col-12 mb-2">
      {{'records'|translate:{count:filteredUsers.length} }}
      <span *ngIf="errorCount > 0" class="text-danger">
        {{'faulty-records'|translate:{count:errorCount} }}
      </span>
    </div>
    <div class="col-12">

      <div class="card">
        <div class="table-responsive" style="min-height: 80vh">
          <form (ngSubmit)="submitUserList($event)" autocomplete="off"
                autocomplete="false"
                name="user-list">
            <table class="table table-hover mb-0">
              <thead>
              <tr>
                <th class="border-top-0"><!--idx--></th>
                <th class="border-top-0"><!--status--></th>
                <th class="border-top-0">{{'name'|translate}}</th>
                <th class="border-top-0">{{'forename'|translate}}</th>
                <th class="border-top-0 d-none d-md-table-cell">{{'email'|translate}}</th>
                <th class="border-top-0 d-none d-md-table-cell">{{'user-name'|translate}}</th>
                <th class="border-top-0">{{'language'|translate}}</th>
                <th class="border-top-0">{{'role'|translate}}</th>
                <th class="border-top-0">{{'client'|translate}}</th>
                <th class="border-top-0"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let user of filteredUsers; trackBy:trackByKey; let idx = index"
                  [class.table-danger]="testResult[idx] != true"
                  [class.fade-in-1]="selectedUserIdx == idx"
                  (click)="edit(idx)">
                <td>{{idx+1}}</td>
                <td *ngIf="selectedUserIdx != idx"><i class="fa fa-check" aria-hidden="true"
                                                      [class.fa-check]="user.active"
                                                      [class.text-success]="user.active"
                                                      [class.fa-times]="!user.active"
                                                      [class.text-danger]="!user.active">

                </i>
                </td>
                <td *ngIf="selectedUserIdx == idx">
                  <input type="checkbox" name="user-{{idx}}[active]" [(ngModel)]="user.active">
                </td>
                <td *ngIf="selectedUserIdx != idx">{{user.name}}</td>
                <td *ngIf="selectedUserIdx == idx">
                  <input autocomplete="off"
                         class="form-control form-control-danger" type="text" name="user-{{idx}}[name]"
                         [(ngModel)]="user.name">
                  <p class="text-danger" *ngIf="testResult[idx].name">
                    {{testResult[idx].name|translate}}
                  </p>
                </td>
                <td *ngIf="selectedUserIdx != idx">{{user.forename}}</td>
                <td *ngIf="selectedUserIdx == idx">
                  <input autocomplete="off"
                         class="form-control form-control-danger" type="text" name="user-{{idx}}[forname]"
                         [(ngModel)]="user.forename">
                  <p class="text-danger" *ngIf="testResult[idx].forename">
                    {{testResult[idx].forename|translate}}
                  </p>
                </td>
                <td class="text-nowrap text-truncate" *ngIf="selectedUserIdx != idx">{{user.email}}</td>
                <td *ngIf="selectedUserIdx == idx">
                  <input autocomplete="off"
                         class="form-control form-control-danger" type="text" name="user-{{idx}}[email]"
                         [(ngModel)]="user.email">
                  <p class="text-danger" *ngIf="testResult[idx].email">
                    {{testResult[idx].email|translate}}
                  </p>
                </td>
                <td class="text-nowrap text-truncate" *ngIf="selectedUserIdx != idx">{{user.login}}</td>
                <td *ngIf="selectedUserIdx == idx">
                  <input autocomplete="off"
                         class="form-control form-control-danger" type="text" name="user-{{idx}}[login]"
                         [(ngModel)]="user.login">
                  <p class="text-danger" *ngIf="testResult[idx].login">
                    {{testResult[idx].login|translate}}
                  </p>
                </td>
                <td class="text-nowrap text-truncate" *ngIf="selectedUserIdx != idx">{{user.lang|translate}}</td>
                <td *ngIf="selectedUserIdx == idx">
                  <select id="user_lang" [(ngModel)]="user.lang" style="min-width: 2rem"
                          class="custom-select" name="user_lang">
                    <option *ngFor="let lang of supportedLangList" [value]="lang">{{lang|translate}}</option>
                  </select>
                  <p class="text-danger" *ngIf="testResult[idx].lang">
                    {{testResult[idx].lang|translate}}
                  </p>
                </td>
                <td>{{user.role}}</td>
                <td class="text-nowrap text-truncate">{{user.client.name}}</td>
                <td class="text-right text-nowrap">
                  <button type="button" class="btn btn-link btn-table"
                          (click)="delete(user,$event)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="form-actions d-flex justify-content-end" *ngIf="errorCount == 0">
              <button class="btn btn-outline-success" type="submit">
                <span>{{'save'|translate}}</span>
              </button>
            </div>
            <div class="form-actions d-flex justify-content-end" *ngIf="errorCount > 0">
              <button class="btn btn-outline-success" type="submit">
                <span>{{'validate-again'|translate}}</span>
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>

  <h4 class="fade-in-1" *ngIf="userImported">
    <i class="fa fa-check fa-3x" aria-hidden="true" style="color: green;"></i>
    {{'user-import-finished'|translate:{userCount:users.length} }}
  </h4>
</div>

import {UserCard} from '../module/user-card.model';
export class PhaseIntervall {

  public static SECONDS_A_DAY = 86400;
  /*
   Phase intervals in days
   */
  public static PHASE_DAYS: number[] = [0, 3, 10, 30, 65];

  public static isDueCard(userCard: UserCard, unixTime: number): boolean {
    if (userCard.phase <= 1 || userCard.lastLearnt == null) {
      return true;
    }

    if (userCard.phase >= 6) {
      return false;
    }

    return (userCard.lastLearnt + PhaseIntervall.PHASE_DAYS[userCard.phase - 1] * PhaseIntervall.SECONDS_A_DAY) < unixTime;
  }
}

import {Component, OnDestroy} from '@angular/core';
import {User} from '../user.model';
import {UserService} from '../user.service';
import {LoadingService} from '../../util/loading/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {FileUploader} from 'ng2-file-upload';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {Language} from '../../util/language.model';
import {TitleService} from '../../util/title/title.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnDestroy {

  static supportedLanguages: string[] = ['en', 'de', 'ind'];

  public uploader: FileUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });

  user: User;
  errors: Map<string, string> = new Map();
  auth: any;
  languages: Language[];
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private userService: UserService,
              public loadingService: LoadingService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private titleService: TitleService) {
    titleService.title = 'to-profile';
    this._initLanguages();
    this._sHelper.sub = this.authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth) {
        this._init();
      }
    });
  }

  private _init() {
    this.user = User.createWith(this.auth.user);

    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);
    this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    this.uploader.onCompleteAll = this.onCompleteAll.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
  }

  onSuccessItem(item: any, response: any, status: any, headers: any): any {
    if (headers.location) {
      this.user.img = headers.location;
    }
    console.log({item, response, status, headers});
  }

  onBeforeUploadItem(fileItem): any {
    this.errors.delete('error');
    this.errors.delete('img-load');
    this.loadingService.open();
  }

  onCompleteAll(): any {
    this.loadingService.dismiss();
  }

  onErrorItem(item, response, status, headers): any {
    let errorJSON = null;
    try {
      errorJSON = JSON.parse(response);
    } catch (e) {
      this.errors.set('error', 'img-upload-error');
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set(k, errorJSON[k]);
        });
    }
    console.log(item, response, status, headers);
  }

  submit(event) {
    this.loadingService.open();

    if (!this.user.login || this.user.login.trim() === '') {
      this.errors.set('login', '');
    } else {
      this.user.login = this.user.login.trim();
      this.errors.delete('login');
    }

    if (!this.user.email || this.user.email.trim() === '') {
      this.errors.set('email', '');
    } else {
      this.user.email = this.user.email.trim();
      if (this._validateEmail()) {
        this.errors.delete('email');
      } else {
        this.errors.set('email', 'email-error');
      }
    }

    if (!this.user.name || this.user.name.trim() === '') {
      this.errors.set('name', '');
    } else {
      this.user.name = this.user.name.trim();
      if (this.user.name.length >= 2 && this.user.name.length <= 100) {
        this.errors.delete('name');
      } else {
        this._sHelper.sub = this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('name', res);
          });
      }
    }

    if (!this.user.forename || this.user.forename.trim() === '') {
      this.errors.set('forename', '');
    } else {
      this.user.forename = this.user.forename.trim();
      if (this.user.forename.length >= 2 && this.user.forename.length <= 100) {
        this.errors.delete('forename');
      } else {
        this._sHelper.sub = this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('forename', res);
          });
      }
    }

    this.errors.delete('password');
    if (this.user.password && this.user.password.trim().length > 0) {
      this.user.password = this.user.password.trim();
      if (this.user.password.length < 6 || this.user.password.length > 20) {
        this._sHelper.sub = this.translate.get('error-length', {min: '6', max: '20'}).subscribe(
          res => {
            this.errors.set('password', res);
          });
      }
    }

    if (this.errors.size == 0) {
      this.user.client = this.auth.client;
      this._sHelper.sub = this.userService.update(this.user).subscribe(
        updated => {
          if (updated) {
            // refresh auth object
            Object.assign(this.auth.user, this.user);
            this.translate.use(this.user.lang);
            this.tooltipService.show({translateKey: 'update-success'});
          }
          this.loadingService.dismiss();
        }, error => {
          console.log(error);
          this._processServerErrors(error);
          this.loadingService.dismiss();
        });
    } else {
      this.loadingService.dismiss();
    }

  }

  private _initLanguages() {
    this.languages = [];
    this.translate.get(UserProfileComponent.supportedLanguages).subscribe(
      t => {
        Object.keys(t).forEach(
          k => {
            this.languages.push(new Language(k, t[k]));
          }
        );
        this.languages.sort(Language.sortByName);
      }
    );
  }

  private _processServerErrors(error) {
    let errorJSON = null;
    try {
      errorJSON = error.json();
    } catch (e) {
      this.errors.set('form', error.statusText);
      //console.error(e);
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set(k, errorJSON[k]);
        });
    }
  }

  clearImage() {
    this.user.img = null;
    this.errors.delete('error');
  }

  private _validateEmail(): boolean {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.user.email);
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

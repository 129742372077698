import { CertWizardModuleStat } from './cert-wizard-module-stat';

export class CertWizardUserStat {
  // timestamp stat was generated
  public timeStamp = 0;

// certWizardId
  public certWizardId = 0;
// userId
  public userId = 0;

// card count
  public cardCount = 0;

// card count requested
  public cardCountRequested = 0;

// card count answered
  public cardCountAnswered = 0;

// card count of learning cards
  public learnCardCount = 0;

// card count requested of learning cards
  public learnCardCountRequested = 0;

// card count answered of learning cards
  public learnCardCountAnsweredCorrect = 0;

  public learnCardCountAnsweredWrong = 0;

  public learnCardCountUnanswered = 0;

// cumulative average answer time
  public averageAnswerTime = 0;

// exam flag
  public exam = false;

// percentage answered for certificate or exam (depends on exam flag)
  public percentageCorrect = 0;

  public percentageWrong = 0;

  public percentageUnanswered = 0;


  public cardsTowardsCertificate = 0;

  public prerequisiteLearnAcquired = false;

  public prerequisiteCertAcquired = false;

  public certPenaltySecLeft = 0

// List of CertWizardModuleStat
  public certWizardModuleStats: CertWizardModuleStat[] = [];
}

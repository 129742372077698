import {
  Component,
  OnDestroy,
  AfterViewInit,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';


@Component({
  selector: 'app-editor',
  template: `<editor #editorComponent [(ngModel)]="html" (ngModelChange)="change.emit(html)"
    [init]="{
    menubar: false,
    statusbar: false,
    plugins: [
     'lists link paste help wordcount'
    ],
    toolbar: 'undo redo | link | formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | help'
  }"
  ></editor>`
})
export class EditorComponent implements AfterViewInit, OnDestroy {
  @Input() elementId: String;
  @Input() value: String;
  @Output() change = new EventEmitter<any>();

  public html: String;

  ngAfterViewInit() {
    this.html = this.value;
  }

  ngOnDestroy() {
  }
}

export class ClientStat {

  serverUnixTime: number = null;
  clientId: string = null;
  userCount: number = null;

  constructor() {
  }

  static createWith(obj: any): ClientStat {
    let newObj = new ClientStat();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

}

import {Component, OnDestroy} from '@angular/core';
import {Asad} from '../asad.model';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {AsadService} from '../asad.service';
import {LoadingService} from '../../util/loading/loading.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {AuthService} from '../../auth/auth.service';
import {Router, ActivatedRoute} from '@angular/router';
import {UserAsad} from '../user-asad.model';
import {DeleteConfirmComponent} from '../../util/confirm-modal/delete-confirm.component';
import {AsadUserComponent} from '../asad-user/asad-user.component';
import {AsadStat} from '../../stats/asad-stat.model';
import {StatsService} from '../../stats/stats.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {ConfirmComponent} from '../../util/confirm-modal/confirm.component';

@Component({
  selector: 'app-asad-list',
  templateUrl: './asad-list.component.html',
  styleUrls: ['./asad-list.component.css']
})
export class AsadListComponent implements OnDestroy {

  asads: Asad[] = [];
  filteredAsads: Asad[] = [];
  stats: Map<string, AsadStat> = new Map();
  auth: any;
  searchQuery = '';

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private asadService: AsadService,
              private statsService: StatsService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private tooltipService: TooltipService,
              public authService: AuthService,
              private router: Router,
              private route: ActivatedRoute) {
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  _init() {
    this.loadingService.open();
    this._sHelper.sub = this.asadService.findAllByUser(this.auth.user).subscribe(
      asads => {
        this.asads = asads.sort(Asad.sortByName);
        this.doSearch();
        this.loadingService.dismiss();

        // get module stats
        this.statsService.findAsadStatsByUserAndAsadIds(this.auth.user, asads).subscribe(
          stats => stats.forEach(stat => this.stats.set(stat.asadId, stat)));

      },
      e => {
        this.loadingService.dismiss();
      }
    );
  }

  doSearch() {
    this.filteredAsads = this.asads.filter(
      (item: Asad) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.description && !match) {
            match = item.description.toLowerCase().indexOf(query) != -1;
          }

        }

        return match;
      });
  }

  getOwner(asad: Asad) {
    if (asad.user.id == this.auth.user.id) {
      return 'me';
    } else {
      return asad.user.forename + ' ' + asad.user.name
    }
  }

  getPermission(asad: Asad) {
    let permission = '';
    asad.userAsads.forEach((item: UserAsad) => {
      if (item.user.id == this.auth.user.id) {
        permission = item.permission;
      }
    });
    return permission.toLowerCase();
  }

  getCardCount(asad: Asad) {
    if (this.stats.get(asad.id)) {
      return this.stats.get(asad.id).cardCount;
    }
    return 0;
  }

  edit(asad) {
    this.router.navigate(['/asads', asad.id]);
  }

  test(asad, event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(ConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'sent-test-asad';
    modalRef.result.then(
      yes => {
        if (yes) {
          this.loadingService.open();
          this._sHelper.sub = this.asadService.testAsad(this.auth.user, asad.id).subscribe(
            asad => {
              console.log('Asad test sent! id: ' + asad.id);
            },
            e => console.error(e),
            () => this.loadingService.dismiss()
          );
        }
      })
  }

  delete(asad, event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(DeleteConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'delete-text';
    modalRef.componentInstance.params = {name: asad.name};
    modalRef.result.then(
      (deleteItem) => {
        if (deleteItem) {
          this._sHelper.sub = this.asadService.delete(this.auth.user, asad.id).subscribe(
            deleted => {
              if (deleted) {
                // delete item in local array
                let delFn = (item, i, arr) => item.id === asad.id ? arr.splice(i, 1) : false;
                this.asads.some(delFn);
                this.doSearch();
                this.tooltipService.show({translateKey: 'delete-success'});
              } else {
                this.tooltipService.show({
                  translateKey: 'delete-error',
                  type: 'danger',
                  stay: true
                });
              }
            });
        }
      });
  }

  editUserAsad(asad, event) {
    event.stopPropagation();
    let modalRef: NgbModalRef = this.modalService.open(AsadUserComponent);
    modalRef.componentInstance.asad = asad;
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

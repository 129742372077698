<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">{{'modules'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">

    <div class="col order-0" style="margin-bottom: 15px">
      <div class="dropdown">
        <button class="btn btn-outline-success dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-plus" aria-hidden="true"></i>
          {{'new-module'|translate}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" routerLink="/modules/newOneSide">
            <img [src]="'assets/img/ONE_SIDE.png'"/>
            {{'ONE_SIDE'|translate}}
          </a>
          <a class="dropdown-item" routerLink="/modules/newTwoSide">
            <img [src]="'assets/img/TWO_SIDE.png'"/>
            {{'TWO_SIDE'|translate}}
          </a>
          <a class="dropdown-item" routerLink="/modules/newMC">
            <img [src]="'assets/img/MULTIPLE_CHOICE.png'"/>
            {{'MULTIPLE_CHOICE'|translate}}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/public/modules">
            {{'search-public-area'|translate}}
          </a>
        </div>
      </div>
    </div>
    <div class="col col-sm-5 col-lg-4 order-1 text-sm-right" style="margin-bottom: 15px">
      <searchbar class="float-sm-right" [(query)]="searchQuery" (input)="doSearch()"
                 placeholder="{{'search'|translate}} {{'modules'|translate}}"></searchbar>
    </div>
  </div>
  <div class="row">
    <div class="col-12">

      <div class="card">
        <div class="table-responsive" *ngIf="filteredModules.length">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th class="border-top-0"><!--type--></th>
              <th class="border-top-0">{{'name'|translate}}</th>
              <th class="border-top-0 d-none d-md-table-cell">{{'description'|translate}}</th>
              <th class="border-top-0">{{'owner'|translate}}</th>
              <th class="border-top-0">{{'permission'|translate}}</th>
              <th class="border-top-0"><!--actions--></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let module of filteredModules; trackBy:trackByKey" (click)="learn(module,$event)">
              <td>
                <div style="width: 80px" class="d-flex justify-content-between">
                  <img [src]="'assets/img/'+module.type+'.png'" style="height: 32px"/>
                  <div class="text-primary-2 text-right align-self-center">
                    {{getModuleCardCount(module)}}
                  </div>
                </div>
              </td>
              <td>{{module.name}}</td>
              <td class="d-none d-md-table-cell">{{module.description}}</td>
              <td>{{getModuleOwner(module)|translate}}</td>
              <td>{{getModulePermission(module)|translate}}</td>
              <td>
                <div class="d-flex justify-content-end">
                  <span class="mr-1 text-primary-2"
                        *ngIf="getModuleOwner(module) == 'me'">
                    {{module.userModules.length-1}}
                  </span>
                  <button class="btn btn-link btn-table mr-2 text-primary-2"
                          title="{{'users'|translate}}"
                          (click)="editUserModule(module,$event)"
                          *ngIf="getModuleOwner(module) == 'me'">
                    <i class="fa fa-users" aria-hidden="true"></i>
                  </button>
                  <button class="btn btn-link btn-table mr-2 text-primary-2"
                          title="{{'edit-module'|translate}}"
                          (click)="edit(module,$event)">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <button class="btn btn-link btn-table text-primary-2"
                          title="{{'delete'|translate}}"
                          (click)="delete(module,$event)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="!filteredModules.length">
          <h5 class="card-title text-muted text-center" style="margin-top: 12px">
            {{'empty-search-module'|translate}}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

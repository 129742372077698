import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  selector: '[focusEl]'
})
export class FocusElDirective  implements OnInit {

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    this.el.nativeElement.focus();
    console.log('ngOnInit FocusElDirective')
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {AuthService} from '../../auth/auth.service';
import {LoadingService} from '../../util/loading/loading.service';
import {CertWizardService} from '../cert-wizard.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CertWizard} from '../model/cert-wizard';
import {CertWizardStat} from '../model/cert-wizard-stat';
import {ScaleType} from '@swimlane/ngx-charts';
import * as lodash from 'lodash';
import domtoimage from 'dom-to-image';
import {ChartType} from '../model/chart-type';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cert-wizard-stats',
  templateUrl: './cert-wizard-stats.component.html',
  styleUrls: ['./cert-wizard-stats.component.css']
})
export class CertWizardStatsComponent implements OnInit, OnDestroy {

  private sHelper: SubscriptionHelper = new SubscriptionHelper;
  public auth: any;
  public certWizard: CertWizard;
  public certWizardStat: CertWizardStat;

  public statsUser = [];
  public statsUserNames: {translationKey: string, value: string | number}[] = [];

  public statsUserDevice = [];
  public statsUserDeviceNames: {translationKey: string, value: string | number}[] = [];

  public statsAnswers = [];
  public statsAnswersNames: {translationKey: string, value: string | number}[] = [];

  public statsCert = [];
  public statsCertNames: {translationKey: string, value: string | number}[] = [];

  public statsUserLang = [];
  public statsUserLangNames: {translationKey: string, value: string | number}[] = [];

  public statsRequested = [];
  public statsRequestedNames: {translationKey: string, value: string | number}[] = [];


  palette1 = [
    '#F26419', '#F6AE2D', '#758E4F', '#86BBD8', '#33658A',
    '#214E9C', '#7AA7E6', '#5F7E5D', '#AE8074', '#A54764'
  ]

  palette2 = [
    '#001219', '#005f73', '#0A9396', '#94D2BD', '#E9D8A6',
    '#EE9B00', '#CA6702', '#BB3E03', '#AE2012', '#9B2226'
  ]

  palette3 = [
    '#F94144', '#F3722C', '#F8961E', '#F9844A', '#F9C74F',
    '#90BE6D', '#43AA8B', '#4D908E', '#577590', '#277DA1'
  ]

  public colorScheme: any = {
    selectable: false,
    group: ScaleType.Linear,
    name: 'right',
    domain: [
      '#F26419', '#F6AE2D', '#758E4F', '#86BBD8', '#33658A',
      '#214E9C', '#7AA7E6', '#5F7E5D', '#AE8074', '#A54764'
    ]
  }

  public selection = ChartType.barVertical;
  public downloading = false;


  public constructor(public authService: AuthService,
                     public loadingService: LoadingService,
                     private readonly certWizardService: CertWizardService,
                     private readonly route: ActivatedRoute,
                     private readonly router: Router,
                     private readonly translate: TranslateService) {
    this.sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        void this.init();
      }
    });
  }

  private async init(): Promise<void> {
    this.loadingService.open();
    let certWizardId = this.route.snapshot.params['id'];
    await this.certWizardService.findByUserAndId(this.auth.user, certWizardId)
      .then((cw: CertWizard) => {
        this.certWizard = cw;
      })
      .catch(() => {
        this.loadingService.dismiss();
        void this.router.navigate(['message/not-found']);
      });
    this.certWizardStat = await this.certWizardService.geCumulativeStats(this.auth.user, this.certWizard);

    // --------------------------------------------------------------------------------
    this.statsUser = [
      { name: await this.translate.get('registered').toPromise(), value: this.certWizardStat.registeredUserSum },
      { name: await this.translate.get('unregistered').toPromise(), value: this.certWizardStat.unregisteredUserSum },
    ];
    this.statsUserNames = [
      {translationKey: 'user-count', value: this.certWizardStat.userSum},
      {translationKey: 'user-count-registered', value: this.certWizardStat.registeredUserSum},
      {translationKey: 'user-count-unregistered', value: this.certWizardStat.unregisteredUserSum}
    ];
    // --------------------------------------------------------------------------------


    // --------------------------------------------------------------------------------
    if (this.certWizardStat.unknownDeviceSum > 0) {
      this.statsUserDevice = [
        { name: await this.translate.get('phone').toPromise(), value: this.certWizardStat.mobileUserSum },
        { name: await this.translate.get('tablet').toPromise(), value: this.certWizardStat.tabletUserSum },
        { name: await this.translate.get('desktop').toPromise(), value: this.certWizardStat.desktopUserSum },
        { name: await this.translate.get('unknown').toPromise(), value: this.certWizardStat.unknownDeviceSum },
      ];
      this.statsUserDeviceNames = [
        {translationKey: 'phone', value: this.certWizardStat.mobileUserSum},
        {translationKey: 'tablet', value: this.certWizardStat.tabletUserSum},
        {translationKey: 'desktop', value: this.certWizardStat.desktopUserSum},
        {translationKey: 'unknown', value: this.certWizardStat.unknownDeviceSum}
      ];
    } else {
      this.statsUserDevice = [
        { name: await this.translate.get('phone').toPromise(), value: this.certWizardStat.mobileUserSum },
        { name: await this.translate.get('tablet').toPromise(), value: this.certWizardStat.tabletUserSum },
        { name: await this.translate.get('desktop').toPromise(), value: this.certWizardStat.desktopUserSum },
      ];
      this.statsUserDeviceNames = [
        {translationKey: 'phone', value: this.certWizardStat.mobileUserSum},
        {translationKey: 'tablet', value: this.certWizardStat.tabletUserSum},
        {translationKey: 'desktop', value: this.certWizardStat.desktopUserSum}
      ];
    }
    // --------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------
    for (const langStat of this.certWizardStat.languageStat) {
      this.statsUserLang.push(
        { name: await this.translate.get(langStat.lang).toPromise(), value: langStat.amount }
      );
      this.statsUserLangNames.push({translationKey: langStat.lang, value: langStat.amount});
    }
    // --------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------
    this.statsRequested = [
      {
        name: await this.translate.get('learn-mode').toPromise(),
        value: this.certWizardStat.learnCardRequestedSum - this.certWizardStat.learnCardRequestedViaAsadSum
      },
      {
        name: await this.translate.get('email').toPromise(),
        value: this.certWizardStat.learnCardRequestedViaAsadSum
      },
    ];
    this.statsRequestedNames = [
      {translationKey: 'requested-sum', value: this.certWizardStat.learnCardRequestedSum},
      {
        translationKey: 'requested-learn',
        value: this.certWizardStat.learnCardRequestedSum - this.certWizardStat.learnCardRequestedViaAsadSum
      },
      {translationKey: 'requested-mail', value: this.certWizardStat.learnCardRequestedViaAsadSum}
    ];
    // --------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------
    this.statsAnswers = [
      { name: await this.translate.get('correct').toPromise(), value: this.certWizardStat.learnCardAnsweredCorrectSum },
      { name: await this.translate.get('wrong').toPromise(), value: this.certWizardStat.learnCardAnsweredWrongSum },
    ];
    this.statsAnswersNames = [
      {
        translationKey: 'answered-sum',
        value: this.certWizardStat.learnCardAnsweredCorrectSum + this.certWizardStat.learnCardAnsweredWrongSum
      },
      {translationKey: 'answered-correct', value: this.certWizardStat.learnCardAnsweredCorrectSum},
      {translationKey: 'answered-wrong', value: this.certWizardStat.learnCardAnsweredWrongSum}
    ];
    // --------------------------------------------------------------------------------

    // --------------------------------------------------------------------------------
    this.statsCert = [
      { name: await this.translate.get('with-cert').toPromise(), value: this.certWizardStat.userWithCertSum },
      { name: await this.translate.get('without-cert').toPromise(), value: this.certWizardStat.userWithoutCertSum },
    ];
    this.statsCertNames = [
      {translationKey: 'cert-acquired', value: this.certWizardStat.userWithCertSum},
      {translationKey: 'cert-not-acquired', value: this.certWizardStat.userWithoutCertSum},
    ];
    // --------------------------------------------------------------------------------

    this.loadingService.dismiss();
  }

  public async edit(certWizard: CertWizard, event: Event): Promise<void> {
    event.stopPropagation();
    await this.router.navigate(['/manager/cert-wizards', certWizard.id]);
  }

  public shuffleArray<T>(array: T[]): T[] {
    const clone = lodash.cloneDeep(array);
    for (let i = clone.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [clone[i], clone[j]] = [clone[j], clone[i]];
    }
    return clone;
  }

  public async downloadStats(): Promise<void> {
    this.downloading = true;
    setTimeout(async () => {
      domtoimage.toPng(document.getElementById('statsContainer'), { quality: 1 })
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = this.certWizard.name;
          link.href = dataUrl;
          link.click();
          link.remove();
          this.downloading = false;
        }).catch(() => {
        this.downloading = false;
      });
    }, 200);
  }

  public switchChart(selection: string): void {
    this.selection = selection as ChartType;
  }

  public switchColors(palette: string[]): void {
    this.colorScheme.domain = palette;
    this.colorScheme = Object.assign({}, this.colorScheme);
  }

  public ngOnInit() {
  }

  public ngOnDestroy(): void {
    this.sHelper.unsubscribeAll();
  }



}

export class CertWizardListItem {
  public id: string;
  public hash: string;
  public active: boolean;
  public name: string;
  public description: string;
  public asadId: number;
  public created: number;
  public modified: number;
  public deleted: number;

  static sortByName(a: CertWizardListItem, b: CertWizardListItem): number {
    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  static sortByCreated(a: CertWizardListItem, b: CertWizardListItem): number {
    let nameA = a.modified; // ignore upper and lowercase
    let nameB = b.modified; // ignore upper and lowercase
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }
    return 0;
  }
}

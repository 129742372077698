import {Module} from '../module/module.model';
import {KTracker} from './ktracker.model';

export class KTrackerModule {

  id: string = null;
  module: Module = null;

  constructor() {
  }


  static createWith(obj: any): KTrackerModule {
    let newObj = new KTrackerModule();
    Object.keys(newObj).forEach(
      key => {
        if ('module' === key && obj[key]) {
          newObj.module = Module.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

}

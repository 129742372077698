export class Image {

  id: string = null;
  name: string = null;
  code: string = null;

  constructor() {
  }

  static createWith(obj: any): Image {
    let newObj = new Image();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

}

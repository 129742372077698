import {KTracker} from './ktracker.model';
import {User} from '../user/user.model';

export class UserKTracker {

  id: string = null;
  user: User = null;
  kTracker: KTracker = null;

  constructor() {
  }

  static createWith(obj: any): UserKTracker {
    let newObj = new UserKTracker();
    Object.keys(newObj).forEach(
      key => {
        if ('user' === key && obj[key]) {
          newObj.user = User.createWith(obj[key]);
        } else if ('kTracker' === key && obj[key]) {
          newObj.kTracker = KTracker.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

}

import {KTrackerModuleStat} from './ktracker-module-stat.model';

export class KTrackerStat {

  serverUnixTime: number = null;
  ktrackerId: string = null;
  ktrackerName: string = null;
  userId: string = null;
  moduleCount: number = null;
  userCount: number = null;
  dueCardIds: number[] = [];

  cardCount: number = null;
  sentCardCount: number = null;
  sentRequests: number = null;
  averageAnswerTime: number = null;

  correctAnswersCount: number = null;
  wrongAnswersCount: number = null;

  moduleStats: KTrackerModuleStat[] = [];

  constructor() {
  }

  static createWith(obj: any): KTrackerStat {
    let newObj = new KTrackerStat();
    Object.keys(newObj).forEach(
      key => {
        if ('moduleStats' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(um => {
            newObj.moduleStats.push(KTrackerModuleStat.createWith(um));
          })
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

}

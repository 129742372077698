import {Component, OnInit, OnDestroy} from '@angular/core';
import {AuthService} from '../auth.service';
import {LoadingService} from '../../util/loading/loading.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {TitleService} from '../../util/title/title.service';
import {Log} from 'ng2-logger'

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css']
})
export class LoginFormComponent implements OnInit, OnDestroy {

  log = Log.create('LoginFormComponent');

  login: string;
  password: string;
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;
  isError: boolean;
  isErrorLostPassword: boolean;
  isSuccessLostPassword: boolean;
  lostPassword = false;

  constructor(public authService: AuthService,
              public loadingService: LoadingService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: TitleService) {
    titleService.title = 'login';

    this.loadingService.open();
    this._sHelper.sub = this.route.params.subscribe((params: Params) => {
        if (params['token']) {
          AuthService.authToken = 'Bearer ' + params['token'];
          this.authService.autoLogin();
        } else {
          this.loadingService.dismiss();
        }
      },
      (error) => {
        this.loadingService.dismiss();
      });

    this._sHelper.sub = this.authService.auth.subscribe(auth => {
      if (auth) {
        this.router.navigate(['/dashboard']);
        this.loadingService.dismiss();
      }
    });
  }

  ngOnInit(): void {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('bg-gradient');
  }

  submit() {
    this.loadingService.open('login');

    if (this.lostPassword) {
      this._sHelper.sub = this.authService.sendLoginMail(this.login)
        .subscribe(resp => {
          this.log.info(resp);
          this.isErrorLostPassword = false;
          this.isSuccessLostPassword = true;
          this.loadingService.dismiss();

        }, err => {
          this.log.error(err);
          this.isErrorLostPassword = true;
          this.loadingService.dismiss();
        });
    } else {
      this._sHelper.sub = this.authService.login(this.login, this.password)
        .subscribe(resp => {
          this.log.info(resp);
          this.loadingService.dismiss();
          this.router.navigate(['/dashboard']);
        }, err => {
          this.log.error(err);
          this.isError = true;
          this.loadingService.dismiss();
        });
    }
  }

  showLostPassword(event) {
    event.preventDefault();
    this.lostPassword = true;
    this.isError = false;
  }

  cancelLostPassword() {
    this.lostPassword = false;
    this.isErrorLostPassword = false;
  }

  ngOnDestroy() {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove('bg-gradient');
    this._sHelper.unsubscribeAll();
  }

}

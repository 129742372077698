export class ModuleStat {

  serverUnixTime: number = null;
  phasesHoldDays: number[] = [];
  moduleId: string = null;
  lectionCount: number = null;
  cardCount: number = null;
  userCount: number = null;
  activeCardCount: number = null;
  averageLearnTime: number = null;
  favoriteCardCount: number = null;
  dueCardCount: number = null;
  cardCountPhases: number[] = [];
  nextLearnDate: number = null;

  constructor() {
  }

  static createWith(obj: any): ModuleStat {
    let newObj = new ModuleStat();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

}

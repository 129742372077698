import {User} from '../user/user.model';
import {UserKTracker} from './user-ktracker.model';
import {KTrackerModule} from './ktracker-module.model';

export class KTracker {

  id: string = null;
  // creator of this KTracker
  user: User = null;
  name: string = null;
  description: string = null;
  active = true;
  scheduleDays = '0123456';
  scheduleTime = '6';
  cardsPerDay = 1;
  expireTimeHour = 0;
  answerTimeSec = 0;

  created: number = null;
  modified: number = null;
  userKTrackers: UserKTracker[] = [];
  ktrackerModules: KTrackerModule[] = [];

  constructor() {
  }

  static createWith(obj: any): KTracker {
    let newObj = new KTracker();
    Object.keys(newObj).forEach(
      key => {
        if ('userKTrackers' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(um => {
            newObj.userKTrackers.push(UserKTracker.createWith(um));
          })
        } else if ('ktrackerModules' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(um => {
            newObj.ktrackerModules.push(KTrackerModule.createWith(um));
          })
        } else if ('user' === key && obj[key]) {
          newObj.user = User.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

  static sortByName(a: KTracker, b: KTracker): number {
    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}

export class KTrackerLectionStat {

  lectionId: number = null;
  lectionName: string = null;
  cardCount: number = null;
  sentCardCount: number = null;
  sentRequests: number = null;
  averageAnswerTime: number = null;

  correctAnswersCount: number = null;
  wrongAnswersCount: number = null;

  constructor() {
  }

  static createWith(obj: any): KTrackerLectionStat {
    let newObj = new KTrackerLectionStat();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

}

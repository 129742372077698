import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-image-with-loading-spinner',
  templateUrl: './image-with-loading-spinner.component.html',
  styleUrls: ['./image-with-loading-spinner.component.css']
})
export class ImageWithLoadingSpinnerComponent {

  @Input()
  url: string;

  @Input()
  alt: string;

  isLoading: boolean;

  constructor() {
    this.isLoading = true;
  }

  hideLoader() {
    this.isLoading = false;
  }

}

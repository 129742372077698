import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {Module} from '../../module/module.model';
import {ModuleService} from '../../module/module.service';
import {StatsService} from '../../stats/stats.service';
import {ModuleStat} from '../../stats/module-stat.model';
import * as moment from 'moment';

@Component({
  selector: 'module-list-last-activity',
  templateUrl: './module-list-last-activity.component.html',
  styleUrls: ['./module-list-last-activity.component.css']
})
export class ModuleListLastActivityComponent implements OnDestroy {

  modules: Module[] = [];
  filteredModules: Module[] = [];
  stats: Map<string, ModuleStat> = new Map();
  listSectionVisible: boolean[] = [false, false, false, false, false];
  auth: any;
  searchQuery = '';
  today = '';

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private moduleService: ModuleService,
              private statsService: StatsService,
              public authService: AuthService,
              private router: Router) {

    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  _init() {
    this.today = moment().locale(this.auth.user.lang).format('l');
    this._sHelper.sub = this.moduleService.findAllByUser(this.auth.user).subscribe(
      modules => {
        this.modules = modules.sort(this.sortModulesByLastActivity.bind(this));
        this.calcListSections();
        this.doSearch();

        // get module stats
        this.statsService.findModuleStatsByUserAndModuleIds(this.auth.user, modules).subscribe(
          stats => {
            stats.forEach(stat => this.stats.set(stat.moduleId, stat));
            this.calcNextTrainingDayCount();
          });
      },
      e => {
      }
    );
  }

  doSearch() {
    this.filteredModules = this.modules.filter(
      (item: Module) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.description && !match) {
            match = item.description.toLowerCase().indexOf(query) != -1;
          }

        }

        return match;
      });
  }

  sortModulesByLastActivity(a: Module, b: Module): number {
    let now: number = moment().hours(0).minutes(0).seconds(0).milliseconds(0).utc(true).unix();
    let lastActivityA = a.userModule(this.auth.user).lastActivity || now;
    let lastActivityB = b.userModule(this.auth.user).lastActivity || now;

    if (lastActivityA > lastActivityB) {
      return -1;
    }
    if (lastActivityA < lastActivityB) {
      return 1;
    }
    // equal
    return 0;
  }

  calcNextTrainingDayCount() {
    this.modules.forEach(
      module => {
        if (this.stats.get(module.id) && this.stats.get(module.id).nextLearnDate) {
          let now: number = moment().hours(0).minutes(0).seconds(0).milliseconds(0).utc(true).unix();
          let days = moment.duration(this.stats.get(module.id).nextLearnDate - now, 'seconds').asDays();
          (<any>module).nextTrainingDayCount = Math.round(days);
        } else {
          (<any>module).nextTrainingDayCount = 0;
        }
      });

  }

  calcListSections() {
    this.modules.forEach(
      module => {
        let lastActivity = module.userModule(this.auth.user).lastActivity;
        let now: number = moment().hours(0).minutes(0).seconds(0).milliseconds(0).utc(true).unix();
        if (lastActivity == null) {
          lastActivity = now;
        } else {
          lastActivity = moment.unix(lastActivity).hours(0).minutes(0).seconds(0).milliseconds(0).utc(true).unix();
        }
        let days = moment.duration(now - lastActivity, 'seconds').asDays();

        //today
        if (!this.listSectionVisible[0] && days < 1) {
          this.listSectionVisible[0] = true;
          (<any>module).listSection = 0;
        }
        if (!this.listSectionVisible[1] && days >= 1 && days < 2) {
          this.listSectionVisible[1] = true;
          (<any>module).listSection = 1;
        }
        if (!this.listSectionVisible[2] && days >= 2 && days <= 10) {
          this.listSectionVisible[2] = true;
          (<any>module).listSection = 2;
        }
        if (!this.listSectionVisible[3] && days >= 11 && days <= 30) {
          this.listSectionVisible[3] = true;
          (<any>module).listSection = 3;
        }
        if (!this.listSectionVisible[4] && days >= 31) {
          this.listSectionVisible[4] = true;
          (<any>module).listSection = 4;
        }
      });
  }

  learn(module) {
    this.router.navigate(['/learn', module.id]);
  }


  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment'
import {Client} from './client.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ClientService {

  resource = 'clients';

  constructor(private http: HttpClient) {
  }

  findAll(): Observable<Client[]> {
    return this.http.get<Client[]>(environment.backendApi + '/' + this.resource)
      .map(data => {
        console.log(data)
        return data.map(c => Client.createWith(c));
      });
  }

  findById(id: string): Observable<Client> {
    return this.http.get<Client>(environment.backendApi + '/' + this.resource + '/' + id)
      .map(data => {
        console.log(data);
        return Client.createWith(data);
      });
  }

  persist(client: Client): Observable<string> {
    return this.http.post<string>(environment.backendApi + '/' + this.resource, client)
      .map(id => {
        console.log(id);
        return id;
      });
  }

  update(client: Client): Observable<boolean> {
    return this.http.post<boolean>(environment.backendApi + '/' + this.resource + '/' + client.id, client)
      .map(res => {
        console.log(res);
        return true;
      });
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(environment.backendApi + '/' + this.resource + '/' + id)
      .pipe(
        map(data => {
          return true;
        }),
        catchError((err, obs) => {
          return obs;
        })
      );
  }

}

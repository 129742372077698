<div class="modal-header">
  <h4 class="modal-title">
    {{'select-modules'|translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <searchbar class="mb-4" [(query)]="searchQuery" (input)="doSearch()"
             placeholder="{{'search'|translate}} {{'modules'|translate}}">

  </searchbar>
  <div class="scrollable">
    <div class="list-group list-group-flush">
      <span class="list-group-item list-group-item-action d-flex"
            [class.active-1]="selectedModules.get(module.id)"
            *ngFor="let module of filteredModules"
            (click)="toggleModule(module)">
        <div class="mr-auto d-flex">
          <div style="width: 50px">
            <img [src]="'assets/img/'+module.type+'.png'" style="height: 32px"/>
          </div>
          <div class="align-self-center">
          {{module.name}}
          </div>
        </div>
        <span class="text-primary-2"
              [class.text-white]="selectedModules.get(module.id)">
          {{getModuleCardCount(module)}}
        </span>
      </span>
      <div class="text-muted text-center mt-5 h5" *ngIf="filteredModules.length == 0">
        {{'empty-search-module'|translate}}
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end text-capitalize mt-2">
    {{'selected'|translate}}:
    <div class="text-primary-2 text-right" style="width: 20px">{{selectedModules.size}}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-outline-success"
          (click)="submit()"
          [disabled]="selectedModules.size == 0">{{'apply'|translate}}
  </button>
  <button type="button" class="btn btn-secondary" (click)="modal.close()">{{'cancel'|translate}}</button>
</div>

import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class ModalWrapperService {

  private _openState = new BehaviorSubject<boolean>(null);
  openState: Observable<boolean> = this._openState.asObservable();

  constructor(private modalService: NgbModal, ) {
  }

  open(content: any, options?: NgbModalOptions): NgbModalRef {

    if (options) {
      options.backdrop = 'static';
    } else {
      options = {backdrop: 'static'};
    }

    let modalRef: NgbModalRef = this.modalService.open(content, options);
    modalRef.result.then(
      (result) => {
        this._openState.next(false);
      }, () => {
        this._openState.next(false);
      });
    this._openState.next(true);
    return modalRef;
  }

}

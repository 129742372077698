import {Component, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {ModuleService} from '../module.service';
import {LoadingService} from '../../util/loading/loading.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {AuthService} from '../../auth/auth.service';
import {Module, ModuleKind} from '../module.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Language} from '../../util/language.model';
import {Lection} from '../lection.model';
import {Card} from '../card.model';
import {CardMcChoice} from '../card-mc-choice.model';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {ModuleImportComponent} from '../module-import/module-import.component';
import {CardEditorComponent} from '../card-editor/card-editor.component';
import {environment} from '../../../environments/environment';
import {FileUploader} from 'ng2-file-upload';
import {Image} from '../image.model';
import {CardImage} from '../card-image.model';
import {TitleService} from '../../util/title/title.service';
import {UserLection} from '../user-lection.model';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {ConfirmComponent} from '../../util/confirm-modal/confirm.component';
import {UserModule} from '../user-module.model';

@Component({
  selector: 'app-module-detail',
  templateUrl: './module-detail.component.html',
  styleUrls: ['./module-detail.component.css']
})
export class ModuleDetailComponent implements OnDestroy {

  static supportedLanguages: string[] = ['es', 'en', 'de', 'fr'];

  public uploader: FileUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });
  uploadFrontImage = true;
  selectedCardImageUpload: Card;

  dataChanged = false;
  isEditMode: boolean;
  isReadonly = false;
  isPublicPreviewMode = false;

  errors: Map<string, string> = new Map();
  auth: any;
  module: Module;
  languages: Language[];
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;
  private _ngbModalRef: NgbModalRef = null;

  constructor(private moduleService: ModuleService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              public authService: AuthService,
              private titleService: TitleService) {
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  private _init() {
    this.loadingService.open();
    this._sHelper.sub = this.route.params.subscribe((params: Params) => {
        let module_id = parseInt(params['id']);
        if (module_id) {
          this.isPublicPreviewMode = this.route.routeConfig.path.includes('public');
          this.isEditMode = true;
          if (this.isPublicPreviewMode) {
            this._sHelper.sub = this.moduleService.findPublicById(params['id']).subscribe(
              m => {
                this.module = m;
                this.isReadonly = true;
                this._initModule();
              },
              e => console.error(e),
              () => this.loadingService.dismiss()
            );
          } else {
            this._sHelper.sub = this.moduleService.findByUserAndId(this.auth.user, params['id']).subscribe(
              m => {
                this.module = m;
                this.isReadonly = m.permission(this.auth.user) == 'READ';
                this._initModule();
              },
              e => console.error(e),
              () => this.loadingService.dismiss()
            );
          }
        } else {
          this.isEditMode = false;
          this._initNewModule(params);

          this.addLection();
          this.loadingService.dismiss();
        }
      },
      e => {
        console.error(e);
        this.loadingService.dismiss();
      });

    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);
    this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    this.uploader.onCompleteAll = this.onCompleteAll.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
  }

  onSuccessItem(item: any, response: any, status: any, headers: any): any {
    if (response) {
      let img = Image.createWith(JSON.parse(response));
      console.log('img:', img);
      let cardImage = new CardImage();
      cardImage.image = img;
      cardImage.cardFrontImage = this.uploadFrontImage;
      this.selectedCardImageUpload.cardImages.push(cardImage);
      this.dataChanged = true;
    }
    console.log({item, response, status, headers});
  }

  onBeforeUploadItem(fileItem): any {
    //this.loadingService.open();
  }

  onCompleteAll(): any {
    //this.loadingService.dismiss();
  }

  onErrorItem(item, response, status, headers): any {
    console.log(item, response, status, headers);
  }

  private _initNewModule(params: Params) {
    this.titleService.title = 'new-module';
    this.module = new Module();
    this.module.user = this.auth.user;
    this.module.visibility = 'PRIVATE';

    switch (params['id']) {
      case 'newOneSide':
        this.module.type = ModuleKind.ONE_SIDE;
        this._initLanguages();
        break;
      case 'newTwoSide':
        this.module.type = ModuleKind.TWO_SIDE;
        this._initLanguages();
        break;
      case 'newMC':
        this.module.type = ModuleKind.MULTIPLE_CHOICE;
        break;
    }
  }

  private _initModule() {
    this.titleService.title = this.module.name;
    if (this.module.type != 'MULTIPLE_CHOICE') {
      this._initLanguages();
    } else {
      // MULTIPLE_CHOICE
      this.module.lections.forEach(l => {
        l.cards.forEach(c => this.fillMcChoices(c));
      });
    }
  }

  private _initLanguages() {
    this.languages = [];
    this.translate.get(ModuleDetailComponent.supportedLanguages).subscribe(
      t => {
        Object.keys(t).forEach(
          k => {
            this.languages.push(new Language(k, t[k]));
          }
        );
        this.languages.sort(Language.sortByName);
      }
    );
  }

  private addLection() {
    let l = new Lection();
    l.userLections.push(new UserLection());
    l.name = 'Lektion ' + (this.module.lections.length + 1);
    for (let i = 0; i < 10; i++) {
      this.addCard(l);
    }
    this.module.lections.push(l);
    this.dataChanged = true;
  }

  private addCard(lection: Lection) {
    let c = new Card();
    if (this.module.type == 'MULTIPLE_CHOICE') {
      for (let i = 0; i < 6; i++) {
        let mcc = new CardMcChoice();
        c.cardMcChoices.push(mcc);
      }
      c.cardMcChoices[0].correct = true;
    }
    lection.cards.push(c);
    this.dataChanged = true;
  }

  private fillMcChoices(c: Card) {
    for (let i = c.cardMcChoices.length; i < 6; i++) {
      let mcc = new CardMcChoice();
      if (i == 0) {
        mcc.correct = true;
      }
      c.cardMcChoices.push(mcc);
    }
  }

  removeCard(l, idx) {
    l.cards.splice(idx, 1);
    this.dataChanged = true;
  }

  removeLection(idx) {
    this.module.lections.splice(idx, 1);
    this.dataChanged = true;
  }

  openImport() {
    this._ngbModalRef = this.modalService.open(ModuleImportComponent);
    this._ngbModalRef.componentInstance.module = this.module;
    this._ngbModalRef.componentInstance.lections = this.module.lections;
    this._ngbModalRef.result.then(() => {
      this.dataChanged = true;
    }, () => {
    });
  }

  openEditor(c: Card) {
    this._ngbModalRef = this.modalService.open(CardEditorComponent);
    this._ngbModalRef.componentInstance.module = this.module;
    this._ngbModalRef.componentInstance.card = c;
    this._ngbModalRef.result.then(() => {
      this.dataChanged = true;
    }, () => {
    });
  }

  openImageUpload(content, card) {
    this.selectedCardImageUpload = card;
    this._ngbModalRef = this.modalService.open(content);
  }

  removeEmptyCards() {
    this.module.lections.forEach(
      l => {
        l.cards = l.cards.filter(c => c.frontText.trim().length > 0 || c.backText.trim().length > 0)
        l.cards.forEach(c => c.cardMcChoices = c.cardMcChoices.filter(mcc => mcc.text.trim().length > 0));
      }
    )
  }

  removeImage(c: Card, cImgIdx: number) {
    c.cardImages.splice(cImgIdx, 1);
    this.dataChanged = true;
  }

  submit(event) {
    if (!this.dataChanged) {
      this.location.back();
      return;
    }

    this.loadingService.open();
    this.errors.clear();

    if (!this.module.name || this.module.name.trim() === '') {
      this.errors.set('name', '');
      this.errors.set('form', 'fields-required');
    } else {
      this.module.name = this.module.name.trim();
      if (this.module.name.length >= 2 && this.module.name.length <= 100) {
        this.errors.delete('name');
      } else {
        this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('name', res);
          });
      }
    }

    if (this.errors.size == 0) {
      this.removeEmptyCards();
      console.log('submit module: ', this.module);
      if (this.isEditMode) {
        this._sHelper.sub = this.moduleService.update(this.auth.user, this.module).subscribe(
          updated => {
            if (updated) {
              this.tooltipService.show({translateKey: 'update-success'});
              this.location.back();
            }
            this.loadingService.dismiss();
          }, error => {
            console.log(error);
            this._processServerErrors(error);
            this.loadingService.dismiss();
          });
      } else {
        this._sHelper.sub =
          this.moduleService.persist(this.auth.user, this.module).subscribe(
            newId => {
              if (newId) {
                this.tooltipService.show({translateKey: 'create-success'});
                this.location.back();
              }
              this.loadingService.dismiss();
            }, error => {
              this._processServerErrors(error);
              this.loadingService.dismiss();
            });

      }
    } else {
      this.loadingService.dismiss();
    }
  }

  getModulePermission(module: Module) {
    switch (module.visibility) {
      case 'PUBLIC_READ':
        return 'read';
      case 'PUBLIC_WRITE':
        return 'write';
    }
    return 'unknown';
  }

  connectUser(module, event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(ConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'connect-to-module';
    modalRef.componentInstance.translateParams = {name: module.name};
    modalRef.result.then(
      (connectUser) => {
        if (connectUser) {
          let um: UserModule = new UserModule();
          um.user = this.auth.user;
          um.permission = this.getModulePermission(module);
          this._sHelper.sub = this.moduleService.updatePublicUserModule(module, um)
            .subscribe(ok => {
              if (ok) {
                this.tooltipService.show({translateKey: 'action-success'});
                this.location.back();
              } else {
                this.tooltipService.show({
                  translateKey: 'action-error',
                  type: 'danger',
                  stay: true
                });

              }
            });
        }
      })
  }

  private _processServerErrors(error) {
    let errorJSON = null;
    try {
      errorJSON = error.json();
    } catch (e) {
      this.errors.set('form', error.statusText);
      console.error(e);
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set(k, errorJSON[k]);
        });
    }
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

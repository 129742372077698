<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">Knowledge Tracker</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">

    <div class="col order-0" style="margin-bottom: 15px">
      <button class="btn btn-outline-success" type="button" routerLink="/manager/ktrackers/new">
        <i class="fa fa-plus" aria-hidden="true"></i>
        {{'new-ktracker'|translate}}
      </button>
    </div>

    <div class="col col-sm-5 col-lg-4 order-1 text-sm-right" style="margin-bottom: 15px">
      <searchbar class="float-sm-right" [(query)]="searchQuery" (input)="doSearch()"
                 placeholder="{{'search'|translate}}"></searchbar>
    </div>

  </div>
  <div class="row">
    <div class="col-12">

      <div class="card">
        <div class="table-responsive" *ngIf="filteredKTrackers.length">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th class="border-top-0" style="width: 25px;"><!--status--></th>
              <th class="border-top-0 text-nowrap"><!--kTracker stats--></th>
              <th class="border-top-0">{{'name'|translate}}</th>
              <th class="border-top-0 d-none d-md-block">{{'description'|translate}}</th>
              <th class="border-top-0"><!--actions--></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let kTracker of filteredKTrackers" (click)="showStats(kTracker)">
              <td><i class="fa fa-check" aria-hidden="true"
                     [class.fa-check]="kTracker.active"
                     [class.text-success]="kTracker.active"
                     [class.fa-times]="!kTracker.active"
                     [class.text-danger]="!kTracker.active">

              </i>
              </td>
              <td class="text-primary-2 text-nowrap" style="width: 1px;">
                <i class="fa fa-clone" aria-hidden="true"></i>
                {{stats.get(kTracker.id)?.cardCount}}
                <i class="fa fa-users ml-1" aria-hidden="true"></i>
                {{stats.get(kTracker.id)?.userCount}}
                <i class="fa fa-flag-checkered ml-1" aria-hidden="true"></i>
                {{calcKTrackerFinish(kTracker)}}%
              </td>
              <td>{{kTracker.name}}</td>
              <td class="d-none d-md-block text-truncate">{{kTracker.description}}</td>
              <td>
                <div class="d-flex flex-row justify-content-end">
                  <button class="btn btn-link btn-table mr-2 text-primary-2"
                          title="{{'edit-ktracker'|translate }}"
                          (click)="edit(kTracker,$event)">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                  <button class="btn btn-link btn-table mr-2 text-primary-2" (click)="test(kTracker,$event)"
                          *ngIf="auth.user.role == 'admin'">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  </button>
                  <button class="btn btn-link btn-table text-primary-2"
                          title="{{'delete'|translate }}"
                          (click)="delete(kTracker,$event)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="!filteredKTrackers.length">
          <h5 class="card-title text-muted text-center" style="margin-top: 12px">
            {{'empty-search-ktracker'|translate}}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

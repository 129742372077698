import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../util/title/title.service';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.css']
})
export class TosComponent implements OnInit {

  constructor(private titleService: TitleService) {
    titleService.title = 'tos';
  }

  ngOnInit() {
  }

}

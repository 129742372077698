import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LoadingService} from '../../util/loading/loading.service';
import {AuthService} from '../../auth/auth.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../user/user.model';
import {CertWizardUserStat} from '../model/cert-wizard-user-stat';
import {Router} from '@angular/router';
import {CertWizard} from '../model/cert-wizard';
import {CountdownEvent} from 'ngx-countdown';
import {Auth} from '../../auth/auth';
import {SubscriptionHelper} from '../../util/subscription-helper';
import * as lodash from 'lodash'
import {UserRole} from '../../user/user-role';

@Component({
  selector: 'app-cert-wizard-certificate-modal',
  templateUrl: './cert-wizard-certificate-modal.component.html',
  styleUrls: ['./cert-wizard-certificate-modal.component.css']
})
export class CertWizardCertificateModalComponent implements OnInit, OnDestroy {

  @Input() userStat: CertWizardUserStat = new CertWizardUserStat();
  @Input() certWizard: CertWizard = new CertWizard();
  @Input() token = null;
  @Input() availableLang: string[] = [];

  public user: User = new User();
  public showLangSelect = false;
  public userHasName = false;

  public showForm = false;
  public examNecessary = false;
  public repeatExam = false;
  public timerActive = false;

  public errors: Map<string, string> = new Map();

  private sHelper: SubscriptionHelper = new SubscriptionHelper;

  public constructor(
    private readonly loadingService: LoadingService,
    private readonly authService: AuthService,
    private readonly activeModal: NgbActiveModal,
    private readonly router: Router,
  ) {
    this.user.forename = '';
    this.user.name = '';
  }



  public async generateCertificate(): Promise<void> {
    this.errors = new Map();
    if (this.user.forename.length < 2) {
      this.errors.set('forename', 'error-between-2-and-20-chars-at-least');
    }
    if (this.user.name.length < 2) {
      this.errors.set('name', 'error-between-2-and-20-chars-at-least');
    }
    if (this.errors.size > 0) {
      return;
    }
    this.activeModal.close(this.user);
  }

  public close(): void {
    this.activeModal.close(false);
  }

  public startExam(): void {
    void this.router.navigate([`/learn/cert-wizards/${this.certWizard.id}`],
      {queryParams: {token: this.token, exam: true}});
    this.activeModal.close(false);
  }

  public handleCountdownEvent(event: CountdownEvent): void {
    if (event.action === 'done') {
      this.timerActive = false;
    }
  }

  public ngOnInit(): void {
    this.sHelper.sub = this.authService.auth.subscribe(async (auth: Auth | null | undefined) => {
      if (auth) {
        console.log(auth.user.role !== UserRole.dummy)
        if (auth.user.role !== UserRole.dummy && auth.user.role !== UserRole.cert_wizard) {
          this.userHasName = true;
        } else {
          this.userHasName = false;
          auth.user.name = ''
          auth.user.forename = ''
        }

        this.user = lodash.cloneDeep(auth.user);
        if (this.availableLang.length > 0 && !this.availableLang.includes(this.user.lang)) {
          this.showLangSelect = true;
          this.user.lang = this.availableLang[0];
        }
      }
    })

    this.timerActive = this.userStat.certPenaltySecLeft > 0;

    if (this.userStat.exam) {
      this.showForm = this.userStat.prerequisiteCertAcquired;
      this.examNecessary = this.userStat.prerequisiteCertAcquired === false;
      this.repeatExam = this.userStat.prerequisiteCertAcquired === true;
    } else {
      this.showForm = this.userStat.prerequisiteLearnAcquired;
      this.examNecessary = false;
      this.repeatExam = false;
    }
  }

  public ngOnDestroy(): void {
    this.sHelper.unsubscribeAll();
  }
}

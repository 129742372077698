import {Image} from './image.model';
import {environment} from '../../environments/environment';

export class CardImage {

  id: string = null;
  cardFrontImage: boolean = null;
  image: Image = null;

  constructor() {
  }

  getImageURL() {
    return environment.backendApi + '/images/' + this.image.code + '/' + this.image.name;
  }

  static createWith(obj: any): CardImage {
    let newObj = new CardImage();
    Object.keys(newObj).forEach(
      key => {
        if ('image' === key && obj[key]) {
          newObj.image = Image.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

}

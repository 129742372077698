import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../user/user.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {User} from '../../user/user.model';
import {AuthService} from '../../auth/auth.service';
import {Asad} from '../asad.model';
import {UserAsad} from '../user-asad.model';
import {AsadService} from '../asad.service';
import {Client} from '../../client/client.model';

@Component({
  selector: 'app-asad-user',
  templateUrl: './asad-user.component.html',
  styleUrls: ['./asad-user.component.css']
})
export class AsadUserComponent implements OnDestroy {

  loading = true;

  selectedClient: Client = null;
  clients: Client[] = [];

  _asad: Asad;

  certWizard = false;

  set asad(asad: Asad) {
    this._asad = asad;
    // create copy to cancel changes
    asad.userAsads.forEach(
      ua => {
        this.assignedUsers.set(ua.user.id, UserAsad.createWith(ua));
        if (this._asad.user.id == ua.user.id) {
          this.userAsadOwner = ua;
        }
      }
    )
  }

  searchQuery = '';
  users: User[] = [];
  userAsadOwner: UserAsad;
  filteredUsers: User[] = [];
  assignedUsers: Map<string, UserAsad> = new Map();
  permission = 'READ';
  auth: any;

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;


  constructor(private asadService: AsadService,
              private userService: UserService,
              public authService: AuthService,
              public modal: NgbActiveModal) {
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }
    });
  }

  _init() {
    this.loading = true;
    this._sHelper.sub = this.userService.findAll().subscribe(
      users => {
        this.users = users.sort(User.sortByName);
        let tmpMap: Map<string, Client> = new Map();
        this.users.forEach(user => {
          tmpMap.set(user.client.id, user.client);
        });
        tmpMap.forEach(client => this.clients.push(client));
        this.clients = this.clients.sort(Client.sortByName);
        this.doSearch();
        this.loading = false;
      },
      e => {
        this.loading = false;
      }
    );
  }

  doSearch() {
    this.filteredUsers = this.users.filter(
      (item: User) => {
        if (item.id === this.auth.user.id) {
          return false;
        }

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.forename && !match) {
            match = item.forename.toLowerCase().indexOf(query) != -1;
          }
          if (item.email && !match) {
            match = item.email.toLowerCase().indexOf(query) != -1;
          }
        }

        let clientMatch = false;
        if (!this.selectedClient) {
          clientMatch = true;
        } else {
          clientMatch = item.client.id == this.selectedClient.id;
        }


        return match && clientMatch;
      });
  }

  toggleUser(user: User) {
    if (this._asad.user.id == user.id) {
      return;
    }

    if (this.assignedUsers.get(user.id)) {
      this.assignedUsers.delete(user.id);
    } else {
      this.assignUser(user);
    }
  }

  selectAll() {
    this.filteredUsers
      .filter(u => !this.assignedUsers.get(u.id))
      .forEach(this.assignUser.bind(this));
  }

  assignUser(user: User) {
    let um = UserAsad.createWith(this.userAsadOwner);
    if (this.certWizard) {
      um = UserAsad.createUaByUaForCertWizard(this.userAsadOwner);
    }
    um.id = null;
    um.permission = this.permission;
    um.user = user;
    this.assignedUsers.set(user.id, um);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  submit() {
    if (this.loading) {
      return;
    }
    this.loading = true;

    this.asadService.updateUserAsad(this.auth.user, this._asad, Array.from(this.assignedUsers.values())).subscribe(
      resp => {
        this._asad.userAsads = Array.from(this.assignedUsers.values());
        this.modal.close();
        this.loading = false;
      });

  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

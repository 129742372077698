export class UserCard {

  id: string = null;
  favorite = false;
  lastLearnt: number = null;
  lastKnown = false;
  lastAnswerTime: number = null;
  phase: number = null;

  constructor() {
  }

  static createWith(obj: any): UserCard {
    let newObj = new UserCard();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

}

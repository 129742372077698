import {LanguageStat} from './language-stat';

export class CertWizardStat {
  // certWizardId
  public certWizardId = 0;

  // timestamp stat was generated
  public timeStamp = 0;

  public userSum = 0;

  public languageStat: LanguageStat[] = [];

  public registeredUserSum = 0;

  public unregisteredUserSum = 0;

  public mobileUserSum = 0;

  public desktopUserSum = 0;

  public tabletUserSum = 0;

  public unknownDeviceSum = 0;

  public userWithCertSum = 0;

  public userWithoutCertSum = 0;

  public learnCardAnsweredCorrectSum = 0;

  public learnCardAnsweredWrongSum = 0;

  public learnCardUnansweredSum = 0;

  public learnCardRequestedSum = 0;

  public learnCardRequestedViaAsadSum = 0;

}

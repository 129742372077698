import {AfterViewInit, Component, OnDestroy, Renderer2} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {SubscriptionHelper} from '../subscription-helper';
import {LoadingService} from '../loading/loading.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageType} from '../message-type';
import {TitleService} from '../title/title.service';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
export class MessageComponent implements AfterViewInit, OnDestroy {

  private sHelper: SubscriptionHelper = new SubscriptionHelper;

  private body: HTMLBodyElement;
  private wrapperRef: HTMLElement;

  public type: MessageType;
  public header: string;
  public message: string;
  public action: string;
  public actionLink: string;
  public danger: boolean;

  public token: string;

  constructor(
    private readonly authService: AuthService,
    private readonly loadingService: LoadingService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly titleService: TitleService
  ) {}

  private async init(): Promise<void> {
    this.loadingService.open();
    const notificationId = this.activatedRoute.snapshot.params['id'] as MessageType;

    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');
    if (this.token) {
      AuthService.authToken = `Bearer ${this.token}`;
      await this.authService.autoLogin();
    }

    switch (notificationId) {
      case MessageType.NOT_FOUND:
        this.header = 'not-found-header';
        this.message = 'not-found-message';
        this.action = 'not-found-action';
        this.actionLink = '/';
        this.danger = true;
        break;
      case MessageType.INACTIVE:
        this.header = 'inactive-header';
        this.message = 'inactive-message';
        this.danger = true;
        break;
      case MessageType.DEACTIVATE_ASAD:
        this.header = 'deactivate-asad-header';
        this.message = 'deactivate-asad-message';
        this.danger = false;
        break;
      default:
        this.header = 'not-found-header';
        this.message = 'not-found-message';
        this.action = 'not-found-action';
        this.actionLink = '/';
        this.danger = true;
        break;
    }
    this.titleService.title = this.header;
    this.loadingService.dismiss();
  }

  public nav(): void {
    void this.router.navigate([this.actionLink]);
  }

  public ngAfterViewInit(): void {
    this.body = document.getElementsByTagName('body')[0];
    this.wrapperRef = document.getElementById('content-wrapper');
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '0px');
    this.renderer.setStyle(this.body, 'padding-bottom', '0px');

    void this.init();
  }


  public ngOnDestroy(): void {
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '52px');
    this.renderer.setStyle(this.body, 'padding-bottom', '6rem');
    this.sHelper.unsubscribeAll();
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">{{'to-profile'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">

  <div class="form-group row" *ngIf="user">

    <div class="col-xs-12 col-sm-3 d-sm-flex justify-content-end">
      <div class="rounded-corners" *ngIf="user.img && !errors.get('img-load')">
        <a href="{{user.img}}" data-lightbox="image-profile">
          <img class="rounded-corners" [src]="user.img" (error)="errors.set('img-load',true)"/>
        </a>
      </div>
      <i class="fa fa-user-circle-o text-muted user-avatar-icon" aria-hidden="true"
         *ngIf="!user.img || errors.get('img-load')"></i>
    </div>

    <div class="col-xs-12 col-sm-9 d-sm-flex flex-sm-column-reverse align-items-sm-start mt-2">
      <label for="fileUpload" class="btn btn-secondary" style="margin-bottom: 0">
        {{'img-upload'|translate}}
      </label>
      <button class="btn btn-outline-danger mb-sm-2" *ngIf="user.img" (click)="clearImage()">
        {{'img-clear'|translate}}
      </button>
      <p class="text-danger" *ngIf="errors.get('error') != undefined">
        {{errors.get('error')|translate}}
      </p>
      <input id="fileUpload" style="display: none" type="file" ng2FileSelect [uploader]="uploader"/>
    </div>
  </div>

  <form (ngSubmit)="submit($event)" autocomplete="off" *ngIf="auth && !loadingService.isOpen()" name="user-detail">

    <div class="row" style="margin-bottom: 15px">
      <div class="col-xs-12 offset-sm-3 col-sm-9">
        <p class="text-danger" *ngIf="errors.get('form') != undefined">{{errors.get('form')|translate}}</p>
      </div>
    </div>
    <div class="form-group row">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right">{{'client'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        {{this.auth.client.name}}
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('login') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_login">{{'user-name'|translate}}*</label>
      <div class="col-xs-12 col-sm-9">
        <input required autocomplete="off" class="form-control form-control-danger" type="text" name="user[login]"
               id="user_login" [(ngModel)]="user.login" minlength="2" maxlength="20">
        <div class="form-control-feedback">{{errors.get('login')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('email') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="user_email">{{'email'|translate}}*</label>
      <div class="col-xs-12 col-sm-9">
        <input required autocomplete="off" class="form-control form-control-danger" type="text" name="user[email]"
               id="user_email" [(ngModel)]="user.email" minlength="7" maxlength="100">
        <div class="form-control-feedback">{{errors.get('email')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('name') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="user_name">{{'name'|translate}}*</label>
      <div class="col-xs-12 col-sm-9">
        <input required autocomplete="off" class="form-control form-control-danger" type="text" name="user[name]"
               id="user_name" [(ngModel)]="user.name" minlength="2" maxlength="100">
        <div class="form-control-feedback">{{errors.get('name')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('forename') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_forename">{{'forename'|translate}}*</label>
      <div class="col-xs-12 col-sm-9">
        <input required autocomplete="off" class="form-control form-control-danger" type="text" name="user[forename]"
               id="user_forename" [(ngModel)]="user.forename" minlength="2" maxlength="100">
        <div class="form-control-feedback">{{errors.get('forename')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('password') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_password">{{'new-password'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="new-password" class="form-control form-control-danger" type="password" name="user[password]"
               id="user_password" [(ngModel)]="user.password">
        <div class="form-control-feedback">{{errors.get('password')|translate}}</div>
      </div>
    </div>

    <div class="form-group row">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_lang">{{'language'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <select id="user_lang" [(ngModel)]="user.lang"
                class="custom-select" name="user_lang">
          <option *ngFor="let l of languages" [value]="l.code">{{l.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-actions d-flex">
      <div class="mr-auto">* required</div>
      <button class="btn btn-outline-success flex-row mr-2" type="submit">
        <span>{{'save'|translate}}</span>
      </button>
      <a class="btn btn-secondary" routerLink="/dashboard">{{'cancel'|translate}}</a>
    </div>
  </form>
</div>



import {Component, Input, OnDestroy} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {UserStat} from '../../stats/user-stat.model';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'user-stats-today',
  templateUrl: './user-stats-today.component.html',
  styleUrls: ['./user-stats-today.component.css']
})
export class UserStatsTodayComponent implements OnDestroy {

  _userStat: UserStat;
  @Input()
  set userStat(userStat) {
    this._userStat = userStat;
    if (userStat != null) {
      this.initTransParams();
    }
  }
  auth: any;
  transParams;

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private translate: TranslateService,
              public authService: AuthService) {

    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {

      }

    });
  }

  initTransParams() {
    this._sHelper.sub = this.translate.get(['one-card', 'cards']).subscribe(trans => {
      let cardsTxt = '';
      let color = 'danger';
      if (this._userStat.learnedCardCount[0] == 1) {
        cardsTxt = trans['one-card'];
      } else {
        cardsTxt = this._userStat.learnedCardCount[0] + ' ' + trans['cards'];
      }
      if (this._userStat.learnedCardCount[0] >= 5) {
        color = 'warning';
      }
      if (this._userStat.learnedCardCount[0] >= 10) {
        color = 'success';
      }

      //moment().locale(this.auth.user.lang).format("l");
      let time = '';
      if (this._userStat.moduleLearnTimes[0] > 59) {
        time = moment.duration(this._userStat.moduleLearnTimes[0], 'seconds').minutes() + 'm ';
      }
      time += moment.duration(this._userStat.moduleLearnTimes[0], 'seconds').seconds() + 's';

      this.transParams = {
        cards: cardsTxt,
        time: time,
        color: color
      };
    });
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

export enum CertWizardImageType {
  LOGO_LEFT = 'LOGO_LEFT',
  LOGO_LEFT_SECOND = 'LOGO_LEFT_SECOND',
  LOGO_RIGHT = 'LOGO_RIGHT',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
  LEFT_MAIL_IMAGE = 'LEFT_MAIL_IMAGE',
  RIGHT_MAIL_IMAGE = 'RIGHT_MAIL_IMAGE',
  BOTTOM_MAIL_IMAGE = 'BOTTOM_MAIL_IMAGE'
}

export namespace CertWizardImageType {
  export function stringToLang(lang: String): CertWizardImageType {
    switch (lang.trim().toUpperCase()) {
      case CertWizardImageType.LOGO_LEFT:
        return CertWizardImageType.LOGO_LEFT
      case CertWizardImageType.LOGO_LEFT_SECOND:
        return CertWizardImageType.LOGO_LEFT_SECOND
      case CertWizardImageType.LOGO_RIGHT:
        return CertWizardImageType.LOGO_RIGHT
      case CertWizardImageType.BACKGROUND_IMAGE:
        return CertWizardImageType.BACKGROUND_IMAGE
      default:
        return null
    }
  }
  export function langToString(lang: CertWizardImageType): string {
    switch (lang.trim().toUpperCase()) {
      case CertWizardImageType.LOGO_LEFT:
        return CertWizardImageType.LOGO_LEFT
      case CertWizardImageType.LOGO_LEFT_SECOND:
        return CertWizardImageType.LOGO_LEFT_SECOND
      case CertWizardImageType.LOGO_RIGHT:
        return CertWizardImageType.LOGO_RIGHT
      case CertWizardImageType.BACKGROUND_IMAGE:
        return CertWizardImageType.BACKGROUND_IMAGE
      default:
        return null
    }
  }
}

import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Module} from '../module.model';
import {UserModule} from '../user-module.model';
import {UserService} from '../../user/user.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {User} from '../../user/user.model';
import {ModuleService} from '../module.service';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'app-module-user',
  templateUrl: './module-user.component.html',
  styleUrls: ['./module-user.component.css']
})
export class ModuleUserComponent implements OnDestroy {

  loading = true;

  _module: Module;
  set module(module: Module) {
    this._module = module;
    // create copy to cancel changes
    module.userModules.forEach(
      um => this.assignedUsers.set(um.user.id, UserModule.createWith(um))
    );
  }

  searchQuery = '';
  users: User[] = [];
  filteredUsers: User[] = [];
  assignedUsers: Map<string, UserModule> = new Map();
  permission = 'READ';
  auth: any;

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private moduleService: ModuleService,
              private userService: UserService,
              public authService: AuthService,
              public modal: NgbActiveModal) {
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }
    });
  }

  _init() {
    this.loading = true;
    this._sHelper.sub = this.userService.findAll().subscribe(
      users => {
        // filter users from other clients if auth user is admin
        this.users = users.sort(User.sortByName);
        this.doSearch();
        this.loading = false;
      },
      e => {
        this.loading = false;
      }
    );
  }

  doSearch() {
    this.filteredUsers = this.users.filter(
      (item: User) => {
        if (item.id === this.auth.user.id) {
          return false;
        }

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.forename && !match) {
            match = item.forename.toLowerCase().indexOf(query) != -1;
          }
          if (item.email && !match) {
            match = item.email.toLowerCase().indexOf(query) != -1;
          }
        }

        return match;
      });
  }

  toggleUser(user: User) {
    if (this._module.user.id == user.id) {
      return;
    }

    if (this.assignedUsers.get(user.id)) {
      this.assignedUsers.delete(user.id);
    } else {
      this.assignUser(user);
    }
  }

  selectAll() {
    this.users
      .filter(u => !this.assignedUsers.get(u.id))
      .forEach(this.assignUser.bind(this));
    ;
  }

  assignUser(user: User) {
    let um = new UserModule();
    um.permission = this.permission;
    um.user = user;
    this.assignedUsers.set(user.id, um);
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  submit() {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.moduleService
      .updateModulePermissions(this.auth.user, this._module, Array.from(this.assignedUsers.values())).subscribe(
      resp => {
        this._module.userModules = Array.from(this.assignedUsers.values());
        this.modal.close();
        this.loading = false;
      });

  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited d-flex flex-row flex-wrap">
    <div class="h2 mr-auto text-nowrap">
      {{certWizard?.name}}
    </div>

    <div class="d-flex flex-row flex-wrap">
      <div class="dropdown dropleft p-1">
        <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span *ngFor="let c of colorScheme.domain" class="unselectable-text" style="color: transparent" [style.background-color]="c">cc</span>
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a (click)="switchColors(palette1)" class="dropdown-item">
            <span *ngFor="let c of palette1" class="unselectable-text" style="color: transparent; cursor: pointer" [style.background-color]="c">cc</span>
          </a>
          <a (click)="switchColors(palette2)" class="dropdown-item">
            <span *ngFor="let c of palette2" class="unselectable-text" style="color: transparent; cursor: pointer" [style.background-color]="c">cc</span>
          </a>
          <a (click)="switchColors(palette3)" class="dropdown-item">
            <span *ngFor="let c of palette3" class="unselectable-text" style="color: transparent; cursor: pointer" [style.background-color]="c">cc</span>
          </a>
        </div>
      </div>

      <div class="dropdown dropleft p-1">
        <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{selection|translate}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a (click)="switchChart('bar-vertical-chart')" class="dropdown-item">{{'bar-vertical-chart'|translate}}</a>
          <a (click)="switchChart('bar-horizontal-chart')" class="dropdown-item">{{'bar-horizontal-chart'|translate}}</a>
          <a (click)="switchChart('pie-chart')" class="dropdown-item">{{'pie-chart'|translate}}</a>
          <a (click)="switchChart('doughnut-chart')" class="dropdown-item">{{'doughnut-chart'|translate}}</a>
        </div>
      </div>

      <button class="btn btn-link text-primary-2 p-1" [class.disabled]="downloading"
              (click)="downloadStats()"
              title="{{'download-stats'|translate}}">
        <i class="fa fa-download" style="font-size: 1.5rem" aria-hidden="true"></i>
      </button>
      <button class="btn btn-link pr-0 text-primary-2 p-1"
              (click)="edit(certWizard, $event)"
              title="{{'edit-cert-wizard'|translate}}"
              *ngIf="certWizard">
        <i class="fa fa-pencil" style="font-size: 1.5rem" aria-hidden="true"></i>
      </button>
    </div>
  </div>
</div>


<div *ngIf="certWizardStat" id="statsContainer" class="bg-white">

  <div class="container-fluid container-limited">

    <app-cert-wizard-stats-item
      [name]="'user'"
      [certWizardStat]="certWizardStat"
      [result]="statsUser"
      [resultNames]="statsUserNames"
      [colorScheme]="colorScheme"
      [selection]="selection"
      [downloading]="downloading"
      [identifier]="'user_stat'">
    </app-cert-wizard-stats-item>

    <app-cert-wizard-stats-item
      [name]="'devices'"
      [certWizardStat]="certWizardStat"
      [result]="statsUserDevice"
      [resultNames]="statsUserDeviceNames"
      [colorScheme]="colorScheme"
      [selection]="selection"
      [downloading]="downloading"
      [identifier]="'devices-stat'">
    </app-cert-wizard-stats-item>

    <app-cert-wizard-stats-item
      [name]="'language'"
      [certWizardStat]="certWizardStat"
      [result]="statsUserLang"
      [resultNames]="statsUserLangNames"
      [colorScheme]="colorScheme"
      [selection]="selection"
      [downloading]="downloading"
      [identifier]="'language-stat'">
    </app-cert-wizard-stats-item>

    <app-cert-wizard-stats-item
      [name]="'learn-card-requested'"
      [certWizardStat]="certWizardStat"
      [result]="statsRequested"
      [resultNames]="statsRequestedNames"
      [colorScheme]="colorScheme"
      [selection]="selection"
      [downloading]="downloading"
      [identifier]="'learn-card-requested-stat'">
    </app-cert-wizard-stats-item>

    <app-cert-wizard-stats-item
      [name]="'learn-card-answered'"
      [certWizardStat]="certWizardStat"
      [result]="statsAnswers"
      [resultNames]="statsAnswersNames"
      [colorScheme]="colorScheme"
      [selection]="selection"
      [downloading]="downloading"
      [identifier]="'learn-card-answered-stat'">
    </app-cert-wizard-stats-item>

    <app-cert-wizard-stats-item
      [name]="'cert-stat'"
      [certWizardStat]="certWizardStat"
      [result]="statsCert"
      [resultNames]="statsCertNames"
      [colorScheme]="colorScheme"
      [selection]="selection"
      [downloading]="downloading"
      [identifier]="'cert-stat'">
    </app-cert-wizard-stats-item>

  </div>

</div>

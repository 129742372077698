import {Component, OnDestroy} from '@angular/core';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {LoadingService} from '../../util/loading/loading.service';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {ConfirmComponent} from '../../util/confirm-modal/confirm.component';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {Asad} from '../asad.model';
import {AsadService} from '../asad.service';
import {UserAsad} from '../user-asad.model';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';

@Component({
  selector: 'app-asad-public',
  templateUrl: './asad-public.component.html',
  styleUrls: ['./asad-public.component.css']
})
export class AsadPublicComponent implements OnDestroy {

  asads: Asad[] = [];
  filteredAsads: Asad[] = [];
  auth: any;
  searchQuery = '';

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private asadService: AsadService,
              private modalService: ModalWrapperService,
              public loadingService: LoadingService,
              private tooltipService: TooltipService,
              public authService: AuthService,
              private router: Router) {
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  _init() {
    this.loadingService.open();
    this._sHelper.sub = this.asadService.findAllPublic().subscribe(
      asads => {
        this.asads = asads.filter(asad => !this.hasPermission(asad));
        this.asads = this.asads.sort(Asad.sortByName);
        this.doSearch();
        this.loadingService.dismiss();
      },
      e => {
        this.loadingService.dismiss();
      }
    );
  }

  doSearch() {
    this.filteredAsads = this.asads.filter(
      (item: Asad) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.description && !match) {
            match = item.description.toLowerCase().indexOf(query) != -1;
          }

        }

        return match;
      });
  }

  getPermission(asad: Asad) {
    switch (asad.visibility) {
      case 'PUBLIC_READ':
        return 'read';
      case 'PUBLIC_WRITE':
        return 'write';
    }
    return 'unknown';
  }

  hasPermission(asad: Asad): boolean {
    let hasPermission = false;
    asad.userAsads.forEach(um => {
      if (um.user.id == this.auth.user.id) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  getOwner(asad: Asad) {
    if (asad.user.id == this.auth.user.id) {
      return 'me';
    } else {
      return asad.user.forename + ' ' + asad.user.name
    }
  }

  connectUser(asad, event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(ConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'connect-to-kTracker';
    modalRef.componentInstance.translateParams = {name: asad.name};
    modalRef.result.then(
      (connectUser) => {
        if (connectUser) {
          let um: UserAsad = new UserAsad();
          um.user = this.auth.user;
          um.permission = this.getPermission(asad);
          this._sHelper.sub = this.asadService.updatePublicUserAsad(asad, um)
            .subscribe(ok => {
              if (ok) {
                // delete item in local array
                let delFn = (item, i, arr) => item.id === asad.id ? arr.splice(i, 1) : false;
                this.asads.some(delFn);
                this.doSearch();
                this.tooltipService.show({translateKey: 'action-success'});
              } else {
                this.tooltipService.show({
                  translateKey: 'action-error',
                  type: 'danger',
                  stay: true
                });

              }
            });
        }
      })
  }

  edit(asad) {
    this.router.navigate(['/public/asads', asad.id]);
  }


  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Module} from '../module.model';
import {UserModule} from '../user-module.model';
import {UserService} from '../../user/user.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {User} from '../../user/user.model';
import {ModuleService} from '../module.service';
import {AuthService} from '../../auth/auth.service';
import {ModuleStat} from '../../stats/module-stat.model';
import {StatsService} from '../../stats/stats.service';

@Component({
  selector: 'app-module-picker',
  templateUrl: './module-picker.component.html',
  styleUrls: ['./module-picker.component.css']
})
export class ModulePickerComponent implements OnDestroy {

  excludedModules: Map<string, Module> = new Map();

  searchQuery = '';
  modules: Module[] = [];
  filteredModules: Module[] = [];
  moduleTypes: string[] = [];
  selectedModules: Map<string, Module> = new Map();
  stats: Map<string, ModuleStat> = new Map();
  auth: any;

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private moduleService: ModuleService,
              private statsService: StatsService,
              public authService: AuthService,
              public modal: NgbActiveModal) {
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }
    });
  }

  _init() {
    this._sHelper.sub = this.moduleService.findAllByUser(this.auth.user).subscribe(
      modules => {
        this.modules = modules.filter(item => {
          if (this.excludedModules.get(item.id) != null) {
            return false;
          }
          if (this.moduleTypes.length > 0) {
            return this.moduleTypes.findIndex(mType => item.type == mType) != -1;
          }
          return true;
        });
        this.modules = this.modules.sort(Module.sortByName);
        this.doSearch();

        // get module stats
        this.statsService.findModuleStatsByUserAndModuleIds(this.auth.user, modules).subscribe(
          stats => stats.forEach(stat => this.stats.set(stat.moduleId, stat)));
      },
      e => {
      }
    );
  }

  doSearch() {
    this.filteredModules = this.modules.filter(
      (item: Module) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.description && !match) {
            match = item.description.toLowerCase().indexOf(query) != -1;
          }

        }

        return match;
      });
  }

  getModuleCardCount(module: Module) {
    if (this.stats.get(module.id)) {
      return this.stats.get(module.id).cardCount;
    }
    return 0;
  }

  toggleModule(module: Module) {
    if (this.selectedModules.get(module.id)) {
      this.selectedModules.delete(module.id);
    } else {
      this.selectedModules.set(module.id, module);
    }
  }

  submit() {
    let result: Module[] = [];
    this.selectedModules.forEach(v => result.push(v));
    this.modal.close(result);

  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

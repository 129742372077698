import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment'
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../user/user.model';
import {HttpClient} from '@angular/common/http';
import {CertWizard} from '../cert-wizard/model/cert-wizard';

@Injectable()
export class AuthService implements CanActivate {

  // init _auth with undefined in order to know if this value has not been modified by login or autoLogin yet
  private _auth = new BehaviorSubject<any>(undefined);
  auth: Observable<any> = this._auth.asObservable();
  private _authObject;

  constructor(private http: HttpClient,
              private router: Router,
              private translate: TranslateService) {
  }

  login(login: string, password: string): Observable<any> {
    let body = {
      login: login,
      password: password
    };
    return this.http.post(environment.backendApi + '/oauth2/token', body)
      .map((data: any) => {
        this._authObject = data;
        AuthService.authToken = 'Bearer ' + data.token;
        this.translate.use(this._authObject.user.lang);
        this._auth.next(this._authObject);
        return this._authObject;
      });
  }

  sendLoginMail(loginOrEmail: string): Observable<any> {
    return this.http.post(environment.backendApi + '/oauth2/token/lost', loginOrEmail)
      .map(response => {
        console.log(response);
        return response;
      });

  }

  switchToUser(user: User): Observable<any> {
    return this.http.get(environment.backendApi + '/oauth2/token/user/' + user.id)
      .map(data => {
        this._authObject = data;
        AuthService.authTokenAdmin = AuthService.authToken.replace('Bearer ', '');
        this.router.navigateByUrl('/login/' + this._authObject.token);
        this._auth.next(null);
        return this._authObject;
      });
  }

  autoLogin(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let authToken = AuthService.authToken;
      if (authToken) {
        this.http.get(environment.backendApi + '/oauth2/token')
          .subscribe(
            data => {
              this._authObject = data;
              this.translate.use(this._authObject.user.lang);
              this._auth.next(this._authObject);
              if (resolve) {
                resolve(true);
              }
            },
            err => {
              this._authObject = null;
              this._auth.next(null);
              AuthService.authToken = null;
              if (reject) {
                reject(false);
              }
            });
      } else {
        this._auth.next(null);
      }
    });

  }


  /**
   * used to create and impersonate dummy user in order to track performance of user in cert wizard
   * @param clientId client dummy user will be for
   * @param lang language user has chosen
   */
  public createAndImpersonateDummy(clientId: number, lang: string): Observable<any> {
    return this.http.post<any>(environment.backendApi + '/public/clients/' + clientId + '/lang/' + lang + '/dummy-user', null)
      .map(data => {
        AuthService.authToken = 'Bearer ' + data.token;
        this._authObject = data;
        this.translate.use(this._authObject.user.lang);
        this._auth.next(this._authObject);
      });
  }

  /**
   * used to create and impersonate dummy user in order to track performance of user in cert wizard
   * @param user user Object with new mail and optional password
   * @param certWizard
   */
  public createAndImpersonateCertWiz(user: User, certWizard: CertWizard): Promise<any> {
    return this.http.put<any>( `${environment.backendApi}/users/${user.id}/cert-wizards/${certWizard.id}/cert-wizard-user`, user)
      .toPromise()
      .then(data => {
        AuthService.authToken = 'Bearer ' + data.token;
        this._authObject = data;
        this.translate.use(this._authObject.user.lang);
        this._auth.next(this._authObject);
        return data;
      }).catch(res => {
        return {error: res.error};
      });
  }

  static get authToken(): string {
    return sessionStorage.getItem('authToken');
  }

  static set authToken(newAuthToken: string) {
    if (newAuthToken) {
      sessionStorage.setItem('authToken', newAuthToken);
    } else {
      sessionStorage.removeItem('authToken');
    }
  }

  static get authTokenAdmin(): string {
    return sessionStorage.getItem('authTokenAdmin');
  }

  static set authTokenAdmin(newAuthToken: string) {
    if (newAuthToken) {
      sessionStorage.setItem('authTokenAdmin', newAuthToken);
    } else {
      sessionStorage.removeItem('authTokenAdmin');
    }
  }

  logout(): void {
    this.http.delete(environment.backendApi + '/oauth2/token')
      .subscribe(
        response => {
          console.log(response);
        },
        err => {
          console.log(err);
        });
    AuthService.authToken = null;
    this._authObject = null;

    if (AuthService.authTokenAdmin) {
      setTimeout(() => {
        this.router.navigateByUrl('/login/' + AuthService.authTokenAdmin);
        AuthService.authTokenAdmin = null;
      }, 200);

    }

    this._auth.next(undefined);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean>
    | boolean {

    if ((<any>route.params).token) {
      console.log((<any>route.params).token);
      AuthService.authToken = 'Bearer ' + (<any>route.params).token;

      return new Promise((resolve, reject) => {
        this.autoLogin().then(
          ok => {
            if (resolve) {
              resolve(this._canActivate(route));
            } else if (reject) {
              reject(false);
            }
          }, e => {
            reject(false);
          });
      });

    } else {
      return this._canActivate(route);
    }
  }

  _canActivate(route: ActivatedRouteSnapshot): boolean {
    // if (environment.development) {
    //   return true;
    // }
    if (this._authObject) {
      if (route.url[0].path === 'admin') {
        return this._authObject.user.role === 'admin';
      } else if (route.url[0].path === 'manager') {
        return this._authObject.user.role === 'admin' || this._authObject.user.role === 'manager';
      } else if (this._authObject.user.role === 'dummy' || this._authObject.user.role === 'cert_wizard') {
        AuthService.authToken = null;
        this._authObject = null;
        this._auth.next(undefined);
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

}

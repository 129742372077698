<div class="d-flex flex-column aeonik-regular p-2">

  <div class="mt-auto mb-auto">
    <div class="d-flex justify-content-end align-items-end align-content-end">
      <i (click)="close()" class="fa fa-times fa-3x pointy-cursor"></i>
    </div>
  </div>

  <form>
    <div class="form-group pt-2">
      <input [(ngModel)]="email"
             type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="E-Mail" name="email"
             [style.background-color]="'#d0d0d0'"
             [style.color]="'black'">
      <p class="text-danger" *ngIf="errors.get('email')">
        {{errors.get('email')|translate}}
      </p>
    </div>
    <div class="form-group  pt-2">
      <input [(ngModel)]="pin" type="password" class="form-control" id="pin" placeholder="PIN" name="pin"
             [style.background-color]="'#d0d0d0'"
             [style.color]="'black'">
      <p class="text-danger" *ngIf="errors.get('error') == null">
        {{errors.get('error')|translate}}
      </p>
    </div>
  </form>


  <div class="pb-2">
    <button (click)="login()" type="button" class="btn btn-block aeonik-regular"
            [style.background-color] = "certWizard.secondaryColor"
            [style.border-color] = "certWizard.secondaryColor"
            [style.color]="certWizard.secondaryFontColor">
      <h3 class="m-0">
        {{'login'|translate}}
      </h3>
    </button>
  </div>

  <div class="pb-2">
    <button (click)="forgotPin()" type="button" class="btn btn-block aeonik-regular"
            [style.background-color] = "certWizard.color"
            [style.border-color] = "certWizard.color"
            [style.color]="certWizard.fontColor">
      <h3 class="m-0">
        {{'forgot-pin'|translate}}
      </h3>
    </button>
  </div>

</div>

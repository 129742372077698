import {Component, OnDestroy} from '@angular/core';
import {Auth} from '../../auth/auth';
import {LoadingService} from '../../util/loading/loading.service';
import {AuthService} from '../../auth/auth.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {CertWizard} from '../model/cert-wizard';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cert-wizard-register-modal',
  templateUrl: './cert-wizard-register-modal.component.html',
  styleUrls: ['./cert-wizard-register-modal.component.css']
})
export class CertWizardRegisterModalComponent implements OnDestroy {

  public auth: Auth;

  public email: string = null;

  public emailPlaceholder = '';

  public errors: Map<string, string> = new Map();

  public certWizard: CertWizard;

  private sHelper: SubscriptionHelper = new SubscriptionHelper;

  public constructor(
    private readonly loadingService: LoadingService,
    private readonly authService: AuthService,
    private readonly activeModal: NgbActiveModal,
    private readonly translateService: TranslateService
  ) {
    void this.init()
  }

  private async init(): Promise<void> {
    this.loadingService.open();
    this.emailPlaceholder = await this.translateService.get('email').toPromise();
    this.sHelper.sub = this.authService.auth.subscribe(async (auth: Auth) => {
      if (auth === undefined) {
        // has not been initialized
      } else if (auth === null) {
        // no user
      } else {
        this.auth = auth;
        this.loadingService.dismiss();
      }
    });
  }

  public async createCertWizUser(): Promise<void> {
    const user = this.auth.user
    user.email = this.email;
    const res = await this.authService.createAndImpersonateCertWiz(user, this.certWizard);
    if (res.error !== undefined) {
      Object.keys(res.error).forEach((key: string) => {
        this.errors.set(key, res.error[key]);
      });
    } else {
      this.activeModal.close(true);
    }
  }

  public close() {
    this.activeModal.close(false)
  }

  public ngOnDestroy(): void {
    this.sHelper.unsubscribeAll();
  }

}

import { Injectable } from '@angular/core';
import {User} from '../user/user.model';
import {Observable} from 'rxjs';
import {CertWizard} from './model/cert-wizard';
import {Log} from 'ng2-logger';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {Module} from '../module/module.model';
import {UserCertWizardCardAnswer} from './model/user-cert-wizard-card-answer';
import {Card} from '../module/card.model';
import {CertWizardUserStat} from './model/cert-wizard-user-stat';
import {Certificate} from './model/certificate';
import {DeviceDetectorService, DeviceType} from 'ngx-device-detector';
import {CertWizardListItem} from './model/cert-wizard-list-item';



@Injectable({
  providedIn: 'root'
})
export class CertWizardService {
  private readonly log = Log.create('CertWizardService');
  private readonly resource: string = environment.backendApi + '/users/';
  private readonly publicResource: string = environment.backendApi + '/public/';

  public constructor(private http: HttpClient,
                     private deviceService: DeviceDetectorService) {
  }

  public findAllByUser(user: User): Observable<CertWizardListItem[]> {
    return this.http.get<CertWizardListItem[]>(this.resource + user.id + '/cert-wizards')
      .map((data: CertWizardListItem[]) => {
        this.log.info(<any>data);
        return data;
      });
  }

  public findByUserAndId(user: User, id: string): Promise<CertWizard> {
    return this.http.get<CertWizard>(this.resource + user.id + '/cert-wizards/' + id)
      .map((data: CertWizard) => {
        this.log.info('', data);
        return data;
      }).toPromise();
  }

  public findByIdPublic(id: string): Observable<CertWizard> {
    return this.http.get<CertWizard>(this.publicResource + 'cert-wizards/' + id)
      .map((data: CertWizard) => {
        this.log.info('', data);
        return data;
      });
  }

  public findByHashPublic(hash: string): Observable<CertWizard> {
    return this.http.get<CertWizard>(this.publicResource + 'cert-wizards/' + hash + '/by-hash')
      .map((data: CertWizard) => {
        this.log.info('', data);
        return data;
      });
  }

  public update(user: User, certWizard: CertWizard): Observable<CertWizard> {
    return this.http.put<CertWizard>(this.resource + user.id + '/cert-wizards/' + certWizard.id, certWizard)
      .map((data: CertWizard) => {
        this.log.info('', data);
        return data;
      });
  }

  public persist(user: User, certWizard: CertWizard): Observable<string> {
    return this.http.post<string>(this.resource + user.id + '/cert-wizards', certWizard)
      .map(id => {
        this.log.info(id);
        return id;
      });
  }

  public delete(user: User, id: string): Observable<boolean> {
    return this.http.delete<boolean>(this.resource + user.id + '/cert-wizards/' + id)
      .pipe(
        map(data => {
          return true;
        }),
        catchError((err, obs) => {
          return obs;
        })
      );
  }

  public findCertWizardModulePublic(id: string, certWizardModuleId: string): Observable<Module> {
    return this.http.get<Module>(this.publicResource + 'cert-wizards/' + id + '/cert-wizard-module/' + certWizardModuleId)
      .map((data: Module) => {
        this.log.info('', data);
        return data;
      });
  }

  public createUserCertWizard(user: User, certWizard: CertWizard): Observable<any> {
    const device = this.getDevice();
    return this.http.post<any>(this.resource
      + user.id
      + '/cert-wizards/'
      + certWizard.id
      + '/user-cert-wizards'
      + '?deviceType=' + device, null)
      .map((data: any) => {
        this.log.info('', data);
        return data;
      });
  }

  public setCardRequested(user: User, certWizard: CertWizard, card: Card, byMail = false): Observable<boolean> {
    return this.http.put<boolean>(
      this.resource + user.id
      + '/cert-wizards/' + certWizard.id
      + '/cards/' + card.id
      + '/requested'
      + '?mail=' + byMail, null
    ).map(data => {
        this.log.info('setCardRequested', data);
        return data;
      });
  }

  public persistCardAnswer(userCertWizardCardAnswer: UserCertWizardCardAnswer): Observable<boolean> {
    this.log.info(<any>'send CertWizardCardAnswer: ', userCertWizardCardAnswer);
    return this.http.put<boolean>(
      this.resource + userCertWizardCardAnswer.userId + '/cert-wizards/'
      + userCertWizardCardAnswer.certWizardId + '/card-answers', userCertWizardCardAnswer
    ).map(res => {
        this.log.info(<any>res);
        return true;
      });
  }

  public getUserStats(user: User, certWizard: CertWizard): Promise<CertWizardUserStat> {
    return this.http.get<CertWizardUserStat>(
      environment.backendApi + `/stats/users/${user.id}/cert-wizards/${certWizard.id}`
    ).toPromise()
  }

  public uploadCertificate(file: File, lang: string): Promise<Certificate> {
    this.log.info(lang);
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Certificate>(
      environment.backendApi + `/certificates`, formData
    ).toPromise();
  }

  public langAvailable(certWizard: CertWizard): Promise<string[]> {
    return this.http.get<string[]>(
      environment.backendApi + `/certificates/cert-wizards/${certWizard.id}/supported-lang`
    ).toPromise()
  }

  public async getCertificate(user: User, certWizard: CertWizard, certLang: string): Promise<any> {
    return await this.http.put<any>(
      environment.backendApi + `/certificates/cert-wizards/${certWizard.id}?lang=${certLang}`, user,
      {
        headers: new HttpHeaders({
          'Accept': 'application/pdf'
        }),
        'responseType': 'blob' as 'json',
      }
    ).toPromise()
      .then((data) => {
        return Promise.resolve(data);
      }).catch(e => {
        return Promise.reject(e);
      })
  }

  public async forgotPin(login: string, certWizard: CertWizard): Promise<any> {
    let body = {
      login: login,
      password: '',
    };
    return this.http.put<any>(this.resource + 'cert-wizards/' + certWizard.id
      + '/cert-wizard-user/forgot-pin', body)
      .map((data: any) => {
        this.log.info('', data);
        return data;
      }).toPromise();
  }

  public async geCumulativeStats(user: User, certWizard: CertWizard): Promise<any> {
    return this.http.get<CertWizardUserStat>(
      environment.backendApi + `/stats/users/${user.id}/cert-wizards/${certWizard.id}/cumulative`
    ).toPromise()
  }

  public async getNextAsadDateTime(user: User, certWizard: CertWizard): Promise<number> {
    return this.http.get<number>(
      environment.backendApi + `/users/${user.id}/cert-wizards/${certWizard.id}/next-asad`)
      .map((data: number) => {
        this.log.info('', data);
        return data;
      }).toPromise();
  }

  private getDevice(): DeviceType {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();

    if (isMobile) {
      return DeviceType.Mobile;
    } else if (isTablet) {
      return DeviceType.Tablet;
    } else if (isDesktopDevice) {
      return DeviceType.Desktop;
    } else {
      return DeviceType.Unknown
    }
  }
}

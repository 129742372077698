import {User} from '../user/user.model';
import {Image} from '../module/image.model';
import {environment} from '../../environments/environment';
export class Client {

  id: string = null;
  image: Image = null;
  name: string = null;
  code: string = null;
  acceptedDomains: string = null;
  hasPassword = false;
  registrationPassword: string = null;
  users: User[] = [];

  constructor() {
  }

  getImageURL() {
    return environment.backendApi + '/images/' + this.image.code + '/' + this.image.name;
  }

  static createWith(obj: any): Client {
    let newObj = new Client();
    Object.keys(newObj).forEach(
      key => {
        if ('users' === key && Array.isArray(obj[key])) {
          obj[key].forEach(user => newObj.users.push(User.createWith(user)));
        } else if ('image' === key && obj[key]) {
          newObj.image = Image.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

  static sortByName(a: Client, b: Client): number {
    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppComponent} from './app.component';
import {LoginFormComponent} from './auth/login-form/login-form.component';
import {RouterModule} from '@angular/router';
import {PageNotFoundComponent} from './404/page-not-found/page-not-found.component';
import {WelcomeComponent} from './welcome/welcome/welcome.component';
import {appRoutes} from './app.routes';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {ImprintComponent} from './legal/imprint/imprint.component';
import {TosComponent} from './legal/tos/tos.component';
import {PrivacyComponent} from './legal/privacy/privacy.component';
import {NgxPageScrollModule} from 'ngx-page-scroll';
import {AuthService} from './auth/auth.service';
import {LoadingService} from './util/loading/loading.service';
import {LoadingComponent} from './util/loading/loading.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SideNavbarComponent} from './util/side-navbar/side-navbar.component';
import {ClientListComponent} from './client/client-list/client-list.component';
import {ClientService} from './client/client.service';
import {SearchbarComponent} from './util/searchbar/searchbar.component';
import {DeleteConfirmComponent} from './util/confirm-modal/delete-confirm.component';
import {TooltipService} from './util/tooltip/tooltip.service';
import {TooltipComponent} from './util/tooltip/tooltip.component';
import {ClientDetailComponent} from './client/client-detail/client-detail.component';
import {UserListComponent} from './user/user-list/user-list.component';
import {UserService} from './user/user.service';
import {UserDetailComponent} from './user/user-detail/user-detail.component';
import {ModuleListComponent} from './module/module-list/module-list.component';
import {ModuleService} from './module/module.service';
import {ModuleDetailComponent} from './module/module-detail/module-detail.component';
import {ModuleImportComponent} from './module/module-import/module-import.component';
import {CardEditorComponent} from './module/card-editor/card-editor.component';
import {EditorComponent} from './util/editor/editor.component';
import {ModuleUserComponent} from './module/module-user/module-user.component';
import {ModulePublicComponent} from './module/module-public/module-public.component';
import {ConfirmComponent} from './util/confirm-modal/confirm.component';
import {UserProfileComponent} from './user/user-profile/user-profile.component';
import {SignupFormComponent} from './auth/signup-form/signup-form.component';
import {RegistrationService} from './auth/registration.service';
import {AsadListComponent} from './asad/asad-list/asad-list.component';
import {AsadDetailComponent} from './asad/asad-detail/asad-detail.component';
import {AsadService} from './asad/asad.service';
import {ModulePickerComponent} from './module/module-picker/module-picker.component';
import {AsadUserComponent} from './asad/asad-user/asad-user.component';
import {AsadPublicComponent} from './asad/asad-public/asad-public.component';
import {TitleService} from './util/title/title.service';
import {FileUploadModule} from 'ng2-file-upload';
import {LearnStartComponent} from './learn/learn-start/learn-start.component';
import {StatsService} from './stats/stats.service';
import {LearnModuleComponent} from './learn/learn-module/learn-module.component';
import {ModuleListLastActivityComponent} from './dashboard/module-list-last-activity/module-list-last-activity.component';
import {UserStatsTodayComponent} from './dashboard/user-stats-today/user-stats-today.component';
import {UserStatsKTrackerComponent} from './dashboard/user-stats-ktracker/user-stats-ktracker.component';
import {SystemLogListComponent} from './logs/system-log-list/system-log-list.component';
import {SystemLogService} from './logs/system-log.service';
import {ModalWrapperService} from './util/modal/modal-wrapper.service';
import {UserImportComponent} from './user/user-import/user-import.component';
import {FocusElDirective} from './user/focus-element/focus.directive';
import {KTrackerListComponent} from './ktracker/ktracker-list/ktracker-list.component';
import {KTrackerService} from './ktracker/ktracker.service';
import {KTrackerDetailComponent} from './ktracker/ktracker-detail/ktracker-detail.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UserPickerComponent} from './user/user-picker/user-picker.component';
import {KTrackerStatsComponent} from './ktracker/ktracker-stats/ktracker-stats.component';
import {Log} from 'ng2-logger'
import {environment} from '../environments/environment';
import {AppSettingsService} from './app.settings';
import {MatSliderModule} from '@angular/material/slider';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { MatNativeDateModule } from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {TokenInterceptor} from './util/extended-http/token-interceptor.service';
import { CertWizardListComponent } from './cert-wizard/cert-wizard-list/cert-wizard-list.component';
import { CertWizardDetailComponent } from './cert-wizard/cert-wizard-detail/cert-wizard-detail.component';
import { CertWizardStatsComponent } from './cert-wizard/cert-wizard-stats/cert-wizard-stats.component';
import { CertWizardPublicComponent } from './cert-wizard/cert-wizard-public/cert-wizard-public.component';
import {ChartsModule} from 'ng2-charts';
import {ColorPickerModule} from 'ngx-color-picker';
import { CertWizardLearnComponent } from './cert-wizard/cert-wizard-learn/cert-wizard-learn.component';
import { CertWizardDashboardComponent } from './cert-wizard/cert-wizard-dashboard/cert-wizard-dashboard.component';
import { CertWizardRegisterModalComponent } from './cert-wizard/cert-wizard-register-modal/cert-wizard-register-modal.component';
import { CertWizardCertificateModalComponent } from './cert-wizard/cert-wizard-certificate-modal/cert-wizard-certificate-modal.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { CertWizardLoginComponent } from './cert-wizard/cert-wizard-login/cert-wizard-login.component';
import { ImgModalComponent } from './util/img-modal/img-modal.component';
import { CertWizardPrivacyPolicyModalComponent } from './cert-wizard/cert-wizard-privacy-policy-modal/cert-wizard-privacy-policy-modal.component';
import { CountdownModule } from 'ngx-countdown';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { CertWizardStatsItemComponent } from './cert-wizard/cert-wizard-stats-item/cert-wizard-stats-item.component';
import { MessageComponent } from './util/message/message.component';
import {PinchZoomModule} from 'ngx-pinch-zoom';
import { ImageWithLoadingSpinnerComponent } from './util/image-with-loading-spinner/image-with-loading-spinner.component';
import {EditorModule} from '@tinymce/tinymce-angular';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    PageNotFoundComponent,
    WelcomeComponent,
    ImprintComponent,
    TosComponent,
    PrivacyComponent,
    LoadingComponent,
    DashboardComponent,
    SideNavbarComponent,
    ClientListComponent,
    ClientDetailComponent,
    SearchbarComponent,
    DeleteConfirmComponent,
    TooltipComponent,
    UserListComponent,
    UserDetailComponent,
    ModuleListComponent,
    ModuleDetailComponent,
    ModuleImportComponent,
    CardEditorComponent,
    EditorComponent,
    ModuleUserComponent,
    ModulePublicComponent,
    ConfirmComponent,
    UserProfileComponent,
    SignupFormComponent,
    AsadListComponent,
    AsadDetailComponent,
    AsadUserComponent,
    ModulePickerComponent,
    UserPickerComponent,
    AsadPublicComponent,
    LearnStartComponent,
    LearnModuleComponent,
    ModuleListLastActivityComponent,
    UserStatsTodayComponent,
    UserStatsKTrackerComponent,
    SystemLogListComponent,
    UserImportComponent,
    FocusElDirective,
    KTrackerListComponent,
    KTrackerDetailComponent,
    KTrackerStatsComponent,
    CertWizardListComponent,
    CertWizardDetailComponent,
    CertWizardStatsComponent,
    CertWizardPublicComponent,
    CertWizardLearnComponent,
    CertWizardDashboardComponent,
    CertWizardRegisterModalComponent,
    CertWizardCertificateModalComponent,
    CertWizardLoginComponent,
    ImgModalComponent,
    CertWizardPrivacyPolicyModalComponent,
    CertWizardStatsItemComponent,
    MessageComponent,
    ImageWithLoadingSpinnerComponent,
  ],
  entryComponents: [
    LoadingComponent,
    DeleteConfirmComponent,
    ModuleImportComponent,
    CardEditorComponent,
    ModuleUserComponent,
    ModulePickerComponent,
    UserPickerComponent,
    ConfirmComponent,
    AsadUserComponent
  ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        RouterModule.forRoot(appRoutes, {useHash: true, relativeLinkResolution: 'legacy'}),
        NgxPageScrollModule,
        MatSliderModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        BrowserAnimationsModule,
        FileUploadModule,
        ChartsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        NgxPageScrollModule,
        ColorPickerModule,
        NgxChartsModule,
        CountdownModule,
        ClipboardModule,
      PinchZoomModule,
      EditorModule
    ],
  providers: [
    AuthService,
    LoadingService,
    ClientService,
    UserService,
    ModuleService,
    TooltipService,
    RegistrationService,
    AsadService,
    TitleService,
    StatsService,
    SystemLogService,
    ModalWrapperService,
    KTrackerService,
    AppSettingsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    HttpClient
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(public authService: AuthService) {
    authService.autoLogin();
    if (environment.production) {
      Log.setProductionMode();
    }
  }
}

import { Component, OnDestroy} from '@angular/core';
import {CertWizard} from '../model/cert-wizard';
import {LoadingService} from '../../util/loading/loading.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {AuthService} from '../../auth/auth.service';
import {StatsService} from '../../stats/stats.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import { CertWizardService } from '../cert-wizard.service';
import {Router} from '@angular/router';
import {DeleteConfirmComponent} from '../../util/confirm-modal/delete-confirm.component';
import { Clipboard } from '@angular/cdk/clipboard';
import {TitleService} from '../../util/title/title.service';
import {ConfirmComponent} from '../../util/confirm-modal/confirm.component';
import {AsadService} from '../../asad/asad.service';
import {CertWizardListItem} from '../model/cert-wizard-list-item';

@Component({
  selector: 'app-cert-wizard-list',
  templateUrl: './cert-wizard-list.component.html',
  styleUrls: ['./cert-wizard-list.component.css']
})
export class CertWizardListComponent implements OnDestroy {

  public certWizards: CertWizardListItem[] = [];
  public filteredCertWizards: CertWizardListItem[] = [];
  public auth: any;
  public searchQuery = '';

  private sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(public authService: AuthService,
              private readonly certWizardService: CertWizardService,
              private readonly statsService: StatsService,
              public loadingService: LoadingService,
              private readonly modalService: ModalWrapperService,
              private readonly tooltipService: TooltipService,
              private readonly router: Router,
              private readonly clipboard: Clipboard,
              private readonly asadService: AsadService,
              private titleService: TitleService) {
    titleService.title = 'CertWizard';
    this.sHelper.sub = this.authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth) {
        this.init();
      }
    });
  }

  private init() {
    this.loadingService.open();
    this.sHelper.sub = this.certWizardService.findAllByUser(this.auth.user).subscribe(
      (certWizards: CertWizardListItem[]) => {
        this.certWizards = certWizards.sort(CertWizardListItem.sortByCreated);
        this.filteredCertWizards = this.certWizards;
        this.doSearch();
        this.loadingService.dismiss();
      },
      () => {
        this.loadingService.dismiss();
      }
    );
  }

  public doSearch() {
    this.filteredCertWizards = this.certWizards.filter(
      (item: CertWizardListItem) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) !== -1;
          }
          if (item.description && !match) {
            match = item.description.toLowerCase().indexOf(query) !== -1;
          }

        }

        return match;
      });
  }

  public async showStats(certWizard: CertWizardListItem, event: Event): Promise<void> {
    event.stopPropagation();
    await this.router.navigate(['/manager/cert-wizards/stats', certWizard.id]);
  }

  public async edit(certWizard: CertWizardListItem, event: Event): Promise<void> {
    event.stopPropagation();
    await this.router.navigate(['/manager/cert-wizards', certWizard.id]);
  }

  public delete(certWizard: CertWizardListItem, event: Event): void {
    event.stopPropagation();
    const modalRef = this.modalService.open(DeleteConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'delete-text';
    modalRef.componentInstance.params = {name: certWizard.name};
    modalRef.result.then(
      (deleteItem) => {
        if (deleteItem) {
          this.sHelper.sub = this.certWizardService.delete(this.auth.user, certWizard.id).subscribe(
            deleted => {
              if (deleted) {
                // delete item in local array
                let delFn = (item, i, arr) => item.id === certWizard.id ? arr.splice(i, 1) : false;
                this.certWizards.some(delFn);
                this.doSearch();
                this.tooltipService.show({translateKey: 'delete-success'});
              } else {
                this.tooltipService.show({
                  translateKey: 'delete-error',
                  type: 'danger',
                  stay: true
                });
              }
            });
        }
      });
  }

  public copyCertLink(certWizard: CertWizardListItem, event: Event): void {
    event.stopPropagation();
    this.tooltipService.show({translateKey: 'link-copied'});
    this.clipboard.copy(`${location.origin}/#/public/cert-wizards/${certWizard.hash}`);
  }

  public test(asadId, event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(ConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'sent-test-asad';
    modalRef.result.then(
      yes => {
        if (yes) {
          this.loadingService.open();
          this.sHelper.sub = this.asadService.testAsad(this.auth.user, asadId).subscribe(
            a => {
              console.log('Asad test sent! id: ' + a.id);
            },
            e => console.error(e),
            () => this.loadingService.dismiss()
          );
        }
      })
  }

  public ngOnDestroy(): void {
    this.sHelper.unsubscribeAll();
  }
}

import {Module} from 'app/module/module.model';
import {SupportedLang} from './supported-lang';

export class CertWizardModule {
  public id: number = null;

  public lang: SupportedLang = SupportedLang.de;

  public module: Module = null;
  /**
   * determines if module is used for certificate
   */
  public exam = false;

  public constructor() {
  }
}

import {Component, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../user/user.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {User} from '../../user/user.model';
import {AuthService} from '../../auth/auth.service';
import {StatsService} from '../../stats/stats.service';

@Component({
  selector: 'app-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.css']
})
export class UserPickerComponent implements OnDestroy {

  excludedUsers: Map<string, User> = new Map();

  searchQuery = '';
  users: User[] = [];
  filteredUsers: User[] = [];
  selectedUsers: Map<string, User> = new Map();
  auth: any;

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private userService: UserService,
              private statsService: StatsService,
              public authService: AuthService,
              public modal: NgbActiveModal) {
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }
    });
  }

  _init() {
    this._sHelper.sub = this.userService.findAll().subscribe(
      users => {
        this.users = users.filter(item => this.excludedUsers.get(item.id) == null);
        this.users = this.users.sort(User.sortByName);
        this.doSearch();
      },
      e => {
      }
    );
  }

  doSearch() {
    this.filteredUsers = this.users.filter(
      (item: User) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.forename && !match) {
            match = item.forename.toLowerCase().indexOf(query) != -1;
          }
        }

        return match;
      });
  }

  toggleUser(user: User) {
    if (this.selectedUsers.get(user.id)) {
      this.selectedUsers.delete(user.id);
    } else {
      this.selectedUsers.set(user.id, user);
    }
  }

  submit() {
    let result: User[] = [];
    this.selectedUsers.forEach(v => result.push(v));
    this.modal.close(result);

  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">{{'tos'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">
    <div class="col-xs-12 col-md-3 push-md-9" style="margin-bottom: 30px">
      <div class="list-group">
        <a class="list-group-item list-group-item-action" pageScroll href="#1">1. Inhalte und Urheberrechte auf easylearner</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#2">2. Verantwortlichkeit des Betreibers von easylearner</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#3">3. Korrektheit von Inhalten auf easylearner</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#4">4. Verhaltensregeln</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#5">5. Verlässlichkeit und Verfügbarkeit von easylearner</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#6">6. Privatsphäre</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#7">7. easylearner FREE und easylearner PRO</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#8">8. Sonstiges</a>
      </div>
    </div>
    <div class="col-xs-12 col-md-9 pull-md-3">
      <p>Die Webseiten www.easy-learner.de und www.easylearner.de
        werden zusammenfassend "easylearner" genannt.</p>
      <p>Nachfolgend wird jede Person, die ein Mitgliedskonto auf
        easylearner besitzt, "Benutzer" genannt.</p>

      <h4 id="1">1. Inhalte und Urheberrechte auf easylearner</h4>
      <p>Jeder Benutzer ist für alle Inhalte, die er auf easylearner eingibt und speichert, d.h. Texte, Bilder,
        MP3-Dateien, verantwortlich.</p>
      <p>Bei allen Karteikarten, die ein Benutzer erstellt oder verändert, ist er verpflichtet, sicherzustellen, dass
        kein urheberrechtlich geschützter Inhalt enthalten ist. Dies gilt vor allem für die Inhalte sämtlicher Werke von
        Verlagen jeglicher Art.</p>
      <p>Jeder Benutzer bestätigt und versichert gegenüber easylearner, dass er über sämtliche erforderlichen Rechte,
        Vollmachten und Befugnisse verfügt um seine / ihre Inhalte zu publizieren.</p>
      <p>Jeder Benutzer erkennt an, dass er für alle Inhalte, die er während der Nutzung von easylearner erstellt,
        überträgt oder darstellt, und die Auswirkungen seiner Handlungen (einschließlich etwaiger Verluste oder Schäden,
        die easylearner oder Dritten hierbei entstehen können), haftet.</p>
      <p>Sollte ein Benutzer Inhalte auf easylearner einstellen, die gegen geltendes Recht verstoßen, so behält sich der
        Betreiber von easylearner das Recht vor, diese Inhalte umgehend zu entfernen.</p>
      <p>Die von Benutzern selbständig erarbeiteten und als öffentlich verfügbar eingestellten Inhalte dürfen gemäß der
        Creative Commons Licence weiterverwendet werden.</p>
      <p>Bei nicht öffentlich verfügbar gemachten Inhalten, die nicht Urheberrechte Dritter verletzen, verbleibt das
        Urheberrecht beim Benutzer.</p>

      <h4 id="2">2. Verantwortlichkeit des Betreibers von easylearner</h4>
      <p>Der Betreiber von easylearner ist in keiner Weise für die von den Nutzern hochgeladenen Inhalte verantwortlich
        und macht sich diese auch nicht zu Eigen.</p>
      <p>easylearner bietet lediglich den technischen Dienst zur Erstellung, Speicherung und des Austauschs der
        einzelnen Karteikarten.</p>
      <p>Eine ständige Überprüfung aller eingestellten Inhalte auf mögliche Rechtsverletzungen ist nicht realisierbar
        und findet daher grundsätzlich nicht statt.</p>
      <p>Selbstverständlich reagiert der Betreiber easylearner aber auf Rechtsverstöße nach berechtigtem Hinweis und
        löscht rechtsverletzende Inhalte dann unverzüglich.</p>

      <h4 id="3">3. Korrektheit von Inhalten auf easylearner</h4>
      <p>Alle Inhalte auf easylearner werden von den Benutzern dieser Webseite erstellt. Es gibt hier keine offiziellen
        Inhalte von Universitäten, Bildungsanstalten oder anderen Institutionen.</p>
      <p>Die Korrektheit der Inhalte dieser Seite wird nicht gewährleistet. Alle Inhalte werden kontinuierlich durch die
        Benutzer der Seite erstellt und/oder verändert. Eine Überprüfung auf Korrektheit durch easylearner ist hierbei
        nicht möglich.</p>
      <p>Dies gilt nicht für Inhalte von Verlagen, die in Kooperation mit easylearner kostenpflichtig erhältlich
        sind.</p>

      <h4 id="4">4. Verhaltensregeln</h4>
      <p>Auf easylearner sind keine rassistischen, hetzerischen oder beleidigenden Äußerungen in jeglicher hier
        möglichen Form erlaubt.</p>
      <p>Auf easylearner muss die Netiquette eingehalten werden.</p>
      <p>Der Betreiber von easylearner behält sich das Recht vor, Benutzerkonten von Benutzern, die sich nicht an die
        Verhaltensregeln halten, stillzulegen.</p>

      <h4 id="5">5. Verlässlichkeit und Verfügbarkeit von easylearner</h4>
      <p>Eine ständige Verfügbarkeit von easylearner ist nicht gewährleistet.</p>
      <p>Jeder Benutzer ist aufgefordert, selbst so gründlich wie möglich Vorsorge für den Fall zu treffen, dass
        easylearner ausfällt. Hierzu sollten regelmäßig die Druck- oder Export-Funktionen für das jeweilige Lernmaterial
        benutzt werden. So stellt jeder Benutzer selbst sicher, dass ihm sein hier erstelltes Lernmaterial auch dann zur
        Verfügung steht, falls easylearner mal ausfallen sollte - was bei easylearner jederzeit wie grundsätzlich bei
        jeder Internet-Seite passieren kann.</p>

      <h4  id="6">6. Privatsphäre</h4>
      <p>Die Mailadresse und ggf. der reale Name eines Benutzers werden, sofern bei easylearner hinterlegt, nicht an
        Dritte weitergegeben.</p>
      <p>Die bei easylearner gespeicherten Lernstatistiken eines Nutzers bleiben ebenfalls geschützt, solange der
        Benutzer die Möglichkeit zur Einsicht nicht explizit einem anderen Benutzer gewährt hat. (Dies macht ein
        Benutzer implizit, wenn er sich auf easylearner mit einem anderen Benutzer "vernetzt".)</p>
      <p>Es gelten die separat hinterlegten Datenschutzbedingungen.</p>

      <h4  id="7">7. easylearner FREE und easylearner PRO</h4>
      <p>Ein Benutzer erhält den PRO-Status, wenn er den angegebenen Betrag für den jeweilig angegebenen Zeitraum
        zahlt.</p>
      <p>Sollte ein Benutzer per PayPal zahlen, wird der Pro-Status erst aktiviert, wenn die Zahlung durch PayPal als
        "Abgeschlossen" markiert wurde.</p>
      <p>Im Falle einer Zahlung per Überweisung kann und darf es zu einer Verzögerung des Aktivierens des PRO-Status für
        den Nutzer kommen.</p>
      <p>Im Falle des Zahlens per PayPal kann und darf es im Falle von technischen Komplikationen ebenfalls zu einer
        Verzögerung des Aktivierens des PRO-Status kommen.</p>

      <h4 id="8">8. Sonstiges</h4>
      <p>Für Lern- bzw. Prüfungserfolg gibt es seitens des Betreibers von easylearner keine Gewährleistung.</p>
      <p>Der Betreiber von easylearner behält sich das Recht vor, diese Nutzungsbedingungen zur ändern. Der Betreiber
        von easylearner behält sich im Falle des Verstoßes eines Benutzers gegen diese Nutzungsbedingungen das Recht
        vor, von diesem Benutzer eingestellte Inhalte zu löschen. Für den Fall des mehrmaligen Verstoßes behält er sich
        das Recht vor, entsprechende Benutzerkonten zu schließen.</p>
    </div>
  </div>
</div>

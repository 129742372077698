import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cert-wizard-privacy-policy-modal',
  templateUrl: './cert-wizard-privacy-policy-modal.component.html',
  styleUrls: ['./cert-wizard-privacy-policy-modal.component.css']
})
export class CertWizardPrivacyPolicyModalComponent implements OnInit {

  constructor(private readonly activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public close() {
    this.activeModal.close(false)
  }
}

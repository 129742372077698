import {Injectable} from '@angular/core';
import 'rxjs/add/operator/map';
import {Subject} from 'rxjs';
import {TooltipConfig} from './tooltip.component';

@Injectable()
export class TooltipService {

  private _tooltip = new Subject<TooltipConfig>();
  public tooltip = this._tooltip.asObservable();

  constructor() {
  }

  show(config: TooltipConfig) {
    this._tooltip.next(config);
  }

}

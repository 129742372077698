<div class="d-flex justify-content-center align-content-center align-items-center aeonik-regular"
     [style.background-color]="certWizard.tertiaryColor"
     [style.min-height]="windowHeight+'px'">

  <div [style.max-width]="wrapperMaxWidth + 'px'"
       [style.width]="wrapperWidth + 'px'"
       [style.max-height]="wrapperMaxHeight+'px'"
       [style.background-color]="'white'"
       class="cert-wiz-wrapper">

    <nav #navbar class="navbar d-flex flex-row justify-content-between navbar-light bg-primary-4">
      <a #navRef class="navbar-brand mr-auto" style="padding-bottom: 0;padding-top: 2px">
        <img #navImageLeft src="" height="40" alt="">
      </a>

      <img #navImageRight src="" height="40" alt="">

    </nav>


    <div class="d-flex flex-column overflow-auto"
         [style.min-height]="contentMinHeight+'px'">

      <p *ngIf="!showDisclaimer" class="mt-2 ml-2 font-weight-bold scroll-target-top">{{progressCards}}/{{cardAmount}}</p>

      <div #learnCardContainer class="d-flex justify-content-center mb-auto mt-auto">



        <div class="learn-card mb-auto mt-auto">





          <!-------------------------------------------------------------------------------------------------------------------------
           --------------------------------------------------- top part of card --------------------------------------------------
           --------------------------------------------------- holds questions and answers ------------------------------------------
           -------------------------------------------------------------------------------------------------------------------------->
          <!-------------------------------------------------- question -------------------------------------------------->
          <div *ngIf="currentLearnViewState === 'ONE_SIDE_QUESTION'
        || currentLearnViewState === 'TWO_SIDE_QUESTION_NO_INPUT'
        || currentLearnViewState === 'TWO_SIDE_QUESTION_INPUT'
        || currentLearnViewState === 'MULTIPLE_CHOICE_QUESTION'
        || currentLearnViewState === 'MULTIPLE_CHOICE_ANSWER'">
            <div class="text-center aeonik-black question m-2" [innerHTML]="showDisclaimer ? ('saw-disclaimer' | translate): currentCard.frontText"></div>
          </div>

          <div *ngIf="currentLearnViewState === 'TWO_SIDE_ANSWER_INPUT'
        || currentLearnViewState === 'TWO_SIDE_ANSWER_NO_INPUT'">
            <div class="text-center aeonik-black question m-2" [innerHTML]="showDisclaimer ? ('saw-disclaimer' | translate): currentCard.frontText"></div>
          </div>
          <!-------------------------------------------------- question -------------------------------------------------->

          <!-------------------------------------------------------------------------------------------------------------------------
 --------------------------------------------------- middle part of card -----------------------------------------------------
 --------------------------------------------------- holds pictures -------------------------------------------------------
 ------------------------------------------------------------------------------------------------------------------------->
          <!-------------------------------------------------- front card image -------------------------------------------------->
          <div #questionElem *ngIf="currentLearnViewState === 'ONE_SIDE_QUESTION'
        || currentLearnViewState === 'TWO_SIDE_QUESTION_NO_INPUT'
        || currentLearnViewState === 'TWO_SIDE_QUESTION_INPUT'
        || currentLearnViewState === 'MULTIPLE_CHOICE_QUESTION'">
            <div *ngIf="currentCard.frontCardImages.length > 0 && !showDisclaimer" class="m-3">
              <app-image-with-loading-spinner
                [url]="getImageURL(currentCard.frontCardImages[0].image)"
                [alt]="currentCard.frontCardImages[0].image.name">
              </app-image-with-loading-spinner>
            </div>
          </div>
          <!-------------------------------------------------- front card image -------------------------------------------------->

          <!-------------------------------------------------- back card image -------------------------------------------------->
          <div *ngIf="currentLearnViewState === 'TWO_SIDE_ANSWER_NO_INPUT' || currentLearnViewState ===  'TWO_SIDE_ANSWER_INPUT'"
               class="m-3">
            <div *ngIf="currentCard.backCardImages.length > 0">
              <app-image-with-loading-spinner
                [url]="getImageURL(currentCard.backCardImages[0].image)"
                [alt]="currentCard.backCardImages[0].image.name">
              </app-image-with-loading-spinner>
            </div>
          </div>
          <!-------------------------------------------------- back card image -------------------------------------------------->

          <!-------------------------------------------------- two side answer -------------------------------------------------->
          <div *ngIf="currentLearnViewState === 'TWO_SIDE_ANSWER_NO_INPUT' || currentLearnViewState === 'TWO_SIDE_ANSWER_INPUT'">
            <div class="text-center aeonik-regular answer m-2" [innerHTML]="currentCard.backText"></div>
          </div>
          <!-------------------------------------------------- two side answer -------------------------------------------------->


          <!-------------------------------------------------- two side answer input -------------------------------------------------->
          <div *ngIf="currentLearnViewState === 'TWO_SIDE_ANSWER_INPUT'">
            <div class="text-center aeonik-regular align-middle answer">
              {{'your-answer'|translate}}
            </div>
            <div class="text-center aeonik-regular align-middle answer"
                 [class.text-success]="currentTwoSideAnswerCorrect"
                 [class.text-danger]="!currentTwoSideAnswerCorrect"
            >"{{currentTwoSideAnswerText}}"</div>
          </div>
          <!-------------------------------------------------- two side answer -------------------------------------------------->


          <!-------------------------------------------------- multiple choice question -------------------------------------------------->
          <div *ngIf="currentLearnViewState === 'MULTIPLE_CHOICE_QUESTION'">
            <div class="border-005 ml-1 mr-1"></div>
            <div *ngFor="let mc of currentCard.cardMcChoices; let i = index">
              <div class="pb-1 pl-1 pr-1 d-flex flex-grow-1">

                <div (click)="checkMcChoice(i)" class="d-flex align-items-center align-content-center btn btn-block btn-labeled text-left">
                  <span class="btn-labeled-left d-flex align-items-center justify-content-center">
                  <i class="fa fa-circle fa-3x"
                     [style.color]="isCheckedMcChoice(i) ? certWizard.color : certWizard.tertiaryColor"></i>
                </span>
                  <span class="btn-labeled-left-text answer">
                    {{mc.text}}
                  </span>
                </div>

              </div>
              <div class="border-005 ml-1 mr-1"></div>
            </div>
          </div>
          <!-------------------------------------------------- multiple choice question -------------------------------------------------->


          <!-------------------------------------------------- multiple choice answer -------------------------------------------------->
          <div *ngIf="currentLearnViewState === 'MULTIPLE_CHOICE_ANSWER'">

            <div class="border-005 ml-1 mr-1"></div>
            <div *ngFor="let mc of currentCard.cardMcChoices; let i = index" >
              <div class="pb-1 pl-1 pr-1 d-flex flex-grow-1">

                <div (click)="checkMcChoice(i)" class="d-flex align-items-center align-content-center btn btn-block btn-labeled text-left">
                  <span class="btn-labeled-left d-flex align-items-center justify-content-center">
                  <i class="fa fa-3x"
                     [class.fa-check-circle]="isCorrectMcChoice(i)"
                     [class.fa-times-circle]="!isCorrectMcChoice(i)"
                     [style.color]="isCorrectMcChoice(i) ? 'green' : 'red'"></i>
                </span>
                  <span class="btn-labeled-left-text answer ">
                    {{mc.text}}
                  </span>
                </div>

              </div>
              <div class="border-005 ml-1 mr-1"></div>
            </div>
          </div>
          <!-------------------------------------------------- multiple choice answer -------------------------------------------------->


          <!-------------------------------------------------------------------------------------------------------------------------
           --------------------------------------------------- bottom part of card --------------------------------------------------
           --------------------------------------------------- holds different kinds of buttons -------------------------------------
           -------------------------------------------------------------------------------------------------------------------------->
          <!-------------------------------------------------- show answer with input -------------------------------------------------->
          <div *ngIf="currentLearnViewState === 'TWO_SIDE_QUESTION_INPUT'" class="input-group">
            <input type="text" class="form-control ml-1 mb-1 mt-1" placeholder="{{'enter-answer-here'|translate}}" [(ngModel)]="currentTwoSideAnswerText">
            <div class="input-group-append mr-1 mb-1 mt-1 action-btn">
              <button (click)="handleNextCard()" class="btn btn-success">{{'answer'|translate}}</button>
            </div>
          </div>
          <!-------------------------------------------------- show answer with input -------------------------------------------------->


          <!-------------------------------------------------- show answer without input -------------------------------------------------->
          <div class="p-1">
            <button
              *ngIf="currentLearnViewState === 'TWO_SIDE_QUESTION_NO_INPUT'
            || currentLearnViewState ===  'MULTIPLE_CHOICE_QUESTION'"
              (click)="handleNextCard()" type="button" class="btn btn-block text-center action-btn"
              [style.background-color] = "certWizard.color"
              [style.border-color] = "certWizard.color"
              [style.color] = "certWizard.fontColor"
              style="font-size: 1.5rem">
              {{'show-answer'|translate}}
            </button>
          </div>
          <!-------------------------------------------------- show answer without input -------------------------------------------------->


          <!-------------------------------------------------- known or not known -------------------------------------------------->
          <div *ngIf="currentLearnViewState === 'TWO_SIDE_ANSWER_NO_INPUT'
        || currentLearnViewState === 'TWO_SIDE_ANSWER_INPUT'" class="d-flex flex-row flex-wrap p-2" role="group">

            <button (click)="handleNextCard(false)"
                    type="button" class="btn btn-block btn-danger text-left action-btn"
                    [disabled]="currentLearnViewState === 'TWO_SIDE_ANSWER_INPUT' && currentTwoSideAnswerCorrect"
                    style="font-size: 1.5rem">
              {{'not-known'|translate}}
            </button>

            <button (click)="handleNextCard(true)"
                    type="button" class="btn btn-block btn-success text-left action-btn"
                    [disabled]="currentLearnViewState === 'TWO_SIDE_ANSWER_INPUT' && !currentTwoSideAnswerCorrect"
                    style="font-size: 1.5rem">
              {{'known'|translate}}

            </button>
          </div>
          <!-------------------------------------------------- known or not known -------------------------------------------------->


          <!-------------------------------------------------- show next -------------------------------------------------->
          <div #nextBtnElem class="d-flex align-content-end justify-content-end scroll-target-bottom pr-2 pb-2">
            <button
              *ngIf="currentLearnViewState === 'MULTIPLE_CHOICE_ANSWER'
            || currentLearnViewState === 'ONE_SIDE_QUESTION'
            || currentLearnViewState === 'TWO_SIDE_ANSWER_NO_INPUT'
            || currentLearnViewState === 'TWO_SIDE_ANSWER_INPUT'"
              (click)="handleNextCard(false)" type="button" class="btn text-left action-btn"
              style="font-size: 1.5rem;"
              [style.background-color] = "certWizard.color"
              [style.border-color] = "certWizard.color"
              [style.color] = "certWizard.fontColor">
              {{'next'|translate}}
            </button>
          </div>
          <!-------------------------------------------------- show next -------------------------------------------------->
        </div>
      </div>
    </div>


    <i *ngIf="showWrongAnswerAnimation" class="fa fa-remove fa-5x scale-up-center-fade-out absolute-center" aria-hidden="true" style="color: red"></i>

    <i *ngIf="showRightAnswerAnimation" class="fa fa-check fa-5x scale-up-center-fade-out absolute-center" aria-hidden="true" style="color: green"></i>


  </div>
</div>

import {Component, OnInit, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ClientService} from '../client.service';
import {Client} from '../client.model';
import {Image} from '../../module/image.model';
import {LoadingService} from '../../util/loading/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {environment} from '../../../environments/environment';
import {FileUploader} from 'ng2-file-upload';
import {AuthService} from '../../auth/auth.service';
import {TitleService} from '../../util/title/title.service';

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit, OnDestroy {

  public uploader: FileUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });

  isEditMode: boolean;
  auth: any;
  client: Client;
  errors: Map<string, string> = new Map();

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private clientService: ClientService,
              public loadingService: LoadingService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private authService: AuthService,
              private titleService: TitleService) {
    this.client = new Client();
    this._sHelper.sub = this.authService.auth.subscribe(auth => {
      this.auth = auth;
    });
  }

  ngOnInit() {
    this.loadingService.open();
    this._sHelper.sub = this.route.params.subscribe((params: Params) => {
        if ('new' == params['id']) {
          this.titleService.title = 'new-client';
          this.isEditMode = false;
          this.loadingService.dismiss();
        } else {
          this.titleService.title = 'edit-client';
          this.isEditMode = true;
          this._sHelper.sub = this.clientService.findById(params['id']).subscribe(
            client => {
              this.client = client;
              this.loadingService.dismiss();
            });
        }

      },
      (error) => {
        this.loadingService.dismiss();
      });

    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);
    this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    this.uploader.onCompleteAll = this.onCompleteAll.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
  }

  onSuccessItem(item: any, response: any, status: any, headers: any): any {
    if (response) {
      let img = Image.createWith(JSON.parse(response));
      console.log('img:', img);
      this.errors.delete('img-load');
      this.client.image = img;
    }
    console.log({item, response, status, headers});
  }

  onBeforeUploadItem(fileItem): any {
    this.errors.delete('error');
    this.loadingService.open();
  }

  onCompleteAll(): any {
    this.loadingService.dismiss();
  }

  onErrorItem(item, response, status, headers): any {
    let errorJSON = null;
    try {
      errorJSON = JSON.parse(response);
    } catch (e) {
      this.errors.set('error', 'img-upload-error');
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set(k, errorJSON[k]);
        });
    }
    console.log(item, response, status, headers);
  }

  getRegistrationPage(): string {
    if (this.client) {
      let port = '';
      if (location.port != '' && location.port != '80' && location.port != '443') {
        port = ':' + location.port;
      }

      return location.protocol + '//'
        + location.hostname + port
        + location.pathname
        + '#/signup/' + this.client.code;
    }
    return '';
  }

  submit() {
    this.loadingService.open();

    if (!this.client.name || this.client.name.trim() === '') {
      this.errors.set('name', '');
    } else {
      this.client.name = this.client.name.trim();
      if (this.client.name.length >= 2 && this.client.name.length <= 100) {
        this.errors.delete('name');
      } else {
        this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('name', res);
          });
      }
    }

    if (!this.client.registrationPassword || this.client.registrationPassword.trim() === '') {
      this.errors.delete('password');
    } else {
      this.client.registrationPassword = this.client.registrationPassword.trim();
      if (this.client.registrationPassword.length >= 6 && this.client.registrationPassword.length <= 20) {
        this.errors.delete('password');
      } else {
        this._sHelper.sub = this.translate.get('error-length', {min: '6', max: '20'}).subscribe(
          res => {
            this.errors.set('password', res);
          });
      }
    }

    if (this.errors.size == 0) {
      if (this.isEditMode) {
        this.client.users = null;
        this._sHelper.sub = this.clientService.update(this.client).subscribe(
          updated => {
            if (updated) {
              // refresh auth object if user's client was updated
              if (this.auth.client.id == this.client.id) {
                Object.assign(this.auth.client, this.client);
              }
              this.tooltipService.show({translateKey: 'update-success'});
              if (this.auth.user.role == 'admin') {
                this.router.navigate(['/admin/clients']);
              }
            }
            this.loadingService.dismiss();
          }, error => {
            console.log(error);
            this.errors.set('form', 'update-error');
            this.loadingService.dismiss();
          });
      } else {
        this._sHelper.sub = this.clientService.persist(this.client).subscribe(
          newId => {
            if (newId) {
              this.tooltipService.show({translateKey: 'create-success'});
              this.router.navigate(['/admin/clients']);
            }
            this.loadingService.dismiss();
          }, error => {
            console.log(error);
            this.errors.set('form', 'create-error');
            this.loadingService.dismiss();
          });

      }
    } else {
      this.loadingService.dismiss();
    }
  }

  clearImage() {
    this.client.image = null;
    this.errors.delete('error');
  }

  getImageURL() {
    return environment.backendApi + '/images/' + this.client.image.code + '/' + this.client.image.name;
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

<div class="modal-header">
  <h4 class="modal-title" *ngIf="_module">
    {{_module.name}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <div class="loading d-flex text-center" *ngIf="loading">
    <i class="fa fa-spinner fa-spin fa-4x fa-fw align-self-center w-100"></i>
  </div>

  <searchbar class="mb-1" [(query)]="searchQuery" (input)="doSearch()"
             placeholder="{{'search'|translate}} {{'user'|translate}}">

  </searchbar>
  <br>
  <button type="button"
          class="btn btn-link pl-0"
          (click)="selectAll()"
          [disabled]="assignedUsers.size == users.length">
    <i class="fa fa-check-square-o" aria-hidden="true"></i>
    {{'select-all'|translate}}
  </button>

  <div class="scrollable">
    <div class="list-group list-group-flush">
      <span class="list-group-item list-group-item-action d-flex"
            [class.active-1]="assignedUsers.get(user.id)"
            (click)="toggleUser(user)"
            *ngFor="let user of filteredUsers;let umIdx = index">
        <span class="pt-2 mr-auto">{{user.name}} {{user.forename}}</span>
        <select *ngIf="assignedUsers.get(user.id)"
                [(ngModel)]="assignedUsers.get(user.id).permission"
                style="width: initial"
                class="" name="permission"
                [disabled]="_module.user.id == user.id"
                (click)="stopPropagation($event)">
          <option value="READ">{{'read'|translate}}</option>
          <option value="WRITE">{{'write'|translate}}</option>
        </select>
      </span>
    </div>
  </div>
  <div class="d-flex justify-content-end text-capitalize mt-2">
    {{'selected'|translate}}:
    <div class="text-primary-2 text-right" style="width: 20px">{{assignedUsers.size-1}}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-success"
          [class.disabled]="loading"
          (click)="submit()">
    {{'apply'|translate}}
  </button>
  <button type="button" class="btn btn-secondary" (click)="modal.close()">{{'cancel'|translate}}</button>
</div>

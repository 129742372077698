import {Component, OnDestroy} from '@angular/core';
import {Module} from '../../module/module.model';
import {Router, Params, ActivatedRoute} from '@angular/router';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {AuthService} from '../../auth/auth.service';
import {LoadingService} from '../../util/loading/loading.service';
import {ModuleService} from '../../module/module.service';
import {TitleService} from '../../util/title/title.service';
import {StatsService} from '../../stats/stats.service';
import {forkJoin} from 'rxjs';
import {ModuleStat} from '../../stats/module-stat.model';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Log} from 'ng2-logger';

@Component({
  selector: 'app-learn-start',
  templateUrl: './learn-start.component.html',
  styleUrls: ['./learn-start.component.css']
})
export class LearnStartComponent implements OnDestroy {

  module: Module;
  moduleStat: ModuleStat;
  auth: any;
  _sHelper: SubscriptionHelper = new SubscriptionHelper;
  phaseDaysText = '';
  nextTrainingDayCount = 0;
  averageLearnTime = '';
  isReadonly = false;

  private readonly log = Log.create('LearnStartComponent');

  constructor(private moduleService: ModuleService,
              private statsService: StatsService,
              private translate: TranslateService,
              public loadingService: LoadingService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private titleService: TitleService) {
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this.log.info('auth != null')
        this._init();
      }

    });
  }

  private _init() {
    this.loadingService.open();
    this.log.info('this.loadingService.open()')
    this._sHelper.sub = this.route.params.subscribe((params: Params) => {
        let module_id = parseInt(params['module_id']);
        // Parallel
        this.log.info('this._sHelper.sub')
        this._sHelper.sub = forkJoin(
          [
            this.moduleService.findByUserAndId(this.auth.user, '' + module_id),
            this.statsService.findModuleStatsByUserAndModuleIds(this.auth.user, ['' + module_id])
          ])
          .subscribe(
            results => {
              this.log.info('this._sHelper.sub subscribe')
              this.module = results[0];
              this.titleService.title = this.module.name;
              this.isReadonly = this.module.permission(this.auth.user) == 'READ';

              let moduleStats = results[1];
              if (moduleStats.length > 0) {
                this.moduleStat = moduleStats[0];
                this.calcNextTrainingDayCount();
                this._sHelper.sub = this.translate.get('phase').subscribe(phase => {
                  this.phaseDaysText =
                    phase + ' 1 - ' + this.moduleStat.phasesHoldDays[0] + '<br>'
                    + phase + ' 2 - ' + this.moduleStat.phasesHoldDays[1] + '<br>'
                    + phase + ' 3 - ' + this.moduleStat.phasesHoldDays[2] + '<br>'
                    + phase + ' 4 - ' + this.moduleStat.phasesHoldDays[3] + '<br>'
                    + phase + ' 5 - ' + this.moduleStat.phasesHoldDays[4] + '<br>';

                  if (this.moduleStat.averageLearnTime > 59) {
                    this.averageLearnTime = moment.duration(this.moduleStat.averageLearnTime, 'seconds').minutes() + 'm ';
                  }
                  this.averageLearnTime += moment.duration(this.moduleStat.averageLearnTime, 'seconds').seconds() + 's';

                });
              }
            },
            e => console.error(e),
            () => this.loadingService.dismiss());
      },
      e => {
        console.error(e);
        this.loadingService.dismiss();
      });

  }

  getHeightForPhase(idx): string {
    if (this.moduleStat.cardCountPhases[idx] == 0) {
      return '1px';
    }

    let maxCardCount = 0;
    this.moduleStat.cardCountPhases.forEach(count => {
      if (maxCardCount < count) {
        maxCardCount = count;
      }
    });
    return '' + (160 / maxCardCount * this.moduleStat.cardCountPhases[idx]) + 'px';
  }

  getColorForPhase(idx) {
    switch (idx) {
      case 0:
        return '#708FFF';
      case 1:
        return '#4D4AFF';
      case 2:
        return '#4F00FF';
      case 3:
        return '#3D00FF';
      case 4:
        return '#21009C';
      case 5:
        return '#210F3D';
    }
  }

  edit() {
    this.router.navigate(['/modules', this.module.id]);
  }

  learn(learnMode) {
    this.router.navigate(['/learn/module', this.module.id, {mode: learnMode}]);
  }

  calcNextTrainingDayCount() {
    if (this.moduleStat.nextLearnDate) {
      let now: number = moment().hours(0).minutes(0).seconds(0).milliseconds(0).utc(true).unix();
      let days = moment.duration(this.moduleStat.nextLearnDate - now, 'seconds').asDays();
      this.nextTrainingDayCount = Math.round(days);
    }
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

import {Component, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {Asad} from '../asad.model';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {AsadService} from '../asad.service';
import {LoadingService} from '../../util/loading/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {UserAsad} from '../user-asad.model';
import {Module} from '../../module/module.model';
import {ModulePickerComponent} from '../../module/module-picker/module-picker.component';
import {AsadModule} from '../asad-module.model';
import {ModuleStat} from '../../stats/module-stat.model';
import {StatsService} from '../../stats/stats.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {forkJoin} from 'rxjs';
import {AsadStat} from '../../stats/asad-stat.model';
import {ConfirmComponent} from '../../util/confirm-modal/confirm.component';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-asad-detail',
  templateUrl: './asad-detail.component.html',
  styleUrls: ['./asad-detail.component.css']
})
export class AsadDetailComponent implements OnDestroy {

  dataChanged = false;
  isEditMode: boolean;
  isReadonly = false;
  isPublicPreviewMode = false;

  errors: Map<string, string> = new Map();
  scheduleDays: boolean[] = [];
  availableHours: string[] = [];
  startDate: Date;
  repetitionsMin = 1;
  repetitionsMax = 10;
  auth: any;
  asad: Asad;
  userAsads: UserAsad[];
  cardCount = 0;
  moduleStats: Map<string, ModuleStat> = new Map();
  _sHelper: SubscriptionHelper = new SubscriptionHelper;
  _ngbModalRef: NgbModalRef = null;

  constructor(private dateAdapter: DateAdapter<any>,
              private asadService: AsadService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private statsService: StatsService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              public authService: AuthService) {
    for (let i = 0; i < 24; i++) {
      this.availableHours.push('' + i);
    }
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  private _init() {
    this.loadingService.open();
    this.dateAdapter.setLocale(this.auth.user.lang);
    this._sHelper.sub = this.route.params.subscribe((params: Params) => {
        let asad_id = parseInt(params['id']);
        if (asad_id) {
          this.isPublicPreviewMode = this.route.routeConfig.path.includes('public');
          this.isEditMode = true;
          if (this.isPublicPreviewMode) {
            this._sHelper.sub = this.asadService.findPublicById(params['id']).subscribe(
              asad => {
                this.asad = asad;
                this.isReadonly = true;
                this.getModuleStats();
              },
              e => console.error(e),
              () => this.loadingService.dismiss()
            );
          } else {
            // Parallel
            this._sHelper.sub = forkJoin(
              [
                this.asadService.findByUserAndId(this.auth.user, params['id']),
                this.asadService.findUserAsads(this.auth.user, params['id'])
              ])
              .subscribe(
                results => {
                  this.asad = results[0];
                  this._initUserAsad();
                  this.isReadonly = this.asad.permission(this.auth.user) == 'READ';
                  this.getModuleStats();

                  if (results[1]) {
                    this.userAsads = results[1];
                  }

                },
                e => console.error(e),
                () => this.loadingService.dismiss()
              );

          }
        } else {
          this.isEditMode = false;
          this._initNewAsad();
          this.loadingService.dismiss();
        }
      },
      e => {
        console.error(e);
        this.loadingService.dismiss();
      });
  }

  private _initNewAsad() {
    this.asad = new Asad();
    // this.kTracker.user = this.auth.user;
    this.asad.visibility = 'PRIVATE';
    this.asad.userAsads.push(new UserAsad());
    this._initUserAsad();
  }

  private _initUserAsad() {
    let days = this.asad.userAsads[0].scheduleDays;
    for (let i = 0; i < 7; i++) {
      this.scheduleDays.push(days.includes('' + i));
    }

    if (this.asad.userAsads[0].startDate) {
      this.startDate = new Date(this.asad.userAsads[0].startDate);
    } else {
      this.startDate = new Date();
      this.startDate.setHours(0, 0, 0, 0);
    }

    if (this.asad.userAsads[0].repetitionsDone) {
      this.repetitionsMin = this.asad.userAsads[0].repetitionsDone;
      this.repetitionsMax = this.asad.userAsads[0].repetitionsDone + 10;
    }
  }

  addModule() {

    this._ngbModalRef = this.modalService.open(ModulePickerComponent);
    this.asad.asadModules.forEach(
      am => this._ngbModalRef.componentInstance.excludedModules.set(am.module.id, am.module)
    );

    this._ngbModalRef.result.then((result: Module[]) => {
      if (result) {
        result.forEach(m => {
          let am = new AsadModule();
          am.module = m;
          this.asad.asadModules.push(am);
          this.getModuleStats();
        });
        this.dataChanged = true;

        if (!this.asad.name && this.asad.asadModules.length == 1) {
          this.asad.name = this.asad.asadModules[0].module.name;
        }
      }
    }, (reason) => {
    });
  }

  getModuleStats() {
    let modules: Module[] = [];
    this.asad.asadModules.forEach(
      am => {
        if (!this.moduleStats.get(am.module.id)) {
          modules.push(am.module);
        }
      });

    if (modules.length > 0) {
      this.statsService.findModuleStatsByUserAndModuleIds(this.auth.user, modules).subscribe(
        stats => {
          stats.forEach(stat => this.moduleStats.set(stat.moduleId, stat));
          this.calcCardCount();
        });
    } else {
      this.calcCardCount();
    }
  }

  calcCardCount() {
    this.cardCount = 0;
    this.asad.asadModules.forEach(
      am => {
        if (this.moduleStats.get(am.module.id)) {
          this.cardCount += this.moduleStats.get(am.module.id).cardCount;
        }
      });
  }

  remove(asadModule: AsadModule, event) {
    event.stopPropagation();
    // delete item in local array
    let delFn = (item, i, arr) => item.module.id === asadModule.module.id ? arr.splice(i, 1) : false;
    this.asad.asadModules.some(delFn);
    this.dataChanged = true;
    this.calcCardCount();
  }

  submit(event) {
    if (!this.dataChanged && this.isEditMode) {
      this._navigateNext();
      return;
    }
    this.loadingService.open();
    this.errors.clear();

    if (!this.asad.name || this.asad.name.trim() === '') {
      this.errors.set('name', '');
    } else {
      this.asad.name = this.asad.name.trim();
      if (this.asad.name.length < 2 || this.asad.name.length > 100) {
        this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('name', res);
          });
      }
    }

    // user has to select one day at least
    let oneChecked = false;
    this.scheduleDays.forEach((check) => {
      if (check) {
        oneChecked = true;
      }
    });

    if (!oneChecked) {
      this.errors.set('schedule-days', 'error-schedule-days');
    }

    if (this.errors.size == 0) {
      // serialize schedule
      this.asad.userAsads[0].scheduleDays = '';
      this.scheduleDays.forEach((check, idx) => {
        if (check) {
          this.asad.userAsads[0].scheduleDays += idx;
        }
      });

      this.asad.userAsads[0].startDate = this.startDate.getTime();

      if (this.isEditMode) {
        // check if ASaD is connected with other users
        if (this.asad.user.id == this.auth.user.id
          && this.userAsads
          && this.userAsads.length > 1) {
          // ask if connected users ASaD settings should be updated with this settings
          const modalRef = this.modalService.open(ConfirmComponent, {size: 'sm'});
          modalRef.componentInstance.body = 'asad-update-connected-users';
          modalRef.componentInstance.translateParams = {userCount: this.userAsads.length - 1};
          modalRef.result.then(
            (updateConnectedUsers) => {
              if (updateConnectedUsers) {
                const result = this.userAsads
                  .filter(ua => ua.user.id != this.asad.user.id)
                  .map(ua => {
                    ua.active = this.asad.userAsads[0].active;
                    ua.scheduleDays = this.asad.userAsads[0].scheduleDays;
                    ua.scheduleTime = this.asad.userAsads[0].scheduleTime;
                    ua.emailLearnLink = this.asad.userAsads[0].emailLearnLink;
                  });
                this.asadService.updateUserAsad(this.auth.user, this.asad, this.userAsads).subscribe();
              }
              this._updateAsad();
            });
        } else {
          this._updateAsad();
        }

      } else {
        this._sHelper.sub =
          this.asadService.persist(this.auth.user, this.asad).subscribe(
            newId => {
              if (newId) {
                this.tooltipService.show({translateKey: 'create-success'});
                this._navigateNext();
              }
              this.loadingService.dismiss();
            }, error => {
              this._processServerErrors(error);
              this.loadingService.dismiss();
            });

      }
    } else {
      this.loadingService.dismiss();
    }
  }

  private _updateAsad() {
    this._sHelper.sub = this.asadService.update(this.auth.user, this.asad).subscribe(
      updated => {
        if (updated) {
          this.tooltipService.show({translateKey: 'update-success'});
          this._navigateNext();
        }
        this.loadingService.dismiss();
      },
      e => {
        console.error(e);
        this._processServerErrors(e);
        this.loadingService.dismiss();
      });
  }

  private _navigateNext() {
    this.router.navigate(['/asads']);
  }

  private _processServerErrors(error) {
    let errorJSON = null;
    try {
      errorJSON = error.json();
    } catch (e) {
      this.errors.set('error', error.statusText);
      console.error(e);
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set(k, errorJSON[k]);
        });
    }
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

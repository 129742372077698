import {
  AfterViewChecked, AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ModuleService} from '../../module/module.service';
import {StatsService} from '../../stats/stats.service';
import {CertWizardService} from '../cert-wizard.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {LoadingService} from '../../util/loading/loading.service';
import {Image} from '../../module/image.model';
import {environment} from '../../../environments/environment';
import {CertWizard} from '../model/cert-wizard';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {Auth} from '../../auth/auth';
import {UserRole} from '../../user/user-role';
import {CertWizardModule} from '../model/cert-wizard-module';
import {CertWizardUserStat} from '../model/cert-wizard-user-stat';
import {CertWizardRegisterModalComponent} from '../cert-wizard-register-modal/cert-wizard-register-modal.component';
import {CertWizardCertificateModalComponent} from '../cert-wizard-certificate-modal/cert-wizard-certificate-modal.component';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {User} from '../../user/user.model';
import {CertWizardImage} from '../model/cert-wizard-image';
import {stringToLang, SupportedLang} from '../model/supported-lang';
import {CertWizardImageType} from '../model/CertWizardImageType';
import {Color, ScaleType} from '@swimlane/ngx-charts';
import * as tinyColor from 'tinycolor2';
import {TitleService} from '../../util/title/title.service';
import * as dayjs from 'dayjs';
import * as locale_de from 'dayjs/locale/de';
import * as locale_en from 'dayjs/locale/en';
import * as locale_fr from 'dayjs/locale/fr';
import * as locale_it from 'dayjs/locale/it';
import * as locale_bg from 'dayjs/locale/bg';
import * as locale_cs from 'dayjs/locale/cs';
import * as locale_hu from 'dayjs/locale/hu';
import * as locale_pl from 'dayjs/locale/pl';
import * as locale_pt from 'dayjs/locale/pt';
import * as locale_ro from 'dayjs/locale/ro';
import * as locale_sh from 'dayjs/locale/hr';
import * as locale_sk from 'dayjs/locale/sk';
import * as locale_sl from 'dayjs/locale/sl';
import * as locale_sq from 'dayjs/locale/sq';
import * as locale_es from 'dayjs/locale/es';

@Component({
  selector: 'app-cert-wizard-dashboard',
  templateUrl: './cert-wizard-dashboard.component.html',
  styleUrls: ['./cert-wizard-dashboard.component.css']
})
export class CertWizardDashboardComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {

  // current user
  public auth: Auth;
  public dummy = false;

  public token = '';
  public exam = false;
  public certWizard: CertWizard = new CertWizard();
  public learnModules: CertWizardModule[] = [];
  public examModules: CertWizardModule[] = [];

  public userStat: CertWizardUserStat = new CertWizardUserStat();

  public certLoading = false;
  public waitForStats = false;

  public selectedLang: SupportedLang = SupportedLang.de;

  public wrapperMaxHeight = 650;
  public wrapperMaxWidth = 414;
  public wrapperWidth = 414;
  public contentMinHeight = 0;
  public windowHeight = 0

  public statsData = [];
  public colorScheme: Color = {
    selectable: false,
    group: ScaleType.Linear,
    name: 'right',
    domain: ['green', 'red', '#a4a4a4']
  }

  public certBlob: Blob = null;
  public certBlobName = '';

  public FALLBACK_LEFT_LOGO = '';

  public currentYear: number = new Date().getFullYear();

  public sawFooter = false;
  public sawIds = [3, 22];
  public sawSelectedLink = '';

  public nextAsadText = '';

  // company styles
  @ViewChild('navbar') navbar: ElementRef;
  @ViewChild('navRef') navRef: ElementRef;
  @ViewChild('navImageLeft') navImageLeft: ElementRef;
  @ViewChild('navImageRight') navImageRight: ElementRef;


  private body: HTMLBodyElement;
  private wrapperRef: HTMLElement;

  private sHelper: SubscriptionHelper = new SubscriptionHelper;



  @HostListener('window:resize', ['$event'])
  handleScreenSize() {
    this.resize();
  }

  private resize(): void {
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '0px');
    this.renderer.setStyle(this.body, 'padding-bottom', '0px');

    if (window.innerWidth <= 414) {
      this.wrapperMaxHeight = 915;
      this.wrapperMaxWidth = 414;
      this.wrapperWidth = window.innerWidth;
    } else {
      this.wrapperMaxHeight = 850;
      this.wrapperMaxWidth = 450;
      this.wrapperWidth = this.navbar.nativeElement.offsetWidth
    }

    this.windowHeight = window.innerHeight;
    this.contentMinHeight = window.innerHeight
      - this.navbar.nativeElement.offsetHeight;
    if (this.contentMinHeight > this.wrapperMaxHeight) {
      this.contentMinHeight = this.wrapperMaxHeight - this.navbar.nativeElement.offsetHeight;
    }
  }


  public constructor(private readonly moduleService: ModuleService,
                     private readonly statsService: StatsService,
                     private readonly certWizardService: CertWizardService,
                     private readonly modalService: ModalWrapperService,
                     private readonly authService: AuthService,
                     private readonly route: ActivatedRoute,
                     private readonly renderer: Renderer2,
                     private readonly changeDetector: ChangeDetectorRef,
                     private readonly translate: TranslateService,
                     private readonly router: Router,
                     private readonly tooltipService: TooltipService,
                     private readonly loadingService: LoadingService,
                     private readonly titleService: TitleService) {
    this.titleService.title = 'CertWizard Detail';
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.init()
  }

  private async init(): Promise<void> {
    this.FALLBACK_LEFT_LOGO = `${location.origin}/assets/img/el-logo.svg`;

    this.loadingService.open();

    const certWizardId: string = this.route.snapshot.params['cert-wizard-id'];
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.exam = this.route.snapshot.queryParamMap.get('exam')?.toLowerCase() === 'true';

    if (this.token) {
      AuthService.authToken = `Bearer ${this.token}`;
      await this.authService.autoLogin();
    }


    await this.certWizardService.findByIdPublic(certWizardId).toPromise()
      .then(cw => {
        this.certWizard = cw;
        this.handleCertWizardActive();
      }).catch(async () => {
        await this.certWizardService.findByHashPublic(certWizardId).toPromise()
          .then(cw => {
            this.certWizard = cw;
            this.handleCertWizardActive();
          }).catch(async () => {
            this.loadingService.dismiss();
            void await this.router.navigate(['message/not-found']);
          })
      });

    // await this.certWizardService.findByIdPublic(certWizardId).toPromise()
    //   .then((cw: CertWizard) => {
    //     this.certWizard = cw;
    //     this.handleCertWizardActive();
    //   }).catch(() => {
    //     this.loadingService.dismiss();
    //     void this.router.navigate(['message/not-found']);
    //   });


    await this.handleRefresh();
    this.updateModuleKindLists();
    this.setCompanyStyles();
    this.sHelper.sub = this.authService.auth.subscribe(async (auth: Auth) => {
      if (auth === undefined) {
        // has not been initialized
        await this.authService
          .createAndImpersonateDummy(this.certWizard.clientId, this.translate.currentLang)
          .toPromise();
      } else if (auth === null) {
        // create new dummy user
        await this.authService
          .createAndImpersonateDummy(this.certWizard.clientId, this.translate.currentLang)
          .toPromise();
      } else {
        this.auth = auth;
        this.dummy = auth.user.role === UserRole.dummy;
        await this.translate.use(this.auth.user.lang).toPromise();
        this.selectedLang = stringToLang(this.translate.currentLang);
        await this.certWizardService.createUserCertWizard(this.auth.user, this.certWizard).toPromise();
        await this.handleLocaleNextAsadMessage();
        const title = this.certWizard.certWizardDisplayNames
          .find(cwdn => cwdn.lang === this.auth.user.lang)?.displayName
        if (title) {
          this.titleService.title = title;
        }
        await this.getUserStat();
        this.statsData = [
          { name: 'right', value: this.userStat.learnCardCountAnsweredCorrect },
          { name: 'wrong', value: this.userStat.learnCardCountAnsweredWrong },
          { name: 'unseen', value: this.userStat.learnCardCountUnanswered },
        ];
        this.handleSawLink();
        this.handleReturnFromExam();
        this.loadingService.dismiss();
      }
    });
  }

  public async handleLocaleNextAsadMessage(): Promise<void> {
    let epochNextAsad = null;
    try {
      epochNextAsad = await this.certWizardService.getNextAsadDateTime(this.auth.user, this.certWizard);
      const date = dayjs(epochNextAsad)

      switch (this.selectedLang) {
        case SupportedLang.de:
          await this.asadTextByLocale(date, locale_de);
          break;
        case SupportedLang.en:
          await this.asadTextByLocale(date, locale_en);
          break;
        case SupportedLang.fr:
          await this.asadTextByLocale(date, locale_fr);
          break;
        case SupportedLang.it:
          await this.asadTextByLocale(date, locale_it);
          break;
        case SupportedLang.bg:
          await this.asadTextByLocale(date, locale_bg);
          break;
        case SupportedLang.cs:
          await this.asadTextByLocale(date, locale_cs);
          break;
        case SupportedLang.es:
          await this.asadTextByLocale(date, locale_es);
          break;
        case SupportedLang.hu:
          await this.asadTextByLocale(date, locale_hu);
          break;
        case SupportedLang.pl:
          await this.asadTextByLocale(date, locale_pl);
          break;
        case SupportedLang.pt:
          await this.asadTextByLocale(date, locale_pt);
          break;
        case SupportedLang.ro:
          await this.asadTextByLocale(date, locale_ro);
          break;
        case SupportedLang.sh:
          await this.asadTextByLocale(date, locale_sh);
          break;
        case SupportedLang.sk:
          await this.asadTextByLocale(date, locale_sk);
          break;
        case SupportedLang.sl:
          await this.asadTextByLocale(date, locale_sl);
          break;
        case SupportedLang.sq:
          await this.asadTextByLocale(date, locale_sq);
          break;
      }
    } catch (e) {/** ignore **/}
  }

  public async asadTextByLocale(date: dayjs.Dayjs, locale: any): Promise<void> {
    this.nextAsadText = await this.translate
      .get(
        'next-asad',
        {
          dayOfWeek: date.locale(locale).format('dddd'),
          day: date.locale(locale).format('DD'),
          monthName: date.locale(locale).format('MMM')
        }
      ).toPromise();
  }

  public handleSawLink(): void {
    if (this.sawIds.includes(Number(this.certWizard.id))) {
      this.sawFooter = true;
      switch (this.auth.user.lang) {
        case SupportedLang.it:
          this.sawSelectedLink = 'https://www.safeatwork.ch/it/vision';
          break;
        case SupportedLang.fr:
          this.sawSelectedLink = 'https://www.safeatwork.ch/fr/vision';
          break;
        default:
          // includes de
          this.sawSelectedLink = 'https://www.safeatwork.ch/de/vision';
          break;
      }
    }
  }

  public handleReturnFromExam(): void {
    if (this.exam) {
      if (this.userStat.prerequisiteCertAcquired) {
        this.tooltipService.show(
          {
            translateKey: 'exam-passed',
            type: 'light',
            showTime: 4000,
          }
        );

        void this.getCertificateModal();
      } else {
        this.tooltipService.show(
          {
            translateKey: 'exam-failed',
            type: 'light',
            showTime: 6000,
          }
        );
      }
    }
  }

  public getImageURL(image: Image) {
    return environment.backendApi + '/images/' + image.code + '/' + image.name;
  }

  private async getUserStat(): Promise<void> {
    this.userStat = await this.certWizardService.getUserStats(this.auth.user, this.certWizard);
  }

  private updateModuleKindLists(): void {
    this.learnModules = this.certWizard.certWizardModules.filter(m => !m.exam);
    this.examModules = this.certWizard.certWizardModules.filter(m => m.exam);
  }

  private handleCertWizardActive(): void {
    this.loadingService.dismiss();
    if (!this.certWizard.active) {
      void this.router.navigate(['message/inactive']);
    }
  }

  public hexToRGB(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
    } else {
      return 'rgb(' + r + ', ' + g + ', ' + b + ')';
    }
  }

  public navigateLearnView(certWizardModule?: CertWizardModule): void {
    void this.router.navigate([`/learn/cert-wizards/${this.certWizard.hash}`],
      {queryParams: {token: this.token}});
  }

  public setCompanyStyles(): void {
    this.renderer.setStyle(this.navbar.nativeElement, 'background-color', this.certWizard.color);

    if (this.getLogoLeftSecondByLang()) {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.getImageURL(this.getLogoLeftSecondByLang().image));
    } else if (this.getLogoLeftByLang()) {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.getImageURL(this.getLogoLeftByLang().image));
    } else {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.FALLBACK_LEFT_LOGO);
    }
    if (this.getLogoRightByLang()) {
      this.renderer.setAttribute(
        this.navImageRight.nativeElement,
        'src',
        this.getImageURL(this.getLogoRightByLang().image));
    }
  }


  public createCertWizUserModal(): void {
    let modalRef: NgbModalRef = this.modalService.open(CertWizardRegisterModalComponent, { size: 'sm', centered: true });
    modalRef.componentInstance.certWizard = this.certWizard;
    modalRef.result.then((success: boolean) => {
      if (success) {
        this.tooltipService.show({translateKey: 'register-success', type: 'light'});
      }
    })
  }

  public async getCertificateModal(): Promise<void> {

    let certLang = this.selectedLang as string;
    const availableLang = await this.certWizardService.langAvailable(this.certWizard);

    this.waitForStats = true;
    await this.getUserStat();
    this.waitForStats = false;

    const modalRef: NgbModalRef = this.modalService.open(
      CertWizardCertificateModalComponent,
      { size: 'sm', centered: true }
    );


    modalRef.componentInstance.availableLang = availableLang;
    modalRef.componentInstance.userStat = this.userStat;
    modalRef.componentInstance.certWizard = this.certWizard;
    modalRef.componentInstance.token = this.token;
    modalRef.componentInstance.availableLang = availableLang;

    modalRef.result.then((user: User | boolean) => {
      if (typeof user === 'object') {
        this.certLoading = true;
        this.tooltipService.show(
          {
            translateKey: 'certificate-creating',
            type: 'light',
            showTime: 4000,
          }
        );
        if (user.lang) {
          certLang = user.lang
        }
        this.certWizardService.getCertificate(user as User, this.certWizard, certLang)
          .then(async (cert: Blob) => {
            await this.getUserStat();
            this.certLoading = false;
            this.certBlob = new Blob([cert], { type: 'application/pdf' });
            this.certBlobName = user.forename.toLowerCase() + '_' + user.name.toLowerCase();
          }).catch((e) => {
          this.certLoading = false;
          this.certBlob = null;
          this.tooltipService.show(
            {
              translateKey: 'action-error',
              type: 'danger',
              showTime: 4000,
            }
          );
        })
      }
    });

  }

  public downloadCert(): void {
    const url = URL.createObjectURL(this.certBlob);
    const anchor = document.createElement('a');
    anchor.download = this.certBlobName;
    anchor.href = url;
    anchor.click();
    this.tooltipService.show(
      {
        translateKey: 'certificate-downloaded',
        type: 'light',
        showTime: 4000,
      }
    );
    anchor.remove();
  }

  public getLogoLeftByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      // @TODO
      // .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT)
      .findIndex(c => c.lang === SupportedLang.de && c.type === CertWizardImageType.LOGO_LEFT)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getLogoRightByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      // @TODO
      // .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_RIGHT)
      .findIndex(c => c.lang === SupportedLang.de && c.type === CertWizardImageType.LOGO_RIGHT)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getLogoLeftSecondByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      // @TODO
      // .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT_SECOND)
      .findIndex(c => c.lang === SupportedLang.de && c.type === CertWizardImageType.LOGO_LEFT_SECOND)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getBackgroundImage(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.type === CertWizardImageType.BACKGROUND_IMAGE)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getExamCertWizardModuleByLang(): CertWizardModule[] {
    return this.certWizard.certWizardModules.filter(c => c.lang === this.selectedLang && c.exam);
  }

  public ngAfterViewChecked(): void {
    this.body = document.getElementsByTagName('body')[0];
    this.wrapperRef = document.getElementById('content-wrapper');
    this.renderer.setAttribute(this.body, 'background-color', '#D0D0D0');
    this.resize();
  }

  public async handleRefresh(): Promise<void> {
    // tslint:disable-next-line:triple-equals
    if (history.state?.navigationId == 1) {
      await this.router.navigate([`cert-wizards/${this.certWizard.hash}/cert-wizard-dashboard`],
        {queryParams: {token: this.token}});
    }
  }

  public isHexDark(color: string): boolean {
    return tinyColor(color).isDark();
  }

  public ngOnInit(): void {
    this.body = document.getElementsByTagName('body')[0];
    this.renderer.setStyle(this.body, 'padding-bottom', '0');
    this.renderer.addClass(this.body, 'bg-image');
  }

  public ngOnDestroy() {
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '52px');
    this.renderer.setStyle(this.body, 'padding-bottom', '6rem');
    this.sHelper.unsubscribeAll();
  }
}

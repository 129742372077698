import {Component, OnInit} from '@angular/core';
import {Lection} from '../lection.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Module} from '../module.model';
import * as papaparse from 'papaparse';
import {Card} from '../card.model';
import {CardMcChoice} from '../card-mc-choice.model';
import {UserLection} from '../user-lection.model';

@Component({
  selector: 'app-module-import',
  templateUrl: './module-import.component.html',
  styleUrls: ['./module-import.component.css']
})
export class ModuleImportComponent implements OnInit {

  static readonly activateLectionCount = 2;

  module: Module
  lections: Lection[] = [];
  importOption: number;
  newLectionCardCount = 10;
  selectedLection: Lection;
  lectionName = '';
  csvData = '';

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.lections.length) {
      this.selectedLection = this.lections[0];
      this.importOption = 0;
    } else {
      this.importOption = 1;
    }
  }

  importData() {

    if (this.csvData.trim().length > 0) {
      let csvObj = papaparse.parse(this.csvData, {delimiter: '\t'});
      let currentLection: Lection = null;
      let activatedLections = 0;

      if (this.importOption == 0) {
        currentLection = this.selectedLection;
      } else {
        currentLection = new Lection();
        currentLection.userLections.push(new UserLection());
        activatedLections++;
        if (this.lectionName.trim().length > 0) {
          currentLection.name = this.lectionName.trim();
        } else {
          currentLection.name = 'Import Lektion';
        }
      }

      let currentCard = null;
      let isMCModule = this.module.type == 'MULTIPLE_CHOICE';
      csvObj.data.forEach(row => {
        let isNewCard = false;
        if (row[0]) {
          if (currentCard && isMCModule) {
            this.fillMcChoices(currentCard);
            currentCard.cardMcChoices.sort(CardMcChoice.sortByCorrect);
          }
          let nextCard = this._getCurrentCard(currentLection);
          currentCard = nextCard.c;
          isNewCard = nextCard.isNewCard;
          currentCard.frontText = row[0];
        }

        if (row[1] && !isMCModule) {
          currentCard.backText = row[1];
        }
        if (row[1] && isMCModule) {
          let mcc = this._getCurrentMcc(currentCard);
          mcc.text = row[1];
          if (row[2]) {
            mcc.correct = true;
          }
        }

        if (this.importOption == 1
          && Number.isInteger(this.newLectionCardCount)
          && this.newLectionCardCount == currentLection.cards.length) {
          let oldLection = currentLection;
          currentLection = new Lection();
          let userLection = new UserLection();
          currentLection.userLections.push(userLection);
          if (activatedLections == ModuleImportComponent.activateLectionCount) {
            userLection.active = false;
          } else {
            activatedLections++;
          }
          currentLection.name = oldLection.name;
        }

        if (isNewCard) {
          currentLection.cards.push(currentCard);
          if (currentLection.cards.length == 1) {
            this.module.lections.push(currentLection);
          }
        }
      });
      if (currentCard && isMCModule) {
        this.fillMcChoices(currentCard);
        currentCard.cardMcChoices.sort(CardMcChoice.sortByCorrect);
      }
      this.modal.close();
    }
  }

  _getCurrentCard(lection: Lection): { c: Card, isNewCard: boolean } {
    let result: Card = new Card();
    let isNewCard = true;
    for (let i = lection.cards.length - 1; i >= 0; i--) {
      let c: Card = lection.cards[i];
      if (c.frontText == ''
        && c.backText == ''
        && c.cardImages.length == 0) {
        result = c;
        isNewCard = false;
      } else {
        break;
      }
    }
    return {c: result, isNewCard: isNewCard};
  }

  _getCurrentMcc(card: Card): CardMcChoice {
    let result: CardMcChoice = new CardMcChoice();
    let isNew = true;
    let idx = card.cardMcChoices.length - 1;
    for (idx; idx >= 0; idx--) {
      let mcc: CardMcChoice = card.cardMcChoices[idx];
      if (mcc.text == '') {
        result = mcc;
        isNew = false;
      } else {
        break;
      }
    }
    if (isNew) {
      card.cardMcChoices.push(result);
      // the first answer is always correct
      if (idx == -1) {
        result.correct = true;
      }
    }
    return result;
  }

  private fillMcChoices(c: Card) {
    for (let i = c.cardMcChoices.length; i < 6; i++) {
      let mcc = new CardMcChoice();
      if (i == 0) {
        mcc.correct = true;
      }
      c.cardMcChoices.push(mcc);
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {User} from '../user.model';
import {UserService} from '../user.service';
import {LoadingService} from '../../util/loading/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FileUploader} from 'ng2-file-upload';
import {environment} from '../../../environments/environment';
import {AuthService} from '../../auth/auth.service';
import {Client} from '../../client/client.model';
import {ClientService} from '../../client/client.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {Language} from '../../util/language.model';
import {TitleService} from '../../util/title/title.service';
import {AppSettingsService} from '../../app.settings';
import {Log} from 'ng2-logger';
import {HttpErrorResponse} from '@angular/common/http';
import {supportedLangAsList} from '../../cert-wizard/model/supported-lang';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit, OnDestroy {

  public static supportedLanguages: string[] = ['en', 'de', 'ind'];

  private log = Log.create('UserDetailComponent');

  public uploader: FileUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });

  isEditMode: boolean;
  user: User;
  errors: Map<string, string> = new Map();
  auth: any;
  clients: Client[] = [];
  languages: Language[];
  supportedLangList = supportedLangAsList();
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private userService: UserService,
              private clientService: ClientService,
              public loadingService: LoadingService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              private route: ActivatedRoute,
              private router: Router,
              private authService: AuthService,
              private titleService: TitleService,
              private appSettings: AppSettingsService) {
    this.user = new User();
    this._initLanguages();

    this._sHelper.sub = this.authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth) {
        this._init();
      }
    });

  }

  ngOnInit() {
  }

  private _init() {
    this.loadingService.open();
    if (this.auth.user.role === 'admin') {
      this._sHelper.sub = this.clientService.findAll().subscribe(
        clients => {
          this.clients = clients;
          this._initUser();
        }
      );
    } else {
      this.clients.push(this.auth.client);
      this._initUser();
    }

    this.uploader.onSuccessItem = this.onSuccessItem.bind(this);
    this.uploader.onBeforeUploadItem = this.onBeforeUploadItem.bind(this);
    this.uploader.onCompleteAll = this.onCompleteAll.bind(this);
    this.uploader.onErrorItem = this.onErrorItem.bind(this);
  }

  private _initUser() {
    this._sHelper.sub = this.route.params.subscribe((params: Params) => {
        if ('new' === params['id']) {
          this.titleService.title = 'new-user';
          this.isEditMode = false;
          this.loadingService.dismiss();
          this.user.lang = this.auth.user.lang;
          let clientId = this.appSettings.load(AppSettingsService.SELECTED_CLIENT_ID);
          if (clientId) {
            this.user.client = this.clients.find(client => client.id.toString() === clientId);
          } else {
            this.user.client = this.auth.client;
          }
        } else {
          this.titleService.title = 'edit-user';
          this.isEditMode = true;
          this._sHelper.sub = this.userService.findById(params['id']).subscribe(
            u => this.user = u,
            e => console.log(e),
            () => this.loadingService.dismiss()
          );
        }
      },
      (error) => {
        this.loadingService.dismiss();
      });
  }

  onSuccessItem(item: any, response: any, status: any, headers: any): any {
    if (headers.location) {
      this.user.img = headers.location;
    }
    console.log({item, response, status, headers});
  }

  onBeforeUploadItem(fileItem): any {
    this.errors.delete('error');
    this.errors.delete('img-load');
    this.loadingService.open();
  }

  onCompleteAll(): any {
    this.loadingService.dismiss();
  }

  onErrorItem(item, response, status, headers): any {
    let errorJSON = null;
    try {
      errorJSON = JSON.parse(response);
    } catch (e) {
      this.errors.set('error', 'img-upload-error');
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set(k, errorJSON[k]);
        });
    }
    console.log(item, response, status, headers);
  }

  async submit(event) {
    this.loadingService.open();
    this.errors.clear();

    if (!this.user.login || this.user.login.trim() === '') {
      this.errors.set('login', 'empty-form');
    } else {
      this.user.login = this.user.login.trim();
    }

    if (!this.user.email || this.user.email.trim() === '') {
      this.errors.set('email', 'empty-form');
    } else {
      this.user.email = this.user.email.trim();
      if (this._validateEmail()) {
      } else {
        this.errors.set('email', 'email-error');
      }
    }

    if (!this.user.name || this.user.name.trim() === '') {
      this.errors.set('name', 'empty-form');
    } else {
      this.user.name = this.user.name.trim();
      if (this.user.name.length >= 2 && this.user.name.length <= 100) {
      } else {
        this._sHelper.sub = this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('name', res);
          });
      }
    }

    if (!this.user.forename || this.user.forename.trim() === '') {
      this.errors.set('forename', 'empty-form');
    } else {
      this.user.forename = this.user.forename.trim();
      if (this.user.forename.length >= 2 && this.user.forename.length <= 100) {
        this.errors.delete('forename');
      } else {
        this._sHelper.sub = this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('forename', res);
          });
      }
    }

    if (this.isEditMode) {
      this.errors.delete('password');
      if (this.user.password && this.user.password.trim().length > 0) {
        this.user.password = this.user.password.trim();
        if (this.user.password.length < 6 || this.user.password.length > 20) {
          this._sHelper.sub = this.translate.get('error-length', {min: '6', max: '20'}).subscribe(
            res => {
              this.errors.set('password', res);
            });
        }
      }
    } else {
      // create user mode
      if (!this.user.password || this.user.password.trim() === '') {
        this.errors.set('password', 'empty-form');
      } else {
        this.user.password = this.user.password.trim();
        if (this.user.password.length >= 6 && this.user.password.length <= 20) {
          this.errors.delete('password');
        } else {
          this._sHelper.sub = this.translate.get('error-length', {min: '6', max: '20'}).subscribe(
            res => {
              this.errors.set('password', res);
            });
        }
      }
    }

    if (this.errors.size === 0) {
      this.log.info('', this.user);
      if (this.isEditMode) {
        this._sHelper.sub = this.userService.update(this.user).subscribe(
          updated => {
            if (updated) {
              // refresh auth object if user updated own data
              if (this.auth.user.id === this.user.id) {
                Object.assign(this.auth.user, this.user);
                this.translate.use(this.user.lang);
              }
              this.tooltipService.show({translateKey: 'update-success'});
              this.router.navigate(['/manager/users']);
            }
            this.loadingService.dismiss();
          }, error => {
            console.log(error);
            this._processServerErrors(error);
            this.loadingService.dismiss();
          });
      } else {
        this._sHelper.sub = this.userService.persist(this.user).subscribe(
          newId => {
            if (newId) {
              this.tooltipService.show({translateKey: 'create-success'});
              this.router.navigate(['/manager/users']);
            }
            this.loadingService.dismiss();
          }, error => {
            this.handleBackendFormError(error as HttpErrorResponse);
            this.loadingService.dismiss();
          });

      }
    } else {
      this.loadingService.dismiss();
    }

  }

  private _initLanguages() {
    this.languages = [];
    this.translate.get(UserDetailComponent.supportedLanguages).subscribe(
      t => {
        Object.keys(t).forEach(
          k => {
            this.languages.push(new Language(k, t[k]));
          }
        );
        this.languages.sort(Language.sortByName);
      }
    );
  }

  private _processServerErrors(error) {
    let errorJSON = null;
    try {
      errorJSON = error.json();
    } catch (e) {
      this.errors.set('form', error.statusText);
      console.error(e);
    }
    if (errorJSON && !Array.isArray(errorJSON)) {
      errorJSON = [errorJSON];
    }
    if (errorJSON && Array.isArray(errorJSON) && errorJSON.length > 0) {
      Object.keys(errorJSON[0]).forEach(
        k => {
          this.errors.set(k, errorJSON[0][k]);
        });
    }
  }

  clearImage() {
    this.user.img = null;
    this.errors.delete('error');
  }

  private _validateEmail(): boolean {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.user.email);
  }

  private handleBackendFormError(res: HttpErrorResponse): void {
    // case defined error
    res.error.forEach((obj: Object) => {
      for (let key of Object.keys(obj)) {
        this.errors.set(key, obj[key]);
      }
    })
    // case undefined error
    if (res.error.length === 0) {
      this._processServerErrors(res);
    }
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

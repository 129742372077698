<div class="jumbotron jumbotron-fluid jumbotron-small"
     [class.jumbotron-active]="certWizard && certWizard.active">
  <div class="container-fluid container-limited d-flex flex-row">
    <div class="mr-auto">
      <span class="h2" *ngIf="isEditMode">
        {{'edit-cert-wizard'|translate}}
      </span>
      <span class="h2" *ngIf="!isEditMode">
      {{'new-cert-wizard'|translate}}
    </span>
    </div>
  </div>
</div>

<ul *ngIf="isEditMode" class="nav nav-tabs nav-pills nav-fill border-0">
  <li class="nav-item">
    <a class="nav-link active" id="cert-wizard-tab" data-toggle="tab" href="#cert-wizard" role="tab" aria-controls="cert-wizard" aria-selected="true">
      Einstellungen CertWizard
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="asad-tab" data-toggle="tab" href="#asad" role="tab" aria-controls="asad" aria-selected="true">
      Einstellungen ASaD
    </a>
  </li>
</ul>



<div class="tab-content" id="myTabContent">

  <div class="tab-pane fade show active"  id="cert-wizard"  role="tabpanel" aria-labelledby="cert-wizard-tab">
    <div class="container-fluid container-limited">
      <form (change)="dataChanged = true" autocomplete="off"
            *ngIf="auth && !loadingService.isOpen()" name="detail">

        <div class="row mb-4 mt-4">
          <div class="col-xs-12 offset-sm-3 col-sm-9">
            <p class="card-text text-danger" *ngIf="errors.get('error') != undefined">
              {{errors.get('error')|translate}}
            </p>
          </div>
        </div>

        <!----------------------------- active ----------------------------->
        <div class="form-group row">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label >{{'active'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" placement="top" ngbTooltip="{{'tooltip-active'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9">
            <input *ngIf="certWizard.active" type="checkbox" name="active" checked (change)="certWizard.active = !certWizard.active">
            <input *ngIf="!certWizard.active" type="checkbox" name="active" (change)="certWizard.active = !certWizard.active">
          </div>
        </div>
        <!----------------------------- active ----------------------------->

        <!----------------------------- learnMode ----------------------------->
        <div class="form-group row">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label >{{'learn-mode'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" placement="top" ngbTooltip="{{'tooltip-learn-mode'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9">
            <input *ngIf="certWizard.learnMode" type="checkbox" name="active" checked (change)="certWizard.learnMode = !certWizard.learnMode">
            <input *ngIf="!certWizard.learnMode" type="checkbox" name="active" (change)="certWizard.learnMode = !certWizard.learnMode">
          </div>
        </div>
        <!----------------------------- learnMode ----------------------------->


        <!----------------------------- name ----------------------------->
        <div class="form-group row" [class.has-danger]="errors.get('name') != undefined">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label class="form-control-label" for="name">
              {{'name'|translate}}
              <span style="color: red">*</span>
            </label>

            <button type="button" class="btn btn-link btn-sm" placement="top" ngbTooltip="{{'tooltip-name'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9">
            <input autocomplete="off" class="form-control form-control-danger" type="text" name="name"
                   id="name" [(ngModel)]="certWizard.name" minlength="2" maxlength="100">
            <div class="form-control-feedback">{{errors.get('name')|translate}}</div>
          </div>
        </div>
        <!----------------------------- name ----------------------------->


        <!----------------------------- description ----------------------------->
        <div class="form-group row"
             [class.has-danger]="errors.get('description') != undefined">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="description">{{'description'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-description'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9">
        <textarea class="form-control form-control-danger" [(ngModel)]="certWizard.description"
                  autocomplete="off"
                  maxlength="200" rows="2" name="description" id="description">
        </textarea>
            <div class="form-control-feedback">{{errors.get('description')|translate}}</div>
          </div>
        </div>
        <!----------------------------- description ----------------------------->

        <div class="dropdown-divider"></div>

        <!----------------------------- color ----------------------------->
        <ng-template #colorTooltip>
          <span>{{'tooltip-color'|translate}}</span>
          <a href="https://color.adobe.com/de/create/image" target="_blank" class="btn btn-link btn-sm">Adobe Color</a>
        </ng-template>

        <div class="form-group row" [class.has-danger]="errors.get('color') != undefined">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="colorBtn">{{'color'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top"
                    [ngbTooltip]="colorTooltip" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <button type="button" class="btn btn-secondary btn-sm pl-1"
                    [(colorPicker)]="certWizard.color"
                    [cpPosition]="'bottom'"
                    [cpOutputFormat]="'hex'"
                    id="colorBtn">

              <i class="fa fa-paint-brush"></i>
              {{'choose'|translate}}
            </button>

            <div class="btn btn-secondary btn-sm ml-2"
                 [style.min-width]="'6rem'"
                 [style.min-height]="'2rem'"
                 [style.background]="certWizard.color"
                 [style.color]="getFontColor(certWizard.color)"
                 [style.border-color]="'black'">
              {{'preview'|translate}}
            </div>
          </div>
        </div>
        <!----------------------------- color ----------------------------->

        <!----------------------------- secondary color ----------------------------->
        <ng-template #secondaryColorTooltip>
          <span>{{'tooltip-secondary-color'|translate}}</span>
          <a href="https://color.adobe.com/de/create/image" target="_blank" class="btn btn-link btn-sm">Adobe Color</a>
        </ng-template>

        <div class="form-group row" [class.has-danger]="errors.get('color') != undefined">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="secondaryColorBtn">{{'secondary-color'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top"
                    [ngbTooltip]="secondaryColorTooltip" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <button type="button" class="btn btn-secondary btn-sm pl-1"
                    [(colorPicker)]="certWizard.secondaryColor"
                    [cpPosition]="'bottom'"
                    [cpOutputFormat]="'hex'"
                    id="secondaryColorBtn">

              <i class="fa fa-paint-brush"></i>
              {{'choose'|translate}}
            </button>

            <div class="btn btn-secondary btn-sm ml-2"
                 [style.min-width]="'6rem'"
                 [style.min-height]="'2rem'"
                 [style.background]="certWizard.secondaryColor"
                 [style.color]="getFontColor(certWizard.secondaryColor)"
                 [style.border-color]="'black'">
              {{'preview'|translate}}
            </div>
          </div>
        </div>
        <!----------------------------- secondary color ----------------------------->

        <!----------------------------- tertiary color ----------------------------->
        <ng-template #tertiaryColorTooltip>
          <span>{{'tooltip-tertiary-color'|translate}}</span>
          <a href="https://color.adobe.com/de/create/image" target="_blank" class="btn btn-link btn-sm">Adobe Color</a>
        </ng-template>

        <div class="form-group row" [class.has-danger]="errors.get('color') != undefined">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="tertiaryColorBtn">{{'tertiary-color'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top"
                    [ngbTooltip]="tertiaryColorTooltip" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <button type="button" class="btn btn-secondary btn-sm pl-1"
                    [(colorPicker)]="certWizard.tertiaryColor"
                    [cpPosition]="'bottom'"
                    [cpOutputFormat]="'hex'"
                    id="tertiaryColorBtn">

              <i class="fa fa-paint-brush"></i>
              {{'choose'|translate}}
            </button>

            <div class="btn btn-secondary btn-sm ml-2"
                 [style.min-width]="'6rem'"
                 [style.min-height]="'2rem'"
                 [style.background]="certWizard.tertiaryColor"
                 [style.color]="getFontColor(certWizard.tertiaryColor)"
                 [style.border-color]="'black'">
              {{'preview'|translate}}
            </div>
          </div>
        </div>
        <!----------------------------- tertiary color ----------------------------->

        <!----------------------------- background upload ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="backgroundUploader">{{'background-image'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top"
                    ngbTooltip="{{'tooltip-background-image'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-start" *ngIf="getBackgroundImage()">
            <a href="{{this.getImageURL(getBackgroundImage())}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.getImageURL(getBackgroundImage())"/>
            </a>

            <button class="btn btn-danger" (click)="removeBackgroundImage()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div *ngIf="!getBackgroundImage()" class="col-xs-12 col-sm-9 d-flex align-items-center">
            <label *ngIf="!backgroundUploader.isUploading" class="btn btn-secondary btn-sm" id="backgroundUploadLabel"
                   style="margin-left: 0.25rem; margin-right: 0.25rem" for="backgroundUploader">
              <div>
                <i class="fa fa-picture-o" aria-hidden="true"></i>
                {{'add'|translate}}
              </div>
            </label>

            <div *ngIf="backgroundUploader.isUploading" class="d-flex align-content-center align-items-center justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>

        </div>
        <!----------------------------- background upload ----------------------------->

        <!----------------------------- background default ----------------------------->
        <div *ngIf="!getBackgroundImage()" class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="backgroundUploader">{{'default'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top"
                    ngbTooltip="{{'tooltip-background-image-default'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <a href="{{this.FALLBACK_BACKGROUND_IMAGE}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.FALLBACK_BACKGROUND_IMAGE"/>
            </a>
          </div>

        </div>
        <!----------------------------- background default ----------------------------->

        <!----------------------------- left mail upload ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="leftMailImageUploader">{{'left-mail-image'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-left-mail-image'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-start" *ngIf="getMailImageByType('LEFT_MAIL_IMAGE')">
            <a href="{{this.getImageURL(getMailImageByType('LEFT_MAIL_IMAGE'))}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.getImageURL(getMailImageByType('LEFT_MAIL_IMAGE'))"/>
            </a>

            <button class="btn btn-danger" (click)="removeMailImageByType('LEFT_MAIL_IMAGE')">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div *ngIf="!getMailImageByType('LEFT_MAIL_IMAGE')" class="col-xs-12 col-sm-9 d-flex align-items-center">
            <label *ngIf="!leftMailImageUploader.isUploading" class="btn btn-secondary btn-sm" id="leftMailImageUploaderLabel"
                   style="margin-left: 0.25rem; margin-right: 0.25rem" for="leftMailImageUploader">
              <div>
                <i class="fa fa-picture-o" aria-hidden="true"></i>
                {{'add'|translate}}
              </div>
            </label>

            <div *ngIf="leftMailImageUploader.isUploading" class="d-flex align-content-center align-items-center justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>

        </div>
        <!----------------------------- left mail upload ----------------------------->


        <!----------------------------- left mail default ----------------------------->
        <div *ngIf="!getMailImageByType('LEFT_MAIL_IMAGE')" class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="backgroundUploader">{{'default'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top"
                    ngbTooltip="{{'tooltip-left-mail-image-default'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <a href="{{this.FALLBACK_LEFT_MAIL_IMAGE}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.FALLBACK_LEFT_MAIL_IMAGE"/>
            </a>
          </div>
        </div>
        <!----------------------------- left mail default ----------------------------->

        <!----------------------------- right mail upload ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="rightMailImageUploader">{{'right-mail-image'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-right-mail-image'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-start" *ngIf="getMailImageByType('RIGHT_MAIL_IMAGE')">
            <a href="{{this.getImageURL(getMailImageByType('RIGHT_MAIL_IMAGE'))}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.getImageURL(getMailImageByType('RIGHT_MAIL_IMAGE'))"/>
            </a>

            <button class="btn btn-danger" (click)="removeMailImageByType('RIGHT_MAIL_IMAGE')">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div *ngIf="!getMailImageByType('RIGHT_MAIL_IMAGE')" class="col-xs-12 col-sm-9 d-flex align-items-center">
            <label  *ngIf="!rightMailImageUploader.isUploading" class="btn btn-secondary btn-sm"
                    style="margin-left: 0.25rem; margin-right: 0.25rem" for="rightMailImageUploader">
              <div>
                <i class="fa fa-picture-o" aria-hidden="true"></i>
                {{'add'|translate}}
              </div>
            </label>
            <div *ngIf="rightMailImageUploader.isUploading" class="d-flex align-content-center align-items-center justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>

        </div>
        <!----------------------------- right mail upload ----------------------------->

        <!----------------------------- bottom mail upload ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="bottomMailImageUploader">{{'bottom-mail-image'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-bottom-mail-image'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-start" *ngIf="getMailImageByType('BOTTOM_MAIL_IMAGE')">
            <a href="{{this.getImageURL(getMailImageByType('BOTTOM_MAIL_IMAGE'))}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.getImageURL(getMailImageByType('BOTTOM_MAIL_IMAGE'))"/>
            </a>

            <button class="btn btn-danger" (click)="removeMailImageByType('BOTTOM_MAIL_IMAGE')">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div *ngIf="!getMailImageByType('BOTTOM_MAIL_IMAGE')" class="col-xs-12 col-sm-9 d-flex align-items-center">
            <label *ngIf="!bottomMailImageUploader.isUploading" class="btn btn-secondary btn-sm"
                   style="margin-left: 0.25rem; margin-right: 0.25rem" for="bottomMailImageUploader">
              <div>
                <i class="fa fa-picture-o" aria-hidden="true"></i>
                {{'add'|translate}}
              </div>
            </label>

            <div *ngIf="bottomMailImageUploader.isUploading" class="d-flex align-content-center align-items-center justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>

        </div>
        <!----------------------------- bottom mail upload ----------------------------->

        <!----------------------------- bottom mail default ----------------------------->
        <div *ngIf="!getMailImageByType('BOTTOM_MAIL_IMAGE')" class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="backgroundUploader">{{'default'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top"
                    ngbTooltip="{{'tooltip-bottom-mail-image-default'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <a href="{{this.FALLBACK_BOTTOM_MAIL_IMAGE}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.FALLBACK_BOTTOM_MAIL_IMAGE"/>
            </a>
          </div>
        </div>
        <!----------------------------- bottom mail default ----------------------------->


        <div class="dropdown-divider"></div>


        <!----------------------------- learningControlPercentage ----------------------------->
        <div class="form-group row align-items-center">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="penalty">{{'learning-control-percentage'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-cert-requirement'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <div style="min-width: 30px">
              <span *ngIf="certWizard.learningControlPercentage == 0">{{'off'|translate}}</span>
              <span *ngIf="certWizard.learningControlPercentage != 0">{{certWizard.learningControlPercentage}}</span>
            </div>
            <mat-slider id="learningControlPercentage" min="0" max="100" step="5" style="width: 100%"
                        [value]="certWizard.learningControlPercentage"
                        (input)="certWizard.learningControlPercentage = $event.value; dataChanged=true"></mat-slider>
          </div>
        </div>
        <!----------------------------- learningControlPercentage ----------------------------->

        <!----------------------------- penalty ----------------------------->
        <div class="form-group row align-items-center">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="penalty">{{'time-penalty'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-cert-penalty'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <div style="min-width: 30px">
              <span *ngIf="certWizard.certPenaltyTimeMin == 0">{{'off'|translate}}</span>
              <span *ngIf="certWizard.certPenaltyTimeMin != 0">{{certWizard.certPenaltyTimeMin}}</span>
            </div>
            <mat-slider id="penalty" min="0" max="24" step="1" style="width: 100%"
                        [value]="certWizard.certPenaltyTimeMin"
                        (input)="certWizard.certPenaltyTimeMin = $event.value; dataChanged=true"></mat-slider>
          </div>
        </div>
        <!----------------------------- penalty ----------------------------->

        <!----------------------------- exam ----------------------------->
        <div class="form-group row">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="exam">{{'exam'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-exam'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9">
            <input id="exam" type="checkbox" name="active" [(ngModel)]="certWizard.exam">
          </div>
        </div>
        <!----------------------------- exam ----------------------------->

        <div class="dropdown-divider"></div>
        <!----------------------------- select lang ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="certUploadLabel">{{'language'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-lang-select'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>



          <div class="col-xs-12 col-sm-9 d-flex align-items-center">

            <div class="dropdown">

              <button type="button"
                      class="btn btn-outline-secondary btn-sm dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      [disabled]="certLoading">
                {{selectedLang|translate}}
              </button>

              <ul class="dropdown-menu multi-column columns-2">
                <div class="row">
                  <div class="col-sm-6">
                    <ul class="multi-column-dropdown">

                      <li *ngFor="let lang of supportedLang">
                        <button (click)="this.selectedLang = lang" type="button" class="btn hover" role="button">
                          {{lang|translate}}
                        </button>
                      </li>

                    </ul>
                  </div>
                  <div class="col-sm-6">
                    <ul class="multi-column-dropdown">

                      <li *ngFor="let lang of supportedLang">
                        <button *ngIf="!isSettingForLangComplete(lang)" type="button" class="btn"
                                placement="top" ngbTooltip="{{'tooltip-missing-settings'|translate}}">
                          <i class="fa fa-exclamation-triangle" aria-hidden="true" style="color: grey"></i>
                        </button>
                        <button *ngIf="isSettingForLangComplete(lang)" type="button" class="btn"
                                placement="top" ngbTooltip="{{'tooltip-no-missing-settings'|translate}}">
                          <i class="fa fa-check" aria-hidden="true" style="color: green"></i>
                        </button>
                      </li>

                    </ul>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
        <!----------------------------- select lang ----------------------------->

        <!----------------------------- display-name ----------------------------->
        <div class="form-group row" [class.has-danger]="errors.get('display-name') != undefined">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="displayName">{{'display-name'|translate}}
              <span style="color: red">*</span>
            </label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-display-name'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9">
            <input autocomplete="off" class="form-control form-control-danger" type="text" name="companyName"
                   id="displayName" [(ngModel)]="getDisplayNameByLang().displayName" minlength="2" maxlength="100">
            <div class="form-control-feedback">{{errors.get('display-name')|translate}}</div>
          </div>
        </div>
        <!----------------------------- display-name ----------------------------->

        <!----------------------------- cert upload ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="certUploadLabel">{{'certificate'|translate}}
              <span style="color: red">*</span>
            </label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-cert'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-start" *ngIf="getCertificateByLang(selectedLang)">

            <img class="thumbnail"
                 [style.background-color]="certWizard.color"
                 src="../../../assets/img/cert-wizard/icon_certificate_el_color.svg"/>

            <button class="btn btn-danger" (click)="removeCertificateByLang()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div *ngIf="!getCertificateByLang(selectedLang)" class="col-xs-12 col-sm-9 d-flex align-items-center">
            <label *ngIf="!certLoading" class="btn btn-secondary btn-sm" id="certUploadLabel"
                   style="margin-left: 0.25rem; margin-right: 0.25rem" for="certUploader">
              <div>
                <i class="fa fa-picture-o" aria-hidden="true"></i>
                {{'add'|translate}}
              </div>
            </label>
            <div *ngIf="certLoading" class="d-flex align-content-center align-items-center justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>

        </div>
        <!----------------------------- cert upload ----------------------------->


        <!----------------------------- logo left upload ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label>{{'logo-left'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-left-logo'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-start" *ngIf="getLogoLeftByLang()">
            <a href="{{this.getImageURL(getLogoLeftByLang())}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.getImageURL(getLogoLeftByLang())"/>
            </a>

            <button class="btn btn-danger" (click)="removeLogoLeftByLang()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div *ngIf="!getLogoLeftByLang()" class="col-xs-12 col-sm-9 d-flex align-items-center">
            <label  *ngIf="!logoLeftUploader.isUploading" class="btn btn-secondary btn-sm" id="logoLeftUploadLabel"
                    style="margin-left: 0.25rem; margin-right: 0.25rem" for="logoLeftUploader">
              <div>
                <i class="fa fa-picture-o" aria-hidden="true"></i>
                {{'add'|translate}}
              </div>
            </label>

            <div *ngIf="logoLeftUploader.isUploading" class="d-flex align-content-center align-items-center justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>

        </div>
        <!----------------------------- logo left upload ----------------------------->

        <!----------------------------- left logo default ----------------------------->
        <div *ngIf="!getLogoLeftByLang()" class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="backgroundUploader">{{'default'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top"
                    ngbTooltip="{{'tooltip-left-logo-default'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>
          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <a href="{{this.FALLBACK_LEFT_LOGO}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.FALLBACK_LEFT_LOGO"/>
            </a>
          </div>
        </div>
        <!----------------------------- left logo default ----------------------------->


        <!----------------------------- logo left second upload ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="logoLeftSecondUploadLabel">{{'logo-left-second'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-second-left-logo'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-start" *ngIf="getLogoLeftSecondByLang()">
            <a href="{{this.getImageURL(getLogoLeftSecondByLang())}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.getImageURL(getLogoLeftSecondByLang())"/>
            </a>

            <button class="btn btn-danger" (click)="removeLogoLeftSecondByLang()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div *ngIf="!getLogoLeftSecondByLang()" class="col-xs-12 col-sm-9 d-flex align-items-center">
            <label *ngIf="!logoLeftSecondUploader.isUploading" class="btn btn-secondary btn-sm" id="logoLeftSecondUploadLabel"
                   style="margin-left: 0.25rem; margin-right: 0.25rem" for="logoLeftSecondUploader">
              <div>
                <i class="fa fa-picture-o" aria-hidden="true"></i>
                {{'add'|translate}}
              </div>
            </label>
            <div *ngIf="logoLeftSecondUploader.isUploading" class="d-flex align-content-center align-items-center justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>

        </div>
        <!----------------------------- logo left second upload ----------------------------->

        <!----------------------------- logo right upload ----------------------------->
        <div class="row mb-4">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label for="logoRightUploadLabel">{{'logo-right'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-right-logo'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-start" *ngIf="getLogoRightByLang()">
            <a href="{{this.getImageURL(getLogoRightByLang())}}" data-lightbox="image-card">
              <img class="thumbnail" [style.background-color]="certWizard.color"
                   [src]="this.getImageURL(getLogoRightByLang())"/>
            </a>

            <button class="btn btn-danger" (click)="removeLogoRightByLang()">
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <div *ngIf="!getLogoRightByLang()" class="col-xs-12 col-sm-9 d-flex align-items-center">
            <label  *ngIf="!logoRightUploader.isUploading" class="btn btn-secondary btn-sm" id="logoRightUploadLabel"
                    style="margin-left: 0.25rem; margin-right: 0.25rem" for="logoRightUploader">
              <div>
                <i class="fa fa-picture-o" aria-hidden="true"></i>
                {{'add'|translate}}
              </div>
            </label>
            <div *ngIf="logoRightUploader.isUploading" class="d-flex align-content-center align-items-center justify-content-center">
              <div class="lds-dual-ring"></div>
            </div>
          </div>

        </div>
        <!----------------------------- logo right upload ----------------------------->

        <!----------------------------- learn cards select ----------------------------->
        <div class="form-group row">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label>{{'learning-cards'|translate}}</label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-learn-cards'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <span class="btn btn-secondary btn-sm" (click)="addModules()">
              <i class="fa fa-plus" aria-hidden="true"></i>
          {{'add-learning-cards'|translate}}
            </span>
          </div>
        </div>
        <!----------------------------- learn cards select ----------------------------->

        <div class="row mb-4" *ngIf="getLearnCertWizardModuleByLang(selectedLang).length > 0">
          <label class="control-label col-xs-12 col-sm-3 text-sm-right">
          </label>
          <div class="col-sm-9">
            <div class="card">
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <tr *ngFor="let am of getLearnCertWizardModuleByLang(selectedLang)">
                    <td class="pt-0 pb-0 align-middle" style="width: 90px">
                      <div style="width: 100%" class="d-flex justify-content-between align-items-center">
                        <img [src]="'assets/img/'+am.module.type+'.png'" style="height: 32px"/>
                        <div class="text-info text-right align-self-center">
                          {{stats.get(am.module.id)?.cardCount}}
                        </div>
                      </div>
                    </td>
                    <td>{{am.module.name}}</td>
                    <td class="d-none d-md-table-cell">{{am.module.description}}</td>
                    <td class="d-flex flex-row justify-content-end pr-3">
                      <button class="btn btn-link btn-table" (click)="remove($event, am)">
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>


        <!----------------------------- exam cards select ----------------------------->
        <div class="form-group row" *ngIf="certWizard.exam">
          <div class=" col-xs-12 col-sm-3 text-sm-right">
            <label>{{'exam-cards'|translate}}
              <span style="color: red">*</span>
            </label>

            <button type="button" class="btn btn-link btn-sm" role="button" placement="top" ngbTooltip="{{'tooltip-exam-cards'|translate}}" triggers="click">
              <i class="fa fa-info-circle" aria-hidden="true"></i>
            </button>
          </div>

          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <span class="btn btn-secondary btn-sm" (click)="addModules(true)">
              <i class="fa fa-plus" aria-hidden="true"></i>
          {{'add-exam-cards'|translate}}
            </span>
          </div>
        </div>
        <!----------------------------- exam cards select ----------------------------->
        <div class="row mb-4" *ngIf="getExamCertWizardModuleByLang(selectedLang).length > 0 && certWizard.exam">
          <label class="control-label col-xs-12 col-sm-3 text-sm-right">
            <!--        {{'exam-cards'|translate}}-->
          </label>
          <div class="col-sm-9">
            <div class="card">
              <div class="table-responsive">
                <table class="table table-hover mb-0">
                  <tr *ngFor="let am of getExamCertWizardModuleByLang(selectedLang)">
                    <td class="pt-0 pb-0 align-middle" style="width: 90px">
                      <div style="width: 100%" class="d-flex justify-content-between align-items-center">
                        <img [src]="'assets/img/'+am.module.type+'.png'" style="height: 32px"/>
                        <div class="text-info text-right align-self-center">
                          {{stats.get(am.module.id)?.cardCount}}
                        </div>
                      </div>
                    </td>
                    <td>{{am.module.name}}</td>
                    <td class="d-none d-md-table-cell">{{am.module.description}}</td>
                    <td class="d-flex flex-row justify-content-end pr-3">
                      <button class="btn btn-link btn-table" (click)="remove($event, am)">
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>

  <div *ngIf="isEditMode" class="tab-pane fade"  id="asad"  role="tabpanel" aria-labelledby="asad-tab">

    <div class="container-fluid container-limited pt-4">

        <div class="form-group row" [class.has-danger]="errors.get('schedule-days') != undefined">
          <label class="control-label col-xs-12 col-sm-3 text-sm-right"
                 for="description">{{'training-days'|translate}}</label>
          <div class="col-xs-12 col-sm-9">
            <label class="mr-2">
              <input type="checkbox" name="mo" [(ngModel)]="scheduleDays[0]">
              {{'mon'|translate}}
            </label>
            <label class="mr-3">
              <input type="checkbox" name="di" [(ngModel)]="scheduleDays[1]">
              {{'tue'|translate}}
            </label>
            <label class="mr-3">
              <input type="checkbox" name="mi" [(ngModel)]="scheduleDays[2]">
              {{'wed'|translate}}
            </label>
            <label class="mr-3">
              <input type="checkbox" name="do" [(ngModel)]="scheduleDays[3]">
              {{'thu'|translate}}
            </label>
            <label class="mr-3">
              <input type="checkbox" name="fr" [(ngModel)]="scheduleDays[4]">
              {{'fri'|translate}}
            </label>
            <label class="mr-3">
              <input type="checkbox" name="sa" [(ngModel)]="scheduleDays[5]">
              {{'sat'|translate}}
            </label>
            <label class="mr-3">
              <input type="checkbox" name="so" [(ngModel)]="scheduleDays[6]">
              {{'sun'|translate}}
            </label>
            <div class="form-control-feedback">{{errors.get('schedule-days')|translate}}</div>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <div class="col-xs-12 col-sm-3 text-sm-right">{{'time-of-day'|translate}}</div>
          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <div style="min-width: 30px">{{certWizard.asad.userAsads[0].scheduleTime}}</div>
            <mat-slider id="schedule-time" min="0" max="23" step="1" style="width: 100%"
                        [value]="certWizard.asad.userAsads[0].scheduleTime"
                        (input)="certWizard.asad.userAsads[0].scheduleTime = $event.value; dataChanged=true"></mat-slider>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <div class="col-xs-12 col-sm-3 text-sm-right">{{'repetitions'|translate}}</div>
          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <div style="min-width: 30px">{{certWizard.asad.userAsads[0].repetitions}}</div>
            <mat-slider id="repetitions" [min]="repetitionsMin" [max]="repetitionsMax" step="1" style="width: 100%"
                        [value]="certWizard.asad.userAsads[0].repetitions"
                        (input)="certWizard.asad.userAsads[0].repetitions = $event.value; dataChanged=true"></mat-slider>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <div class="col-xs-12 col-sm-3 text-sm-right">{{'repetition-break-in-weeks'|translate}}</div>
          <div class="col-xs-12 col-sm-9 d-flex align-items-center">
            <div style="min-width: 30px">{{certWizard.asad.userAsads[0].repetitionBreakInWeeks}}</div>
            <mat-slider id="repetitionBreakInWeeks" min="0" max="10" step="1" style="width: 100%"
                        [(ngModel)]="certWizard.asad.userAsads[0].repetitionBreakInWeeks"
                        (input)="certWizard.asad.userAsads[0].repetitionBreakInWeeks = $event.value; dataChanged=true"></mat-slider>
          </div>
        </div>

      <div class="form-group row align-items-center">
        <div class=" col-xs-12 col-sm-3 text-sm-right">{{'user'|translate}}</div>

        <div class="col-xs-12 col-sm-9 d-flex align-items-center">
          <div style="min-width: 30px">{{certWizard.asad.userAsads.length}}</div>
          <button (click)="editUserAsad()" class="btn btn-secondary btn-sm pl-1" type="button">{{"add-user"|translate}}</button>
        </div>
      </div>

    </div>

  </div>

</div>


    <button class="btn btn-success fab-button-save" (click)="save()">
      <span>{{'save'|translate}}</span>
    </button>
    <button type="button"
            class="btn btn-secondary fab-button-cancel"
            (click)="location.back()">
      {{'cancel'|translate}}
    </button>





<input id="leftMailImageUploader" style="display: none" type="file" accept="image/png, image/jpeg" ng2FileSelect [uploader]="leftMailImageUploader"/>
<input id="rightMailImageUploader" style="display: none" type="file" accept="image/png, image/jpeg" ng2FileSelect [uploader]="rightMailImageUploader"/>
<input id="bottomMailImageUploader" style="display: none" type="file" accept="image/png, image/jpeg" ng2FileSelect [uploader]="bottomMailImageUploader"/>

<input id="backgroundUploader" style="display: none" type="file" accept="image/*" ng2FileSelect [uploader]="backgroundUploader"/>
<input id="logoLeftUploader" style="display: none" type="file" accept="image/*" ng2FileSelect [uploader]="logoLeftUploader"/>
<input id="logoRightUploader" style="display: none" type="file" accept="image/*" ng2FileSelect [uploader]="logoRightUploader"/>
<input id="logoLeftSecondUploader" style="display: none" type="file" accept="image/*" ng2FileSelect [uploader]="logoLeftSecondUploader"/>
<input onclick="this.value = null" id="certUploader" style="display: none" type="file" (change)="uploadCertificate($event)"/>


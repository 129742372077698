<div class="aeonik-regular">
  <!-- Trigger the Modal -->
  <img id="preview" [src]="this.getImageURL(image)" alt="Snow" style="width:100%;max-width:90vw">

  <!-- The Modal -->
  <div id="myModal" class="modal">

    <!-- The Close Button -->
    <span class="close">&times;</span>

    <!-- Modal Content (The Image) -->
    <img class="modal-content" id="content">

    <!-- Modal Caption (Image Text) -->
    <div id="caption"></div>
  </div>

</div>

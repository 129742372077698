<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <h2>{{'privacy'|translate}}</h2>
    <SMALL>die
      Business-Lernplattform der Little Helper Verlags GmbH
    </SMALL>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">
    <div class="col-xs-12 col-md-3 push-md-9" style="margin-bottom: 30px">
      <div class="list-group">
        <a class="list-group-item list-group-item-action" pageScroll href="#1">Erhebung und Speicherung
          personenbezogener Daten sowie Art und Zweck von deren Verwendung</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#2">a) Bei einem Besuch der Website</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#3">b) Bei Nutzung unseres
          Kontaktformulars</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#4">Weitergabe von Daten</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#5">Betroffenenrechte</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#6">Widerspruchsrecht</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#7">Verwendung von Scriptbibliotheken (Google
          Webfonts)</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#8">Datensicherheit</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#9">Aktualität und Änderung dieser
          Datenschutzerklärung</a>
      </div>
    </div>
    <div class="col-xs-12 col-md-9 pull-md-3">
      <p>Nach den Vorgaben der Datenschutzgrundverordnung (DSGVO-EU)</p>
      <p>Diese Datenschutz-Information gilt für die Datenverarbeitung durch:</p>
      <p>Verantwortlicher:</p>
      <p>Little Helper® Verlags GmbH<br>
        Geschäftsführer: Martin Lengefeld<br>
        Am Tannenhof 13<br>
        D - 78464 Konstanz<br>
        E-mail: info(at)little-helper-verlag.de<br>
      </p>

      <h4 id="1">Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h4>
      <p>Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur
        Bereitstellung einer funktionierenden Website sowie unserer Inhalte und Leistungen erforderlich ist. Die
        Erhebung und Verwendung personenbezogener Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des
        Nutzers. Eine Ausnahme gilt nur in den Fällen, in denen eine vorherige Einholung einer Einwilligung aus
        tatsächlichen Gründen nicht möglich ist und die Verarbeitung der Daten durch gesetzliche Vorschriften gestattet
        ist.</p>

      <h4 id="2">a) Bei einem Besuch der Website</h4>
      <p>Beim Aufrufen unserer Website www.little-helper-verlag.de werden durch den auf Ihrem jeweiligen Endgerät zum
        Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen
        werden vorübergehend in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun
        erfasst und bis zur automatisierten Löschung gespeichert:</p>

      <p>Uhrzeit und Datum des Zugriffs,<br>
        IP-Adresse des anfragenden Rechners,<br>
        Name und URL der abgerufenen Datei,<br>
        Website, von der aus der Zugriff erfolgt (Referrer-URL),<br>
        verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</p>


      <p>Die genannten Daten werden zu dem folgenden Zwecken durch uns verarbeitet:<br>

        Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,<br>
        Gewährleistung einer komfortablen Nutzung unserer Website,<br>
        Auswertung der Systemsicherheit und -stabilität sowie<br>
        zu weiteren administrativen Zwecken.</p>

      <p>Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse
        folgt aus den vorgenannten, oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die
        erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.</p>

      <p>Innerhalb unseres Internetangebotes werden auch Cookies verwendet. Cookies sind kleine Datenpakete, die über
        den Browser auf der Festplatte Ihres Computers abgelegt werden. Sie dienen der Steuerung der Internetverbindung
        während Ihres Besuchs oder bei einem späteren Besuch auf unseren Webseiten und machen den Besuch dadurch
        komfortabler.</p>

      <p>Manche Browser lassen bereits in der Grundeinstellung Cookies zu. Sollten Sie diese nicht wünschen, können Sie
        die Einstellung Ihres Browsers ändern. Wie dies erfolgt, entnehmen Sie bitte den Angaben des
        Browser-Herstellers. Sofern Sie sich gegen Cookies entscheiden, kann es vorkommen, dass Teile unseres
        Internetangebotes nicht genutzt werden können.</p>

      <p>Darüber hinaus setzen wir beim Besuch unserer Website keine Cookies für Analysedienste ein.</p>

      <h4 id="3">b) Bei Nutzung unseres Kontaktformulars</h4>
      <p>Auf unserer Website ist ein Kontaktformular eingerichtet, das für die elektronische Kontaktaufnahme genutzt
        werden kann. Die Angabe einer gültigen E-Mail-Adresse ist dabei erforderlich, damit nachvollzogen werden kann,
        von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt
        werden.

        Nimmt ein Nutzer die Möglichkeit des Kontaktformulars wahr, so werden die in der Eingabemaske eingegeben Daten
        an uns übermittelt und gespeichert. Folgende Daten werden gespeichert:

        Name, E-Mail und Ihre Anfrage.

        Zum Zeitpunkt der Absendung der Nachricht werden zudem folgende Daten gespeichert:

        IP-Adresse des Nutzers, Datum und Uhrzeit der Registrierung.

        Für die Verarbeitung der Daten wird im Rahmen des Absende-Vorgangs Ihre Einwilligung eingeholt und auf diese
        Datenschutzerklärung verwiesen.

        Alternativ ist eine Kontaktaufnahme über die auf unserer Website bereitgestellte E-Mail-Adresse möglich. In
        diesem Fall werden die mit der E-Mail übermittelten personenbezogenen Daten des Nutzers gespeichert.

        Es erfolgt in diesem Zusammenhang keine Weitergabe der Daten an Dritte. Die Daten werden ausschließlich für die
        Verarbeitung der Kommunikation verwendet. Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt
        nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung.

        Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der
        von Ihnen gestellten Anfrage automatisch gelöscht.</p>

      <h4 id="4">Weitergabe von Daten</h4>
      <p>Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken
        findet nicht statt.

        Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:


        Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,
        die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
        Interesse an der Nichtweitergabe Ihrer Daten haben,
        für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung
        besteht, sowie
        dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen
        mit Ihnen erforderlich ist.</p>

      <h4 id="5">Betroffenenrechte</h4>
      <p>Sie haben das Recht:

        - gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.
        Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die
        Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
        Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
        Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben
        wurden, sowie über das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf.
        aussagekräftigen Informationen zu deren Einzelheiten verlangen;
        - gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns
        gespeicherten personenbezogenen Daten zu verlangen;
        - gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit
        nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
        rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen erforderlich ist;
        - gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die
        Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
        ablehnen und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
        - gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
        verlangen;
        - gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat
        zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr
        fortführen dürfen und
        - gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die
        Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Sitzes wenden.</p>

      <h4 id="6">Widerspruchsrecht</h4>
      <p>Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f
        DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer
        personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer besonderen Situation
        ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles
        Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.


        Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an:
        info(at)little-helper-verlag.de</p>

      <h4 id="7">Verwendung von Scriptbibliotheken (Google Webfonts)</h4>
      <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden wir auf dieser
        Website Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts (https://www.google.com/webfonts/).
        Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres Browsers übertragen. Falls der
        Browser die Google Webfonts nicht unterstützt oder den Zugriff unterbindet, werden Inhalte in einer
        Standardschrift angezeigt.

        Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum Betreiber der
        Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken
        – dass Betreiber entsprechender Bibliotheken Daten erheben.

        Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier:
        https://www.google.com/policies/privacy/</p>

      <h4 id="8">Datensicherheit</h4>
      <p>Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
        zufällige oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den
        unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend der technologischen
        Entwicklung fortlaufend verbessert.</p>

      <h4 id="9">Aktualität und Änderung dieser Datenschutzerklärung</h4>
      <p>Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Mai 2018.

        Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher
        beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die
        jeweils aktuelle Datenschutzerklärung kann jederzeit auf der Website unter
        app.easy-learner.de/#/privacy von Ihnen abgerufen und ausgedruckt werden.</p>
    </div>
  </div>
</div>

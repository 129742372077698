<div class="menu-list">

  <ul id="menu-content" class="menu-content collapse out">

    <!-- Maybe there is a better way to enable disable menu toggling? -->

    <li routerLink="/dashboard" routerLinkActive="active" class="d-md-none d-block"
        data-toggle="collapse" data-target="#menu-content">
      <a>
        <i class="fa fa-dashboard fa-lg"></i> Dashboard
      </a>
    </li>
    <li routerLink="/dashboard" routerLinkActive="active" class="d-none d-md-block">
      <a>
        <i class="fa fa-dashboard fa-lg"></i> Dashboard
      </a>
    </li>

    <li routerLink="/modules" routerLinkActive="active" class="d-md-none d-block"
        data-toggle="collapse" data-target="#menu-content">
      <a>
        <i class="fa fa-clone fa-lg"></i> {{'modules'|translate}}
      </a>
    </li>
    <li routerLink="/modules" routerLinkActive="active" class="d-none d-md-block">
      <a>
        <i class="fa fa-clone fa-lg"></i> {{'modules'|translate}}
      </a>
    </li>

    <li routerLink="/asads" routerLinkActive="active" class="d-md-none d-block"
        data-toggle="collapse" data-target="#menu-content">
      <a>
        <i class="fa fa-paper-plane-o fa-lg"></i> ASaD
      </a>
    </li>
    <li routerLink="/asads" routerLinkActive="active" class="d-none d-md-block">
      <a>
        <i class="fa fa-paper-plane-o fa-lg"></i> ASaD
      </a>
    </li>

    <div *ngIf="auth && (auth.user.role == 'admin' || auth.user.role == 'manager')">
      <li routerLink="/manager/ktrackers" routerLinkActive="active" class="d-md-none d-block"
          data-toggle="collapse" data-target="#menu-content">
        <a>
          <i class="fa fa-line-chart fa-lg"></i> KTracker
        </a>
      </li>
      <li routerLink="/manager/ktrackers" routerLinkActive="active" class="d-none d-md-block">
        <a>
          <i class="fa fa-line-chart fa-lg"></i> KTracker
        </a>
      </li>
    </div>

    <div *ngIf="auth && (auth.user.role == 'admin' || auth.user.role == 'manager')">
      <li routerLink="manager/cert-wizards" routerLinkActive="active" class="d-md-none d-block"
          data-toggle="collapse" data-target="#menu-content">
        <a>
          <i class="fa fa-magic fa-lg"></i> CertWizard
        </a>
      </li>
      <li routerLink="manager/cert-wizards" routerLinkActive="active" class="d-none d-md-block">
        <a>
          <i class="fa fa-magic fa-lg"></i> CertWizard
        </a>
      </li>
    </div>

    <li data-toggle="collapse" data-target="#admin" class="collapsed" *ngIf="auth && auth.user.role == 'admin'">
      <a href="#"><i class="fa fa-wrench fa-lg"></i> Admin <span class="arrow"></span></a>
    </li>
    <ul routerLinkActive="in" class="sub-menu collapse" id="admin" *ngIf="auth && auth.user.role == 'admin'">

      <li routerLink="/admin/clients" routerLinkActive="active" class="d-md-none d-block"
          data-toggle="collapse" data-target="#menu-content">
        <a>{{'clients'|translate}}</a>
      </li>
      <li routerLink="/admin/clients" routerLinkActive="active" class="d-none d-md-block">
        <a>{{'clients'|translate}}</a>
      </li>

      <li routerLink="/manager/users" routerLinkActive="active" class="d-md-none d-block"
          data-toggle="collapse" data-target="#menu-content">
        <a>{{'users'|translate}}</a>
      </li>
      <li routerLink="/manager/users" routerLinkActive="active" class="d-none d-md-block">
        <a>{{'users'|translate}}</a>
      </li>

      <li routerLink="/admin/logs" routerLinkActive="active" class="d-md-none d-block"
          data-toggle="collapse" data-target="#menu-content">
        <a>Logs</a>
      </li>
      <li routerLink="/admin/logs" routerLinkActive="active" class="d-none d-md-block">
        <a>Logs</a>
      </li>

    </ul>

    <li data-toggle="collapse" data-target="#manager" class="collapsed" *ngIf="auth && auth.user.role == 'manager'">
      <a href="#"><i class="fa fa-wrench fa-lg"></i> Admin <span class="arrow"></span></a>
    </li>
    <ul routerLinkActive="in" class="sub-menu collapse" id="manager" *ngIf="auth && auth.user.role == 'manager'">

      <li class="text-truncate" routerLink="/manager/clients/{{auth.client.id}}" routerLinkActive="active"
          class="d-md-none d-block"
          data-toggle="collapse" data-target="#menu-content">
        <a>{{auth.client.name}}</a>
      </li>
      <li class="text-truncate" routerLink="/manager/clients/{{auth.client.id}}" routerLinkActive="active"
          class="d-none d-md-block">
        <a>{{auth.client.name}}</a>
      </li>

      <li routerLink="/manager/users" routerLinkActive="active" class="d-md-none d-block"
          data-toggle="collapse" data-target="#menu-content">
        <a>{{'users'|translate}}</a>
      </li>
      <li routerLink="/manager/users" routerLinkActive="active" class="d-none d-md-block">
        <a>{{'users'|translate}}</a>
      </li>

    </ul>

  </ul>
</div>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment'
import {Client} from '../client/client.model';
import {User} from '../user/user.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class RegistrationService {

  resource = 'registration';

  constructor(private http: HttpClient) {
  }

  findByCode(code: string): Observable<Client> {
    return this.http.get<Client>(environment.backendApi + '/' + this.resource + '/clients/' + code);
  }

  testCredentials(code: string, email: string, password: string): Observable<any> {
    let credentials: string;
    if (password) {
      credentials = btoa(email + ':' + password);
    } else {
      credentials = btoa(email);
    }
    return this.http.get<any>(environment.backendApi + '/' + this.resource
      + '/clients/'
      + code
      + '/' + credentials);
  }

  registerUser(user: User, code: string, email: string, password: string): Observable<any> {
    let credentials: string;
    if (password) {
      credentials = btoa(email + ':' + password);
    } else {
      credentials = btoa(email);
    }
    return this.http.post<any>(environment.backendApi + '/' + this.resource
      + '/clients/'
      + code
      + '/' + credentials, user);
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small"
     [class.jumbotron-active]="kTracker && kTracker.active">
  <div class="container-fluid container-limited d-flex flex-row">
    <div class="mr-auto">
      <span class="h2" *ngIf="isEditMode">
        {{'edit-ktracker'|translate}}
      </span>
      <span class="h2" *ngIf="!isEditMode">
      {{'new-ktracker'|translate}}
    </span>
    </div>
  </div>
</div>

<div class="container-fluid container-limited">
  <form (ngSubmit)="submit($event)" (change)="dataChanged = true" autocomplete="off"
        *ngIf="auth && !loadingService.isOpen()" name="detail">

    <div class="row mb-4">
      <div class="col-xs-12 offset-sm-3 col-sm-9">
        <p class="card-text text-danger" *ngIf="errors.get('error') != undefined">
          {{errors.get('error')|translate}}
        </p>
      </div>
    </div>

    <div class="form-group row">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="active">{{'active'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <input id="active" type="checkbox" name="active" [(ngModel)]="kTracker.active">
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('name') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="name">
        {{'name'|translate}}<span>*</span>
      </label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="off" class="form-control form-control-danger" type="text" name="name"
               id="name" [(ngModel)]="kTracker.name" minlength="2" maxlength="100">
        <div class="form-control-feedback">{{errors.get('name')|translate}}</div>
      </div>
    </div>

    <div class="form-group row"
         [class.has-danger]="errors.get('description') != undefined">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right"
             for="description">{{'description'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <textarea class="form-control form-control-danger" [(ngModel)]="kTracker.description"
                  autocomplete="off"
                  maxlength="200" rows="2" name="description" id="description">
        </textarea>
        <div class="form-control-feedback">{{errors.get('description')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('schedule-days') != undefined">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right"
             for="description">{{'training-days'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <label class="mr-2">
          <input type="checkbox" name="mo" [(ngModel)]="scheduleDays[0]">
          {{'mon'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="di" [(ngModel)]="scheduleDays[1]">
          {{'tue'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="mi" [(ngModel)]="scheduleDays[2]">
          {{'wed'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="do" [(ngModel)]="scheduleDays[3]">
          {{'thu'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="fr" [(ngModel)]="scheduleDays[4]">
          {{'fri'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="sa" [(ngModel)]="scheduleDays[5]">
          {{'sat'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="so" [(ngModel)]="scheduleDays[6]">
          {{'sun'|translate}}
        </label>
        <div class="form-control-feedback">{{errors.get('schedule-days')|translate}}</div>
      </div>
    </div>

    <div class="form-group row align-items-center">
      <div class="col-xs-12 col-sm-3 text-sm-right">{{'time-of-day'|translate}}</div>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div style="min-width: 30px">{{kTracker.scheduleTime}}</div>
        <mat-slider id="schedule-time" min="0" max="23" step="1" style="width: 100%"
                   [value]="kTracker.scheduleTime"
                   (input)="kTracker.scheduleTime = $event.value; dataChanged=true"></mat-slider>
      </div>
    </div>

    <div class="form-group row align-items-center">
      <div class="col-xs-12 col-sm-3 text-sm-right">{{'cards-per-day'|translate}}</div>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div style="min-width: 30px">{{kTracker.cardsPerDay}}</div>
        <mat-slider id="cardsPerDay" min="1" max="10" step="1" style="width: 100%"
                   [value]="kTracker.cardsPerDay"
                   (input)="kTracker.cardsPerDay = $event.value; dataChanged=true"></mat-slider>
      </div>
    </div>
<!--
    <div class="form-group row align-items-center">
      <div class="col-xs-12 col-sm-3 text-sm-right">{{'cards-expire-time-hour'|translate}}</div>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div style="min-width: 30px">
          <span *ngIf="kTracker.expireTimeHour == 0">{{'off'|translate}}</span>
          <span *ngIf="kTracker.expireTimeHour != 0">{{kTracker.expireTimeHour}}</span>
        </div>
        <mat-slider id="expireTimeHour" min="0" max="48" step="2" style="width: 100%"
                   [value]="kTracker.expireTimeHour"
                   (input)="kTracker.expireTimeHour = $event.value; dataChanged=true"></mat-slider>
      </div>
    </div>
-->
    <div class="form-group row align-items-center">
      <div class="col-xs-12 col-sm-3 text-sm-right">{{'answer-time-sec'|translate}}</div>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div style="min-width: 30px">
          <span *ngIf="kTracker.answerTimeSec == 0">{{'off'|translate}}</span>
          <span *ngIf="kTracker.answerTimeSec != 0">{{kTracker.answerTimeSec}}</span>
        </div>
        <mat-slider id="answerTimeSec" min="0" max="60" step="5" style="width: 100%"
                   [value]="kTracker.answerTimeSec"
                   (input)="kTracker.answerTimeSec = $event.value; dataChanged=true"></mat-slider>
      </div>
    </div>

    <div class="form-group row">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right"
             for="schedule-time">{{'card-count'|translate}}</label>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div class="text-info" style="min-width: 40px">
          {{cardCount}}
        </div>
        <span class="btn btn-secondary btn-sm" (click)="addModule()">
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{'add-modules'|translate}}
            </span>
      </div>
    </div>

    <div class="form-group row">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right"
             for="schedule-time">{{'user-count'|translate}}</label>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div class="text-info" style="min-width: 40px">
          {{kTracker.userKTrackers.length}}
        </div>
        <span class="btn btn-secondary btn-sm" (click)="addUser()">
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{'add-user'|translate}}
            </span>
      </div>
    </div>

    <div class="row mb-4">
      <label class="control-label col-12 col-sm-3 text-sm-right"></label>
      <div class="col-12 col-sm-9 text-sm-right">
        <button class="btn btn-outline-success  mr-2" type="submit">
          <span>{{'save'|translate}}</span>
        </button>
        <button type="button"
                class="btn btn-secondary"
                (click)="location.back()">
          {{'cancel'|translate}}
        </button>
      </div>
    </div>

    <div class="row mb-4" *ngIf="kTracker.ktrackerModules.length > 0">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right">
        {{'modules'|translate}}
      </label>
      <div class="col-sm-9">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <tr *ngFor="let am of kTracker.ktrackerModules">
                <td class="pt-0 pb-0 align-middle" style="width: 90px">
                  <div style="width: 100%" class="d-flex justify-content-between align-items-center">
                    <img [src]="'assets/img/'+am.module.type+'.png'" style="height: 32px"/>
                    <div class="text-info text-right align-self-center">
                      {{stats.get(am.module.id)?.cardCount}}
                    </div>
                  </div>
                </td>
                <td>{{am.module.name}}</td>
                <td class="d-none d-md-table-cell">{{am.module.description}}</td>
                <td class="d-flex flex-row justify-content-end pr-3">
                  <button class="btn btn-link btn-table" (click)="remove(am,$event)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4" *ngIf="kTracker.userKTrackers.length > 0">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right">
        {{'user'|translate}}
      </label>
      <div class="col-sm-9">
        <div class="card">
          <div class="list-group list-group-flush">
            <div class="list-group-item list-group-item-action d-flex pr-3 pl-2"
                 *ngFor="let uk of kTracker.userKTrackers">
              <div class="mr-auto d-flex">
                <div class="rounded-corners" *ngIf="uk.user.img">
                  <img class="rounded-corners" [src]="uk.user.img" (error)="uk.user.img = null"/>
                </div>
                <i class="fa fa-user-circle-o user-avatar-icon" aria-hidden="true"
                   *ngIf="!uk.user.img"></i>
                <div class="align-self-center ml-4">
                  {{uk.user.name}} {{uk.user.forename}}
                </div>
              </div>
              <button class="btn btn-link btn-table" (click)="removeUser(uk,$event)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>

<div class="d-flex flex-row justify-content-center align-items-center vh-100 aeonik-regular"
     [style.background-color]="'#D0D0D0'">
  <div class="card m-1" style="width: 25rem;">
    <div class="card-body">
      <h3 class="card-title aeonik-black"
          [class.text-success]="!danger"
          [class.text-danger]="danger">{{header|translate}}</h3>
      <h5 class="card-text">{{message|translate}}</h5>
      <button *ngIf="action" (click)="nav()" class="btn btn-primary">{{action|translate}}</button>
    </div>
  </div>
</div>

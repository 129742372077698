import {Component, ElementRef, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
// import {PageScrollConfig} from 'ngx-page-scroll';
import {AuthService} from './auth/auth.service';
import {Router, NavigationStart} from '@angular/router';
import {SubscriptionHelper} from './util/subscription-helper';
import {ModalWrapperService} from './util/modal/modal-wrapper.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy {

  currentYear: number = new Date().getFullYear();
  auth: any;
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;
  learnMode = false;
  certWizardMode = false;
  navbarActive = false;
  modalOpen = false;
  showPrivacyHint = false;

  constructor(translate: TranslateService,
              public authService: AuthService,
              private _eref: ElementRef,
              private router: Router,
              private modalService: ModalWrapperService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    let lang = navigator.language;
    if (lang.indexOf('-') !== -1) {
      lang = lang.substring(0, lang.indexOf('-'));
    }
    translate.use(lang);

    // PageScrollConfig.defaultDuration = 100;

    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      this.showPrivacyHint = false;
    });

    this._sHelper.sub = router.events.subscribe(
      event => {
        if (event instanceof NavigationStart) {
          if (event.url.startsWith('/learn/module')
            || event.url.startsWith('/learn/ktracker')
            || event.url.startsWith('/learn/cert-wizards')
            || event.url.startsWith('/public/cert-wizards')
            || event.url.includes('/cert-wizard-dashboard')
            || event.url.includes('/message')
            || event.url.includes('/no-nav')) {
            this.learnMode = true;
          } else {
            this.learnMode = false;
          }

          if (event.url.startsWith('/learn/cert-wizards')
            || event.url.startsWith('/public/cert-wizards')
            || event.url.endsWith('/cert-wizard-dashboard')) {
            this.certWizardMode = true;
          } else {
            this.certWizardMode = false;
          }
        }
      }
    );

    this._sHelper.sub = modalService.openState.subscribe(
      open => {
        if (open != null) {
          this.modalOpen = open;
        }
      }
    );

    this.showPrivacyHint = localStorage.getItem('showPrivacyHint') == null;
  }

  closePrivacyHint() {
    this.showPrivacyHint = false;
    localStorage.setItem('showPrivacyHint', 'false');
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['']);
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

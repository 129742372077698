import {Component, OnInit} from '@angular/core';
import {Client} from '../../client/client.model';
import {User} from '../user.model';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {UserService} from '../user.service';
import {LoadingService} from '../../util/loading/loading.service';
import {AuthService} from '../../auth/auth.service';
import {TitleService} from '../../util/title/title.service';
import {ClientService} from '../../client/client.service';
import * as papaparse from 'papaparse';
import {UserRole} from '../user-role';
import {supportedLangAsList} from '../../cert-wizard/model/supported-lang';

@Component({
  selector: 'app-user-import',
  templateUrl: './user-import.component.html',
  styleUrls: ['./user-import.component.css']
})
export class UserImportComponent implements OnInit {

  selectedClient: { id, name } = null;
  selectedUserIdx: number = null;
  clients: { id, name }[] = [];
  users: User[] = [];
  filteredUsers: User[] = [];

  testResult: any[] = [];
  errorsCsvData: Map<string, string> = new Map();
  csvData = '';
  auth: any;
  searchQuery = '';
  errorCount = 0;
  userImported = false;

  supportedLangList = supportedLangAsList();

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private clientService: ClientService,
              private userService: UserService,
              public loadingService: LoadingService,
              public authService: AuthService,
              private titleService: TitleService) {
    titleService.title = 'import-user';
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth) {
        this._init();
      }
    });
  }

  _init() {
    this.selectedClient = this.auth.client;
    if (this.auth.user.role == 'admin') {
      console.log('auth client', this.auth.client);
      this.loadingService.open();
      this._sHelper.sub = this.clientService.findAll().subscribe(
        clients => {
          clients.forEach(client => {
            let simpleClient = {id: client.id, name: client.name};
            this.clients.push(simpleClient);
            if (this.auth.client.id == simpleClient.id) {
              this.selectedClient = simpleClient;
              console.log('selected client', this.selectedClient);
            }
          });
          this.clients = this.clients.sort(Client.sortByName);

          this.loadingService.dismiss();
        },
        (error) => {
          console.log(error);
          this.loadingService.dismiss();
        }
      );
    }
  }

  submitCsvData(event) {
    event.preventDefault();
    this.loadingService.open();
    this.testResult = [];
    this.searchQuery = '';
    this.selectedUserIdx = null;

    // prepare csv
    this.csvData = this.csvData.trim();
    if (this.csvData.length > 0) {
      let csvObj = papaparse.parse(this.csvData, {delimiter: '\t'});
      this.users = [];

      csvObj.data.forEach(
        (row, idx) => {
          if (row.length < 3) {
            return;
          }
          if (!row[0] && !row[1] && !row[2]) {
            return;
          }
          let user: User = new User();

          user.client = <any>this.selectedClient;
          user.active = true;
          user.lang = row[3] ? row[3] : this.auth.user.lang;
          user.name = row[0];
          user.forename = row[1];
          user.email = row[2];
          user.login = user.email;
          user.role = UserRole.user;
          user.password = '' + Math.random() * 13 * new Date().getTime();
          console.log(user.password);
          this.users.push(user);
        });
    }
    this.doSearch();
    this._testUserData();
  }

  _testUserData() {
    this.loadingService.open();
    this.errorCount = 0;
    // test data on server
    this.userService.testAll(this.users).subscribe(
      result => {
        this.testResult = result;
        this.selectedUserIdx = null;
        this.loadingService.dismiss();
      },
      resultWithErrors => {
        this.testResult = resultWithErrors.error;
        this.testResult.forEach(item => {
          if (item != true) {
            this.errorCount++;
          }
        });
        console.log(this.testResult);
        this.loadingService.dismiss();
      });
  }

  submitUserList(event) {
    event.preventDefault();

    if (this.errorCount > 0) {
      // validate data again
      this._testUserData();
    } else {
      // persist user data
      this.userService.persistAll(this.users).subscribe(
        result => {
          this.testResult = result;
          this.userImported = true;
          this.loadingService.dismiss();
        },
        resultWithErrors => {
          this.testResult = resultWithErrors.json();
          this.testResult.forEach(item => {
            if (item != true) {
              this.errorCount++;
            }
          });
          console.log(this.testResult);
          this.loadingService.dismiss();
        }
      );
    }
  }

  doSearch() {
    this.filteredUsers = this.users.filter(
      (item: User) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.forename && !match) {
            match = item.forename.toLowerCase().indexOf(query) != -1;
          }
          if (item.email && !match) {
            match = item.email.toLowerCase().indexOf(query) != -1;
          }
        }

        let clientMatch = false;
        if (!this.selectedClient) {
          clientMatch = true;
        } else {
          clientMatch = item.client.id == this.selectedClient.id;
        }


        return match && clientMatch;
      });
  }

  delete(user, event) {
    event.stopPropagation();
    let idx = this.users.indexOf(user);
    if (this.selectedUserIdx == idx) {
      this.selectedUserIdx = null;
    }
    this.users.splice(idx, 1);
    let item: any = this.testResult.splice(idx, 1);
    if (item[0] != true) {
      this.errorCount--;
    }
    this.doSearch();
  }

  edit(idx) {
    this.selectedUserIdx = idx;
  }

  ngOnInit() {
  }

}

import {
  Component, OnInit, Input, ViewEncapsulation, OnDestroy, Output, EventEmitter
} from '@angular/core';
import {
  trigger, transition, style,
  animate
} from '@angular/animations';
import {AuthService} from '../../auth/auth.service';

@Component({
  selector: 'side-navbar',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.css'],

  animations: [
    trigger('navbarAnimation',
      [
        transition(
          ':enter', [
            style({
              opacity: 0,
              transform: 'translateX(-100%)'
            }),
            animate('400ms ease-in')
          ]
        ),
        transition(
          ':leave', [
            animate('400ms ease-out', style({
              opacity: 0,
              transform: 'translateX(-100%)'
            }))
          ]
        )
      ]
    )
  ],
  host: {
    '[@navbarAnimation]': 'auth',
    'class': 'nav-side-menu'
  },
})
export class SideNavbarComponent implements OnInit, OnDestroy {

  @Output() navbarActiveState = new EventEmitter();
  auth: any;


  constructor(public authService: AuthService) {
    authService.auth.subscribe(auth => {
      this.auth = auth;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.navbarActiveState.emit(true);
  }

  ngOnDestroy() {
    this.navbarActiveState.emit(false);
  }
}

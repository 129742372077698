<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2" *ngIf="isEditMode">{{'edit-client'|translate}}</span>
    <span class="h2" *ngIf="!isEditMode">{{'new-client'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">

  <div class="form-group row">

    <div class="col-xs-12 col-sm-3 d-sm-flex justify-content-end">
      <div *ngIf="client.image != null && errors.get('img-load') == null">
        <a href="{{getImageURL()}}" data-lightbox="image-profile">
          <img class="img-fluid" [src]="getImageURL()" (error)="errors.set('img-load',true)"/>
        </a>
      </div>
      <i class="fa fa-picture-o text-muted user-avatar-icon" aria-hidden="true"
         *ngIf="client.image == null || errors.get('img-load') != null"></i>
    </div>

    <div class="col-xs-12 col-sm-9 d-sm-flex flex-sm-column-reverse align-items-sm-start mt-2">
      <label for="fileUpload" class="btn btn-secondary" style="margin-bottom: 0">
        {{'img-upload'|translate}}
      </label>
      <button class="btn btn-outline-danger mb-sm-2" *ngIf="client.image == null" (click)="clearImage()">
        {{'img-clear'|translate}}
      </button>
      <p class="text-danger" *ngIf="errors.get('error') == null">
        {{errors.get('error')|translate}}
      </p>
      <input id="fileUpload" style="display: none" type="file" ng2FileSelect [uploader]="uploader"/>
    </div>
  </div>

  <form (submit)="submit()" *ngIf="!loadingService.isOpen()">

    <div class="row mb-4">
      <div class="col-xs-12 offset-sm-3 col-sm-9">
        <p class="card-text text-danger" *ngIf="errors.get('form') != undefined">{{errors.get('form')|translate}}</p>
      </div>
    </div>

    <div class="form-group row" *ngIf="isEditMode">
      <label class="control-label text-truncate col-xs-12 col-sm-3 text-sm-right">{{'registration-page'|translate}}</label>
      <div class="col-xs-12 col-sm-9 text-truncate">
        <a href="{{getRegistrationPage()}}">{{getRegistrationPage()}}</a>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('name') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="user_name">{{'name'|translate}}*</label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="off" class="form-control form-control-danger" type="text" name="client[name]"
               id="user_name" [(ngModel)]="client.name" maxlength="100">
        <div class="form-control-feedback">{{errors.get('name')|translate}}</div>
      </div>
    </div>

    <div class="form-group row">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="acceptedDomains">{{'accepted-domains'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="off" class="form-control form-control-danger" type="text" name="client[acceptedDomains]"
               id="acceptedDomains" [(ngModel)]="client.acceptedDomains" maxlength="200">
        <div class="form-control-feedback"></div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('password') != undefined">
      <label class="form-control-label text-truncate col-xs-12 col-sm-3 text-sm-right" for="registrationPassword">
        {{'registration-password'|translate}}
      </label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="off" class="form-control form-control-danger" type="text" name="client[registrationPassword]"
               id="registrationPassword" [(ngModel)]="client.registrationPassword" maxlength="20">
        <div class="form-control-feedback">{{errors.get('password')|translate}}</div>
      </div>
    </div>

    <div class="form-actions d-flex">
      <div class="mr-auto">* required</div>
      <button class="btn btn-outline-success flex-row mr-2" type="submit">
        <span>{{'save'|translate}}</span>
      </button>
      <button type="button"
              class="btn btn-secondary"
              (click)="location.back()">{{'cancel'|translate}}</button>
    </div>
  </form>
</div>



import {Component, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../util/loading/loading.service';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {Module} from '../../module/module.model';
import {ModulePickerComponent} from '../../module/module-picker/module-picker.component';
import {ModuleStat} from '../../stats/module-stat.model';
import {StatsService} from '../../stats/stats.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {KTracker} from '../ktracker.model';
import {TitleService} from '../../util/title/title.service';
import {KTrackerService} from '../ktracker.service';
import {KTrackerModule} from '../ktracker-module.model';
import {UserPickerComponent} from '../../user/user-picker/user-picker.component';
import {UserKTracker} from '../user-ktracker.model';
import {User} from '../../user/user.model';
import {KTrackerStat} from '../../stats/ktracker-stat.model';
import {forkJoin} from 'rxjs';
import {KTrackerModuleStat} from '../../stats/ktracker-module-stat.model';
import {KTrackerLectionStat} from '../../stats/ktracker-lection-stat.model';

@Component({
  selector: 'app-ktracker-stats',
  templateUrl: './ktracker-stats.component.html',
  styleUrls: ['./ktracker-stats.component.css']
})
export class KTrackerStatsComponent implements OnDestroy {

  auth: any;
  kTracker: KTracker;
  kTrackerStat: KTrackerStat;

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;
  private _ngbModalRef: NgbModalRef = null;
  private Math: any;
  public labelsCorrectWrongAnswers: string[] = [];
  public dataCorrectWrongAnswers: number[] = [];
  public doughnutChartType = 'doughnut';
  public doughnutChartOptions = {legend: {display: true, labels: {fontSize: 16}}};
  public translations;
  public moduleCorrectWrongAnswersLabels = [];

  public colorsCorrectWrongAnswers: Array<any> = [
    {
      backgroundColor: ['#81C784', '#E57373'],
      borderColor: '#ffffff'
    }
  ];

  constructor(private kTrackerService: KTrackerService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private statsService: StatsService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private titleService: TitleService,
              public authService: AuthService) {
    this.Math = Math;
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  private _init() {
    this.loadingService.open();
    let kTrackerId = this.route.snapshot.params['id'];

    // Parallel
    this._sHelper.sub = forkJoin(
      [
        this.kTrackerService.findByUserAndId(this.auth.user, kTrackerId),
        this.statsService.findKTrackerStatsByUserAndKTrackerIds(this.auth.user, ['' + kTrackerId]),
        this.translate.get(['open-cards', 'requested-cards', 'correct-answers', 'wrong-answers'])
      ])
      .subscribe(
        results => {
          this.kTracker = results[0];
          this.titleService.title = 'Stats: ' + this.kTracker.name;

          this.kTrackerStat = results[1][0];
          this.dataCorrectWrongAnswers.push(this.kTrackerStat.correctAnswersCount);
          this.dataCorrectWrongAnswers.push(this.kTrackerStat.wrongAnswersCount);

          this.translations = results[2];
          this.labelsCorrectWrongAnswers.push(this.kTrackerStat.correctAnswersCount + ' ' + results[2]['correct-answers']);
          this.labelsCorrectWrongAnswers.push(this.kTrackerStat.wrongAnswersCount + ' ' + results[2]['wrong-answers']);

          this._initModuleStatLabels();
        },
        e => console.error(e),
        () => this.loadingService.dismiss()
      );

  }

  moduleStat(idx) {
    return this.kTrackerStat.moduleStats[idx];
  }

  _initModuleStatLabels() {

    this.kTrackerStat.moduleStats.forEach(moduleStat => {
      this.moduleCorrectWrongAnswersLabels.push(
        [moduleStat.correctAnswersCount + ' ' + this.translations['correct-answers'],
          moduleStat.wrongAnswersCount + ' ' + this.translations['wrong-answers']]
      );
    });
  }

  edit() {
    this.router.navigate(['/manager/ktrackers', this.kTracker.id]);
  }

  calcKTrackerFinish(kTrackerStat) {
    if (!kTrackerStat || kTrackerStat.cardCount == 0) {
      return 0;
    } else {
      return Math.round(kTrackerStat.sentCardCount / kTrackerStat.cardCount * 100);
    }
  }

  calcAnswers(stat: KTrackerModuleStat | KTrackerLectionStat) {
    if (!stat || stat.sentRequests == 0) {
      return 0;
    } else {
      return Math.round((stat.correctAnswersCount + stat.wrongAnswersCount) / stat.sentRequests * 100);
    }
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

export class KTrackerCardStat {

  cardId: number = null;
  averageAnswerTime: number = null;
  correctAnswersCount: number = null;
  wrongAnswersCount: number = null;


  constructor() {
  }

  static createWith(obj: any): KTrackerCardStat {
    let newObj = new KTrackerCardStat();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

}

import {stringToLang, SupportedLang} from './supported-lang';
import {Image} from '../../module/image.model';
import {CertWizardImageType} from './CertWizardImageType';

export class CertWizardImage {
  public id: string = null;

  public lang: SupportedLang = SupportedLang.de;

  public type: CertWizardImageType = CertWizardImageType.BACKGROUND_IMAGE;

  public image: Image = null;

  constructor(lang: string, type: CertWizardImageType, image: Image) {
    this.lang = stringToLang(lang);
    this.type = type;
    this.image = image;
  }
}

export class UserCertWizardCardAnswer {

  public userId: string = null;
  public certWizardId: string = null;
  public cardId: string = null;
  public answeredCorrect = false;
  public answerHasMeaning = false;

  constructor(userId: string, certWizardId: string, cardId: string, answeredCorrect: boolean, answerHasMeaning: boolean) {
    this.userId = userId;
    this.certWizardId = certWizardId;
    this.cardId = cardId;
    this.answeredCorrect = answeredCorrect;
    this.answerHasMeaning = answerHasMeaning;
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../environments/environment'
import {Module} from './module.model';
import {User} from '../user/user.model';
import {UserModule} from './user-module.model';
import {UserCard} from './user-card.model';
import {Card} from './card.model';
import {Log} from 'ng2-logger'
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ModuleService {
  log = Log.create('ModuleService');

  resource = 'users';

  constructor(private http: HttpClient) {
  }

  findAllByUser(user: User): Observable<Module[]> {
    return this.http.get<Module[]>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules')
      .map(data => {
        this.log.info('', data);
        return data.map(m => Module.createWith(m));
      });
  }

  findAllPublic(): Observable<Module[]> {
    return this.http.get<Module[]>(environment.backendApi + '/public/modules')
      .map(data => {
        this.log.info('', data);
        return data.map(m => Module.createWith(m));
      });
  }

  findPublicById(id: string): Observable<Module> {
    return this.http.get<Module>(environment.backendApi + '/public/modules/' + id)
      .map(data => {
        this.log.info('', data);
        return Module.createWith(data);
      });
  }

  findByUserAndId(user: User, id: string): Observable<Module> {
    return this.http.get<Module>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules/'
      + id)
      .map(data => {
        this.log.info('', data);
        return Module.createWith(data);
      });
  }

  persist(user: User, module: Module): Observable<string> {
    return this.http.post<string>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules', module)
      .map(id => {
        this.log.info(id);
        return id;
      });
  }

  update(user: User, module: Module): Observable<boolean> {
    return this.http.post<boolean>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules/'
      + module.id, module)
      .map(res => {
        this.log.info(<any>res);
        return true;
      });
  }

  updateModulePermissions(user: User, module: Module, userModules: UserModule[]): Observable<boolean> {
    return this.http.post<boolean>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules/'
      + module.id + '/users'
      , userModules)
      .map(res => {
        this.log.info(<any>res);
        return true;
      });
  }

  updateUserModule(user: User, module: Module, userModule: UserModule): Observable<boolean> {
    return this.http.post<boolean>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules/'
      + module.id + '/user-module'
      , userModule)
      .map(res => {
        this.log.info(<any>res);
        return true;
      });
  }

  updateUserCard(user: User, module: Module, card: Card, userCard: UserCard): Observable<any> {
    return this.http.post<any>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules/'
      + module.id + '/cards/'
      + card.id + '/user-cards'
      , userCard)
      .map(res => {
        this.log.info(res);
        return res;
      });
  }

  updatePublicUserModule(module: Module, userModule: UserModule): Observable<boolean> {
    return this.http.post<boolean>(environment.backendApi + '/public/modules/' + +module.id + '/users', userModule)
      .map(res => {
        this.log.info(<any>res);
        return true;
      });
  }

  delete(user: User, id: string): Observable<boolean> {
    return this.http.delete<boolean>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules/'
      + id)
      .pipe(
        map(data => {
          return true;
        }),
        catchError((err, obs) => {
          return obs;
        })
      );
  }

  deleteCard(user: User, moduleId: string, cardId: string): Observable<boolean> {
    return this.http.delete<boolean>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/modules/'
      + moduleId + '/cards/'
      + cardId)
      .pipe(
        map(data => {
          return true;
        }),
        catchError((err, obs) => {
          return obs;
        })
      );
  }
}

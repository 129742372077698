import {Component, OnDestroy} from '@angular/core';
import {LoadingService} from '../../util/loading/loading.service';
import {AuthService} from '../../auth/auth.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {TitleService} from '../../util/title/title.service';
import {SystemLog} from '../system-log.model';
import {SystemLogService} from '../system-log.service';
import * as moment from 'moment';

@Component({
  selector: 'system-log-list',
  templateUrl: './system-log-list.component.html',
  styleUrls: ['./system-log-list.component.css']
})
export class SystemLogListComponent implements OnDestroy {

  selectedCode: string = null;
  codes: string[] = [];
  systemLogs: SystemLog[] = [];
  filteredSystemLogs: SystemLog[] = [];
  auth: any;
  searchQuery = '';
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private systemLogService: SystemLogService,
              public loadingService: LoadingService,
              public authService: AuthService,
              private titleService: TitleService) {
    titleService.title = 'Logging';
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth) {
        this._init();
      }
    });
  }

  _init() {
    this.loadingService.open();
    this._sHelper.sub = this.systemLogService.findAll(this.selectedCode, 100).subscribe(
      systemLogs => {
        this.systemLogs = systemLogs;

        // get system log codes
        let tmpMap: Map<string, string> = new Map();
        this.systemLogs.forEach(sl => {
          tmpMap.set(sl.code, sl.code);
        });
        tmpMap.forEach(code => this.codes.push(code));

        this.doSearch();

        this.loadingService.dismiss();
      },
      e => {
        this.loadingService.dismiss();
      }
    );
  }

  doSearch() {
    this.filteredSystemLogs = this.systemLogs.filter(
      (item: SystemLog) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.message) {
            match = item.message.toLowerCase().indexOf(query) != -1;
          }
        }

        let codeMatch = false;
        if (!this.selectedCode) {
          codeMatch = true;
        } else {
          codeMatch = item.code == this.selectedCode;
        }


        return match && codeMatch;
      });
  }

  formatDate(date: number) {
    return moment.unix(date).locale(this.auth.user.lang).format('lll');
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">{{'public-modules'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">
    <div class="col-12 mb-4">
      <searchbar class="float-right" [(query)]="searchQuery" (input)="doSearch()"
                 placeholder="{{'search'|translate}} {{'modules'|translate}}"></searchbar>
    </div>
  </div>
  <div class="row">
    <div class="col-12">

      <div class="card">
        <div class="table-responsive" *ngIf="filteredModules.length">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th class="border-top-0"><!--type--></th>
              <th class="border-top-0">{{'name'|translate}}</th>
              <th class="border-top-0 d-none d-md-block">{{'description'|translate}}</th>
              <th class="border-top-0">{{'owner'|translate}}</th>
              <th class="border-top-0">{{'permission'|translate}}</th>
              <th class="border-top-0"><!--actions--></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let module of filteredModules; trackBy:trackByKey" (click)="edit(module)">
              <td>
                <div style="width: 80px" class="d-flex justify-content-between">
                  <img [src]="'assets/img/'+module.type+'.png'" style="height: 32px"/>
                  <div class="text-info text-right align-self-center">
                    {{stats.get(module.id).cardCount}}
                  </div>
                </div>
              </td>
              <td>{{module.name}}</td>
              <td class="d-none d-md-block">{{module.description}}</td>
              <td>{{getModuleOwner(module)|translate}}</td>
              <td>{{getModulePermission(module)|translate}}</td>
              <td class="d-flex flex-row justify-content-end">
                <button class="btn btn-link btn-table mr-2" (click)="connectUser(module,$event)" title="{{'add-module'|translate }}">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="!filteredModules.length">
          <h5 class="card-title text-muted text-center" style="margin-top: 12px">
            {{'empty-search-module'|translate}}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

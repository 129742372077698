<div class="modal-header">
  <h4 class="modal-title">
    {{'select-users'|translate}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <searchbar class="mb-4" [(query)]="searchQuery" (input)="doSearch()"
             placeholder="{{'search'|translate}} {{'users'|translate}}">

  </searchbar>
  <div class="scrollable">
    <div class="list-group list-group-flush">
      <span class="list-group-item list-group-item-action"
            [class.active]="selectedUsers.get(user.id)"
            *ngFor="let user of filteredUsers"
            (click)="toggleUser(user)">
        <div class="mr-auto d-flex">
          <div class="rounded-corners" *ngIf="user.img">
              <img class="rounded-corners" [src]="user.img" (error)="user.img = null"/>
          </div>
          <i class="fa fa-user-circle-o user-avatar-icon" aria-hidden="true"
             *ngIf="!user.img"></i>
          <div class="align-self-center ml-4">
            {{user.name}} {{user.forename}}
          </div>
        </div>
      </span>
      <div class="text-muted text-center mt-5 h5" *ngIf="filteredUsers.length == 0">
        {{'empty-search-user'|translate}}
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end text-capitalize mt-2">
    {{'selected'|translate}}:
    <div class="text-info text-right" style="width: 20px">{{selectedUsers.size}}</div>
  </div>
</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-outline-success"
          (click)="submit()"
          [disabled]="selectedUsers.size == 0">{{'apply'|translate}}
  </button>
  <button type="button" class="btn btn-secondary" (click)="modal.close()">{{'cancel'|translate}}</button>
</div>

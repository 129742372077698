<div class="modal-header">
  <h4 class="modal-title">{{'import-data'|translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="lections.length">
    <label>
      <input type="radio" [(ngModel)]="importOption" name="importOption" [value]="0"/>
      {{'import-into-existing-lection'|translate}}:
    </label>
  </p>
  <p *ngIf="lections.length">
    <select [(ngModel)]="selectedLection" class="form-control" name="importOption:importLection"
            (click)="importOption=0">
      <option *ngFor="let l of lections" [ngValue]="l">{{l.name}}</option>
    </select>
  </p>
  <p>
    <label>
      <input type="radio" [(ngModel)]="importOption" name="importOption" [value]="1"/>
      {{'import-into-new-lection'|translate}}:
    </label>
  </p>
  <p>
    <input type="text" (focus)="importOption = 1" [(ngModel)]="lectionName" class="form-control"
           placeholder="{{'name'|translate}}" name="lection.name"/>
  </p>
  <p>
    {{'auto-create-new-lection'|translate}}:
    <input type="number" [(ngModel)]="newLectionCardCount" class="form-control" name="cardCount"/>
  </p>

  <p>
    {{'copy-data-into-field'|translate}}:
  </p>
  <textarea [(ngModel)]="csvData" class="form-control" placeholder="{{'csv-data'|translate}}" rows="10"
            name="importData"></textarea>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="importData()">{{'import-data'|translate}}</button>
</div>

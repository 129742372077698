import {Component, OnInit} from '@angular/core';
import {Module} from '../module.model';
import {Card} from '../card.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-card-editor',
  templateUrl: './card-editor.component.html',
  styleUrls: ['./card-editor.component.css']
})
export class CardEditorComponent implements OnInit {

  module: Module;
  card: Card;
  frontText: string;
  backText: string;

  constructor(public modal: NgbActiveModal) {
  }

  ngOnInit() {
    this.frontText = this.card.frontText;
    this.backText = this.card.backText;
  }

  onFrontTextChange(event) {
    console.log(event)
    this.frontText = event;
  }

  onBackTextChange(event) {
    this.backText = event;
  }

  apply() {
    this.card.frontText = this.frontText;
    this.card.backText = this.backText;
    this.modal.close();
  }
}

import {Injectable} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LoadingComponent} from './loading.component';
import {ModalWrapperService} from '../modal/modal-wrapper.service';

@Injectable()
export class LoadingService {

  ngbModalRef: NgbModalRef = null;

  constructor(private modalService: ModalWrapperService) {
  }

  open(loadingText?: string) {
    if (!this.ngbModalRef) {
      this.ngbModalRef = this.modalService.open(LoadingComponent,
        {
          backdrop: 'static',
          windowClass: 'loading-modal'
        });
    }
    if (loadingText) {
      this.ngbModalRef.componentInstance.loadingText = loadingText;
    }
  }

  isOpen(): boolean {
    return this.ngbModalRef != null;
  }

  dismiss() {
    if (this.ngbModalRef) {
      this.ngbModalRef.close();
      this.ngbModalRef = null;
    }
  }
}

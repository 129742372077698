import {PageNotFoundComponent} from './404/page-not-found/page-not-found.component';
import {LoginFormComponent} from './auth/login-form/login-form.component';
import {Routes} from '@angular/router';
import {ImprintComponent} from './legal/imprint/imprint.component';
import {TosComponent} from './legal/tos/tos.component';
import {PrivacyComponent} from './legal/privacy/privacy.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthService} from './auth/auth.service';
import {ClientListComponent} from './client/client-list/client-list.component';
import {ClientDetailComponent} from './client/client-detail/client-detail.component';
import {UserListComponent} from './user/user-list/user-list.component';
import {UserDetailComponent} from './user/user-detail/user-detail.component';
import {ModuleListComponent} from './module/module-list/module-list.component';
import {ModuleDetailComponent} from './module/module-detail/module-detail.component';
import {ModulePublicComponent} from './module/module-public/module-public.component';
import {UserProfileComponent} from './user/user-profile/user-profile.component';
import {SignupFormComponent} from './auth/signup-form/signup-form.component';
import {AsadListComponent} from './asad/asad-list/asad-list.component';
import {AsadDetailComponent} from './asad/asad-detail/asad-detail.component';
import {AsadPublicComponent} from './asad/asad-public/asad-public.component';
import {LearnStartComponent} from './learn/learn-start/learn-start.component';
import {LearnModuleComponent} from './learn/learn-module/learn-module.component';
import {SystemLogListComponent} from './logs/system-log-list/system-log-list.component';
import {UserImportComponent} from './user/user-import/user-import.component';
import {KTrackerListComponent} from './ktracker/ktracker-list/ktracker-list.component';
import {KTrackerDetailComponent} from './ktracker/ktracker-detail/ktracker-detail.component';
import {KTrackerStatsComponent} from './ktracker/ktracker-stats/ktracker-stats.component';
import {CertWizardListComponent} from './cert-wizard/cert-wizard-list/cert-wizard-list.component';
import {CertWizardDetailComponent} from './cert-wizard/cert-wizard-detail/cert-wizard-detail.component';
import {CertWizardStatsComponent} from './cert-wizard/cert-wizard-stats/cert-wizard-stats.component';
import {CertWizardPublicComponent} from './cert-wizard/cert-wizard-public/cert-wizard-public.component';
import {CertWizardLearnComponent} from './cert-wizard/cert-wizard-learn/cert-wizard-learn.component';
import {CertWizardDashboardComponent} from './cert-wizard/cert-wizard-dashboard/cert-wizard-dashboard.component';
import {MessageComponent} from './util/message/message.component';

export const appRoutes: Routes = [
  {path: 'login', component: LoginFormComponent},
  {path: 'login/:token', component: LoginFormComponent},
  {path: 'signup/:code', component: SignupFormComponent},
  {path: 'imprint', component: ImprintComponent},
  {path: 'tos', component: TosComponent},
  {path: 'privacy', component: PrivacyComponent},
  {path: 'privacy/no-nav', component: PrivacyComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthService]},
  {path: 'profile', component: UserProfileComponent, canActivate: [AuthService]},
  {path: 'modules', component: ModuleListComponent, canActivate: [AuthService]},
  {path: 'modules/:id', component: ModuleDetailComponent, canActivate: [AuthService]},
  {path: 'public/modules/:id', component: ModuleDetailComponent, canActivate: [AuthService]},
  {path: 'public/modules', component: ModulePublicComponent, canActivate: [AuthService]},
  {path: 'learn/:module_id', component: LearnStartComponent, canActivate: [AuthService]},
  {path: 'learn/module/:module_id', component: LearnModuleComponent, canActivate: [AuthService]},
  {path: 'learn/ktracker/:ktracker_id', component: LearnModuleComponent, canActivate: [AuthService]},
  {path: 'learn/cert-wizards/:cert-wizard-id', component: CertWizardLearnComponent},
  {path: 'asads', component: AsadListComponent, canActivate: [AuthService]},
  {path: 'asads/:id', component: AsadDetailComponent, canActivate: [AuthService]},
  {path: 'public/asads/:id', component: AsadDetailComponent, canActivate: [AuthService]},
  {path: 'public/asads', component: AsadPublicComponent, canActivate: [AuthService]},
  {path: 'admin/clients', component: ClientListComponent, canActivate: [AuthService]},
  {path: 'admin/clients/:id', component: ClientDetailComponent, canActivate: [AuthService]},
  {path: 'admin/logs', component: SystemLogListComponent, canActivate: [AuthService]},
  {path: 'manager/users', component: UserListComponent, canActivate: [AuthService]},
  {path: 'manager/users/:id', component: UserDetailComponent, canActivate: [AuthService]},
  {path: 'manager/import/users', component: UserImportComponent, canActivate: [AuthService]},
  {path: 'manager/clients/:id', component: ClientDetailComponent, canActivate: [AuthService]},
  {path: 'manager/ktrackers', component: KTrackerListComponent, canActivate: [AuthService]},
  {path: 'manager/ktrackers/:id', component: KTrackerDetailComponent, canActivate: [AuthService]},
  {path: 'manager/ktrackers/stats/:id', component: KTrackerStatsComponent, canActivate: [AuthService]},
  {path: 'public/cert-wizards/:id', component: CertWizardPublicComponent},
  {path: 'cert-wizards/:cert-wizard-id/cert-wizard-dashboard', component: CertWizardDashboardComponent},
  {path: 'manager/cert-wizards', component: CertWizardListComponent, canActivate: [AuthService]},
  {path: 'manager/cert-wizards/:id', component: CertWizardDetailComponent, canActivate: [AuthService]},
  {path: 'manager/cert-wizards/stats/:id', component: CertWizardStatsComponent, canActivate: [AuthService]},
  {path: 'not-found', component: PageNotFoundComponent},
  {path: 'message/:id', component: MessageComponent},
  {path: '', component: LoginFormComponent},
  {path: '**', component: PageNotFoundComponent}
];

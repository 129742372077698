<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">Dashboard</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row" *ngIf="userStat?.moduleCount > 0">
    <div class="col-md-3 pb-4">
      <user-stats-today [userStat]="userStat"></user-stats-today>
      <div class="mt-4" *ngIf="userStat?.dueKTrackers.length > 0">
        <user-stats-ktracker [userStat]="userStat"></user-stats-ktracker>
      </div>
    </div>
    <div class="col-md-9">
      <module-list-last-activity></module-list-last-activity>
    </div>
  </div>
  <div class="card mt-4 mb-4" *ngIf="userStat?.moduleCount == 0">
    <div class="card-body">
      <h4 class="card-title">{{'welcome'|translate}}</h4>
      <p class="card-text">{{'no-modules-text'|translate}}</p>
      <div class="dropdown">
        <button class="btn btn-outline-success dropdown-toggle" type="button" id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
          <i class="fa fa-plus" aria-hidden="true"></i>
          {{'new-module'|translate}}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item" routerLink="/modules/newOneSide">
            <img [src]="'assets/img/ONE_SIDE.png'"/>
            {{'ONE_SIDE'|translate}}
          </a>
          <a class="dropdown-item" routerLink="/modules/newTwoSide">
            <img [src]="'assets/img/TWO_SIDE.png'"/>
            {{'TWO_SIDE'|translate}}
          </a>
          <a class="dropdown-item" routerLink="/modules/newMC">
            <img [src]="'assets/img/MULTIPLE_CHOICE.png'"/>
            {{'MULTIPLE_CHOICE'|translate}}
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/public/modules">
            {{'search-public-area'|translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container" style="margin-top: 50px">
  <div class="row" *ngIf="!loadingService.isOpen()">
    <div
      class="offset-sm-2 col-sm-8 offset-md-3 col-md-7 offset-lg-4 col-lg-5">
      <div class="card" [class.fade-in-1]="lostPassword">
        <h4 class="card-header" *ngIf="!lostPassword">
          {{'login-title'|translate}}
        </h4>
        <h4 class="card-header" *ngIf="lostPassword">
          {{'forgot-password-title'|translate}}
        </h4>

        <form (submit)="submit()">
          <div class="card-body">
            <p class="card-text text-danger" *ngIf="isError && !lostPassword">
              {{'login-error'|translate}}
            </p>
            <p class="card-text text-danger" *ngIf="isErrorLostPassword">
              {{'forgot-password-error'|translate}}
            </p>
            <p *ngIf="lostPassword && !isSuccessLostPassword">{{'forgot-password-text'|translate}}</p>
            <p *ngIf="isSuccessLostPassword">
              <i class="fa fa-check fa-2x" aria-hidden="true" style="color: green;"></i>
              {{'forgot-password-success'|translate}}
            </p>
            <div class="form-group" *ngIf="!isSuccessLostPassword">
              <label for="userName">{{'user-name'|translate}} / {{'email'|translate}}</label>
              <input type="text"
                     id="userName"
                     [(ngModel)]="login"
                     class="form-control"
                     placeholder="{{'user-name'|translate}}" autofocus
                     name="login">
            </div>
            <div class="form-group" *ngIf="!lostPassword">
              <label>{{'password'|translate}}</label>
              <input type="password" [(ngModel)]="password" class="form-control"
                     placeholder="{{'password'|translate}}"
                     name="password">
            </div>
            <div class="form-group" *ngIf="!isSuccessLostPassword">
              <button class="btn btn-primary" type="submit" style="text-transform: capitalize">
                <span *ngIf="!lostPassword">{{'login'|translate}}</span>
                <span *ngIf="lostPassword">{{'submit'|translate}}</span>
              </button>
              <button class="btn btn-secondary"
                      *ngIf="lostPassword"
                      (click)="cancelLostPassword()"
                      style="text-transform: capitalize">
                <span>{{'cancel'|translate}}</span>
              </button>
            </div>
            <a href="#"
               (click)="showLostPassword($event)"
               *ngIf="!lostPassword">
              {{'forgot-password'|translate}}
            </a>
          </div>

        </form>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loadingService.isOpen()">
    <div class="offset-sm-2 col-sm-8 offset-md-3 col-md-7 offset-lg-4 col-lg-5 pt-5">
      <img src="assets/img/mail-footer.svg" class="img-fluid" alt="step stone woman">
    </div>
  </div>
</div>

import {Component, HostBinding} from '@angular/core';
import {TooltipService} from './tooltip.service';

@Component({
  selector: 'tooltip',
  template: `
    <ngb-alert [type]="type" *ngIf="isOpen" (close)="isOpen = false">
      {{ message }} {{ translateKey|translate }}
    </ngb-alert>
  `,
  styleUrls: ['./tooltip.component.css']
})

export class TooltipComponent {

  @HostBinding('class.come-in-top-1') isOpen = false;

  showTime = 3000;

  type = 'success';
  message = '';
  translateKey: string;


  constructor(tooltipService: TooltipService) {
    tooltipService.tooltip.subscribe(
      (config: TooltipConfig) => {
        this.isOpen = true;
        this.type = config.type || 'success';
        this.message = config.message;
        this.translateKey = config.translateKey;
        if (!config.stay) {
          setTimeout(() => this.isOpen = false, config.showTime || this.showTime);
        }
      });
  }


}

export interface TooltipConfig {
  message?: string;
  translateKey?: string;
  type?: string;
  showTime?: number;
  stay?: boolean
}

import {Injectable} from '@angular/core';
import {Log} from 'ng2-logger'
import {AuthService} from './auth/auth.service';

@Injectable()
export class AppSettingsService {
  private log = Log.create('AppSettingsService');

  public static readonly SELECTED_CLIENT_ID = 'selected-client-id';

  private userId: any;

  constructor(private authService: AuthService) {
    authService.auth.subscribe(auth => {
      if (auth) {
        this.userId = auth.user.id;
      }
    });
  }

  load(setting) {
    return localStorage.getItem(this.userId + ':' + setting);
  }

  save(setting, value) {
    localStorage.setItem(this.userId + ':' + setting, value);
  }

  remove(setting) {
    localStorage.removeItem(this.userId + ':' + setting);
  }

}

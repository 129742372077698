import {Component, Input, OnInit, Renderer2} from '@angular/core';
import {Image} from '../../module/image.model';
import {environment} from '../../../environments/environment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'img-modal',
  templateUrl: './img-modal.component.html',
  styleUrls: ['./img-modal.component.css']
})
export class ImgModalComponent implements OnInit {

  @Input() image: Image;
  public alt = 'test';

  constructor(private readonly renderer: Renderer2) { }

  ngOnInit(): void {
    // Get the modal
    let modal = document.getElementById('myModal');

// Get the image and insert it inside the modal - use its "alt" text as a caption
    let img = (document.getElementById('preview') as HTMLElement);
    let modalImg = (document.getElementById('content') as HTMLElement);
    let captionText = (document.getElementById('caption') as HTMLElement);
    img.onclick = () => {
      this.renderer.setStyle(modal, 'display', 'block');
      this.renderer.setAttribute(modalImg, 'src', this.getImageURL(this.image));
      captionText.innerHTML = this.alt;
    }

// Get the <span> element that closes the modal
    let span = document.getElementsByClassName('close')[0];

// When the user clicks on <span> (x), close the modal
    (span as HTMLElement).onclick = function() {
      modal.style.display = 'none';
    }
  }

  public getImageURL(image: Image) {
    return environment.backendApi + '/images/' + image.code + '/' + image.name;
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited d-flex">
    <div class="mr-auto">
    <span class="h2" *ngIf="isReadonly && module">{{module.name}} <small
      class="text-muted">({{'read'|translate}})</small></span>
      <span class="h2" *ngIf="isEditMode && !isReadonly">{{'edit-module'|translate}}</span>
      <span class="h2" *ngIf="!isEditMode && module != null">
      {{'new-module'|translate}} - {{module.type|translate}}
    </span>
    </div>
    <button class="btn btn-link p-0 align-self-end text-primary-2" (click)="openImport()"
            title="{{'import-data'|translate}}" *ngIf="!isReadonly">
      <i class="fa fa-download" style="font-size: 1.5rem" aria-hidden="true"></i>
    </button>
  </div>
</div>

<div class="container-fluid container-limited">
  <form (ngSubmit)="submit($event)" (change)="dataChanged = true" autocomplete="off"
        *ngIf="auth && !loadingService.isOpen()" name="module-detail">

    <div class="row mb-4">
      <div class="col-xs-12 offset-sm-3 col-sm-9">
        <p class="card-text text-danger" *ngIf="errors.get('form') != undefined">
          {{errors.get('form')|translate}}
        </p>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('name') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="module_name">
        {{'name'|translate}}
        <span *ngIf="!isReadonly">*</span>
      </label>
      <div class="col-xs-12 col-sm-9">
        <input required autocomplete="off" class="form-control form-control-danger" type="text" name="module[name]"
               id="module_name" [(ngModel)]="module.name" minlength="2" maxlength="100" *ngIf="!isReadonly">
        <span class="text-truncate" *ngIf="isReadonly"><strong>{{module.name}}</strong></span>
        <div class="form-control-feedback">{{errors.get('name')|translate}}</div>
      </div>
    </div>

    <div class="form-group row"
         [class.has-danger]="errors.get('description') != undefined"
         *ngIf="(isReadonly && module.description) || !isReadonly">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right"
             for="module_description">{{'description'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <textarea class="form-control form-control-danger" [(ngModel)]="module.description"
                  autocomplete="off"
                  maxlength="200" rows="4" name="module[description]" id="module_description" *ngIf="!isReadonly">
        </textarea>
        <span class="text-truncate" *ngIf="isReadonly">{{module.description}}</span>
        <div class="form-control-feedback">{{errors.get('description')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" *ngIf="!isReadonly">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right"
             for="module_visibility">{{'visibility'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <select id="module_visibility" [(ngModel)]="module.visibility"
                class="custom-select" name="module[visibility]" [disabled]="isReadonly">
          <option value="PRIVATE" selected>{{'private'|translate}}</option>
          <option value="PUBLIC_READ">{{'public-read'|translate}}</option>
          <option value="PUBLIC_WRITE">{{'public-write'|translate}}</option>
        </select>
      </div>
    </div>

    <div class="form-group row"
         *ngIf="languages">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right" for="module_lang1"></label>
      <div class="col-xs-12 col-sm-9">
        <select id="module_lang1" [(ngModel)]="module.lang1"
                *ngIf="!isReadonly || (isReadonly && module.lang1)"
                class="custom-select" name="module[lang1]" [disabled]="isReadonly">
          <option [value]="null">{{'select-language'|translate}}</option>
          <option *ngFor="let l of languages" [value]="l.code">{{l.name}}</option>
        </select>
        <select id="module_lang2" [(ngModel)]="module.lang2"
                *ngIf="(!isReadonly && module.type=='TWO_SIDE') || (isReadonly && module.lang2)"
                class="custom-select" name="module[lang2]"
                [disabled]="isReadonly">
          <option [value]="null">{{'select-language'|translate}}</option>
          <option *ngFor="let l of languages" [value]="l.code">{{l.name}}</option>
        </select>
      </div>
    </div>

    <div class="row mb-4">
      <label class="control-label col-12 col-sm-3 text-sm-right"></label>
      <div class="col-12 col-sm-9 text-sm-right">
        <button class="btn btn-outline-success  mr-2" type="submit" *ngIf="!isPublicPreviewMode">
          <span>{{'save'|translate}}</span>
        </button>
        <button class="btn btn-outline-success  mr-2"
                type="button"
                (click)="connectUser(module,$event)"
                title="{{'add-module'|translate }}"
                *ngIf="isPublicPreviewMode">
          <i class="fa fa-plus" aria-hidden="true"></i>
          <span>{{'add-module'|translate}}</span>
        </button>
        <button type="button"
                class="btn btn-secondary" (click)="location.back()">
          <span *ngIf="!isPublicPreviewMode">{{'cancel'|translate}}</span>
          <span *ngIf="isPublicPreviewMode">{{'close'|translate}}</span>
        </button>
      </div>
    </div>

    <div class="row pb-4" *ngFor="let l of module.lections; let lidx = index">
      <div class="col-12">
        <div class="card pb-3" [class.active]="l.userLections[0].active">
          <div class="card-header p-3 d-flex flex-row">
            <input required autocomplete="off" class="form-control form-control-danger"
                   type="text" name="module[lection]-{{lidx}}"
                   [(ngModel)]="l.name" minlength="2" maxlength="100"
                   *ngIf="!isReadonly">
            <div class="mr-auto pt-2 h5"
                 [class.text-white]="l.userLections[0].active"
                 *ngIf="isReadonly">
              {{l.name}}
            </div>
            <label class="custom-control custom-checkbox m-2" *ngIf="!isPublicPreviewMode">
              <input type="checkbox"
                     id="module[lection]-{{lidx}}.active"
                     [(ngModel)]="l.userLections[0].active"
                     class="custom-control-input" name="module[lection]-{{lidx}}.active">
              <label class="custom-control-label text-nowrap"
                     [class.text-white]="l.userLections[0].active"
                     for="module[lection]-{{lidx}}.active">
                {{'learn-lection'|translate}}
              </label>
            </label>
            <span class="btn btn-secondary card-btn" (click)="removeLection(lidx)" *ngIf="!isReadonly">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>

          </div>

          <div class="pl-3 pr-3 pt-3" *ngFor="let c of l.cards; let cidx = index">
            <div class="row">

              <div class="col-sm-12 d-md-flex flex-md-row align-items-md-start">
                <div><span class="card-number">{{cidx+1}}</span></div>
                <div class="text-img-container mr-md-2">
                  <input type="text" [(ngModel)]="c.frontText"
                         class="form-control  mb-2 mb-md-0" name="{{lidx}}-{{cidx}}.frontText"
                         *ngIf="!isReadonly">
                  <div *ngIf="isReadonly"
                       style="padding-top: .10rem;">
                    {{c.frontText}}
                  </div>
                  <div class="d-md-flex flex-md-row align-items-md-start">
                    <div style="position: relative" *ngFor="let cImg of c.cardImages; let cImgIdx = index">
                      <a href="{{cImg.getImageURL()}}" data-lightbox="image-card" *ngIf="cImg.cardFrontImage">
                        <img class="card-img-thumbnail img-thumbnail"
                             [src]="cImg.getImageURL()"
                             *ngIf="cImg.cardFrontImage"/>
                      </a>
                      <a class="img-btn" (click)="removeImage(c,cImgIdx)"
                         *ngIf="cImg.cardFrontImage && !isReadonly">
                        <i class="fa fa-times" style="vertical-align: top" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="text-img-container" *ngIf="module.type == 'TWO_SIDE'">
                  <input type="text" [(ngModel)]="c.backText"
                         class="form-control" name="{{lidx}}-{{cidx}}.backText"
                         *ngIf="!isReadonly">
                  <div *ngIf="isReadonly"
                       style="padding-top: .10rem;">
                    {{c.backText}}
                  </div>
                  <div class="d-md-flex flex-md-row align-items-md-start">
                    <div style="position: relative" *ngFor="let cImg of c.cardImages; let cImgIdx = index">
                      <a href="{{cImg.getImageURL()}}" data-lightbox="image-card" *ngIf="!cImg.cardFrontImage">
                        <img class="card-img-thumbnail img-thumbnail"
                             [src]="cImg.getImageURL()"
                             *ngIf="!cImg.cardFrontImage"/>
                      </a>
                      <a class="img-btn" (click)="removeImage(c,cImgIdx)"
                         *ngIf="!cImg.cardFrontImage && !isReadonly">
                        <i class="fa fa-times" style="vertical-align: top" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="d-flex">
                  <div class="dropdown" *ngIf="!isReadonly">
                    <label class="btn btn-secondary card-btn"
                           id="dropdownMenuButton"
                           [attr.data-toggle]="module.type == 'TWO_SIDE'? 'dropdown' : null"
                           for="fileUpload"
                           (click)="selectedCardImageUpload = c"
                           aria-haspopup="true" aria-expanded="false">
                      <i class="fa fa-picture-o" aria-hidden="true"></i>
                    </label>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                      <label (click)="uploadFrontImage = true"
                             for="fileUpload"
                             class="dropdown-item">
                        {{'front-side'|translate}}
                      </label>
                      <label (click)="uploadFrontImage = false;true"
                             for="fileUpload"
                             class="dropdown-item">
                        {{'back-side'|translate}}
                      </label>
                    </div>
                  </div>

                  <span class="btn btn-secondary card-btn" *ngIf="!isReadonly" (click)="openEditor(c)">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </span>
                  <span class="btn btn-secondary card-btn" (click)="removeCard(l,cidx)" *ngIf="!isReadonly">
                  <i class="fa fa-times" aria-hidden="true"></i>
                </span>
                </div>

              </div>


            </div>

            <div class="pl-3 pr-3 pt-3" *ngFor="let mcc of c.cardMcChoices; let mccidx = index">
              <div class="row">

                <div class="col-sm-12 d-flex flex-row p-0 pl-md-4" *ngIf="!isReadonly || (isReadonly && mcc.text)">

                  <label class="custom-control custom-checkbox">
                    <input type="checkbox"
                           id="{{lidx}}.{{cidx}}.{{mccidx}}.correct"
                           [(ngModel)]="mcc.correct"
                           class="custom-control-input" name="{{lidx}}.{{cidx}}.{{mccidx}}.correct"
                           [disabled]="mccidx == 0 || isReadonly">
                    <label class="custom-control-label" for="{{lidx}}.{{cidx}}.{{mccidx}}.correct"></label>
                  </label>

                  <input type="text" [(ngModel)]="mcc.text"
                         class="form-control mr-md-2 mb-2 mb-md-0" name="{{lidx}}.{{cidx}}.{{mccidx}}.text"
                         *ngIf="!isReadonly">
                  <span *ngIf="isReadonly">{{mcc.text}}</span>
                </div>

              </div>
            </div>
          </div>
          <div class="text-right pt-3 pr-2" *ngIf="!isReadonly">
            <span class="btn btn-secondary btn-sm mr-2" (click)="addCard(l)">
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{'add-card'|translate}}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="text-right pb-3" *ngIf="!isReadonly">
            <span class="btn btn-secondary btn-sm" (click)="addLection()">
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{'add-lection'|translate}}
            </span>
    </div>

    <div class="d-flex flex-row mb-4 mt-2" *ngIf="module.lections.length">
      <div class="mr-auto"><span *ngIf="!isPublicPreviewMode">* required</span></div>
      <button class="btn btn-outline-success  mr-2" type="submit" *ngIf="!isPublicPreviewMode">
        <span>{{'save'|translate}}</span>
      </button>
      <button class="btn btn-outline-success  mr-2"
              type="button"
              (click)="connectUser(module,$event)"
              title="{{'add-module'|translate }}"
              *ngIf="isPublicPreviewMode">
        <i class="fa fa-plus" aria-hidden="true"></i>
        <span>{{'add-module'|translate}}</span>
      </button>
      <button type="button"
              class="btn btn-secondary" (click)="location.back()">
        <span *ngIf="!isPublicPreviewMode">{{'cancel'|translate}}</span>
        <span *ngIf="isPublicPreviewMode">{{'close'|translate}}</span>
      </button>
    </div>

  </form>
</div>
<input id="fileUpload" style="display: none" type="file" ng2FileSelect [uploader]="uploader"/>

import {Module} from './module.model';
import {CardMcChoice} from './card-mc-choice.model';
import {CardImage} from './card-image.model';
import {UserCard} from './user-card.model';

export class Card {

  //module: Module = null;
  frontText = '';
  backText = '';
  cardMcChoices: CardMcChoice[] = [];
  cardImages: CardImage[] = [];
  frontCardImages: CardImage[] = [];
  backCardImages: CardImage[] = [];
  created: number = null;
  modified: number = null;
  userCards: UserCard[] = [];

  constructor(public id: string = null) {
  }

  static createWith(obj: any): Card {
    let newObj = new Card();
    Object.keys(newObj).forEach(
      key => {
        if ('cardMcChoices' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(i => {
            newObj.cardMcChoices.push(CardMcChoice.createWith(i));
          })
        } else if ('cardImages' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(i => {
            newObj.cardImages.push(CardImage.createWith(i));
          })
        } else if ('userCards' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(userCard => {
            newObj.userCards.push(UserCard.createWith(userCard));
          })
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }
}

<div class="row">
  <div class="col-12">
    <div [id]="identifier" class="card mb-2">
      <div class="card-header">
        <div class="d-flex flex-row align-items-center justify-content-between">

          <div>
            {{name|translate}}
          </div>

          <div *ngIf="!downloading" class="d-flex flex-row align-items-center justify-content-between">

            <div class="dropdown dropleft pl-1">
              <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{selection|translate}}
              </button>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a (click)="switchChart('bar-vertical-chart')" class="dropdown-item">{{'bar-vertical-chart'|translate}}</a>
                <a (click)="switchChart('bar-horizontal-chart')" class="dropdown-item">{{'bar-horizontal-chart'|translate}}</a>
                <a (click)="switchChart('pie-chart')" class="dropdown-item">{{'pie-chart'|translate}}</a>
                <a (click)="switchChart('doughnut-chart')" class="dropdown-item">{{'doughnut-chart'|translate}}</a>
              </div>
            </div>

            <button (click)="downloadStat()" class="btn btn-link text-primary-2"
                    title="{{'download-stats'|translate}}">
              <i class="fa fa-download" style="font-size: 1.5rem" aria-hidden="true"></i>
            </button>

          </div>

        </div>

      </div>

      <div class="card-body d-flex flex-column flex-md-row align-items-center bg-white">

        <table class="table table-sm mr-md-auto mt-1" style="width: 300px">
          <tbody>

          <tr *ngFor="let resultName of resultNames; let i=index;">

            <td class="text-muted" [class.border-0]="i == 0">{{resultName.translationKey|translate}}</td>
            <td class="text-primary-2 text-right" [class.border-0]="i == 0">{{resultName.value}}</td>
            <td class="text-primary-2" [class.border-0]="i == 0"></td>

          </tr>

          </tbody>
        </table>

        <div #containerRef class="w-100 ml-2 mr-2">

          <ngx-charts-bar-vertical
            *ngIf="selection == 'bar-vertical-chart'"
            [noBarWhenZero]="true"
            [view]="[containerRef.offsetWidth, 250]"
            [results]="result"
            [legend]="false"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [gradient]="false"
            [scheme]="colorScheme">
          </ngx-charts-bar-vertical>

          <ngx-charts-bar-horizontal
            *ngIf="selection == 'bar-horizontal-chart'"
            [noBarWhenZero]="true"
            [view]="[containerRef.offsetWidth, 250]"
            [results]="result"
            [legend]="false"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxis]="true"
            [yAxis]="true"
            [gradient]="false"
            [scheme]="colorScheme">
          </ngx-charts-bar-horizontal>

          <ngx-charts-pie-chart
            *ngIf="selection == 'pie-chart'"
            [view]="[containerRef.offsetWidth, 250]"
            [results]="result"
            [legend]="false"
            [gradient]="false"
            [scheme]="colorScheme"
            [labels]="true">
          </ngx-charts-pie-chart>

          <ngx-charts-pie-chart
            *ngIf="selection == 'doughnut-chart'"
            [view]="[containerRef.offsetWidth, 250]"
            [results]="result"
            [legend]="false"
            [doughnut]="true"
            [gradient]="false"
            [scheme]="colorScheme"
            [labels]="true">
          </ngx-charts-pie-chart>

        </div>
      </div>

    </div>
  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {CertWizardStat} from '../model/cert-wizard-stat';
import {Color} from '@swimlane/ngx-charts';
import {ChartType} from '../model/chart-type';
import domtoimage from 'dom-to-image';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cert-wizard-stats-item',
  templateUrl: './cert-wizard-stats-item.component.html',
  styleUrls: ['./cert-wizard-stats-item.component.css']
})
export class CertWizardStatsItemComponent implements OnInit {

  @Input()
  public name: string;

  @Input()
  public certWizardStat: CertWizardStat;

  @Input()
  public result: { name: string, value: string | number }[];

  @Input()
  public resultNames: {translationKey: string, value: string | number}[];

  @Input()
  public colorScheme: Color;

  @Input()
  public selection = ChartType.barVertical;

  @Input()
  public downloading = false;

  @Input()
  public identifier: string;

  public constructor(private readonly translateService: TranslateService) { }

  public switchChart(selection: string): void {
    this.selection = selection as ChartType;
  }

  public async downloadStat(): Promise<void> {
    this.downloading = true;
    setTimeout(async () => {
      domtoimage.toPng(document.getElementById(this.identifier), { quality: 1 })
        .then(async (dataUrl) => {
          const link = document.createElement('a');
          link.download = await this.translateService.get(this.name).toPromise();
          link.href = dataUrl;
          link.click();
          link.remove();
          this.downloading = false;
        }).catch(() => {
        this.downloading = false;
      });
    }, 200);
  }

  public ngOnInit(): void {
  }


}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment'
import {User} from './user.model';
import {Log} from 'ng2-logger'
import {HttpClient} from '@angular/common/http';

@Injectable()
export class UserService {
  private log = Log.create('UserService');

  resource = 'users';

  constructor(private http: HttpClient) {
  }

  findAll(): Observable<User[]> {
    return this.http.get<User[]>(environment.backendApi + '/' + this.resource)
      .map(data => {
        this.log.info(<any>data);
        return data.map(item => User.createWith(item));
      });
  }

  findById(id: string): Observable<User> {
    return this.http.get<User>(environment.backendApi + '/' + this.resource + '/' + id)
      .map(data => {
        this.log.info(<any>data);
        return User.createWith(data);
      });
  }

  persist(user: User): Observable<string> {
    return this.http.post<string[]>(environment.backendApi + '/' + this.resource, [user])
      .map(ids => {
        this.log.info(<any>ids);
        return ids[0];
      });
  }

  persistAll(users: User[]): Observable<string[]> {
    return this.http.post<string[]>(environment.backendApi + '/' + this.resource, users)
      .map(ids => {
        this.log.info(<any>ids);
        return ids;
      });
  }

  testAll(users: User[]): Observable<string[]> {
    let params: any = {};
    params.test = 'true';
    return this.http.post<string[]>(
      environment.backendApi + '/' + this.resource, users,
      {params: params})
      .map(res => {
        this.log.info(<any>res);
        return res;
      });
  }

  update(user: User): Observable<boolean> {
    return this.http.post<boolean>(environment.backendApi + '/' + this.resource + '/' + user.id, user)
      .map(res => {
        this.log.info(<any>res);
        return true;
      });
  }

  delete(id: string): Observable<boolean> {
    return this.http.delete<boolean>(environment.backendApi + '/' + this.resource + '/' + id)
      .pipe(
        map(data => {
          return true;
        }),
        catchError((err, obs) => {
          return obs;
        })
      );
  }

}

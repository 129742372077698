<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">{{'imprint'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">
    <div class="col-xs-12 col-md-3 push-md-9" style="margin-bottom: 30px">
      <div class="list-group">
        <a class="list-group-item list-group-item-action" pageScroll href="#1">Haftung für Inhalte</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#2">Haftung für Links</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#3">Urheberrecht</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#4">Datenschutz</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#5">Nutzung von Facebook-Plugins (Like-Button)</a>
        <a class="list-group-item list-group-item-action" pageScroll href="#6">Für den Inhalt verantwortlich § 55 Abs. 2 RStV</a>
      </div>
    </div>
    <div class="col-xs-12 col-md-9 pull-md-3">
      <p>
        Angaben gemäß § 5 TMG<br> Little Helper Verlags GmbH
        <br> Am Tannenhof 13
        <br> 78464 Konstanz
        <br>
        <b>Vertreten durch den Geschäftsführer:</b><br> Martin
        Lengefeld
        <br>
        <b>Kontakt:</b> <br> Telefon: +49 (0)7531-3801544
        <br> E-Mail: info (at) easy learner.de
        <br>
        <b>Registereintrag: </b><br> Eintragung im Registergericht:
        Freiburg
        <br> Registernummer: 704959
        <br>
        <b>Umsatzsteuer-ID: </b><br> Umst. Id Nr. 09196/78016 gemäß
        §27a Umsatzsteuergesetz:
      </p>
      <h3>Haftungsausschluss:</h3>
      <h4 id="1">Haftung für Inhalte</h4>
      <p>Die Inhalte unserer Seiten wurden mit größter Sorgfalt
        erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der
        Inhalte können wir jedoch keine Gewähr übernehmen. Als
        Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
        auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.
        Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
        verpflichtet, übermittelte oder gespeicherte fremde Informationen
        zu überwachen oder nach Umständen zu forschen, die auf eine
        rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
        oder Sperrung der Nutzung von Informationen nach den allgemeinen
        Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung
        ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>


      <h4 id="2">Haftung für Links</h4>
      <p>Unser Angebot enthält Links zu externen Webseiten Dritter,
        auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir
        für diese fremden Inhalte auch keine Gewähr übernehmen. Für die
        Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
        oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
        wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
        überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
        Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
        der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
        Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>

      <h4 id="3">Urheberrecht</h4>
      <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke
        auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die
        Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
        schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur für den privaten, nicht
        kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
        Seite nicht vom Betreiber erstellt wurden, werden die
        Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
        Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
        werden wir derartige Inhalte umgehend entfernen.</p>

      <h4 id="4">Datenschutz</h4>
      <p>Die Nutzung unserer Webseite ist in der Regel ohne Angabe
        personenbezogener Daten möglich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
        stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
        ausdrückliche Zustimmung nicht an Dritte weitergegeben. Wir weisen
        darauf hin, dass die Datenübertragung im Internet (z.B. bei der
        Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht möglich. Der Nutzung von im Rahmen der Impressumspflicht
        veröffentlichten Kontaktdaten durch Dritte zur Übersendung von
        nicht ausdrücklich angeforderter Werbung und
        Informationsmaterialien wird hiermit ausdrücklich widersprochen.
        Die Betreiber der Seiten behalten sich ausdrücklich rechtliche
        Schritte im Falle der unverlangten Zusendung von
        Werbeinformationen, etwa durch Spam-Mails, vor.</p>

      <h4 id="5">Nutzung von Facebook-Plugins (Like-Button)</h4>
      <p>Auf unseren Seiten sind Plugins des sozialen Netzwerks
        Facebook, 1601 South California Avenue, Palo Alto, CA 94304, USA
        integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo
        oder dem "Like-Button" ("Gefällt mir") auf unserer Seite. Eine
        Übersicht über die Facebook-Plugins finden Sie hier:
        http://developers.facebook.com/docs/plugins/. Wenn Sie unsere
        Seiten besuchen, wird über das Plugin eine direkte Verbindung
        zwischen Ihrem Browser und dem Facebook-Server hergestellt.
        Facebook erhält dadurch die Information, dass Sie mit Ihrer
        IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
        "Like-Button" anklicken während Sie in Ihrem Facebook-Account
        eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem
        Facebook-Profil verlinken. Dadurch kann Facebook den Besuch
        unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf
        hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt
        der übermittelten Daten sowie deren Nutzung durch Facebook
        erhalten. Weitere Informationen hierzu finden Sie in der
        Datenschutzerklärung von facebook unter
        http://de-de.facebook.com/policy.php Wenn Sie nicht wünschen, dass
        Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto
        zuordnen kann, loggen Sie sich bitte aus Ihrem
        Facebook-Benutzerkonto aus.</p>

      <h4 id="6">Für den Inhalt verantwortlich § 55 Abs. 2 RStV</h4>
      <p>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV: Martin
        Lengefeld, Geschäftsführer der Little Helper Verlags GmbH, Am Tannenhof 13, D 78464 Konstanz</p>
    </div>
  </div>
</div>

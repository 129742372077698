import {Subscription} from 'rxjs';

export class SubscriptionHelper {

  private _subs: Subscription[] = [];

  constructor() {
  }

  set sub(sub: Subscription) {
    this._subs.push(sub);
  }

  unsubscribeAll(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

}

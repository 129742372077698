<div class="input-group">
  <input class="form-control"
         type="text" name="query"
         [ngModel]="query"
         (ngModelChange)="change($event)"
         placeholder="{{placeholder}}"
         autofocus>
  <div class="input-group-append">
    <button class="btn btn-outline-secondary"
            type="button"
            *ngIf="query"
            (click)="change('')">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
</div>

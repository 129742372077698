import {User} from '../../user/user.model';
import {CertWizardModule} from './cert-wizard-module';
import {CertWizardCertificate} from './cert-wizard-certificate';
import {CertWizardImage} from './cert-wizard-image';
import {SupportedLang} from './supported-lang';
import {CertWizardDisplayName} from './cert-wizard-display-name';
import {Asad} from '../../asad/asad.model';

export class CertWizard {
  public id: string = null;

  public hash: string;
  /**
   * creator of certWizard
   */
  public user: User = null;
  public clientId = 0;
  public name = '';
  public description = '';
  public active = true;
  public learnMode = false;
  /**
   * will new users see onboarding
   */
  public onboarding = true;
  /**
   * link to legal notice of client
   */
  public legalNotice: string = null;
  public displayName: string = null;
  public websiteUrl: string = null;


  public color: string = null;
  public fontColor: string = null;
  public secondaryColor: string = null;
  public secondaryFontColor: string = null;
  public tertiaryColor: string = null;
  public tertiaryFontColor: string = null;


  public font: string = null;
  public answerTimeSec = 0;
  public answerTestTimeSec = 0;
  public certPenaltyTimeMin = 0;
  public learningControlPercentage = 100;
  /**
   * will a final test be mandatory to get certificate
   */
  public exam = false;
  public created: number = null;
  public modified: number = null;
  public certWizardModules: CertWizardModule[] = [];

  /**
   * certificate which user will get after successfully achieving goal of 90% answered correctly
   */
  public certWizardCertificates: CertWizardCertificate[] = [];

  public certWizardImages: CertWizardImage[] = [];

  public certWizardDisplayNames: CertWizardDisplayName[] = [
    new CertWizardDisplayName(SupportedLang.de, null),
    new CertWizardDisplayName(SupportedLang.en, null),
    new CertWizardDisplayName(SupportedLang.it, null),
    new CertWizardDisplayName(SupportedLang.fr, null),
  ];

  public supportedLang?: SupportedLang[] = [];

  public asad: Asad = new Asad();

  static sortByName(a: CertWizard, b: CertWizard): number {
    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  public constructor() {}
}

<div class="fade-in-animation d-flex flex-column aeonik-black p-2 m-0 b-0"
     [style.background-color]="'black'"
     [style.color]="'white'">

  <div style="height: 5rem">
    <div class="d-flex justify-content-end align-items-end align-content-end">
      <i (click)="close()" class="fa fa-times fa-3x pointy-cursor"></i>
    </div>
  </div>

  <div class="mt-auto mb-auto text-center">
    <h4 class="m-0">
      {{'privacy-hint'|translate}}
    </h4>

    <div class="pt-4">
      <button (click)="close()" routerLink="/privacy/no-nav" class="btn btn-sm btn-link border-0">
        {{'privacy-detail'|translate}}
      </button>
    </div>

  </div>

  <div style="height: 5rem">
  </div>
</div>


import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TitleService {


  constructor(private titleService: Title,
              private translate: TranslateService) {
  }

  set title(title: string) {
    try {
      this.translate.get(title).subscribe(
        (transTitle: string) => this.titleService.setTitle(transTitle)
      );
    } catch (e) {
      this.titleService.setTitle(title);
    }
  }
}

export class KTrackerCardAnswer {

  userId: string = null;
  ktrackerId: string = null;
  cardId: string = null;
  answeredCorrect = false;

  constructor() {
  }

}

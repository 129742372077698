import {Asad} from './asad.model';
import {Module} from '../module/module.model';

export class AsadModule {

  id: string = null;
  asad: Asad = null;
  module: Module = null;

  constructor() {
  }


  static createWith(obj: any): AsadModule {
    let newObj = new AsadModule();
    Object.keys(newObj).forEach(
      key => {
        if ('module' === key && obj[key]) {
          newObj.module = Module.createWith(obj[key]);
        } else if ('asad' === key && obj[key]) {
          newObj.asad = Asad.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

}

import {User} from '../user/user.model';

export class UserModule {

  id: string = null;
  inversLearnDirection: boolean = null;
  permission = null;
  lastActivity: number = null;
  user: User = null;

  constructor() {
  }

  static createWith(obj: any): UserModule {
    let newObj = new UserModule();
    Object.keys(newObj).forEach(
      key => {
        if ('user' === key && obj[key]) {
          newObj.user = User.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

  static sortByUserName(a: UserModule, b: UserModule): number {
    let nameA = a.user.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.user.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}

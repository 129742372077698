export enum SupportedLang {
  de = 'de', // deutsch
  en = 'en', // englisch
  it = 'it', // italienisch
  fr = 'fr', // französisch
  pt = 'pt', // portugiesisch
  es = 'es', // spansisch
  sk = 'sk', // slowakisch
  hu = 'hu', // ungarisch
  ro = 'ro', // rumänisch
  pl = 'pl', // polnisch
  cs = 'cs', // tschechisch
  bg = 'bg', // bulgarisch
  sl = 'sl', // slowenisch
  sh = 'sh', // serbokroatisch
  sq = 'sq', // albanisch
}

export function supportedLangAsList(): string[] {
  return Object.keys(SupportedLang)
    .filter(value => isNaN(Number(value)) === true)
    .map(key => SupportedLang[key])
}

export function stringToLang(lang: String): SupportedLang {
  const temp = lang.trim().toLowerCase();

  if (SupportedLang.de.includes(temp)) {
    return SupportedLang.de
  } else if (SupportedLang.en.includes(temp)) {
    return SupportedLang.en
  } else if (SupportedLang.it.includes(temp)) {
    return SupportedLang.it
  } else if (SupportedLang.fr.includes(temp)) {
    return SupportedLang.fr
  } else if (SupportedLang.pt.includes(temp)) {
    return SupportedLang.pt
  } else if (SupportedLang.es.includes(temp)) {
    return SupportedLang.es
  } else if (SupportedLang.sk.includes(temp)) {
    return SupportedLang.sk
  } else if (SupportedLang.hu.includes(temp)) {
    return SupportedLang.hu
  } else if (SupportedLang.ro.includes(temp)) {
    return SupportedLang.ro
  } else if (SupportedLang.pl.includes(temp)) {
    return SupportedLang.pl
  } else if (SupportedLang.cs.includes(temp)) {
    return SupportedLang.cs
  } else if (SupportedLang.bg.includes(temp)) {
    return SupportedLang.bg
  } else if (SupportedLang.sl.includes(temp)) {
    return SupportedLang.sl
  } else if (SupportedLang.sh.includes(temp)) {
    return SupportedLang.sh
  } else if (SupportedLang.sq.includes(temp)) {
    return SupportedLang.sq
  } else {
    return SupportedLang.de
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-app">
  <div class="container-fluid container-limited">
    <div class="row">
      <div class="col-md-6">
        <h1 class="display-4 text-center text-md-left" style="font-size: 42px">
          Der Easy Learner unterstützt Sie effizient beim Lernen.
        </h1>
      </div>
      <div class="col-md-6 text-sm-right pt-4">
        <img src="assets/img/mail-footer.png" class="img-fluid" alt="">
      </div>
    </div>
  </div>
</div>

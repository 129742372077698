<div class="d-flex flex-column aeonik-regular p-2">

  <div class="mt-auto mb-auto">
    <div class="d-flex justify-content-end align-items-end align-content-end">
      <i (click)="close()" class="fa fa-times fa-3x pointy-cursor"></i>
    </div>
  </div>

  <div class="aeonik-black pb-1">
    {{'register'|translate}}
  </div>


  <form>
    <div class="form-group">
      <label for="email">
        {{'email-hint'|translate}}
      </label>
      <input [(ngModel)]="email"
             type="email" class="form-control" id="email" aria-describedby="emailHelp" [placeholder]="emailPlaceholder" name="email"
             [style.background-color]="'#d0d0d0'"
             [style.color]="'black'">
      <p class="text-danger" *ngIf="errors.get('email')">
        {{errors.get('email')|translate}}
      </p>
    </div>
  </form>


  <div class="pt-1 pb-1">
    <button (click)="createCertWizUser()" type="button" class="btn btn-block aeonik-regular"
            [style.background-color] = "certWizard.secondaryColor"
            [style.border-color] = "certWizard.secondaryColor"
            [style.color]="certWizard.secondaryFontColor">
      <h3 class="m-0">
        {{'save'|translate}}
      </h3>
    </button>
  </div>

</div>

import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../util/title/title.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  private _sHelper: SubscriptionHelper = new SubscriptionHelper();

  constructor(private titleService: TitleService,
              public authService: AuthService,
              private router: Router) {
    titleService.title = 'welcome';

    this._sHelper.sub = authService.auth.subscribe(auth => {
      if (auth) {
        this.router.navigate(['dashboard']);
      }
    });

  }

  ngOnInit() {
  }

}

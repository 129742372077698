import {KTrackerLectionStat} from './ktracker-lection-stat.model';

export class KTrackerModuleStat {

  moduleId: number = null;
  cardCount: number = null;
  sentCardCount: number = null;
  sentRequests: number = null;
  averageAnswerTime: number = null;

  correctAnswersCount: number = null;
  wrongAnswersCount: number = null;

  lectionStats: KTrackerLectionStat[] = [];

  constructor() {
  }

  sumAnswers() {
    return this.correctAnswersCount + this.wrongAnswersCount;
  }

  static createWith(obj: any): KTrackerModuleStat {
    let newObj = new KTrackerModuleStat();
    Object.keys(newObj).forEach(
      key => {
        if ('lectionStats' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(um => {
            newObj.lectionStats.push(KTrackerLectionStat.createWith(um));
          })
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

}

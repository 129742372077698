import {Component, TemplateRef, OnDestroy} from '@angular/core';
import {ClientService} from '../client.service';
import {Client} from '../client.model';
import {LoadingService} from '../../util/loading/loading.service';
import {DeleteConfirmComponent} from '../../util/confirm-modal/delete-confirm.component';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {TitleService} from '../../util/title/title.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {KTrackerStat} from '../../stats/ktracker-stat.model';
import {StatsService} from '../../stats/stats.service';
import {ClientStat} from '../../stats/client-stat.model';

@Component({
  selector: 'client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnDestroy {

  clients: Client[] = [];
  stats: Map<string, ClientStat> = new Map();
  filteredClients: Client[] = [];
  auth: any;
  searchQuery = '';
  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private clientService: ClientService,
              private statsService: StatsService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private translate: TranslateService,
              private tooltipService: TooltipService,
              public authService: AuthService,
              private router: Router,
              private titleService: TitleService) {
    titleService.title = 'clients';
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }
    });
  }

  _init() {
    this.loadingService.open();
    this._sHelper.sub = this.clientService.findAll().subscribe(
      clients => {
        this.clients = clients.sort(Client.sortByName);
        this.doSearch();

        // get stats
        this.statsService.findClientStatsByClientIds(this.clients).subscribe(
          stats => stats.forEach(stat => this.stats.set(stat.clientId, stat)));

        this.loadingService.dismiss();
      },
      (error) => {
        console.log(error);
        this.loadingService.dismiss();
      }
    );
  }

  doSearch() {
    this.filteredClients = this.clients.filter(
      (item: Client) => {
        if (!this.searchQuery) {
          return true;
        }
        let nameMatch = false;
        if (item.name) {
          nameMatch = item.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) != -1;
        }

        return nameMatch;
      });
  }

  edit(client) {
    this.router.navigate(['/admin/clients', client.id]);
  }

  delete(client, event) {
    event.stopPropagation();
    this._sHelper.sub = this.translate.get(['delete-text', 'delete-success', 'delete-error'], {name: client.name})
      .subscribe((res: any) => {
        const modalRef = this.modalService.open(DeleteConfirmComponent, {size: 'sm'});
        modalRef.componentInstance.body = res['delete-text'];
        modalRef.result.then(
          (deleteItem) => {
            if (deleteItem) {
              this._sHelper.sub = this.clientService.delete(client.id).subscribe(deleted => {
                if (deleted) {
                  // delete item in local array
                  let delFn = (item, i, arr) => item.id === client.id ? arr.splice(i, 1) : false;
                  this.clients.some(delFn);
                  this.doSearch();
                  this.tooltipService.show({message: res['delete-success']});
                } else {
                  this.tooltipService.show({
                    message: res['delete-error'],
                    type: 'danger',
                    stay: true
                  });
                }
              });

            }
          });
      });
  }

  showUsers(client, event) {
    event.stopPropagation();
    this.router.navigate(['/manager/users', {clientId: client.id}]);
  }

  trackByKey(index: number, item: Client) {
    return item.id;
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

import {Module} from './module.model';
import {Card} from './card.model';
import {UserLection} from './user-lection.model';

export class Lection {

  id: string = null;
  //module: Module = null;
  name: string = null;
  cards: Card[] = [];
  created: number = null;
  modified: number = null;
  userLections: UserLection[] = [];

  constructor() {
  }

  static createWith(obj: any): Lection {
    let newObj = new Lection();
    Object.keys(newObj).forEach(
      key => {
        if ('cards' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(card => {
            newObj.cards.push(Card.createWith(card));
          })
        } else if ('userLections' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(userLection => {
            newObj.userLections.push(UserLection.createWith(userLection));
          })
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

  static sortByName(a: Lection, b: Lection): number {
    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited d-flex">
    <div class="mr-auto">
      <span class="h2">{{'user'|translate}}</span>
    </div>
    <a class="btn btn-link p-0 align-self-end text-primary-2" routerLink="/manager/import/users"
            title="{{'import-data'|translate}}">
      <i class="fa fa-download" style="font-size: 1.5rem" aria-hidden="true"></i>
    </a>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">

    <div class="col order-0" style="margin-bottom: 15px">
      <button routerLink="/manager/users/new" class="btn btn-outline-success">
        <i class="fa fa-plus" aria-hidden="true"></i> {{'new-user'|translate}}
      </button>
    </div>

    <div class="col col-sm-5 col-lg-4 order-1 text-sm-right">
      <select (change)="doSearch()"
              [(ngModel)]="selectedClient"
              (ngModelChange)="selectedClientChanged($event)"
              *ngIf="auth && auth.user.role == 'admin'"
              class="custom-select" style="margin-bottom: 15px">
        <option [ngValue]="null">-- alle --</option>
        <option *ngFor="let c of clients" [ngValue]="c">{{c.name}}</option>
      </select>

      <searchbar style="margin-bottom: 15px" [(query)]="searchQuery" (input)="doSearch()"
                 placeholder="{{'search'|translate}} {{'user'|translate}}"></searchbar>
    </div>
  </div>
  <div class="row">
    <div class="col-12">

      <div class="card">
        <div class="table-responsive" *ngIf="filteredUsers.length">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th class="border-top-0" style="width: 25px;"><!--status--></th>
              <th class="border-top-0">{{'name'|translate}}</th>
              <th class="border-top-0">{{'forename'|translate}}</th>
              <th class="border-top-0 d-none d-md-table-cell">{{'email'|translate}}</th>
              <th class="border-top-0 d-none d-md-table-cell">{{'user-name'|translate}}</th>
              <th class="border-top-0">{{'role'|translate}}</th>
              <th class="border-top-0">{{'client'|translate}}</th>
              <th class="border-top-0"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let user of filteredUsers; trackBy:trackByKey" (click)="edit(user)">
              <td><i class="fa fa-check" aria-hidden="true"
                     [class.fa-check]="user.active"
                     [class.text-success]="user.active"
                     [class.fa-times]="!user.active"
                     [class.text-danger]="!user.active">

              </i>
              </td>
              <td>{{user.name}}</td>
              <td>{{user.forename}}</td>
              <td class="d-none d-md-table-cell">{{user.email}}</td>
              <td class="d-none d-md-table-cell">{{user.login}}</td>
              <td>{{user.role}}</td>
              <td>{{user.client.name}}</td>
              <td class="text-right text-nowrap">
                <button class="btn btn-link btn-table mr-2" *ngIf="showDelete(user)"
                        (click)="switchToUser(user,$event)">
                  <i class="fa fa-user" aria-hidden="true"></i>
                  <i class="fa fa-arrow-right" aria-hidden="true"></i>
                  <i class="fa fa-user" aria-hidden="true"></i>
                </button>
                <button class="btn btn-link btn-table" *ngIf="showDelete(user)"
                        (click)="delete(user,$event)">
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="!filteredUsers.length">
          <h5 class="card-title text-muted text-xs-center" style="margin-top: 12px">
            {{'empty-search-user'|translate}}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="jumbotron jumbotron-fluid jumbotron-small"
     [class.jumbotron-active]="asad && asad.userAsads[0] && asad.userAsads[0].active">
  <div class="container-fluid container-limited d-flex flex-row">
    <div class="mr-auto">
    <span class="h2" *ngIf="isReadonly && asad">{{asad.name}} <small
      class="text-muted">({{'read'|translate}})</small></span>
      <span class="h2" *ngIf="isEditMode && !isReadonly">{{'edit-asad'|translate}}</span>
      <span class="h2" *ngIf="!isEditMode && asad != null">
      {{'new-asad'|translate}}
    </span>
    </div>
  </div>
</div>
<div class="container-fluid container-limited">
  <form (ngSubmit)="submit($event)" (change)="dataChanged = true" autocomplete="off"
        *ngIf="auth && !loadingService.isOpen()" name="detail">

    <div class="row mb-4">
      <div class="col-xs-12 offset-sm-3 col-sm-9">
        <p class="card-text text-danger" *ngIf="errors.get('error') != undefined">
          {{errors.get('error')|translate}}
        </p>
      </div>
    </div>

    <div class="form-group row" *ngIf="!isPublicPreviewMode">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="active">{{'active'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <input id="active" type="checkbox" name="active" [(ngModel)]="asad.userAsads[0].active">
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('name') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="name">
        {{'name'|translate}}<span *ngIf="!isReadonly">*</span>
      </label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="off" class="form-control form-control-danger" type="text" name="name"
               id="name" [(ngModel)]="asad.name" minlength="2" maxlength="100" *ngIf="!isReadonly">
        <span class="text-truncate" *ngIf="isReadonly"><strong>{{asad.name}}</strong></span>
        <div class="form-control-feedback">{{errors.get('name')|translate}}</div>
      </div>
    </div>

    <div class="form-group row"
         [class.has-danger]="errors.get('description') != undefined"
         *ngIf="(isReadonly && asad.description) || !isReadonly">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="description">{{'description'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <textarea class="form-control form-control-danger" [(ngModel)]="asad.description"
                  autocomplete="off"
                  maxlength="200" rows="2" name="description" id="description" *ngIf="!isReadonly">
        </textarea>
        <span class="text-truncate" *ngIf="isReadonly">{{asad.description}}</span>
        <div class="form-control-feedback">{{errors.get('description')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('schedule-days') != undefined"
         *ngIf="!isPublicPreviewMode">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right"
             for="description">{{'training-days'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <label class="mr-2">
          <input type="checkbox" name="mo" [(ngModel)]="scheduleDays[0]">
          {{'mon'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="di" [(ngModel)]="scheduleDays[1]">
          {{'tue'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="mi" [(ngModel)]="scheduleDays[2]">
          {{'wed'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="do" [(ngModel)]="scheduleDays[3]">
          {{'thu'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="fr" [(ngModel)]="scheduleDays[4]">
          {{'fri'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="sa" [(ngModel)]="scheduleDays[5]">
          {{'sat'|translate}}
        </label>
        <label class="mr-3">
          <input type="checkbox" name="so" [(ngModel)]="scheduleDays[6]">
          {{'sun'|translate}}
        </label>
        <div class="form-control-feedback">{{errors.get('schedule-days')|translate}}</div>
      </div>
    </div>

    <div class="form-group row align-items-center" *ngIf="!isPublicPreviewMode">
      <div class="col-xs-12 col-sm-3 text-sm-right">{{'time-of-day'|translate}}</div>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div style="min-width: 30px">{{asad.userAsads[0].scheduleTime}}</div>
        <mat-slider id="schedule-time" min="0" max="23" step="1" style="width: 100%"
                    [value]="asad.userAsads[0].scheduleTime"
                    (input)="asad.userAsads[0].scheduleTime = $event.value; dataChanged=true"></mat-slider>
      </div>
    </div>

    <div class="form-group row" *ngIf="!isPublicPreviewMode">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="active">{{'link-to-learn-mode'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <input id="emailLearnLink" type="checkbox" name="emailLearnLink" [(ngModel)]="asad.userAsads[0].emailLearnLink">
      </div>
    </div>

    <div class="form-group row" *ngIf="!isPublicPreviewMode">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="active">{{'start-date'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <input id="startDate"
               style="border: 1px solid #ced4da;border-radius: .25rem;padding: .375rem .75rem;"
               type="text"
               [(ngModel)]="startDate"
               (ngModelChange)="dataChanged=true"
               name="startDate"
               [matDatepicker]="myDatepicker"
               disabled>
        <mat-datepicker-toggle [for]="myDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #myDatepicker [disabled]="false"></mat-datepicker>
      </div>
    </div>

    <div class="form-group row align-items-center" *ngIf="!isPublicPreviewMode">
      <div class="col-xs-12 col-sm-3 text-sm-right">{{'repetitions'|translate}}</div>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div style="min-width: 30px">{{asad.userAsads[0].repetitions}}</div>
        <mat-slider id="repetitions" [min]="repetitionsMin" [max]="repetitionsMax" step="1" style="width: 100%"
                    [value]="asad.userAsads[0].repetitions"
                    (input)="asad.userAsads[0].repetitions = $event.value; dataChanged=true"></mat-slider>
      </div>
    </div>

    <div class="form-group row" *ngIf="!isPublicPreviewMode">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="active">{{'repetitions-done'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        {{asad.userAsads[0].repetitionsDone}}
      </div>
    </div>

    <div class="form-group row align-items-center" *ngIf="!isPublicPreviewMode">
      <div class="col-xs-12 col-sm-3 text-sm-right">{{'repetition-break-in-weeks'|translate}}</div>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div style="min-width: 30px">{{asad.userAsads[0].repetitionBreakInWeeks}}</div>
        <mat-slider id="repetitionBreakInWeeks" min="0" max="10" step="1" style="width: 100%"
                    [value]="asad.userAsads[0].repetitionBreakInWeeks"
                    (input)="asad.userAsads[0].repetitionBreakInWeeks = $event.value; dataChanged=true"></mat-slider>
      </div>
    </div>

    <div class="form-group row">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right"
             for="schedule-time">{{'card-count'|translate}}</label>
      <div class="col-xs-12 col-sm-9 d-flex align-items-center">
        <div class="text-primary-2" style="min-width: 40px">
          {{cardCount}}
        </div>
        <span class="btn btn-secondary btn-sm"
              (click)="addModule()"
              *ngIf="!isReadonly">
              <i class="fa fa-plus" aria-hidden="true"></i>
              {{'add-modules'|translate}}
            </span>
      </div>
    </div>

    <div class="row mb-4">
      <label class="control-label col-12 col-sm-3 text-sm-right"></label>
      <div class="col-12 col-sm-9 text-sm-right">
        <button class="btn btn-outline-success  mr-2" type="submit" *ngIf="!isPublicPreviewMode">
          <span>{{'save'|translate}}</span>
        </button>
        <button type="button"
                class="btn btn-secondary"
                (click)="location.back()">
          {{'cancel'|translate}}
        </button>
      </div>
    </div>

    <div class="row mb-4" *ngIf="asad.asadModules.length > 0">
      <label class="control-label col-xs-12 col-sm-3 text-sm-right">
        {{'modules'|translate}}
      </label>
      <div class="col-sm-9">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <tr *ngFor="let am of asad.asadModules">
                <td class="pt-0 pb-0 align-middle" style="width: 90px">
                  <div style="width: 100%" class="d-flex justify-content-between align-items-center">
                    <img [src]="'assets/img/'+am.module.type+'.png'" style="height: 32px"/>
                    <div class="text-info text-right align-self-center">
                      {{moduleStats.get(am.module.id)?.cardCount}}
                    </div>
                  </div>
                </td>

                <td>{{am.module.name}}</td>
                <td class="d-none d-md-table-cell">{{am.module.description}}</td>
                <td class="d-flex flex-row justify-content-end" *ngIf="!isReadonly">
                  <button class="btn btn-link btn-table text-primary-2"
                          (click)="remove(am,$event)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>

  </form>
</div>

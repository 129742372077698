import {Certificate} from './certificate';
import {SupportedLang} from './supported-lang';

export class CertWizardCertificate {
  id: string = null;
  lang: SupportedLang = null;
  certificate: Certificate = null;

  public constructor(cert: Certificate, lang: SupportedLang) {
    this.id = null;
    this.lang = lang;
    this.certificate = cert;
  }
}

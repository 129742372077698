import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.css']
})
export class SearchbarComponent implements OnInit {

  @Input() placeholder = 'Search for...';
  @Input() query: string;
  @Output() queryChange = new EventEmitter();
  @Output() input = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  change(newQuery) {
    this.query = newQuery;
    this.queryChange.emit(newQuery);
    this.input.emit(newQuery);
  }
}

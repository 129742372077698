import {User} from '../user/user.model';
import {Asad} from './asad.model';

export class UserAsad {

  id: string = null;
  user: User = null;
  asad: Asad = null;
  active = true;
  emailLearnLink = false;
  permission: string = null;
  scheduleDays = '0123456';
  scheduleTime = '6';
  startDate = 0;
  repetitions = 1;
  repetitionBreakInWeeks = 0;
  repetitionsDone = 0;

  constructor() {
  }


  static createWith(obj: any): UserAsad {
    let newObj = new UserAsad();
    Object.keys(newObj).forEach(
      key => {
        if ('user' === key && obj[key]) {
          newObj.user = User.createWith(obj[key]);
        } else if ('asad' === key && obj[key]) {
          newObj.asad = Asad.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

  static createUaByUaForCertWizard(ua: UserAsad): UserAsad {
    let newUa = new UserAsad();
    newUa.asad = ua.asad;
    newUa.active = true
    newUa.emailLearnLink = true;
    newUa.scheduleDays = ua.scheduleDays;
    newUa.scheduleTime = ua.scheduleTime;
    newUa.startDate = ua.startDate;
    newUa.repetitions = ua.repetitions;
    newUa.repetitionBreakInWeeks = ua.repetitionBreakInWeeks;
    newUa.repetitionsDone = 0;
    return newUa;
  }

}

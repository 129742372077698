import {Component, OnDestroy} from '@angular/core';
import {ModuleStat} from '../../stats/module-stat.model';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CertWizard} from '../model/cert-wizard';
import {LoadingService} from '../../util/loading/loading.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {StatsService} from '../../stats/stats.service';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {CertWizardService} from '../cert-wizard.service';
import {ModulePickerComponent} from '../../module/module-picker/module-picker.component';
import {Module} from '../../module/module.model';
import {CertWizardModule} from '../model/cert-wizard-module';
import {Location} from '@angular/common';
import {FileUploader} from 'ng2-file-upload';
import {environment} from '../../../environments/environment';
import {Image} from '../../module/image.model';
import * as tinyColor from 'tinycolor2';
import {Certificate} from '../model/certificate';
import {CertWizardCertificate} from '../model/cert-wizard-certificate';
import {SupportedLang, supportedLangAsList} from '../model/supported-lang';
import {CertWizardImage} from '../model/cert-wizard-image';
import {CertWizardImageType} from '../model/CertWizardImageType';
import {CertWizardDisplayName} from '../model/cert-wizard-display-name';
import {TitleService} from '../../util/title/title.service';
import {AsadUserComponent} from '../../asad/asad-user/asad-user.component';
import {UserAsad} from '../../asad/user-asad.model';
import {Auth} from '../../auth/auth';

@Component({
  selector: 'app-cert-wizard-detail',
  templateUrl: './cert-wizard-detail.component.html',
  styleUrls: ['./cert-wizard-detail.component.css']
})
export class CertWizardDetailComponent implements OnDestroy {

  public FALLBACK_COLOR = '#FD7B4C';
  public FALLBACK_SECONDARY_COLOR = '#007bff';
  public FALLBACK_TERTIARY_COLOR = '#D0D0D0';
  public FALLBACK_LEFT_MAIL_IMAGE = '';
  public FALLBACK_BOTTOM_MAIL_IMAGE = '';
  public FALLBACK_BACKGROUND_IMAGE = '';
  public FALLBACK_LEFT_LOGO = '';

  public dataChanged = false;
  public isEditMode: boolean;

  public errors: Map<string, string> = new Map();
  public auth: Auth;
  public certWizard: CertWizard;
  public stats: Map<string, ModuleStat> = new Map();
  private sHelper: SubscriptionHelper = new SubscriptionHelper;
  private ngbModalRef: NgbModalRef = null;
  public learnModules: CertWizardModule[] = [];
  public examModules: CertWizardModule[] = [];

  public supportedLang = supportedLangAsList();

  public selectedLang: SupportedLang = SupportedLang.de;

  public certLoading = false;

  public repetitionsMin = 1;
  public repetitionsMax = 10;
  public scheduleDays: boolean[] = [];


  public backgroundUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });

  public logoLeftUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });

  public logoRightUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });

  public logoLeftSecondUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });

  public leftMailImageUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });

  public rightMailImageUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });


  public bottomMailImageUploader = new FileUploader({
    autoUpload: true,
    url: environment.backendApi + '/images',
    authToken: AuthService.authToken
  });


  constructor(private readonly certWizardService: CertWizardService,
              private readonly modalService: ModalWrapperService,
              private readonly statsService: StatsService,
              private readonly translate: TranslateService,
              private readonly tooltipService: TooltipService,
              private readonly route: ActivatedRoute,
              private readonly router: Router,
              public location: Location,
              public authService: AuthService,
              public loadingService: LoadingService,
              private titleService: TitleService) {
    this.titleService.title = 'CertWizard Detail';
    this.sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        void this.init();
      }
    });
  }

  private async init(): Promise<void> {

    this.FALLBACK_LEFT_LOGO = `${location.origin}/assets/img/el-logo.svg`;
    this.FALLBACK_LEFT_MAIL_IMAGE = `${location.origin}/assets/img/el-logo.png`;
    this.FALLBACK_BOTTOM_MAIL_IMAGE = `${location.origin}/assets/img/cert-wizard/icon_learn_card_see_through.png`;
    this.FALLBACK_BACKGROUND_IMAGE = `${location.origin}/assets/img/mail-footer.svg`;


    this.loadingService.open();

    let certWizardId = this.route.snapshot.params['id'];
    if (certWizardId && certWizardId === 'new') {
      this.isEditMode = false;
      this.initNewCertWizard();
      this.loadingService.dismiss();
    } else {
      this.isEditMode = true;
      this.certWizardService.findByUserAndId(this.auth.user, certWizardId)
        .then((cw: CertWizard) =>  {
          this.certWizard = cw;
          this.handleCertWizardColorsFallback();
          this.updateModuleKindLists();
          this.handleScheduleDays();
          this.loadingService.dismiss();
      }).catch(() => {
        this.loadingService.dismiss();
        void this.router.navigate(['message/not-found'])
      })
    }

    this.logoLeftUploader.onSuccessItem = this.onSuccessLogoLeftUpload.bind(this);
    this.logoLeftUploader.onErrorItem = this.onErrorImgUpload.bind(this);

    this.logoRightUploader.onSuccessItem = this.onSuccessLogoRightUpload.bind(this);
    this.logoRightUploader.onErrorItem = this.onErrorImgUpload.bind(this);

    this.logoLeftSecondUploader.onSuccessItem = this.onSuccessLogoLeftSecondUpload.bind(this);
    this.logoLeftSecondUploader.onErrorItem = this.onErrorImgUpload.bind(this);

    this.backgroundUploader.onSuccessItem = this.onSuccessBackgroundUpload.bind(this);
    this.backgroundUploader.onErrorItem = this.onErrorImgUpload.bind(this);

    this.leftMailImageUploader.onSuccessItem = this.onSuccessLeftMailUpload.bind(this);
    this.leftMailImageUploader.onErrorItem = this.onErrorImgUpload.bind(this);

    this.rightMailImageUploader.onSuccessItem = this.onSuccessRightMailUpload.bind(this);
    this.rightMailImageUploader.onErrorItem = this.onErrorImgUpload.bind(this);

    this.bottomMailImageUploader.onSuccessItem = this.onSuccessBottomMailUpload.bind(this);
    this.bottomMailImageUploader.onErrorItem = this.onErrorImgUpload.bind(this);
  }

  public getFontColor(color: string): string {
    return tinyColor(color).isDark() ? '#ffffff' : '#000000'
  }

  public getImageURL(certWizardImage: CertWizardImage): string {
    return environment.backendApi + '/images/' + certWizardImage.image.code + '/' + certWizardImage.image.name;
  }

  private handleScheduleDays() {
    const days = this.certWizard.asad.userAsads[0].scheduleDays;
    for (let i = 0; i < 7; i++) {
      this.scheduleDays.push(days.includes('' + i));
    }
  }

  private initNewCertWizard() {
    this.certWizard = new CertWizard();
    this.handleCertWizardColorsFallback();
    this.updateModuleKindLists();
  }

  public addModules(finalTestModules?: boolean): void {
    this.ngbModalRef = this.modalService.open(ModulePickerComponent);

    if (finalTestModules) {
      this.ngbModalRef.componentInstance.moduleTypes.push('MULTIPLE_CHOICE');
      this.certWizard.certWizardModules
        .filter((am: CertWizardModule) => (am.lang === this.selectedLang) && am.exam)
        .forEach(
          am => this.ngbModalRef.componentInstance.excludedModules.set(am.module.id, am.module)
        );
    }

    if (!finalTestModules) {
      this.certWizard.certWizardModules
        .filter((am: CertWizardModule) => (am.lang === this.selectedLang) && !am.exam)
        .forEach(
          am => this.ngbModalRef.componentInstance.excludedModules.set(am.module.id, am.module)
        );
    }

    this.ngbModalRef.result.then((res: Module[]) => {
      if (res) {
        res.forEach(m => {
          let am = new CertWizardModule();
          am.module = m;
          am.lang = this.selectedLang;
          if (finalTestModules) {
            am.exam = true;
          }
          this.certWizard.certWizardModules.push(am);
          this.updateModuleKindLists();
          // @TODO get stats for card count
          // this.getModuleStats();
        });
        this.dataChanged = true;
      }
    }, (reason) => {
    });
  }

  private updateModuleKindLists(): void {
    this.learnModules = this.certWizard.certWizardModules.filter(m => !m.exam)
    this.examModules = this.certWizard.certWizardModules.filter(m => m.exam)
  }

  public remove(event: Event, certWizardModule: CertWizardModule) {
    event.stopPropagation();
    let delFn = (item, i, arr) => item.id === certWizardModule.id ? arr.splice(i, 1) : false;
    this.certWizard.certWizardModules.some(delFn);
    this.dataChanged = true;
    this.updateModuleKindLists();
    // this.calcCardCount();
  }

  private navigateNext() {
    void this.router.navigate(['/manager/cert-wizards']);
  }

  private processServerErrors(er) {
    let errorJSON = null;
    console.error(er.error)
    try {
      errorJSON = er.error;
    } catch (e) {
      this.errors.set('error', er.statusText);
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set('error', errorJSON[k]);
        });
    }
  }

  public getLogoLeftByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public removeLogoLeftByLang() {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT)
    if (index !== -1) {
      this.certWizard.certWizardImages.splice(index, 1);
    }
    this.dataChanged = true;
  }

  private onSuccessLogoLeftUpload(item: any, response: any, status: any, headers: any): void {
    if (response) {
      const img = Image.createWith(JSON.parse(response));
      this.certWizard.certWizardImages.push(
        new CertWizardImage(this.selectedLang, CertWizardImageType.LOGO_LEFT, img)
      );
      this.dataChanged = true;
    }
  }


  public getLogoRightByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_RIGHT)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public removeLogoRightByLang() {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_RIGHT)
    if (index !== -1) {
      this.certWizard.certWizardImages.splice(index, 1);
    }
    this.dataChanged = true;
  }

  private onSuccessLogoRightUpload(item: any, response: any, status: any, headers: any): void {
    if (response) {
      const img = Image.createWith(JSON.parse(response));
      this.certWizard.certWizardImages.push(
        new CertWizardImage(this.selectedLang, CertWizardImageType.LOGO_RIGHT, img)
      );
      this.dataChanged = true;
    }
  }

  public getLogoLeftSecondByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT_SECOND)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public removeLogoLeftSecondByLang() {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT_SECOND)
    if (index !== -1) {
      this.certWizard.certWizardImages.splice(index, 1);
    }
    this.dataChanged = true;
  }

  private onSuccessLogoLeftSecondUpload(item: any, response: any, status: any, headers: any): void {
    if (response) {
      const img = Image.createWith(JSON.parse(response));
      this.certWizard.certWizardImages.push(
        new CertWizardImage(this.selectedLang, CertWizardImageType.LOGO_LEFT_SECOND, img)
      );
      this.dataChanged = true;
    }
  }

  public getBackgroundImage(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.type === CertWizardImageType.BACKGROUND_IMAGE)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public removeBackgroundImage() {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.type === CertWizardImageType.BACKGROUND_IMAGE)
    if (index !== -1) {
      this.certWizard.certWizardImages.splice(index, 1);
    }
    this.dataChanged = true;
  }

  private onSuccessBackgroundUpload(item: any, response: any, status: any, headers: any): void {
    if (response) {
      const img = Image.createWith(JSON.parse(response));
      this.certWizard.certWizardImages
        .push(new CertWizardImage(this.selectedLang, CertWizardImageType.BACKGROUND_IMAGE, img));
      this.dataChanged = true;
    }
  }

  private onSuccessLeftMailUpload(item: any, response: any, status: any, headers: any): void {
    if (response) {
      const img = Image.createWith(JSON.parse(response));
      this.certWizard.certWizardImages
        .push(new CertWizardImage(this.selectedLang, CertWizardImageType.LEFT_MAIL_IMAGE, img));
      this.dataChanged = true;
    }
  }

  private onSuccessRightMailUpload(item: any, response: any, status: any, headers: any): void {
    if (response) {
      const img = Image.createWith(JSON.parse(response));
      this.certWizard.certWizardImages
        .push(new CertWizardImage(this.selectedLang, CertWizardImageType.RIGHT_MAIL_IMAGE, img));
      this.dataChanged = true;
    }
  }

  private onSuccessBottomMailUpload(item: any, response: any, status: any, headers: any): void {
    if (response) {
      const img = Image.createWith(JSON.parse(response));
      this.certWizard.certWizardImages
        .push(new CertWizardImage(this.selectedLang, CertWizardImageType.BOTTOM_MAIL_IMAGE, img));
      this.dataChanged = true;
    }
  }

  private onErrorImgUpload(item, response, status, headers): void {
    console.log(item, response, status, headers);
  }

  public removeMailImageByType(type: string) {
    const i = this.certWizard.certWizardImages.findIndex(cwi => cwi.type === type as CertWizardImageType);
    if (i <= -1) {
      return;
    }
    this.certWizard.certWizardImages.splice(i, 1);
  }

  public getMailImageByType(type: string): CertWizardImage {
    const i = this.certWizard.certWizardImages.findIndex(cwi => cwi.type === type as CertWizardImageType);
    if (i <= -1) {
      return;
    }
    return this.certWizard.certWizardImages[i];
  }

  public getLearnCertWizardModuleByLang(lang: SupportedLang): CertWizardModule[] {
    return this.certWizard.certWizardModules.filter(c => c.lang === lang && !c.exam);
  }

  public getExamCertWizardModuleByLang(lang: SupportedLang): CertWizardModule[] {
    return this.certWizard.certWizardModules.filter(c => c.lang === lang && c.exam);
  }

  public getCertificateByLang(lang: SupportedLang): CertWizardCertificate {
    const index: number = this.certWizard.certWizardCertificates
      .findIndex((c: CertWizardCertificate) => c.lang === lang)
    if (index !== -1) {
      return this.certWizard.certWizardCertificates[index];
    }
    return null;
  }

  public async uploadCertificate(event: any): Promise<void> {
    if (this.certLoading) {
      return;
    }
    // @TODO check file !== null and mime
    try {
      const file: File = event.target.files[0];
      this.certLoading = true;
      const cert: Certificate = await this.certWizardService.uploadCertificate(file, this.selectedLang);
      this.certWizard.certWizardCertificates.push(new CertWizardCertificate(cert, this.selectedLang));
      this.dataChanged = true;
      this.certLoading = false;
    } catch (e) {
      this.certLoading = false;
      this.tooltipService.show({translateKey: 'create-error'});
    }
  }

  public removeCertificateByLang() {
    const index: number = this.certWizard.certWizardCertificates
      .findIndex((c: CertWizardCertificate) => c.lang === this.selectedLang)
    if (index !== -1) {
      this.certWizard.certWizardCertificates.splice(index, 1);
    }
  }

  public getDisplayNameByLang(): CertWizardDisplayName {
    let index: number = this.certWizard.certWizardDisplayNames
      .findIndex((c: CertWizardDisplayName) => c.lang === this.selectedLang)
    if (index !== -1) {
      return this.certWizard.certWizardDisplayNames[index];
    } else {
      index = this.certWizard.certWizardDisplayNames.push(
        new CertWizardDisplayName(this.selectedLang, '')
      ) - 1;
      return this.certWizard.certWizardDisplayNames[index];
    }
  }

  public removeDisplayNameByLang() {
    const index: number = this.certWizard.certWizardDisplayNames
      .findIndex((c: CertWizardDisplayName) => c.lang === this.selectedLang)
    if (index !== -1) {
      this.certWizard.certWizardDisplayNames.splice(index, 1);
    }
  }

  public handleCertWizardColorsFallback(): void {
    // handle Fallbacks and defaults
    if (!this.certWizard.color) {
      this.certWizard.color = this.FALLBACK_COLOR;
    }
    if (!this.certWizard.secondaryColor) {
      this.certWizard.secondaryColor = this.FALLBACK_SECONDARY_COLOR;
    }
    if (!this.certWizard.tertiaryColor) {
      this.certWizard.tertiaryColor = this.FALLBACK_TERTIARY_COLOR;
    }
    this.certWizard.fontColor = this.getFontColor(this.certWizard.color);
    this.certWizard.secondaryFontColor = this.getFontColor(this.certWizard.secondaryColor);
    this.certWizard.tertiaryFontColor = this.getFontColor(this.certWizard.tertiaryFontColor);
  }

  public isSettingForLangComplete(lang: string): boolean {
    let displayName: boolean;
    // let cert: boolean;
    let learnModule: boolean;
    let examModule: boolean;

    displayName = !!this.certWizard.certWizardDisplayNames
      .find(cwdn => (cwdn.lang === lang as SupportedLang) && (cwdn.displayName));
    // cert = !!this.getCertificateByLang(lang as SupportedLang);
    learnModule = this.getLearnCertWizardModuleByLang(lang as SupportedLang).length > 0;
    if (this.certWizard.exam) {
      examModule = this.getExamCertWizardModuleByLang(lang as SupportedLang).length > 0;
      return displayName && examModule;
    } else {
      return displayName && learnModule;
    }
  }

  public editUserAsad() {
    let modalRef: NgbModalRef = this.modalService.open(AsadUserComponent);
    modalRef.componentInstance.asad = this.certWizard.asad;
    modalRef.componentInstance.certWizard = true;
  }

  public async save(): Promise<void> {
    this.errors.clear();

    delete this.certWizard.supportedLang;

    const langWithNames: SupportedLang[] =
      this.certWizard.certWizardDisplayNames
        .filter(cwdn => cwdn.displayName)
        .map(cwdn => cwdn.lang)
    const langWithModules: SupportedLang[] =
      this.certWizard.certWizardModules
        .filter(cwm => {
          if (this.certWizard.exam) {
            return cwm.exam;
          } else {
            return !cwm.exam;
          }
        })
        .map(cwm => cwm.lang)

    const namesWithoutModule: SupportedLang[] =
      langWithNames.filter(lang => !langWithModules.includes(lang));

    const moduleWithoutName: SupportedLang[] =
      langWithModules.filter(lang => !langWithNames.includes(lang));

    if (!this.certWizard.name || this.certWizard.name.trim() === '') {
      this.errors.set('name', 'fields-required');
      this.errors.set('error', 'fields-required');
    } else if (this.certWizard.certWizardCertificates.length <= 0) {
      this.errors.set('error', 'at-least-one-cert-required');
    } else if (namesWithoutModule.length > 0) {
      if (this.certWizard.exam) {
        this.errors.set('error', 'named-lang-without-exam-module');
      } else {
        this.errors.set('error', 'named-lang-without-module');
      }
    } else if (moduleWithoutName.length > 0) {
      this.errors.set('error', 'module-lang-without-name');
    } else if (langWithNames.length < 1 || langWithModules.length < 1) {
      this.errors.set('error', 'at-least-one-lang-required');
    } else {
      this.certWizard.name = this.certWizard.name.trim();
      if (this.certWizard.name.length < 2 || this.certWizard.name.length > 100) {
        this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('name', res);
          });
      }
    }

    // delete supportedLang, since attribute is unknown for post of CertWizard
    delete this.certWizard.supportedLang;
    // delete hash, since attribute is unknown for post of CertWizard
    delete this.certWizard.hash;

    // remove display names with null
    this.certWizard.certWizardDisplayNames =
      this.certWizard.certWizardDisplayNames.filter(c => c.displayName);

    if (this.errors.size === 0) {

      this.loadingService.open();

      if (this.isEditMode) {
        this.certWizard.asad.userAsads[0].scheduleDays = '';
        this.scheduleDays.forEach((check, idx) => {
          if (check) {
            this.certWizard.asad.userAsads[0].scheduleDays += idx;
          }
        });

        this.certWizard.asad.userAsads
          .map((ua: UserAsad) => {
            if (ua.user.id === this.auth.user.id) {
              ua.active = true;
            }
            ua.scheduleDays = this.certWizard.asad.userAsads[0].scheduleDays;
            ua.scheduleTime = this.certWizard.asad.userAsads[0].scheduleTime;
            ua.repetitionBreakInWeeks = this.certWizard.asad.userAsads[0].repetitionBreakInWeeks;
            ua.repetitions = this.certWizard.asad.userAsads[0].repetitions;
            ua.startDate = this.certWizard.asad.userAsads[0].startDate;
          });

        this.sHelper.sub = this.certWizardService.update(this.auth.user, this.certWizard).subscribe(
          updated => {
            if (updated) {
              this.tooltipService.show({translateKey: 'update-success'});
              this.navigateNext();
            }
            this.loadingService.dismiss();
          },
          e => {
            this.processServerErrors(e);
            this.loadingService.dismiss();
          });
      } else {
        this.sHelper.sub =
          this.certWizardService.persist(this.auth.user, this.certWizard).subscribe(
            cW => {
              if (cW) {
                this.tooltipService.show({translateKey: 'create-success'});
                this.navigateNext();
              }
              this.loadingService.dismiss();
            }, error => {
              this.processServerErrors(error);
              this.loadingService.dismiss();
            });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }

  public ngOnDestroy(): void {
    this.sHelper.unsubscribeAll();
  }
}

export enum LearnViewState {
  /**
   * one side card
   */
  ONE_SIDE_QUESTION = 'ONE_SIDE_QUESTION',

  /**
   * two side card show question without input
   */
  TWO_SIDE_QUESTION_NO_INPUT = 'TWO_SIDE_QUESTION_NO_INPUT',

  /**
   * two side card show question without input
   */
  TWO_SIDE_QUESTION_INPUT = 'TWO_SIDE_QUESTION_INPUT',

  /**
   * two side card show answer without input of user
   */
  TWO_SIDE_ANSWER_NO_INPUT = 'TWO_SIDE_ANSWER_NO_INPUT',

  /**
   * two side card show answer with input of user
   */
  TWO_SIDE_ANSWER_INPUT = 'TWO_SIDE_ANSWER_INPUT',

  /**
   * multiple choice card show question with user selection
   */
  MULTIPLE_CHOICE_QUESTION = 'MULTIPLE_CHOICE_QUESTION',

  /**
   * multiple choice card show answer with user selection
   */
  MULTIPLE_CHOICE_ANSWER = 'MULTIPLE_CHOICE_ANSWER',

  /**
   * learning done
   */
  FINISH = 'FINISH'
}

<div class="card" *ngIf="_userStat">
  <div class="card-header">
    {{'today-performance'|translate}}
  </div>
  <div class="card-body text-center"
       *ngIf="_userStat.learnedCardCount[0] > 0">
    <p [innerHTML]="'user-stat-learned-today'|translate:transParams "></p>

    <p [innerHTML]="'user-stat-learn-more1'|translate"
          *ngIf="_userStat.learnedCardCount[0] <= 2"></p>
    <p [innerHTML]="'user-stat-learn-more2'|translate"
          *ngIf="_userStat.learnedCardCount[0] == 3"></p>
    <p [innerHTML]="'user-stat-learn-more3'|translate"
          *ngIf="_userStat.learnedCardCount[0] >= 4 && _userStat.learnedCardCount[0] < 20"></p>
    <p [innerHTML]="'user-stat-learn-more4'|translate"
       *ngIf="_userStat.learnedCardCount[0] >= 20"></p>
  </div>
  <div class="card-body text-center"
       *ngIf="_userStat.learnedCardCount[0] == 0">
    <h1 class="display-4 text-primary-2" style="font-size: 2rem">{{'keep-trying'|translate}}</h1>
    <br>
    <p [innerHTML]="'user-stat-not-learned-today'|translate"></p>
  </div>
</div>

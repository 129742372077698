import {Component, OnInit, OnDestroy} from '@angular/core';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {Params, ActivatedRoute} from '@angular/router';
import {LoadingService} from '../../util/loading/loading.service';
import {RegistrationService} from '../registration.service';
import {Client} from '../../client/client.model';
import {User} from '../../user/user.model';
import {TranslateService} from '@ngx-translate/core';
import {Language} from '../../util/language.model';
import {FileUploader} from 'ng2-file-upload';
import {environment} from '../../../environments/environment';
import {UserDetailComponent} from '../../user/user-detail/user-detail.component';
import {TitleService} from '../../util/title/title.service';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.css']
})
export class SignupFormComponent implements OnInit, OnDestroy {

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;
  public client: Client;
  private user: User = new User();
  public errors: Map<string, string> = new Map();
  private languages: Language[];
  public registrationStep = 1;

  constructor(public loadingService: LoadingService,
              private translate: TranslateService,
              private regService: RegistrationService,
              private route: ActivatedRoute,
              private titleService: TitleService) {
    this._initLanguages();
    this.user.lang = translate.currentLang;
  }

  ngOnInit() {
    this.loadingService.open();
    this._sHelper.sub = this.route.params.subscribe((params: Params) => {
        if (params['code']) {
          this._sHelper.sub = this.regService.findByCode(params['code']).subscribe(
            client => {
              this.client = client;
              this.titleService.title = 'signup';
              this.loadingService.dismiss();
            },
            e => {
              this.loadingService.dismiss();
            }
          );

        }

      },
      (error) => {
        this.loadingService.dismiss();
      });
  }

  submit(event) {
    this.loadingService.open();
    this.errors.clear();

    if (!this.user.email || this.user.email.trim() === '') {
      this.errors.set('email', '');
    } else {
      this.user.email = this.user.email.trim();
      if (!this._validateEmail()) {
        this.errors.set('email', 'email-error');
      }
    }

    if (this.client.hasPassword) {
      if (!this.client.registrationPassword || this.client.registrationPassword.trim() === '') {
        this.errors.set('registrationPassword', '');
      } else {
        this.client.registrationPassword = this.client.registrationPassword.trim();
        if (this.client.registrationPassword.length < 6 || this.client.registrationPassword.length > 20) {
          this._sHelper.sub = this.translate.get('error-length', {min: '6', max: '20'}).subscribe(
            res => {
              this.errors.set('registrationPassword', res);
            });
        }
      }
    }

    if (this.errors.size == 0) {
      this._sHelper.sub = this.regService.testCredentials(
        this.client.code,
        this.user.email,
        this.client.registrationPassword).subscribe(
        r => {
          this.registrationStep = 2;
          this.loadingService.dismiss();
        }, e => {
          console.log(e);
          this._processServerErrors(e);
          this.loadingService.dismiss();
        });
    } else {
      this.loadingService.dismiss();
    }

  }

  submitRegistration(event) {
    this.loadingService.open();
    this.errors.clear();

    if (!this.user.login || this.user.login.trim() === '') {
      this.errors.set('login', '');
    } else {
      this.user.login = this.user.login.trim();
    }

    if (!this.user.name || this.user.name.trim() === '') {
      this.errors.set('name', '');
    } else {
      this.user.name = this.user.name.trim();
      if (this.user.name.length < 2 && this.user.name.length > 100) {
        this._sHelper.sub = this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('name', res);
          });
      }
    }

    if (!this.user.forename || this.user.forename.trim() === '') {
      this.errors.set('forename', '');
    } else {
      this.user.forename = this.user.forename.trim();
      if (this.user.forename.length < 2 || this.user.forename.length > 100) {
        this._sHelper.sub = this.translate.get('error-length', {min: '2', max: '100'}).subscribe(
          res => {
            this.errors.set('forename', res);
          });
      }
    }

    if (!this.user.password || this.user.password.trim() === '') {
      this.errors.set('password', '');
    } else {
      this.user.password = this.user.password.trim();
      if (this.user.password.length < 6 || this.user.password.length > 20) {
        this._sHelper.sub = this.translate.get('error-length', {min: '6', max: '20'}).subscribe(
          res => {
            this.errors.set('password', res);
          });
      }
    }

    if (this.errors.size == 0) {
      this._sHelper.sub = this.regService.registerUser(
        this.user,
        this.client.code,
        this.user.email,
        this.client.registrationPassword).subscribe(
        r => {
          this.registrationStep = 3;
          this.loadingService.dismiss();
        }, e => {
          console.log(e);
          this._processServerErrors(e);
          this.loadingService.dismiss();
        });
    } else {
      this.loadingService.dismiss();
    }
  }

  private _processServerErrors(error) {
    let errorJSON = null;
    try {
      errorJSON = error.json();
    } catch (e) {
      this.errors.set('error', error.statusText);
      console.error(e);
    }
    if (errorJSON) {
      Object.keys(errorJSON).forEach(
        k => {
          this.errors.set(k, errorJSON[k]);
        });
    }
  }

  private _initLanguages() {
    this.languages = [];
    this.translate.get(UserDetailComponent.supportedLanguages).subscribe(
      t => {
        Object.keys(t).forEach(
          k => {
            this.languages.push(new Language(k, t[k]));
          }
        );
        this.languages.sort(Language.sortByName);
      }
    );
  }

  private _validateEmail(): boolean {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.user.email);
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

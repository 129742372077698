<div class="d-flex flex-column aeonik-regular p-2">

  <div class="mt-auto mb-auto">
    <div class="d-flex justify-content-end align-items-end align-content-end">
      <i (click)="close()" class="fa fa-times fa-3x pointy-cursor"></i>
    </div>
  </div>

  <div *ngIf="examNecessary" class="p-2">
    <h5 class="m-0">
      {{'exam-required'|translate}}
    </h5>
  </div>

  <div *ngIf="examNecessary" class="pt-1 pb-1">
    <button (click)="startExam()"
            type="button" class="btn btn-block aeonik-regular overflow-break"
            [style.background-color] = "certWizard.secondaryColor"
            [style.border-color] = "certWizard.secondaryColor"
            [style.color]="certWizard.secondaryFontColor">
      <h3 class="m-0">
        {{'to-exam'|translate}}
      </h3>
    </button>
  </div>


  <form *ngIf="showForm" class="p-2">
    <div class="form-group">

      <label *ngIf="showLangSelect">
        {{'language'|translate}}
      </label>
      <div *ngIf="showLangSelect" class="w-100 pb-2">
        <button class="btn dropdown dropdown-toggle aeonik-regular overflow-break"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                [style.background-color] = "certWizard.secondaryColor"
                [style.border-color] = "certWizard.secondaryColor"
                [style.color]="certWizard.secondaryFontColor">
                  <span
                    [style.color] = "certWizard.secondaryFontColor">
                    {{user.lang|translate}}
                  </span>
        </button>
        <div class="dropdown-menu">
          <div *ngFor="let lang of availableLang" class="dropdown-item" (click)="user.lang = lang">
            {{lang|translate}}
          </div>
        </div>
      </div>

        <label for="forename">
          {{'forename'|translate}}
        </label>
        <input [(ngModel)]="user.forename" name="forename" type="text" class="form-control mb-1"
               id="forename" placeholder="..." [disabled]="timerActive || userHasName"
               [style.background-color]="'#d0d0d0'"
               [style.color]="'black'">
        <p class="text-danger" *ngIf="errors.get('forename')">
          {{errors.get('forename')|translate}}
        </p>

      <label for="name">
        {{'name'|translate}}
      </label>
      <input [(ngModel)]="user.name" name="name" type="text" class="form-control mb-1"
             id="name" placeholder="..." [disabled]="timerActive || userHasName"
             [style.background-color]="'#d0d0d0'"
             [style.color]="'black'">
      <p class="text-danger" *ngIf="errors.get('name')">
        {{errors.get('name')|translate}}
      </p>
    </div>
  </form>

  <div *ngIf="showForm && timerActive" class="text-center">
    <countdown [config]="{leftTime: userStat.certPenaltySecLeft}"
               (event)="handleCountdownEvent($event)"
               class="aeonik-black">
    </countdown>
    <br>
    {{'proof-create-penalty'| translate}}
  </div>

  <div *ngIf="showForm" class="pt-1 pb-1">
    <button (click)="generateCertificate()"
            type="button" class="btn btn-block aeonik-regular overflow-break"
            [style.background-color] = "certWizard.secondaryColor"
            [style.border-color] = "certWizard.secondaryColor"
            [style.color]="certWizard.secondaryFontColor"
            [disabled]="timerActive">
      <h3 class="m-0">
        {{'create-proof'|translate}}
      </h3>
    </button>
  </div>


  <div *ngIf="repeatExam" class="pt-1 pb-1">
    <button (click)="startExam()"
            type="button" class="btn btn-block aeonik-regular overflow-break"
            [style.background-color] = "certWizard.color"
            [style.border-color] = "certWizard.color"
            [style.color] = "certWizard.fontColor">
        {{'repeat-exam'|translate}}
    </button>
  </div>
</div>

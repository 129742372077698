import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment'
import {SystemLog} from './system-log.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class SystemLogService {

  resource = 'system/log';

  constructor(private http: HttpClient) {
  }

  findAll(code: string, size: number): Observable<SystemLog[]> {
    let params: any = {};
    if (code) {
      params.code = code;
    }
    if (size) {
      params.size = size;
    }

    return this.http.get<SystemLog[]>(environment.backendApi + '/'
      + this.resource, {params: params})
      .map(data => {
        return data;
      });
  }

}

import {Component, OnDestroy} from '@angular/core';
import {Module} from '../module.model';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {ModuleService} from '../module.service';
import {LoadingService} from '../../util/loading/loading.service';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {ConfirmComponent} from '../../util/confirm-modal/confirm.component';
import {UserModule} from '../user-module.model';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {TitleService} from '../../util/title/title.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {StatsService} from '../../stats/stats.service';
import {ModuleStat} from '../../stats/module-stat.model';

@Component({
  selector: 'app-module-public',
  templateUrl: './module-public.component.html',
  styleUrls: ['./module-public.component.css']
})
export class ModulePublicComponent implements OnDestroy {

  modules: Module[] = [];
  filteredModules: Module[] = [];
  stats: Map<string, ModuleStat> = new Map();
  auth: any;
  searchQuery = '';

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private moduleService: ModuleService,
              private statsService: StatsService,
              private modalService: ModalWrapperService,
              public loadingService: LoadingService,
              private tooltipService: TooltipService,
              public authService: AuthService,
              private router: Router,
              private titleService: TitleService) {
    titleService.title = 'public-modules';
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  _init() {
    this.loadingService.open();
    this._sHelper.sub = this.moduleService.findAllPublic().subscribe(
      modules => {
        this.modules = modules.filter(module => !this.hasPermission(module));
        this.modules = this.modules.sort(Module.sortByName);
        this.doSearch();
        this.loadingService.dismiss();

        // get module stats
        this.statsService.findModuleStatsByUserAndModuleIds(this.auth.user, modules).subscribe(
          stats => stats.forEach(stat => this.stats.set(stat.moduleId, stat)));
      },
      e => {
        this.loadingService.dismiss();
      }
    );
  }

  doSearch() {
    this.filteredModules = this.modules.filter(
      (item: Module) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.description && !match) {
            match = item.description.toLowerCase().indexOf(query) != -1;
          }

        }

        return match;
      });
  }

  getModulePermission(module: Module) {
    switch (module.visibility) {
      case 'PUBLIC_READ':
        return 'read';
      case 'PUBLIC_WRITE':
        return 'write';
    }
    return 'unknown';
  }

  hasPermission(module: Module): boolean {
    let hasPermission = false;
    module.userModules.forEach(um => {
      if (um.user.id == this.auth.user.id) {
        hasPermission = true;
      }
    });
    return hasPermission;
  }

  getModuleOwner(module: Module) {
    if (module.user.id == this.auth.user.id) {
      return 'me';
    } else {
      return module.user.forename + ' ' + module.user.name
    }
  }

  connectUser(module, event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(ConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'connect-to-module';
    modalRef.componentInstance.translateParams = {name: module.name};
    modalRef.result.then(
      (connectUser) => {
        if (connectUser) {
          let um: UserModule = new UserModule();
          um.user = this.auth.user;
          um.permission = this.getModulePermission(module);
          this._sHelper.sub = this.moduleService.updatePublicUserModule(module, um)
            .subscribe(ok => {
              if (ok) {
                // delete item in local array
                let delFn = (item, i, arr) => item.id === module.id ? arr.splice(i, 1) : false;
                this.modules.some(delFn);
                this.doSearch();
                this.tooltipService.show({translateKey: 'action-success'});
              } else {
                this.tooltipService.show({
                  translateKey: 'action-error',
                  type: 'danger',
                  stay: true
                });

              }
            });
        }
      })
  }

  edit(module) {
    this.router.navigate(['/public/modules', module.id]);
  }


  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

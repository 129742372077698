<div class="card">
  <div class="card-header">
    {{'due-ktracker'|translate}}
  </div>

  <table class="table table-sm table-hover m-0" *ngIf="userStat.dueKTrackers.length > 0">
    <tbody>
    <tr *ngFor="let kTrackerStat of userStat.dueKTrackers; let idx = index" (click)="startKTracker(kTrackerStat)">
      <td class="text-muted text-left p-3" [class.border-0]="idx == 0">
        {{kTrackerStat.ktrackerName}}
      </td>
      <td class="text-primary-2 text-right p-3" [class.border-0]="idx == 0">
        {{kTrackerStat.dueCardIds.length}}
      </td>
    </tr>
    </tbody>
  </table>

  <div class="card-body text-center"
       *ngIf="userStat.dueKTrackers.length == 0">
    Keine fälligen KTracker!
  </div>

</div>

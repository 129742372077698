import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {TitleService} from '../../util/title/title.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit, OnDestroy {


  private readonly body: HTMLBodyElement;
  private readonly wrapperRef: HTMLElement;

  constructor(private titleService: TitleService,
              private readonly route: ActivatedRoute,
              private readonly renderer: Renderer2) {
    const noNav = this.route.snapshot.url.some(segment => segment.path === 'no-nav')
    if (noNav) {
      this.body = document.getElementsByTagName('body')[0];
      this.wrapperRef = document.getElementById('content-wrapper');
      this.renderer.setStyle(this.wrapperRef, 'padding-top', '0px');
      this.renderer.setStyle(this.body, 'padding-bottom', '0px');
    }
    titleService.title = 'privacy';
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '52px');
    this.renderer.setStyle(this.body, 'padding-bottom', '6rem');
  }

}

import {Component, OnDestroy} from '@angular/core';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {LoadingService} from '../../util/loading/loading.service';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {AuthService} from '../../auth/auth.service';
import {Router, ActivatedRoute} from '@angular/router';
import {DeleteConfirmComponent} from '../../util/confirm-modal/delete-confirm.component';
import {StatsService} from '../../stats/stats.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {KTracker} from '../ktracker.model';
import {KTrackerStat} from '../../stats/ktracker-stat.model';
import {KTrackerService} from '../ktracker.service';
import {TitleService} from '../../util/title/title.service';
import {ConfirmComponent} from '../../util/confirm-modal/confirm.component';

@Component({
  selector: 'app-ktracker-list',
  templateUrl: './ktracker-list.component.html',
  styleUrls: ['./ktracker-list.component.css']
})
export class KTrackerListComponent implements OnDestroy {

  kTrackers: KTracker[] = [];
  filteredKTrackers: KTracker[] = [];
  stats: Map<string, KTrackerStat> = new Map();
  auth: any;
  searchQuery = '';
  Math: any = Math;

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private kTrackerService: KTrackerService,
              private statsService: StatsService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private tooltipService: TooltipService,
              public authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private titleService: TitleService) {
    titleService.title = 'Knowledge Tracker';
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  _init() {
    this.loadingService.open();
    this._sHelper.sub = this.kTrackerService.findAllByUser(this.auth.user).subscribe(
      kTrackers => {
        this.kTrackers = kTrackers.sort(KTracker.sortByName);
        this.doSearch();
        this.loadingService.dismiss();

        // get stats
        this.statsService.findKTrackerStatsByUserAndKTrackerIds(this.auth.user, kTrackers).subscribe(
          stats => stats.forEach(stat => this.stats.set(stat.ktrackerId, stat)));

      },
      e => {
        this.loadingService.dismiss();
      }
    );
  }

  doSearch() {
    this.filteredKTrackers = this.kTrackers.filter(
      (item: KTracker) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.description && !match) {
            match = item.description.toLowerCase().indexOf(query) != -1;
          }

        }

        return match;
      });
  }

  showStats(kTracker) {
    this.router.navigate(['/manager/ktrackers/stats', kTracker.id]);
  }

  edit(kTracker, event) {
    if (event) {
      event.stopPropagation();
    }
    this.router.navigate(['/manager/ktrackers', kTracker.id]);
  }

  test(kTracker: KTracker, event) {
    if (event) {
      event.stopPropagation();
    }
    const modalRef = this.modalService.open(ConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'sent-test-ktracker';
    modalRef.result.then(
      yes => {
        if (yes) {
          this.loadingService.open();
          this._sHelper.sub = this.kTrackerService.testKTracker(this.auth.user, kTracker.id).subscribe(
            kTracker => {
              console.log('KTracker test sent! id: ' + kTracker.id);
            },
            e => console.error(e),
            () => this.loadingService.dismiss()
          );
        }
      })


  }

  delete(kTracker, event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(DeleteConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'delete-text';
    modalRef.componentInstance.params = {name: kTracker.name};
    modalRef.result.then(
      (deleteItem) => {
        if (deleteItem) {
          this._sHelper.sub = this.kTrackerService.delete(this.auth.user, kTracker.id).subscribe(
            deleted => {
              if (deleted) {
                // delete item in local array
                let delFn = (item, i, arr) => item.id === kTracker.id ? arr.splice(i, 1) : false;
                this.kTrackers.some(delFn);
                this.doSearch();
                this.tooltipService.show({translateKey: 'delete-success'});
              } else {
                this.tooltipService.show({
                  translateKey: 'delete-error',
                  type: 'danger',
                  stay: true
                });
              }
            });
        }
      });
  }

  calcKTrackerFinish(kTracker) {
    if (!this.stats.get(kTracker.id) || this.stats.get(kTracker.id).cardCount == 0) {
      return 0;
    } else {
      return Math.round(this.stats.get(kTracker.id).sentCardCount / this.stats.get(kTracker.id).cardCount * 100);
    }
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

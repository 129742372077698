import {Component, OnInit} from '@angular/core';
import {TitleService} from '../../util/title/title.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {

  constructor(private titleService: TitleService) {
    titleService.title = 'imprint';
  }

  ngOnInit() {
  }

}

<div class="card">
  <div class="card-header">
    {{'last-used-modules'|translate}}
  </div>
  <div class="list-group list-group-flush">
    <div *ngFor="let module of filteredModules">
      <div class="list-section" *ngIf="module.listSection == 0">
        {{'today'|translate}}
        <small class="text-muted">- {{today}}</small>
      </div>
      <div class="list-section" *ngIf="module.listSection == 1">{{'yesterday'|translate}}</div>
      <div class="list-section" *ngIf="module.listSection == 2">{{'older-than'|translate:{days:2} }}</div>
      <div class="list-section" *ngIf="module.listSection == 3">{{'older-than'|translate:{days:10} }}</div>
      <div class="list-section" *ngIf="module.listSection == 4">{{'older-than'|translate:{days:30} }}</div>
      <div class="list-group-item list-group-item-action d-flex"
           (click)="learn(module)">
        <div class="mr-auto">
          <img [src]="'assets/img/'+module.type+'.png'" style="height: 32px"/>
          {{module.name}}
        </div>
        <div *ngIf="stats.get(module.id)">
          <small class="d-flex"  *ngIf="stats.get(module.id).dueCardCount > 0">
            {{'due-cards'|translate}}
            <span class="badge badge-success badge-pill ml-2" style="line-height: inherit;">
          {{stats.get(module.id).dueCardCount}}
            </span>
          </small>
          <small class="text-danger"
                 *ngIf="module.nextTrainingDayCount > 1">
            {{ 'in-days' | translate:{days:module.nextTrainingDayCount} }}
          </small>
          <small class="text-danger" *ngIf="module.nextTrainingDayCount == 1">
            {{ 'tomorrow' | translate}}
          </small>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf="!filteredModules.length">
    <h5 class="card-title text-muted text-center" style="margin-top: 12px">
      {{'empty-search-module'|translate}}
    </h5>
  </div>
</div>

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">System Logs</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">

    <div class="col d-flex" style="margin-bottom: 15px">
      <select (change)="doSearch()"
              [(ngModel)]="selectedCode"
              class="custom-select mr-auto"
              style="width: initial">
        <option [ngValue]="null">-- alle --</option>
        <option *ngFor="let c of codes" [ngValue]="c">{{c}}</option>
      </select>

      <searchbar style="margin-bottom: 15px" [(query)]="searchQuery"
                 (input)="doSearch()"
                 placeholder="{{'search'|translate}}">

      </searchbar>
    </div>
  </div>
  <div class="row">
    <div class="col">

      <div class="card">
        <div class="table-responsive" *ngIf="filteredSystemLogs.length">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th class="border-top-0">code</th>
              <th class="border-top-0">message</th>
              <th class="border-top-0">time</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let systemLog of filteredSystemLogs">
              <td>{{systemLog.code}}</td>
              <td>{{systemLog.message}}</td>
              <td>{{formatDate(systemLog.created)}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="!filteredSystemLogs.length">
          <h5 class="card-title text-muted text-xs-center" style="margin-top: 12px">
            {{'empty-search-user'|translate}}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {CertWizardService} from '../cert-wizard.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {StatsService} from '../../stats/stats.service';
import {TranslateService} from '@ngx-translate/core';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT, Location} from '@angular/common';
import {AuthService} from '../../auth/auth.service';
import {LoadingService} from '../../util/loading/loading.service';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {CertWizard} from '../model/cert-wizard';
import {NgbCarousel, NgbModalRef, NgbSlide} from '@ng-bootstrap/ng-bootstrap';
import {CertWizardModule} from '../model/cert-wizard-module';
import {Image} from '../../module/image.model';
import {environment} from '../../../environments/environment';
import {PageScrollService} from 'ngx-page-scroll-core';
import {CertWizardImage} from '../model/cert-wizard-image';
import {stringToLang, SupportedLang} from '../model/supported-lang';
import {CertWizardImageType} from '../model/CertWizardImageType';
import {CertWizardLoginComponent} from '../cert-wizard-login/cert-wizard-login.component';
import * as SVGInject from '@iconfu/svg-inject'
import * as tinyColor from 'tinycolor2';
import {CertWizardPrivacyPolicyModalComponent} from '../cert-wizard-privacy-policy-modal/cert-wizard-privacy-policy-modal.component';
import {CertWizardDisplayName} from '../model/cert-wizard-display-name';
import {Auth} from '../../auth/auth';
import {TitleService} from '../../util/title/title.service';

@Component({
  selector: 'app-cert-wizard-public',
  templateUrl: './cert-wizard-public.component.html',
  styleUrls: ['./cert-wizard-public.component.css'],
})
export class CertWizardPublicComponent implements AfterViewInit, OnDestroy {

  public auth: Auth;
  public certWizard: CertWizard = new CertWizard();
  public learnModules: CertWizardModule[] = [];
  public examModules: CertWizardModule[] = [];

  public userLang = navigator.language;

  public slideTo = 0;

  public selectedLang = 'de';

  public wrapperMaxHeight = 650;
  public wrapperHeight = 650;
  public wrapperMaxWidth = 414;
  public wrapperWidth = 414;
  public slideMinHeight = 0;
  public windowHeight = 0

  public FALLBACK_LEFT_LOGO = '';
  public FALLBACK_BACKGROUND_IMAGE = '';

  private slideIdMap = {
    0: 'slide-ngb-slide-0',
    1: 'slide-ngb-slide-1',
    2: 'slide-ngb-slide-2',
    3: 'slide-ngb-slide-3',
  }
  private sHelper: SubscriptionHelper = new SubscriptionHelper;

  // slides
  @ViewChild('carousel') carousel: NgbCarousel;
  @ViewChildren('slide') slides: QueryList<any>;

  // company styles
  @ViewChild('navbar') navbar: ElementRef;
  @ViewChild('navRef') navRef: ElementRef;
  @ViewChild('navImageLeft') navImageLeft: ElementRef;
  @ViewChild('navImageRight') navImageRight: ElementRef;

  @ViewChild('learnCardSVG') learnCardSVG: ElementRef;
  @ViewChild('progressSVG') progressSVG: ElementRef;
  @ViewChild('mailSVG') mailSVG: ElementRef;
  @ViewChild('asadSVG') asadSVG: ElementRef;

  private body: HTMLBodyElement;
  private wrapperRef: HTMLElement;
  private privacyModalRef: NgbModalRef;


  @HostListener('window:resize', ['$event'])
  handleScreenSize() {
    this.resize();
  }

  public constructor(private readonly certWizardService: CertWizardService,
                     private readonly modalService: ModalWrapperService,
                     private readonly statsService: StatsService,
                     private readonly translate: TranslateService,
                     private readonly tooltipService: TooltipService,
                     private readonly route: ActivatedRoute,
                     private readonly router: Router,
                     private readonly renderer: Renderer2,
                     private readonly location: Location,
                     private readonly pageScrollService: PageScrollService,
                     public authService: AuthService,
                     public loadingService: LoadingService,
                     private readonly titleService: TitleService,
                     @Inject(DOCUMENT) private document: any) {

  }

  private async init(): Promise<void> {
    this.authService.logout();
    this.FALLBACK_LEFT_LOGO = `${location.origin}/assets/img/el-logo.svg`;
    this.FALLBACK_BACKGROUND_IMAGE = `${location.origin}/assets/img/mail-footer.svg`;

    this.sHelper.sub = this.authService.auth.subscribe(auth => {
      if (auth === null || auth === undefined) {
        this.showPrivacyPolicy();
      }
    });
    this.loadingService.open();
    this.selectedLang = stringToLang(this.userLang);
    this.translate.use(this.selectedLang);
    const certWizardId = this.route.snapshot.params['id'];
    this.slideTo = this.route.snapshot.params['slide'];
    await this.certWizardService.findByIdPublic(certWizardId).toPromise()
      .then(certWizard => {
        this.certWizard = certWizard;
      }).catch(async () => {
        await this.certWizardService.findByHashPublic(certWizardId).toPromise()
          .then(certWizard => {
            this.certWizard = certWizard;
          }).catch(async () => {
            this.loadingService.dismiss();
            void await this.router.navigate(['message/not-found']);
          })
      });
    if (!this.certWizard.active) {
      this.loadingService.dismiss();
      await this.router.navigate(['message/inactive']);
      return;
    }
    this.updateModuleKindLists();
    this.setCompanyStyles();
    this.preselectLang();
    this.loadingService.dismiss()
  }

  private preselectLang(): void {
    const index = this.certWizard.supportedLang.findIndex(lang => lang === this.translate.currentLang);
    if (index === -1 && this.certWizard.supportedLang.length > 0) {
      this.selectLang(this.certWizard.supportedLang[0]);
    } else {
      this.selectLang(this.certWizard.supportedLang[index]);
    }
  }

  private resize(): void {
    if (!this.certWizard.id) { return; }
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '0px');
    this.renderer.setStyle(this.body, 'padding-bottom', '0px');

    if (window.innerWidth <= 414) {
      this.wrapperMaxHeight = 915;
      this.wrapperMaxWidth = 414;
    } else {
      this.wrapperMaxHeight = 850;
      this.wrapperMaxWidth = 450;
    }

    this.windowHeight = window.innerHeight;
    this.wrapperWidth = window.innerWidth;
    this.slideMinHeight = window.innerHeight
      - this.navbar.nativeElement.offsetHeight;
    if (this.slideMinHeight > this.wrapperMaxHeight) {
      this.slideMinHeight = this.wrapperMaxHeight - this.navbar.nativeElement.offsetHeight;
    }
  }

  private updateModuleKindLists(): void {
    this.learnModules = this.certWizard.certWizardModules.filter(m => !m.exam);
    this.examModules = this.certWizard.certWizardModules.filter(m => m.exam);
  }


  public navigateLearnView(): void {
    const hasModuleInLang = this.certWizard.certWizardModules.filter(cwm => cwm.lang === this.selectedLang).length > 0;
    if (this.certWizard.learnMode && hasModuleInLang) {
      void this.router.navigate([`/learn/cert-wizards/${this.certWizard.hash}`]);
    } else {
      void this.router.navigate([`/cert-wizards/${this.certWizard.hash}/cert-wizard-dashboard`]);
    }
  }

  public setCompanyStyles(): void {
    if (!this.certWizard.id) { return; }
    this.renderer.setStyle(this.navbar.nativeElement, 'background-color', this.certWizard.color);
    if (this.getLogoLeftByLang()) {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.getImageURL(this.getLogoLeftByLang().image));
    } else {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.FALLBACK_LEFT_LOGO);
    }

    if (this.getLogoRightByLang()) {
      this.renderer.setAttribute(
        this.navImageRight.nativeElement,
        'src',
        this.getImageURL(this.getLogoRightByLang().image));
    }

    this.setBgImage();
  }

  public setBgImage(): void {

    if (this.getBackgroundImage()) {
      try {
        const bgImage = `url(${this.getImageURL(this.getBackgroundImage().image)})`;
        this.carousel.slides.forEach((item: NgbSlide, index: number) => {
          switch (index) {
            case 0:
              const sl0: HTMLElement = document.getElementById('slide-first');
              this.renderer.setStyle(
                sl0,
                'background-image',
                bgImage,
                1
              )
              this.setBgStyles(sl0);
              break;
          }
        });
      } catch (e) {
        setTimeout(() => this.setBgImage(), 20);
      }
    } else {
      try {
        const bgImage = `url(${this.FALLBACK_BACKGROUND_IMAGE})`;
        this.carousel.slides.forEach((item: NgbSlide, index: number) => {
          switch (index) {
            case 0:
              const sl0: HTMLElement = document.getElementById('slide-first');
              this.renderer.setStyle(
                sl0,
                'background-image',
                bgImage,
                1
              )
              this.setBgStyles(sl0);
              break;
          }
        });
      } catch (e) {
        setTimeout(() => this.setBgImage(), 20);
      }
    }
  }

  public getImageURL(image: Image): string {
    return environment.backendApi + '/images/' + image.code + '/' + image.name.split(' ')[0];
  }

  public selectLang(lang: string) {
    this.selectedLang = lang;
    this.translate.use(this.selectedLang.toLowerCase());
    this.setCompanyStyles();
    this.titleService.title =
      this.certWizard.certWizardDisplayNames
        .find(cwdn => cwdn.lang === lang)?.displayName;
  }

  public moveForward(): void {
    if (this.getLogoLeftSecondByLang()) {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.getImageURL(this.getLogoLeftSecondByLang().image));
    }
    this.scrollToTarget();
    this.carousel.next();
  }

  public moveBack(): void {
    if (this.getLogoLeftByLang()) {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.getImageURL(this.getLogoLeftByLang().image));
    }
    this.scrollToTarget();
    this.carousel.prev();
  }

  private scrollToTarget(): void {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '.scroll-target',
      duration: 200,
      scrollOffset: 300,
    })
  }

  public getLogoLeftByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      // @TODO
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getLogoRightByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      // @TODO
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_RIGHT)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getLogoLeftSecondByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      // @TODO
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT_SECOND)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getBackgroundImage(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.type === CertWizardImageType.BACKGROUND_IMAGE)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getDisplayNameByLang(): string {
    const certWizardDisplayName: CertWizardDisplayName | undefined =
      this.certWizard.certWizardDisplayNames.find(cwdn => cwdn.lang === this.selectedLang);
    if (certWizardDisplayName) {
      return certWizardDisplayName.displayName;
    } else {
      return '';
    }
  }

  public showPrivacyPolicy(): void {
    this.privacyModalRef = this.modalService.open(CertWizardPrivacyPolicyModalComponent,
      {centered: true, size: 'sm'});
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.init();
    if (!this.certWizard.id) { return; }
    this.body = document.getElementsByTagName('body')[0];
    this.wrapperRef = document.getElementById('content-wrapper');
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '0px');
    this.renderer.setStyle(this.body, 'padding-bottom', '0px');
    await this.handleSvgColor();
    this.resize();
    this.carousel.select(this.slideIdMap[this.slideTo])
  }

  private async handleSvgColor(): Promise<void> {
    await SVGInject(this.learnCardSVG.nativeElement);
    await SVGInject(this.progressSVG.nativeElement);
    await SVGInject(this.mailSVG.nativeElement);
    await SVGInject(this.asadSVG.nativeElement);

    // @ts-ignore
    const svgList: HTMLCollectionOf<SVGElementTagNameMap[string]> = document.getElementsByTagName('svg');

    for (let i = 0; i < svgList.length; i++) {
      svgList[i].getElementsByTagName('style')[0].innerHTML =
        svgList[i].getElementsByTagName('style')[0].innerHTML.replace('#ffed00', this.certWizard.color)

      svgList[i].getElementsByTagName('style')[0].innerHTML =
        svgList[i].getElementsByTagName('style')[0].innerHTML.replace('#FFED00', this.certWizard.color)

    }
  }

  private setBgStyles(sl: HTMLElement): void {
    this.renderer.setStyle(
      sl,
      'background-position',
      'center',
      1
    )
    this.renderer.setStyle(
      sl,
      'background-repeat',
      'no-repeat',
      1
    )
    this.renderer.setStyle(
      sl,
      'background-size',
      this.getBackgroundImage() ? 'cover' : '100% auto',
      1
    )
  }

  public isMainColorDark(): boolean {
    return tinyColor(this.certWizard.color).isDark();
  }



  public ngOnDestroy(): void {
    this.privacyModalRef.dismiss();
    if (this.certWizard.id && this.certWizard.active) {
      this.renderer.setStyle(this.wrapperRef, 'padding-top', '52px');
      this.renderer.setStyle(this.body, 'padding-bottom', '6rem');
    }
    this.sHelper.unsubscribeAll();
  }
}

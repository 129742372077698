<tooltip class="aeonik-black"></tooltip>
<span id="top"></span>
<div [class.blur-2]="modalOpen">
  <side-navbar (navbarActiveState)="navbarActive = $event"
               *ngIf="auth && !learnMode && !certWizardMode"></side-navbar>
  <nav class="navbar navbar-expand fixed-top flex-row navbar-light bg-primary-1" *ngIf="!learnMode && !certWizardMode">

    <i class="fa fa-bars fa-2x side-navbar-toggle-btn" data-toggle="collapse" data-target="#menu-content"
       *ngIf="auth"></i>

    <a class="navbar-brand mr-auto" style="padding-bottom: 0;padding-top: 2px" routerLink="/">
      <img src="assets/img/el-logo.svg" height="35" alt="">
    </a>

    <ul class="navbar-nav align-items-center">

      <li class="nav-item dropdown pt-2" *ngIf="auth">
        <a class="nav-link dropdown-toggle text-white p-0" id="navbarDropdownMenuLink"
           data-toggle="dropdown" aria-haspopup="true" href="#"
           aria-expanded="false">
          <div class="rounded-corners-container-nav" *ngIf="auth.user.img"
               style="vertical-align: middle">
            <img class="rounded-corners" [src]="auth.user.img" (error)="auth.user.img = null"/>
          </div>
          <i class="fa fa-user-circle-o fa-2x" aria-hidden="true" *ngIf="!auth.user.img"
             style="vertical-align: middle"></i>
          <span style="vertical-align: middle">{{auth.user.login}}</span>
        </a>
        <div class="dropdown-menu dropdown-menu-right"
             aria-labelledby="navbarDropdownMenuLink"
             style="padding: 10px;">
          <table class="table profile">
            <tr>
              <td class="d-none d-sm-block">
                <div class="rounded-corners-container-profile" *ngIf="auth.user.img">
                  <img class="rounded-corners" [src]="auth.user.img" (error)="auth.user.img = null"/>
                </div>
                <i class="fa fa-user-circle-o fa-4x text-muted" aria-hidden="true" *ngIf="!auth.user.img"></i>
              </td>
              <td>
                <h4 style="white-space:nowrap;">{{auth.user.forename}} {{auth.user.name}}</h4>
                <p style="white-space:nowrap;">
                  Login: {{auth.user.login}}<br>

                  Rolle: {{auth.user.role}}<br>
                  <a routerLink="/profile">{{'to-profile'|translate}}</a>
                </p>
              </td>
            </tr>
          </table>
          <button class="btn btn-primary btn-sm btn-block" (click)="logout()">{{'logout'|translate}}</button>
        </div>
      </li>

    </ul>

  </nav>

  <div id="content-wrapper"
       class=""
       [class.nav-side-menu-open]="navbarActive">

    <div *ngIf="!certWizardMode">
      <div id="privacy-hint"
           *ngIf="showPrivacyHint"
           class="d-block px-3 py-2 text-center text-bold text-white old-bv">
        <button (click)="closePrivacyHint()"
                type="button" class="close" aria-label="Close" style="color: white;text-shadow: unset">
          <span aria-hidden="true">&times;</span>
        </button>
        {{'privacy-hint'|translate}}
      </div>
    </div>


    <router-outlet></router-outlet>
  </div>

  <div id="footer"
       *ngIf="!learnMode && !certWizardMode">
    <div class="container text-sm-center"
         [class.nav-side-menu-open]="navbarActive">

      <a routerLink="/imprint" routerLinkActive="active" pageScroll href="#top">{{'imprint'|translate}}</a>
      | <a routerLink="/privacy" routerLinkActive="active" pageScroll href="#top">{{'privacy-short'|translate}}</a>
      | <a routerLink="/tos" routerLinkActive="active" pageScroll href="#top">{{'tos'|translate}}</a>

      <br><sup>©</sup> Little Helper Verlag 2006-{{currentYear}}
    </div>
  </div>
</div>

<ng-template ngbModalContainer></ng-template>

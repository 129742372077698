import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import {AuthService} from '../../auth/auth.service';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/do';
import {TooltipService} from '../tooltip/tooltip.service';
import {Router} from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private authService: AuthService;

  constructor(private injector: Injector,
              private tooltipService: TooltipService,
              private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Late injection of AuthService due to circular dependency problem
    // when we inject it in constructor at bootstrap time.
    if (!this.authService) {
      this.authService = this.injector.get(AuthService);
    }

    if (AuthService.authToken) {
      request = request.clone({
        setHeaders: {
          Authorization: AuthService.authToken
        }
      });
    }

    return next.handle(request).do((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401 || err.status === 403) {
          if (AuthService.authToken) {
            this.tooltipService.show({
              translateKey: 'session-timeout',
              type: 'danger'
            });
            this.authService.logout();
            this.router.navigate(['/login']);
          }
        }
      }
    });
  }
}

export class AsadStat {

  serverUnixTime: number = null;
  asadId: string = null;
  userId: string = null;
  moduleCount: number = null;
  cardCount: number = null;
  userCount: number = null;

  constructor() {
  }

  static createWith(obj: any): AsadStat {
    let newObj = new AsadStat();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

}

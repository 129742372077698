import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {Card} from '../../module/card.model';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {ModuleService} from '../../module/module.service';
import {StatsService} from '../../stats/stats.service';
import {LoadingService} from '../../util/loading/loading.service';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {AuthService} from '../../auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {CertWizardService} from '../cert-wizard.service';
import {CertWizard} from '../model/cert-wizard';
import {Image} from '../../module/image.model';
import {environment} from '../../../environments/environment';
import {Module, ModuleKind} from '../../module/module.model';
import {Queue} from '../../util/queue';
import {LearnViewState} from '../model/learn-view-state';
import * as lodash from 'lodash'
import {TranslateService} from '@ngx-translate/core';
import {CardMcChoice} from '../../module/card-mc-choice.model';
import {UserCertWizardCardAnswer} from '../model/user-cert-wizard-card-answer';
import {CertWizardUserStat} from '../model/cert-wizard-user-stat';
import {PageScrollService} from 'ngx-page-scroll-core';
import {DOCUMENT} from '@angular/common';
import {CertWizardModule} from '../model/cert-wizard-module';
import {Lection} from '../../module/lection.model';
import {CertWizardImage} from '../model/cert-wizard-image';
import {stringToLang, SupportedLang} from '../model/supported-lang';
import {Auth} from '../../auth/auth';
import {CertWizardImageType} from '../model/CertWizardImageType';
import {TitleService} from '../../util/title/title.service';

@Component({
  selector: 'app-cert-wizard-learn',
  templateUrl: './cert-wizard-learn.component.html',
  styleUrls: ['./cert-wizard-learn.component.css']
})
export class CertWizardLearnComponent implements OnInit, OnDestroy, AfterViewInit {

  public currentLearnViewState: LearnViewState;
  public currentTwoSideAnswerText = '';
  public currentTwoSideAnswerCorrect = false;
  public currentCheckedMcChoice: number[] = [];
  public currentCorrectMcChoice: number[] = [];
  public currentWrongMcChoice: number[] = [];

  // current user
  public auth: Auth;

  public certWizardModuleId = '';
  public moduleId = '';
  public cardId = '';
  public token = '';
  public exam = '';

  public certWizard: CertWizard = new CertWizard();
  public modules: Module[] = [];

  public moduleQueue: Queue<Module> = new Queue<Module>();
  public currentModule: Module = new Module();
  public nextModule: Module = new Module();

  public cardQueue: Queue<Card> = new Queue<Card>();
  public currentCard: Card = new Card();
  public nextCard: Card = new Card();

  public cardAmount = 0;


  public showWrongAnswerAnimation = false;
  public showRightAnswerAnimation = false;

  public cards: Card[] = [];
  public progressCards = 0;

  public minHeight = 0;

  public userStat: CertWizardUserStat = new CertWizardUserStat();

  public selectedLang: SupportedLang = SupportedLang.de;

  public wrapperMaxHeight = 650;
  public wrapperHeight = 650;
  public wrapperMaxWidth = 414;
  public wrapperWidth = 414;
  public contentMinHeight = 0;
  public windowHeight = 0

  public FALLBACK_LEFT_LOGO = '';

  public imgLoading: boolean;

  public sawIds = [3, 22];
  public showDisclaimer = false;

  private body: HTMLBodyElement;
  private wrapperRef: HTMLElement;

  private Math: any;
  private sHelper: SubscriptionHelper = new SubscriptionHelper;


  // company styles
  @ViewChild('navbar') navbar: ElementRef;
  @ViewChild('navRef') navRef: ElementRef;
  @ViewChild('navImageLeft') navImageLeft: ElementRef;
  @ViewChild('navImageRight') navImageRight: ElementRef;

  @ViewChild('learnCardContainer') learnCardContainer: ElementRef;

  @ViewChild('questionElem') questionElem: ElementRef;
  @ViewChild('nextBtnElem') nextBtnElem: ElementRef;



  @HostListener('window:resize', ['$event'])
  handleScreenSize() {
    this.resize();
  }

  private resize(): void {
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '0px');
    this.renderer.setStyle(this.body, 'padding-bottom', '0px');

    if (window.innerWidth <= 414) {
      this.wrapperMaxHeight = 915;
      this.wrapperMaxWidth = 414;
    } else {
      this.wrapperMaxHeight = 850;
      this.wrapperMaxWidth = 450;
    }

    this.windowHeight = window.innerHeight;
    this.wrapperWidth = window.innerWidth;
    this.contentMinHeight = window.innerHeight
      - this.navbar.nativeElement.offsetHeight;
    if (this.contentMinHeight > this.wrapperMaxHeight) {
      this.contentMinHeight = this.wrapperMaxHeight - this.navbar.nativeElement.offsetHeight;
    }
  }

  public constructor(private readonly moduleService: ModuleService,
                     private readonly statsService: StatsService,
                     private readonly certWizardService: CertWizardService,
                     private readonly modalService: ModalWrapperService,
                     private readonly authService: AuthService,
                     private readonly route: ActivatedRoute,
                     private readonly renderer: Renderer2,
                     private readonly changeDetector: ChangeDetectorRef,
                     private readonly translate: TranslateService,
                     private readonly router: Router,
                     private readonly tooltipService: TooltipService,
                     private readonly loadingService: LoadingService,
                     private readonly pageScrollService: PageScrollService,
                     private readonly titleService: TitleService,
                     @Inject(DOCUMENT) private document: any) {
    this.Math = Math;
  }

  private async init(): Promise<void> {
    this.FALLBACK_LEFT_LOGO = `${location.origin}/assets/img/el-logo.svg`;

    this.loadingService.open();

    const certWizardId = this.route.snapshot.params['cert-wizard-id'];
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.certWizardModuleId = this.route.snapshot.queryParamMap.get('cert-wizard-module-id');
    this.moduleId = this.route.snapshot.queryParamMap.get('module-id');
    this.cardId = this.route.snapshot.queryParamMap.get('card-id');
    this.exam = this.route.snapshot.queryParamMap.get('exam');

    if (this.token) {
      AuthService.authToken = `Bearer ${this.token}`;
      await this.authService.autoLogin();
    }

    await this.certWizardService.findByIdPublic(certWizardId).toPromise()
      .then(cw => {
        this.certWizard = cw;
        this.handleCertWizardActive();
      }).catch(async () => {
        await this.certWizardService.findByHashPublic(certWizardId).toPromise()
          .then(cw => {
            this.certWizard = cw;
            this.handleCertWizardActive();
          }).catch(async () => {
            console.log('not Found')
            this.loadingService.dismiss();
            void await this.router.navigate(['message/not-found']);
          })
      });

    this.sHelper.sub = this.authService.auth.subscribe(async (auth: Auth) => {
      if (auth === undefined) {
        // has not been initialized
        await this.authService.createAndImpersonateDummy(this.certWizard.clientId, this.translate.currentLang).toPromise();
      } else if (auth === null) {
        // create new dummy user
        await this.authService.createAndImpersonateDummy(this.certWizard.clientId, this.translate.currentLang).toPromise();
      } else {
        this.auth = auth;
        this.translate.use(this.auth.user.lang);
        this.selectedLang = stringToLang(this.translate.currentLang);
        this.titleService.title =
          this.certWizard.certWizardDisplayNames
            .find(cwdn => cwdn.lang === this.auth.user.lang)?.displayName;
        await this.certWizardService.createUserCertWizard(this.auth.user, this.certWizard).toPromise();
        this.setCompanyStyles();
        await this.initModules()
          .catch(() => this.finish())
        this.initCardsForCurrentModule();
      }
    });
  }

  public async initModules(): Promise<void> {

    if (this.sawIds.includes(Number(this.certWizard.id)) && !window.localStorage.getItem('disclaimer-seen')) {
      this.showDisclaimer = true;
    }

    if (this.certWizardModuleId) {
      this.modules.push(
        await this.certWizardService.findCertWizardModulePublic(this.certWizard.id, this.moduleId).toPromise()
      )
    } else if (this.moduleId) {
      this.modules.push(
        await this.moduleService.findByUserAndId(this.auth.user, this.moduleId).toPromise()
      )
    } else {
      if (this.exam) {
        await Promise.all(
          this.certWizard.certWizardModules.map(async (m: CertWizardModule) => {
            if (m.lang === this.selectedLang && m.exam) {
              this.modules.push(
                await this.certWizardService.findCertWizardModulePublic(this.certWizard.id, m.id.toString()).toPromise()
              )
            }
          })
        );
      } else {
        await Promise.all(
          this.certWizard.certWizardModules.map(async (m: CertWizardModule) => {
            if (m.lang === this.selectedLang && !m.exam) {
              this.modules.push(
                await this.certWizardService.findCertWizardModulePublic(this.certWizard.id, m.id.toString()).toPromise()
              )
            }
          })
        );
      }
    }

    const sorted: Module[] = [];
    this.modules.filter(v => v.type === ModuleKind.ONE_SIDE)
      .forEach(v => sorted.push(v));
    this.modules.filter(v => v.type === ModuleKind.MULTIPLE_CHOICE)
      .forEach(v => sorted.push(v));
    this.modules.filter(v => v.type === ModuleKind.TWO_SIDE)
      .forEach(v => sorted.push(v));

    this.moduleQueue = new Queue<Module>(
      lodash.cloneDeep(sorted)
    );
    this.currentModule = this.moduleQueue.pop();
    this.nextModule = this.moduleQueue.pop();

    if (this.cardId) {
      this.cardAmount = 1;
    } else {
      this.cardAmount = this.modules
        .map((m: Module) => m.lections
          .map((l: Lection) => l.cards.length)
          .reduce((pre: number, cur: number) => pre + cur)
        ).reduce((pre: number, cur: number) => pre + cur)
    }
  }

  public initCardsForCurrentModule(): void {
    this.cards = this.currentModule.lections
      .map(l => l.cards)
      .reduce((acc: Card[], cur: Card[]) => {
        return acc.concat(cur);
      })

    if (this.cardId) {
      this.cards = this.cards.filter(c => {
        return +c.id === +this.cardId
      });
    }

    this.cardQueue = new Queue<Card>(
      this.shuffleArray(lodash.cloneDeep(this.cards))
    );
    this.currentCard = this.cardQueue.pop();
    this.nextCard = this.cardQueue.pop();
    this.progressCards++;
    if (this.currentCard === undefined) {
      this.finish();
    }
    this.setCardRequested();

    switch (this.currentModule.type) {
      case ModuleKind.ONE_SIDE:
        this.currentLearnViewState = LearnViewState.ONE_SIDE_QUESTION;
        break;
      case ModuleKind.TWO_SIDE:
        this.currentLearnViewState = LearnViewState.TWO_SIDE_QUESTION_NO_INPUT;
        break;
      case ModuleKind.MULTIPLE_CHOICE:
        this.currentCard.cardMcChoices = this.shuffleArray<CardMcChoice>(this.currentCard.cardMcChoices);
        this.currentCorrectMcChoice = this.getCorrectMcChoiceIndex(this.currentCard.cardMcChoices);
        this.currentWrongMcChoice = this.getWrongMcChoiceIndex(this.currentCard.cardMcChoices);
        this.currentLearnViewState = LearnViewState.MULTIPLE_CHOICE_QUESTION;
        break;
    }

    this.loadingService.dismiss();
  }

  public async handleNextCard(known?: boolean): Promise<void> {

    if (this.showDisclaimer) {
      this.cardFadeOut();
      await this.timeout(500);
      this.showDisclaimer = false;
      window.localStorage.setItem('disclaimer-seen', 'true');
      this.cardFadeIn();
      return;
    }

    switch (this.currentLearnViewState) {
      case LearnViewState.ONE_SIDE_QUESTION:
        await this.handleOneSideQuestion(true);
        break;
      case LearnViewState.TWO_SIDE_QUESTION_NO_INPUT:
        await this.handleTwoSideQuestionNoInput();
        break;
      case LearnViewState.TWO_SIDE_QUESTION_INPUT:
        await this.handleTwoSideQuestionInput();
        break;
      case LearnViewState.TWO_SIDE_ANSWER_NO_INPUT:
        await this.handleTwoSideAnswerNoInput(known);
        break;
      case LearnViewState.TWO_SIDE_ANSWER_INPUT:
        await this.handleTwoSideAnswerInput();
        break;
      case LearnViewState.MULTIPLE_CHOICE_QUESTION:
        await this.handleMultipleChoiceQuestion();
        break;
      case LearnViewState.MULTIPLE_CHOICE_ANSWER:
        await this.handleMultipleChoiceAnswer();
        break;
    }
  }

  private async handleOneSideQuestion(known: boolean): Promise<void>  {
    this.cardFadeOut();
    await this.timeout(500);
    this.persistCardAnswer(known, true);
    if (await this.checkFinish()) {
      return;
    }
    this.progressCards++;
    this.currentCard = lodash.cloneDeep<Card>(this.nextCard);
    this.nextCard = this.cardQueue.pop();
    this.setCardRequested();
    this.scrollToTarget(true);
    this.cardFadeIn();
  }

  private async handleTwoSideQuestionNoInput(): Promise<void> {
    this.cardFadeOut();
    await this.timeout(500);
    this.currentLearnViewState = LearnViewState.TWO_SIDE_ANSWER_NO_INPUT;
    this.scrollToTarget(false);
    this.cardFadeIn();
  }

  private async handleTwoSideAnswerNoInput(known: boolean): Promise<void> {
    this.persistCardAnswer(known, true);
    this.cardFadeOut();
    await this.timeout(500);
    if (await this.checkFinish()) {
      return;
    }
    this.currentLearnViewState = LearnViewState.TWO_SIDE_QUESTION_NO_INPUT;
    this.progressCards++;
    this.currentCard = lodash.cloneDeep<Card>(this.nextCard);
    this.nextCard = this.cardQueue.pop();
    this.setCardRequested();
    this.scrollToTarget(true);
    this.cardFadeIn();
  }

  private async handleTwoSideQuestionInput(): Promise<void> {
    this.currentTwoSideAnswerCorrect = this.currentCard.backText.toLowerCase() === this.currentTwoSideAnswerText.toLowerCase();
    this.persistCardAnswer(this.currentTwoSideAnswerCorrect, true);
    this.showAnswerAnimation(this.currentTwoSideAnswerCorrect);

    // if answer is correct dont show answers again
    if (this.currentTwoSideAnswerCorrect) {
      await this.handleTwoSideAnswerInput();
    } else {
      this.cardFadeOut();
      await this.timeout(500);
      this.currentLearnViewState = LearnViewState.TWO_SIDE_ANSWER_INPUT;
      this.scrollToTarget(false);
      this.cardFadeIn();
    }

  }

  private async handleTwoSideAnswerInput(): Promise<void> {
    this.cardFadeOut();
    await this.timeout(500);
    if (await this.checkFinish()) {
      return;
    }
    this.currentTwoSideAnswerText = '';
    this.currentTwoSideAnswerCorrect = false;
    this.currentLearnViewState = LearnViewState.TWO_SIDE_QUESTION_INPUT;
    this.currentCard = lodash.cloneDeep<Card>(this.nextCard);
    this.nextCard = this.cardQueue.pop();
    this.progressCards++;
    this.setCardRequested();
    this.scrollToTarget(true);
    this.cardFadeIn();
  }

  private async handleMultipleChoiceQuestion(): Promise<void> {
    const answerCorrect: boolean = this.correctChoice()
    this.showAnswerAnimation(answerCorrect);
    this.persistCardAnswer(answerCorrect, true);
    // if answer is correct dont show answers again
    if (answerCorrect) {
      await this.handleMultipleChoiceAnswer();
    } else {
      this.cardFadeOut();
      await this.timeout(500);
      this.currentLearnViewState = LearnViewState.MULTIPLE_CHOICE_ANSWER;
      this.scrollToTarget(false);
      this.cardFadeIn();
    }

  }

  private async handleMultipleChoiceAnswer(): Promise<void> {
    this.cardFadeOut();
    this.currentCheckedMcChoice = [];
    await this.timeout(500);
    if (await this.checkFinish()) {
      return;
    }
    this.currentLearnViewState = LearnViewState.MULTIPLE_CHOICE_QUESTION;
    this.currentCard = lodash.cloneDeep<Card>(this.nextCard);
    this.nextCard = this.cardQueue.pop();
    this.setCardRequested();
    this.currentCard.cardMcChoices = this.shuffleArray<CardMcChoice>(this.currentCard.cardMcChoices);
    this.currentCorrectMcChoice = this.getCorrectMcChoiceIndex(this.currentCard.cardMcChoices);
    this.currentWrongMcChoice = this.getWrongMcChoiceIndex(this.currentCard.cardMcChoices);
    this.progressCards++;
    this.scrollToTarget(true);
    this.cardFadeIn();
  }

  private async checkFinish(): Promise<boolean> {
    // 1 check if all cards of module were used
    if (this.nextCard === undefined) {
      this.cardFadeOut();
      await this.timeout(500);

      // 2 check if all module were used and finish if so
      if (this.nextModule === undefined) {
        this.finish();
        return true;
      }

      // 3 if not all modules were used take next module, init cards and animate
      this.currentModule = lodash.cloneDeep<Module>(this.nextModule);
      this.nextModule = this.moduleQueue.pop();
      this.initCardsForCurrentModule()
      this.cardFadeIn();
      return true
    }
    return false;
  }

  private cardFadeOut(): void {
    this.renderer.removeClass(this.learnCardContainer.nativeElement, 'fade-in');
    this.renderer.addClass(this.learnCardContainer.nativeElement, 'fade-out');
  }

  private cardFadeIn(): void {
    this.renderer.removeClass(this.learnCardContainer.nativeElement, 'fade-out');
    this.renderer.addClass(this.learnCardContainer.nativeElement, 'fade-in');
  }

  public showAnswerAnimation(right: boolean): void {
    if (right) {
      this.showRightAnswerAnimation = true;
      setTimeout(() => this.showRightAnswerAnimation = false, 1000)
    } else  {
      this.showWrongAnswerAnimation = true;
      setTimeout(() => this.showWrongAnswerAnimation = false, 1000)
    }
  }

  public async changeInputMode(): Promise<void> {
    switch (this.currentLearnViewState) {
      case LearnViewState.TWO_SIDE_QUESTION_INPUT:
        this.currentLearnViewState = LearnViewState.TWO_SIDE_QUESTION_NO_INPUT;
        break;
      case LearnViewState.TWO_SIDE_QUESTION_NO_INPUT:
        this.currentLearnViewState = LearnViewState.TWO_SIDE_QUESTION_INPUT;
        break;
      case LearnViewState.TWO_SIDE_ANSWER_INPUT:
        this.tooltipService.show({
          message: await this.translate.get('check-in-answer-first').toPromise()
        });
        break;
      case LearnViewState.TWO_SIDE_ANSWER_NO_INPUT:
        this.tooltipService.show({
          message: await this.translate.get('check-in-answer-first').toPromise()
        })
        break;
    }
  }

  public shuffleArray<T>(array: T[]): T[] {
    const clone = lodash.cloneDeep(array);
    for (let i = clone.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [clone[i], clone[j]] = [clone[j], clone[i]];
    }
    return clone;
  }

  public correctChoice(): boolean {
    const correctMatch = this.currentCorrectMcChoice.map((choice: number) => {
      return this.currentCheckedMcChoice.includes(choice);
    })
    const wrongMatch = this.currentWrongMcChoice.map((choice: number) => {
      return this.currentCheckedMcChoice.includes(choice);
    })
    return (!correctMatch.includes(false) && (!wrongMatch.includes(true)) && (correctMatch.length > 0))
  }

  public getCorrectMcChoiceIndex(cardMcChoices: CardMcChoice[]): number[] {
    return cardMcChoices
      .map((mc: CardMcChoice, index: number) => {
        return { correct: mc.correct, index: index};
      }).filter(obj => obj.correct)
      .map(obj => obj.index);
  }

  public getWrongMcChoiceIndex(cardMcChoices: CardMcChoice[]): number[] {
    return cardMcChoices
      .map((mc: CardMcChoice, index: number) => {
        return { correct: mc.correct, index: index};
      }).filter(mc => !mc.correct)
      .map(mc => mc.index);
  }

  public checkMcChoice(index: number): void {
    const foundIndex = this.currentCheckedMcChoice.indexOf(index);
    if (foundIndex !== -1) {
      this.currentCheckedMcChoice.splice(foundIndex, 1);
    } else {
      this.currentCheckedMcChoice.push(index);
    }
  }

  public isCheckedMcChoice(index: number): boolean {
    return this.currentCheckedMcChoice.includes(index);
  }

  public isCorrectMcChoice(index: number): boolean {
    return this.currentCorrectMcChoice.includes(index);
  }

  public setCompanyStyles(): void {
    this.renderer.setStyle(this.navbar.nativeElement, 'background-color', this.certWizard.color);

    if (this.getLogoLeftSecondByLang()) {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.getImageURL(this.getLogoLeftSecondByLang().image));
    } else if (this.getLogoLeftByLang()) {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.getImageURL(this.getLogoLeftByLang().image));
    } else {
      this.renderer.setAttribute(
        this.navImageLeft.nativeElement,
        'src',
        this.FALLBACK_LEFT_LOGO);
    }
    if (this.getLogoRightByLang()) {
      this.renderer.setAttribute(
        this.navImageRight.nativeElement,
        'src',
        this.getImageURL(this.getLogoRightByLang().image));
    }
  }

  public getImageURL(image: Image) {
    this.imgLoading = true;
    return environment.backendApi + '/images/' + image.code + '/' + image.name;
    this.imgLoading = false;
  }

  public finish(): void {
    console.error('finish was called');
    void this.router.navigate([`cert-wizards/${this.certWizard.hash}/cert-wizard-dashboard`],
      {queryParams: {token: this.token, exam: this.exam}});
  }

  private timeout(ms): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private setCardRequested() {
    if (this.cardId) {
      void this.certWizardService
        .setCardRequested(this.auth.user, this.certWizard, this.currentCard, true)
        .toPromise();
    } else {
      void this.certWizardService
        .setCardRequested(this.auth.user, this.certWizard, this.currentCard, false)
        .toPromise();
    }
  }

  private scrollToTarget(top: boolean): void {
    setTimeout(() => {

      if (top) {
        const questionElemRect = this.questionElem.nativeElement.getBoundingClientRect();
        const topShown = questionElemRect.top >= 0;

        if (!topShown) {
          this.pageScrollService.scroll({
            document: this.document,
            scrollTarget: '.scroll-target-top',
            duration: 1500,
            scrollOffset: 100,
          })
        }

      } else {
        const nextBtnElemRect = this.nextBtnElem.nativeElement.getBoundingClientRect();
        const bottomShown = nextBtnElemRect.bottom <= window.innerHeight;

        if (!bottomShown) {
          this.pageScrollService.scroll({
            document: this.document,
            scrollTarget: '.scroll-target-bottom',
            duration: 1500,
            scrollOffset: -100,
          })
        }

      }
    }, 250)
  }

  private persistCardAnswer(answeredCorrect: boolean, answerHasMeaning: boolean) {
    const cardAnswer = new UserCertWizardCardAnswer(
      this.auth.user.id,
      this.certWizard.id,
      this.currentCard.id,
      answeredCorrect,
      answerHasMeaning);
    void this.certWizardService.persistCardAnswer(cardAnswer).toPromise();
  }

  private handleCertWizardActive(): void {
    if (!this.certWizard.active) {
      this.loadingService.dismiss();
      void this.router.navigate(['message/inactive']);
    }
  }

  public getLogoLeftByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getLogoRightByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_RIGHT)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getLogoLeftSecondByLang(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.lang === this.selectedLang && c.type === CertWizardImageType.LOGO_LEFT_SECOND)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public getBackgroundImage(): CertWizardImage {
    const index: number = this.certWizard.certWizardImages
      .findIndex(c => c.type === CertWizardImageType.BACKGROUND_IMAGE)
    if (index !== -1) {
      return this.certWizard.certWizardImages[index];
    }
    return null;
  }

  public ngOnInit(): void {
  }

  public async ngAfterViewInit(): Promise<void> {
    await this.init()
    this.body = document.getElementsByTagName('body')[0];
    this.wrapperRef = document.getElementById('content-wrapper');
    this.renderer.setStyle(this.wrapperRef, 'padding-top', '0px');
    this.renderer.setStyle(this.body, 'padding-bottom', '0px');
    this.renderer.setAttribute(this.body, 'background-color', '#D0D0D0');
    this.resize();
  }

  public ngOnDestroy() {
    if (this.certWizard.id) {
      this.renderer.setStyle(this.wrapperRef, 'padding-top', '52px');
      this.renderer.setStyle(this.body, 'padding-bottom', '6rem');
    }
    this.sHelper.unsubscribeAll();
  }
}

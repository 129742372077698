import {KTracker} from './ktracker.model';
import {User} from '../user/user.model';
import {UserKTracker} from './user-ktracker.model';
import {Card} from '../module/card.model';
import {Module} from '../module/module.model';
import {KTrackerCardStat} from '../stats/ktracker-card-stat.model';

export class KTrackerCard {

  answerTimeSec: number = null;
  module: Module = null;
  card: Card = null;
  cardStat: KTrackerCardStat = null;

  constructor() {
  }

  static createWith(obj: any): KTrackerCard {
    let newObj = new KTrackerCard();
    Object.keys(newObj).forEach(
      key => {
        if ('module' === key && obj[key]) {
          newObj.module = Module.createWith(obj[key]);
        } else if ('card' === key && obj[key]) {
          newObj.card = Card.createWith(obj[key]);
        } else if ('cardStat' === key && obj[key]) {
          newObj.cardStat = KTrackerCardStat.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2" *ngIf="client && client.name">{{'signup'|translate}} {{registrationStep}}/3 - {{client.name}}</span>
  </div>
</div>
<div class="container-fluid container-limited">

  <div class="row" *ngIf="client && client.image">
    <div class="col-sm-5 col-lg-3">
      <a href="{{client.getImageURL()}}" data-lightbox="image-profile">
        <img class="img-fluid" [src]="client.getImageURL()" (error)="client.image = null"/>
      </a>
    </div>
  </div>

  <div class="row" style="margin-bottom: 15px">
    <div class="col-xs-12 offset-sm-3 col-sm-9">
      <p class="text-danger" *ngIf="errors.get('error') != undefined">{{errors.get('error')|translate}}</p>
    </div>
  </div>

  <form (ngSubmit)="submit($event)" autocomplete="off" *ngIf="!loadingService.isOpen() && registrationStep == 1"
        name="user-registration">

    <div class="form-group row" [class.has-danger]="errors.get('email') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="user_email">
        {{'email'|translate}}*
      </label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="off" class="form-control form-control-danger" type="text" name="user[email]"
               id="user_email" [(ngModel)]="user.email" minlength="7" maxlength="100">
        <div class="form-control-feedback">{{errors.get('email')|translate}}</div>
      </div>
    </div>

    <div class="form-group row"
         [class.has-danger]="errors.get('registrationPassword') != undefined"
         *ngIf="client.hasPassword">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right text-truncate" for="registrationPassword">
        {{'registration-password'|translate}}*
      </label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="off" class="form-control form-control-danger" type="text"
               name="client[registrationPassword]"
               id="registrationPassword" [(ngModel)]="client.registrationPassword" maxlength="20">
        <div class="form-control-feedback">{{errors.get('registrationPassword')|translate}}</div>
      </div>
    </div>

    <div class="form-actions d-flex">
      <div class="mr-auto">* required</div>
      <button class="btn btn-outline-success flex-row mr-2" type="submit">
        <span>{{'start-registration'|translate}}</span>
      </button>
    </div>

  </form>

  <form (ngSubmit)="submitRegistration($event)" autocomplete="off" *ngIf="!loadingService.isOpen() && registrationStep == 2"
        name="user-registration">

    <div class="form-group row" [class.has-danger]="errors.get('login') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_login">{{'user-name'|translate}}*</label>
      <div class="col-xs-12 col-sm-9">
        <input required autocomplete="off" class="form-control form-control-danger" type="text" name="user[login]"
               id="user_login" [(ngModel)]="user.login" minlength="2" maxlength="20">
        <div class="form-control-feedback">{{errors.get('login')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('name') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right" for="user_name">{{'name'|translate}}*</label>
      <div class="col-xs-12 col-sm-9">
        <input required autocomplete="off" class="form-control form-control-danger" type="text" name="user[name]"
               id="user_name" [(ngModel)]="user.name" minlength="2" maxlength="100">
        <div class="form-control-feedback">{{errors.get('name')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('forename') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_forename">{{'forename'|translate}}*</label>
      <div class="col-xs-12 col-sm-9">
        <input required autocomplete="off" class="form-control form-control-danger" type="text" name="user[forename]"
               id="user_forename" [(ngModel)]="user.forename" minlength="2" maxlength="100">
        <div class="form-control-feedback">{{errors.get('forename')|translate}}</div>
      </div>
    </div>

    <div class="form-group row" [class.has-danger]="errors.get('password') != undefined">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_password">
        <span *ngIf="!isEditMode">{{'password'|translate}}*</span>
        <span *ngIf="isEditMode">{{'new-password'|translate}}</span>
      </label>
      <div class="col-xs-12 col-sm-9">
        <input autocomplete="new-password" class="form-control form-control-danger" type="password" name="user[password]"
               id="user_password" [(ngModel)]="user.password">
        <div class="form-control-feedback">{{errors.get('password')|translate}}</div>
      </div>
    </div>

    <div class="form-group row">
      <label class="form-control-label col-xs-12 col-sm-3 text-sm-right"
             for="user_lang">{{'language'|translate}}</label>
      <div class="col-xs-12 col-sm-9">
        <select id="user_lang" [(ngModel)]="user.lang"
                class="custom-select" name="user_lang">
          <option *ngFor="let l of languages" [value]="l.code">{{l.name}}</option>
        </select>
      </div>
    </div>

    <div class="form-actions d-flex">
      <div class="mr-auto">* required</div>
      <button class="btn btn-outline-success flex-row mr-2" type="submit">
        <span>{{'signing-up'|translate}}</span>
      </button>
    </div>

  </form>

  <div class="card mt-4 mb-4"  *ngIf="registrationStep == 3">
    <div class="card-body">
      <h4 class="card-title">{{'email-validation'|translate}}</h4>
      <p class="card-text">{{'email-validation-text'|translate}}</p>
    </div>
  </div>

</div>

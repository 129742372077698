import {SupportedLang} from './supported-lang';

export class CertWizardDisplayName {
  id: string = null;
  lang: SupportedLang = null;
  displayName: string = null;

  public constructor(lang: SupportedLang, displayName: string | null) {
    this.id = null;
    this.lang = lang;
    this.displayName = displayName;
  }
}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited d-flex flex-row">
    <div class="h2 mr-auto text-nowrap">
      {{kTracker?.name}}
    </div>
    <button class="btn btn-link p-0 text-primary-2"
            (click)="edit()"
            title="{{'edit-ktracker'|translate}}"
            *ngIf="kTracker">
      <i class="fa fa-pencil" style="font-size: 1.5rem" aria-hidden="true"></i>
    </button>
  </div>
</div>

<div class="container-fluid container-limited">
  <div class="row">
    <div class="col-12">
      <div class="card mt-2 mb-2" *ngIf="kTrackerStat">
        <div class="card-header">
          {{'summary'|translate}}
        </div>

        <div class="card-body d-flex flex-column flex-md-row">
          <table class="table table-sm mr-md-auto mt-1" style="width: 300px">
            <tbody>
            <tr>
              <td class="text-muted border-0">{{'user-count'|translate}}</td>
              <td class="text-primary-2 text-right border-0">{{kTracker.userKTrackers.length}}</td>
              <td class="text-primary-2 border-0"></td>
            </tr>
            <tr>
              <td class="text-muted">{{'card-count'|translate}}</td>
              <td class="text-primary-2 text-right">{{kTrackerStat.cardCount}}</td>
              <td class="text-primary-2"></td>
            </tr>
            <tr>
              <td class="text-muted">{{'sent-cards'|translate}}</td>
              <td class="text-primary-2 text-right">{{kTrackerStat.sentCardCount}}</td>
              <td class="text-primary-2 text-right">
                {{calcKTrackerFinish(kTrackerStat)}}%
              </td>
            </tr>
            <tr>
              <td class="text-muted">{{'sent-requests'|translate}}</td>
              <td class="text-primary-2 text-right">{{kTrackerStat.sentRequests}}</td>
              <td class="text-primary-2"></td>
            </tr>
            <tr>
              <td class="text-muted">{{'answers'|translate}}</td>
              <td class="text-primary-2 text-right">{{kTrackerStat.correctAnswersCount + kTrackerStat.wrongAnswersCount}}
              </td>
              <td class="text-primary-2 text-right">
                {{calcAnswers(kTrackerStat)}}%
              </td>
            </tr>
            <tr>
              <td class="text-muted">Ø {{'answer-time'|translate}}</td>
              <td class="text-primary-2 text-right">{{kTrackerStat.averageAnswerTime}}</td>
              <td class="text-primary-2">s</td>
            </tr>

            </tbody>
          </table>
          <div style="display: block;max-width: 250px" *ngIf="auth && !loadingService.isOpen()">
            <canvas baseChart
                    [data]="dataCorrectWrongAnswers"
                    [labels]="labelsCorrectWrongAnswers"
                    [chartType]="doughnutChartType"
                    [options]="doughnutChartOptions"
                    [colors]="colorsCorrectWrongAnswers"></canvas>
          </div>
        </div>

      </div>
    </div>
  </div>


  <div class="row">
    <div class="col-12">
      <div class="card mt-4 mb-2" *ngIf="kTrackerStat">

        <table class="table table-sm table-hover m-0">
          <thead class="thead-default">
          <tr>
            <th class="text-right"><!--Module / lection name--></th>
            <th class="text-right">{{'cards'|translate}}</th>
            <th class="text-right">{{'sent-cards'|translate}}</th>
            <th class="text-right">{{'requests'|translate}}</th>
            <th class="text-right">{{'answers'|translate}}</th>
            <th class="text-right">
              <i class="fa fa-check" aria-hidden="true" style="color: green;"></i>
            </th>
            <th class="text-right pr-3">
              <i class="fa fa-remove" aria-hidden="true" style="color: red;"></i>
            </th>
          </tr>
          </thead>
          <tbody *ngFor="let kTModule of kTracker?.ktrackerModules; let midx = index">
          <tr class="bg-primary-2 text-white text-right">
            <td class="text-left">{{kTModule.module.name}}</td>
            <td>{{moduleStat(midx).cardCount}}</td>
            <td>
              {{moduleStat(midx).sentCardCount}}
              <span style="width: 60px;display: inline-block">
                  {{Math.round(moduleStat(midx).sentCardCount / moduleStat(midx).cardCount * 100)}}%
                </span>
            </td>
            <td>{{moduleStat(midx).sentRequests}}</td>
            <td>
              {{moduleStat(midx).correctAnswersCount + moduleStat(midx).wrongAnswersCount}}
              <span style="width: 60px;display: inline-block">
                  {{calcAnswers(moduleStat(midx))}}%
                </span>
            </td>
            <td>{{moduleStat(midx).correctAnswersCount}}</td>
            <td class="pr-3">{{moduleStat(midx).wrongAnswersCount}}</td>
          </tr>
          <tr *ngFor="let lectionStat of moduleStat(midx).lectionStats; let lidx = index" class="text-right">
            <td>{{lectionStat.lectionName}}</td>
            <td>{{lectionStat.cardCount}}</td>
            <td>
              {{lectionStat.sentCardCount}}
              <span style="width: 60px;display: inline-block">
                  {{Math.round(lectionStat.sentCardCount / lectionStat.cardCount * 100)}}%
                </span>
            </td>
            <td>{{lectionStat.sentRequests}}</td>
            <td>
              {{lectionStat.correctAnswersCount + lectionStat.wrongAnswersCount}}
              <span style="width: 60px;display: inline-block" *ngIf="lectionStat.sentRequests>0">
                  {{Math.round((lectionStat.correctAnswersCount + lectionStat.wrongAnswersCount) / lectionStat.sentRequests * 100)}}%
                </span>
              <span style="width: 60px;display: inline-block" *ngIf="lectionStat.sentRequests==0">
                  0%
                </span>
            </td>
            <td>{{lectionStat.correctAnswersCount}}</td>
            <td class="pr-3">{{lectionStat.wrongAnswersCount}}</td>
          </tr>

          </tbody>
        </table>

      </div>
    </div>

  </div>

</div>

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">{{'public-asads'|translate}}</span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">
    <div class="col-12 mb-4">
      <searchbar class="float-right" [(query)]="searchQuery" (input)="doSearch()"
                 placeholder="{{'search'|translate}} ASaD"></searchbar>
    </div>
  </div>
  <div class="row">
    <div class="col-12">

      <div class="card">
        <div class="table-responsive" *ngIf="filteredAsads.length">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th class="border-top-0">{{'name'|translate}}</th>
              <th class="border-top-0 d-none d-md-block">{{'description'|translate}}</th>
              <th class="border-top-0">{{'owner'|translate}}</th>
              <th class="border-top-0">{{'permission'|translate}}</th>
              <th class="border-top-0"><!--actions--></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let asad of filteredAsads" (click)="edit(asad)">
              <td>{{asad.name}}</td>
              <td class="d-none d-md-block">{{asad.description}}</td>
              <td>{{getOwner(asad)|translate}}</td>
              <td>{{getPermission(asad)|translate}}</td>
              <td class="d-flex flex-row justify-content-end">
                <button class="btn btn-link btn-table mr-2" (click)="connectUser(asad,$event)">
                  <i class="fa fa-users" aria-hidden="true"></i>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="!filteredAsads.length">
          <h5 class="card-title text-muted text-center" style="margin-top: 12px">
            {{'empty-search-asad'|translate}}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

import {Component, HostListener, OnDestroy} from '@angular/core';
import {Auth} from '../../auth/auth';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {LoadingService} from '../../util/loading/loading.service';
import {AuthService} from '../../auth/auth.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {CertWizard} from '../model/cert-wizard';
import {CertWizardService} from '../cert-wizard.service';

@Component({
  selector: 'app-cert-wizard-login',
  templateUrl: './cert-wizard-login.component.html',
  styleUrls: ['./cert-wizard-login.component.css']
})
export class CertWizardLoginComponent implements OnDestroy {

  public auth: Auth;

  public email: string = null;
  public pin: string = null;

  public errors: Map<string, string> = new Map();

  public certWizard: CertWizard;

  private sHelper: SubscriptionHelper = new SubscriptionHelper;


  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if ('Enter' == event.key) {
      void this.login();
      return;
    }
  }


  public constructor(
    private readonly loadingService: LoadingService,
    private readonly authService: AuthService,
    private readonly activeModal: NgbActiveModal,
    private readonly tooltipService: TooltipService,
    private readonly certWizardService: CertWizardService,
  ) {
    void this.init();
  }

  private init(): void {
    this.loadingService.open()
    this.sHelper.sub = this.authService.auth.subscribe(async (auth: Auth) => {
      if (auth === undefined) {
        this.auth = null;
        this.loadingService.dismiss();
      } else if (auth === null) {
        this.auth = null;
        this.loadingService.dismiss();
      } else {
        this.auth = auth;
        this.loadingService.dismiss();
      }
    });
  }
  public async login(): Promise<void> {
    this.errors.clear();
    if (!this.validateEmail()) {
      this.errors.set('email', 'email-error');
    }
    if (this.errors.size > 0) {
      return;
    }
    this.sHelper.sub = this.authService.login(this.email, this.pin).subscribe(
      () => this.activeModal.close(true),
      (errors) => {
        if (errors.error.email) {
          this.errors.set('email', 'email-error');
        }
        this.tooltipService.show({translateKey: 'login-error'})
      }
    );
  }

  public async forgotPin(): Promise<void> {
    this.errors.clear();
    if (!this.validateEmail()) {
      this.errors.set('email', 'email-error');
    }
    if (this.errors.size > 0) {
      return;
    }
    this.certWizardService.forgotPin(this.email, this.certWizard)
      .then(() => {
        this.tooltipService.show({translateKey: 'forgot-password-success'})
        this.activeModal.close(false);
      }).catch(errors => {
      if (errors.error.email) {
        this.errors.set('email', 'registration-email-error');
      }
    })
  }

  public close() {
    this.activeModal.close(false)
  }

  private validateEmail(): boolean {
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.email);
  }

  public ngOnDestroy(): void {
    this.sHelper.unsubscribeAll();
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/map';
import {environment} from '../../environments/environment'
import {ModuleStat} from './module-stat.model';
import {User} from '../user/user.model';
import {Module} from '../module/module.model';
import {UserStat} from './user-stat.model';
import {Asad} from '../asad/asad.model';
import {AsadStat} from './asad-stat.model';
import {KTracker} from '../ktracker/ktracker.model';
import {KTrackerStat} from './ktracker-stat.model';
import {Log} from 'ng2-logger'
import {ClientStat} from './client-stat.model';
import {Client} from '../client/client.model';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class StatsService {
  log = Log.create('StatsService');

  resource: string = environment.backendApi + '/stats/';

  constructor(private http: HttpClient) {
  }

  findUserStatsByUserIds(ids: string[] | User[]): Observable<UserStat[]> {

    let url = this.resource + 'users';
    let params: any = {};

    if (<User[]>ids && (<User[]>ids).length > 0 && (<User[]>ids)[0].id) {
      params.id = (<User[]>ids).map(m => m.id);
    } else {
      params.id = ids;
    }

    return this.http.get<any>(url, {params: params})
      .map(data => {
        let result: UserStat[] = [];
        if (Array.isArray(data)) {
          result = data.map(resStat => UserStat.createWith(resStat));
        }
        this.log.info(<any>result);
        return result;
      });
  }

  findClientStatsByClientIds(ids: string[] | Client[]): Observable<ClientStat[]> {

    let url = this.resource + 'clients';
    let params: any = {};

    if (<Client[]>ids && (<Client[]>ids).length > 0 && (<Client[]>ids)[0].id) {
      params.id = (<Client[]>ids).map(m => m.id);
    } else {
      params.id = ids;
    }

    return this.http.get<any>(url, {params: params})
      .map(res => {
        let resJson = res;
        this.log.info(resJson);
        let result: ClientStat[] = [];
        if (Array.isArray(resJson)) {
          result = resJson.map(resStat => ClientStat.createWith(resStat));
        }
        this.log.info(<any>result);
        return result;
      });
  }

  findModuleStatsByUserAndModuleIds(user: User, ids: string[] | Module[]): Observable<ModuleStat[]> {

    let url = this.resource + 'users/' + user.id + '/modules';
    let params: any = {};

    if (<Module[]>ids && (<Module[]>ids).length > 0 && (<Module[]>ids)[0].id) {
      params.id = (<Module[]>ids).map(m => m.id);
    } else {
      params.id = ids;
    }

    return this.http.get<ModuleStat[]>(url, {params: params})
      .map(data => {
        let resJson = data;
        this.log.info(<any>resJson);
        let result: ModuleStat[] = [];
        if (Array.isArray(resJson)) {
          result = resJson.map(resStat => ModuleStat.createWith(resStat));
        }
        this.log.info(<any>result);
        return result;
      });
  }

  findAsadStatsByUserAndAsadIds(user: User, ids: string[] | Asad[]): Observable<AsadStat[]> {

    let url = this.resource + 'users/' + user.id + '/asad';
    let params: any = {};

    if (<Asad[]>ids && (<Asad[]>ids).length > 0 && (<Asad[]>ids)[0].id) {
      params.id = (<Asad[]>ids).map(m => m.id);
    } else {
      params.id = ids;
    }

    return this.http.get<AsadStat[]>(url, {params: params})
      .map(data => {
        let resJson = data;
        this.log.info(<any>resJson);
        let result: AsadStat[] = [];
        if (Array.isArray(resJson)) {
          result = resJson.map(resStat => AsadStat.createWith(resStat));
        }
        this.log.info(<any>result);
        return result;
      });
  }

  findKTrackerStatsByUserAndKTrackerIds(user: User, ids: string[] | KTracker[]): Observable<KTrackerStat[]> {

    let url = this.resource + 'users/' + user.id + '/ktracker';
    let params: any = {};

    if (<KTracker[]>ids && (<KTracker[]>ids).length > 0 && (<KTracker[]>ids)[0].id) {
      params.id = (<KTracker[]>ids).map(m => m.id);
    } else {
      params.id = ids;
    }

    return this.http.get<KTrackerStat[]>(url, {params: params})
      .map(data => {
        let resJson = data;
        this.log.info(<any>resJson);
        let result: KTrackerStat[] = [];
        if (Array.isArray(resJson)) {
          result = resJson.map(resStat => KTrackerStat.createWith(resStat));
        }
        this.log.info(<any>result);
        return result;
      });
  }
}

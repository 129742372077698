<nav class="navbar fixed-top flex-row navbar-inverse bg-primary-1"
     *ngIf="learnMode != learnModeState.DISABLED">

  <a class="navbar-brand nav-link mr-auto"
     (click)="finish();$event.preventDefault();"
     href="#">
    < {{'back'|translate}}
  </a>

  <a class="nav-link text-white" title="{{'change-card-text'|translate}}"
     *ngIf="module && currentViewState != viewState.FINISHED
     && module.type == 'TWO_SIDE' && learnMode != learnModeState.KTRACKER"
     (click)="changeLearnDirection($event)"
     href="">
    <i class="fa fa-retweet" [class.text-primary-2]="module.userModules[0].inversLearnDirection" aria-hidden="true"></i>
  </a>
  <a class="nav-link text-white" title="{{'write-on-off'|translate}}"
     *ngIf="module && currentViewState != viewState.FINISHED && module.type == 'TWO_SIDE'"
     (click)="changeWriteMode($event)"
     href="">
    <i class="fa fa-pencil" [class.text-primary-2]="isWriteMode" aria-hidden="true"></i>
  </a>
  <a class="nav-link text-white" title="{{'mix-cards'|translate}}"
     *ngIf="currentViewState != viewState.FINISHED && learnMode != learnModeState.KTRACKER"
     (click)="shuffleCards($event)"
     href="">
    <i class="fa fa-random" aria-hidden="true"></i>
  </a>
</nav>

<!--#132333-->
<div style="background-color: #132333"
     *ngIf="learnMode != learnModeState.DISABLED">
  <div class="container-fluid container-limited">
    <div class="row">
      <div class="col-12 offset-sm-1 col-sm-10 d-flex flex-sm-row-reverse">
        <div class="w-100 pt-2" *ngIf="answerTimeSec">
          <div class="text-center text-primary-2 h5">{{answerTimeSecLeft + 1 }}</div>
          <mat-progress-bar
            [color]="primary"
            [mode]="determinate"
            [value]="answerTimeProgress">
          </mat-progress-bar>
        </div>

        <div class="text-primary-2 align-self-center" *ngIf="!answerTimeSec">
          <div class="d-sm-flex flex- pt-2 pb-2"
               *ngIf="currentViewState == viewState.SHOW_ANSWER">
            <span class="text-nowrap" *ngIf="this.learnMode == learnModeState.KTRACKER">{{'answer-time'|translate}} {{lastAnswerTime}}s</span>
            <span class="d-none d-sm-block" *ngIf="this.learnMode == learnModeState.KTRACKER">&nbsp;|&nbsp;</span>
            <div class="d-flex"
                 *ngIf="this.learnMode == learnModeState.KTRACKER && kTrackerCard.cardStat.wrongAnswersCount + kTrackerCard.cardStat.correctAnswersCount > 0">
              <span class="text-nowrap">{{'colleagues'|translate}} Ø {{kTrackerCard.cardStat.averageAnswerTime}}s&nbsp;</span>
              <div class="d-flex justify-content-center align-items-center">
                <i class="fa fa-remove" aria-hidden="true" style="color: red;"></i>&nbsp;
                <span class="">{{Math.round(kTrackerCard.cardStat.wrongAnswersCount / (kTrackerCard.cardStat.wrongAnswersCount + kTrackerCard.cardStat.correctAnswersCount) *100)}}%&nbsp;</span>
                <i class="fa fa-check" aria-hidden="true" style="color: green;"></i>&nbsp;
                <span>{{Math.round(kTrackerCard.cardStat.correctAnswersCount / (kTrackerCard.cardStat.wrongAnswersCount + kTrackerCard.cardStat.correctAnswersCount) *100)}}%</span>
                <span class="d-none d-sm-block">&nbsp;|&nbsp;</span>
              </div>
            </div>
            <span class="text-nowrap">{{'cards-left'|translate}} {{this.cardsLeft.length}}</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="container-fluid container-limited mt-3">
  <div class="row">
    <div class="col-12 offset-sm-1 col-sm-10">

      <div class="card border-0" style="overflow: hidden" #learnCard
           *ngIf="currentCard && currentViewState != viewState.FINISHED">
        <div class="text-right pt-1 pr-1" *ngIf="currentUserCard && learnMode != learnModeState.DISABLED">
          <i class="fa fa-star-o fa-2x text-muted" aria-hidden="true"
             *ngIf="!currentUserCard.favorite"
             (click)="toggleFavorite()"></i>
          <i class="fa fa-star fa-2x starred" aria-hidden="true"
             *ngIf="currentUserCard.favorite"
             (click)="toggleFavorite()"></i>
        </div>

        <div class="card-body h3 text-muted text-center" [innerHTML]="getQuestText()"></div>

        <div class="card-body d-flex justify-content-center">
          <div *ngFor="let cImg of currentCard.cardImages; let cImgIdx = index">
            <a href="{{cImg.getImageURL()}}"
               data-lightbox="image-card"
               *ngIf="isQuestImage(cImg)">
              <img class="card-img-thumbnail img-thumbnail"
                   [src]="cImg.getImageURL()"/>
            </a>
          </div>
        </div>

        <div class="p-0" *ngIf="currentViewState == viewState.SHOW_QUESTION && isWriteMode">
          <form (ngSubmit)="submit($event)" autocomplete="off" name="answer-form">
            <div class="input-group input-group-lg" style="width: 100%">
              <input focusEl type="text" class="form-control"
                     style="border-radius: 0;"
                     [(ngModel)]="answerText"
                     placeholder="{{'enter-answer-here'|translate}}"
                     maxlength="200"
                     autocomplete="off" name="writeModeAnswer"> <div class="input-group-append">
										<button class="btn btn-1"
                            style="border-radius: 0;"
                            type="submit" name="writeModeFormSubmit">
											{{'answer'|translate}}
										</button>
									</div>
            </div>
          </form>
        </div>

        <div class="card-body h3 text-center"
             *ngIf="currentViewState == viewState.SHOW_ANSWER && answerText"
             [style.color]="answerColor"
             [innerHTML]="answerText">
        </div>

        <div class="card-body h3 text-center text-primary-2"
             *ngIf="currentViewState == viewState.SHOW_ANSWER && getAnswerText() != ''"
             [innerHTML]="getAnswerText()">
        </div>

        <div class="card-body d-flex justify-content-center"
             *ngIf="currentViewState == viewState.SHOW_ANSWER && currentCard.cardImages.length > 0">
          <div *ngFor="let cImg of currentCard.cardImages; let cImgIdx = index">
            <a href="{{cImg.getImageURL()}}"
               data-lightbox="image-card"
               *ngIf="isAnswerImage(cImg)">
              <img class="card-img-thumbnail img-thumbnail"
                   [src]="cImg.getImageURL()"/>
            </a>
          </div>
        </div>

        <div class="list-group list-group-flush"
             *ngIf="module.type == 'MULTIPLE_CHOICE'">
          <div class="list-group-item h4 p-4"
               [class.list-group-item-info]="mcc.selected && currentViewState == viewState.SHOW_QUESTION"
               [class.list-group-item-action]="!mcc.selected && currentViewState == viewState.SHOW_QUESTION"
               [class.list-group-item-danger]="mcc.wrong"
               [class.list-group-item-success]="!mcc.wrong && mcc.correct && currentViewState == viewState.SHOW_ANSWER"
               *ngFor="let mcc of currentCard.cardMcChoices"
               (click)="toggleMcc(mcc)">
            <i class="fa fa-square-o mr-3" aria-hidden="true"
               *ngIf="!mcc.selected"></i>
            <i class="fa fa-check-square-o mr-3" aria-hidden="true"
               *ngIf="mcc.selected"></i>
            {{mcc.text}}
          </div>
        </div>

        <div class="p-0 d-flex justify-content-between align-content-center"
             *ngIf="currentViewState == viewState.SHOW_ANSWER && module.type != 'MULTIPLE_CHOICE' && learnMode != learnModeState.DISABLED">
          <button #noBtn type="button" class="btn btn-1 p-0 col"
                  (click)="onAnswer(false,$event)">
            <div class="d-flex justify-content-center align-items-center">
              <i class="fa fa-remove fa-3x mr-3" aria-hidden="true" style="color: red;"></i>
              <span>{{'not-known'|translate}}</span>
            </div>
          </button>
          <!--
                    <button #noBtn type="button" class="btn btn-1 pl-2 pr-2" (click)="deleteCard()" *ngIf="!isReadonly">
                      <i class="fa fa-trash fa-2x align-self-center " aria-hidden="true"></i>
                    </button>
          -->

          <button #yesBtn type="button" class="btn btn-1 p-0 col"
                  (click)="onAnswer(true,$event)">
            <div class="d-flex justify-content-center align-items-center">
              <i class="fa fa-check fa-3x text-primary-3 mr-3" aria-hidden="true"></i>
              <span>{{'known'|translate}}</span>
            </div>
          </button>
        </div>

        <div class="p-0"
             *ngIf="currentViewState == viewState.SHOW_ANSWER && module.type == 'MULTIPLE_CHOICE' && learnMode != learnModeState.DISABLED">
          <button type="button" class="btn btn-1 btn-lg btn-block"
                  (click)="initNextCard()">
            {{'next'|translate}} >
          </button>
        </div>

        <div class="p-0"
             *ngIf="currentViewState == viewState.SHOW_QUESTION && !isWriteMode">
          <button type="button" class="btn btn-1 btn-lg btn-block"
                  (click)="showAnswer()">
            {{'show-answer'|translate}}
          </button>
        </div>

      </div>

      <div class="card border-0" style="overflow: hidden" *ngIf="currentViewState == viewState.FINISHED">
        <div class="card-body h3 text-muted text-center">
          <img src="assets/img/ok.svg" height="110" alt="ok icon">
          <br><br>
          {{'end-learn-session'|translate}}
          <br><br>
          {{'cards'|translate}} <span class="text-primary-1">{{cardCount}}</span>
          <br><br>
          <i class="fa fa-remove mr-1" aria-hidden="true" style="color: red;"></i>
          <span class="mr-4">{{noClickCounter}}</span>
          <i class="fa fa-check mr-1" aria-hidden="true" style="color: green;"></i> {{yesClickCounter}}
        </div>
        <div class="p-0">
          <button type="button" class="btn btn-1 btn-lg btn-block"
                  (click)="finish()">
            {{'close'|translate}}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<i #feedbackYes class="fa fa-check feedback-center" aria-hidden="true" style="color: green;"></i>
<i #feedbackNo class="fa fa-remove feedback-center" aria-hidden="true" style="color: red;"></i>


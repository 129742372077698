import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'confirm',
  template: `
    <div class="modal-body">
    <p>{{body|translate:translateParams}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="activeModal.close(true)">{{'yes'|translate}}</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.close(false)">{{'no'|translate}}</button>
  </div>
  `
})
export class ConfirmComponent {
  @Input() body;
  @Input() translateParams;

  constructor(public activeModal: NgbActiveModal) {
  }
}

<div class="d-flex justify-content-center align-content-center align-items-center"
     [style.min-height]="windowHeight+'px'"
     [style.background-color]="certWizard.tertiaryColor">

  <div [style.max-width]="wrapperMaxWidth + 'px'"
       [style.width]="wrapperWidth + 'px'"
       [style.max-height]="wrapperMaxHeight+'px'"
       [style.background-color]="'white'">

    <nav #navbar class="navbar d-flex flex-row justify-content-between navbar-light bg-primary-4">
      <a #navRef class="navbar-brand mr-auto" style="padding-bottom: 0;padding-top: 2px">
        <img #navImageLeft src="" height="40" alt="">
      </a>

      <img class="pl-1" #navImageRight src="" height="40" alt="">

    </nav>


    <ngb-carousel #carousel
                  [animation]="true"
                  [interval]="0"
                  [showNavigationArrows]="false"
                  [showNavigationIndicators]="false">

      <ng-template id="first" ngbSlide>
        <div class="scroll-target d-flex flex-column"
             [style.min-height]="slideMinHeight+'px'">

          <div class="mt-5 ml-2">
            <div class="d-flex flex-column">

              <div class="d-flex flex-column justify-content-start">
                <h1 class="m-0" style="font-size: 1.5rem">
              <span class="pl-1 pr-1 main-text">
                {{'learning-platform'|translate}} <span class="word-break">{{getDisplayNameByLang()}}</span>
              </span>
                </h1>
              </div>

              <div *ngIf="certWizard.supportedLang.length < 5" class="d-flex flex-row mt-4">

                <div *ngFor="let lang of certWizard.supportedLang">
                  <button (click)="selectLang(lang)"
                          class="btn lang-btn"
                          [ngStyle]="{'background-color': (selectedLang == lang) ? certWizard.color : 'white'}">
                    <h3 class="m-0"
                        [ngStyle]="{'color': (selectedLang == lang) ? certWizard.fontColor : 'black'}">
                      {{lang.toUpperCase()}}
                    </h3>
                  </button>
                </div>

              </div>

              <div *ngIf="certWizard.supportedLang.length >= 5" class="d-flex flex-row justify-content-start align-items-center mt-4 ml-2">

                <button class="btn lang-btn-dropdown dropdown dropdown-toggle m-0"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-offset="-14,10"
                        [style.background-color] = "certWizard.color"
                        [style.border-color] = "certWizard.color">
                  <span
                      [style.color] = "certWizard.fontColor">
                    {{selectedLang.toUpperCase()}}
                  </span>

                </button>
                <div class="dropdown-menu dropdown-scroll">
                  <div *ngFor="let lang of certWizard.supportedLang" class="d-flex flex-row justify-content-center align-items-center">
                    <button (click)="selectLang(lang)"
                            class="btn lang-btn-dropdown m-0"
                            [ngStyle]="{'background-color': (selectedLang == lang) ? certWizard.color : 'white'}">
                      <h3 class="m-0"
                          [ngStyle]="{'color': (selectedLang == lang) ? certWizard.fontColor : 'black'}">
                        {{lang.toUpperCase()}}
                      </h3>
                    </button>
                  </div>
                </div>



              </div>

            </div>
          </div>

          <div class="mb-auto mt-auto">
          </div>

          <div class="pb-3 pt-3">
            <div class="d-flex flex-row justify-content-end">

              <button (click)="moveForward()" type="button" class="btn nav-btn nav-btn-right aeonik-regular"
                      [style.background-color] = "certWizard.color"
                      [style.border-color] = "certWizard.color">
                <h3 class="m-0"
                    [style.color]="certWizard.fontColor">
                  {{'next'|translate}}
                </h3>
              </button>

            </div>
          </div>

        </div>
      </ng-template>

      <ng-template id="second" ngbSlide>

        <div class="scroll-target d-flex flex-column"
             [style.min-height]="slideMinHeight+'px'"
             [style.background-color]="certWizard.color">

          <div class="mb-auto mt-auto">

            <div id="onboardingCarousel"
                 class="carousel slide"
                 data-ride="true"
                 data-interval="5000">

              <ol class="carousel-indicators">
                <li data-target="#onboardingCarousel" data-slide-to="0" class="active"
                    [ngClass]="isMainColorDark() ? 'dark-li' : 'bright-li'"></li>
                <li data-target="#onboardingCarousel" data-slide-to="1"
                    [ngClass]="isMainColorDark() ? 'dark-li' : 'bright-li'"></li>
                <li data-target="#onboardingCarousel" data-slide-to="2"
                    [ngClass]="isMainColorDark() ? 'dark-li' : 'bright-li'"></li>
                <li data-target="#onboardingCarousel" data-slide-to="3"
                    [ngClass]="isMainColorDark() ? 'dark-li' : 'bright-li'"></li>
              </ol>

              <div class="carousel-inner">

                <div class="carousel-item active">

                  <div class="d-flex flex-column">

                    <div class="d-flex justify-content-center">
                      <img #learnCardSVG class="onboarding-img" src="../../../assets/img/cert-wizard/icon_learn_card.svg" alt="1. slide">
                    </div>

                    <div class="mb-5 onboarding-text">
                      <div class="d-flex align-items-center align-content-center justify-content-center text-center aeonik-regular ml-5 mr-5">
                        <h2 [style.color]="certWizard.fontColor">
                          {{'onboarding-learn'|translate}}
                        </h2>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="carousel-item">

                  <div class="d-flex flex-column">

                    <div class="d-flex justify-content-center">
                      <img #progressSVG class="onboarding-img" src="../../../assets/img/cert-wizard/icon_learn_progress.svg" alt="2. slide">
                    </div>

                    <div class="mb-5 onboarding-text">
                      <div class="d-flex align-items-center align-content-center justify-content-center text-center aeonik-regular ml-5 mr-5">
                        <h2 [style.color]="certWizard.fontColor">
                          {{'onboarding-stats'|translate}}
                        </h2>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="carousel-item">

                  <div class="d-flex flex-column">

                    <div class="d-flex justify-content-center">
                      <img #mailSVG class="onboarding-img" src="../../../assets/img/cert-wizard/icon_learn_card_mail.svg" alt="3. slide">
                    </div>

                    <div class="mb-5 onboarding-text">
                      <div class="d-flex align-items-center align-content-center justify-content-center aeonik-regular ml-5 mr-5">
                        <h2 class="text-center" [style.color]="certWizard.fontColor">
                          {{'onboarding-asad'|translate}}
                        </h2>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="carousel-item">

                  <div class="d-flex flex-column">

                    <div class="d-flex justify-content-center">
                      <img #asadSVG class="onboarding-img" src="../../../assets/img/cert-wizard/icon_certificate.svg" alt="4. slide">
                    </div>

                    <div class="mb-5 onboarding-text">
                      <div class="d-flex align-items-center align-content-center justify-content-center text-center aeonik-regular ml-5 mr-5">
                        <h2 [style.color]="certWizard.fontColor">
                          {{'onboarding-proof'|translate}}
                        </h2>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <a class="carousel-control-prev" href="#onboardingCarousel" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon"
                      [ngStyle]="{'filter': isMainColorDark() ? 'invert(0%)' : 'invert(100%)'}"
                      aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#onboardingCarousel" role="button" data-slide="next">
                <span class="carousel-control-next-icon"
                      [ngStyle]="{'filter': isMainColorDark() ? 'invert(0%)' : 'invert(100%)'}"
                      aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>

            </div>

          </div>



          <div class="d-flex flex-row-reverse justify-content-between pb-3 pt-3"
               [style.background-color]="'white'">

            <button (click)="navigateLearnView()" type="button" class="btn nav-btn nav-btn-right aeonik-regular"
                    [style.background-color] = "certWizard.color"
                    [style.border-color] = "certWizard.color">
              <h3 class="m-0"
                  [style.color]="certWizard.fontColor">
                {{'next'|translate}}
              </h3>
            </button>

            <button (click)="moveBack()" type="button" class="btn nav-btn nav-btn-left aeonik-regular"
                    [style.background-color] = "certWizard.secondaryColor"
                    [style.border-color] = "certWizard.secondaryColor"
                    [style.color] = "certWizard.secondaryFontColor">
              <h3 class="m-0">
                {{'back'|translate}}
              </h3>
            </button>

          </div>
        </div>

      </ng-template>

    </ngb-carousel>

  </div>
</div>

import {User} from '../user/user.model';
import {UserAsad} from './user-asad.model';
import {AsadModule} from './asad-module.model';

export class Asad {

  id: string = null;
  // creator of this asad
  user: User = null;
  name: string = null;
  description: string = null;
  visibility: string = null;
  created: number = null;
  modified: number = null;
  userAsads: UserAsad[] = [];
  asadModules: AsadModule[] = [];

  constructor() {
  }

  permission(user: User): string {
    let permission = null;
    this.userAsads.some(ua => {
      if (ua.user.id == user.id) {
        permission = ua.permission;
        return true;
      }
      return false;
    });
    return permission;
  }

  userAsad(user: User): UserAsad {
    let userAsad = null;
    this.userAsads.some(um => {
      if (um.user.id == user.id) {
        userAsad = um;
        return true;
      }
      return false;
    });
    return userAsad;
  }

  static createWith(obj: any): Asad {
    let newObj = new Asad();
    Object.keys(newObj).forEach(
      key => {
        if ('userAsads' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(um => {
            newObj.userAsads.push(UserAsad.createWith(um));
          })
        } else if ('asadModules' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(um => {
            newObj.asadModules.push(AsadModule.createWith(um));
          })
        } else if ('user' === key && obj[key]) {
          newObj.user = User.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

  static sortByName(a: Asad, b: Asad): number {
    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }
}

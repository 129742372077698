import {KTrackerStat} from './ktracker-stat.model';
export class UserStat {

  serverUnixTime: number = null;
  userId: string = null;
  moduleCount: number = null;
  learnedCardCount: number[] = [];
  moduleLearnTimes: number[] = [];
  dueKTrackers: KTrackerStat[] = [];

  constructor() {
  }

  static createWith(obj: any): UserStat {
    let newObj = new UserStat();
    Object.keys(newObj).forEach(
      key => {
        if ('dueKTrackers' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(item => {
            newObj.dueKTrackers.push(KTrackerStat.createWith(item));
          })
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

}

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited">
    <span class="h2">A-sentence-a-day<sup>©</sup></span>
  </div>
</div>
<div class="container-fluid container-limited">
  <div class="row">

    <div class="col order-0" style="margin-bottom: 15px">
      <button class="btn btn-outline-success" type="button" routerLink="/asads/new">
        <i class="fa fa-plus" aria-hidden="true"></i>
        {{'new-asad'|translate}}
      </button>
    </div>

    <div class="col col-sm-5 col-lg-4 order-1 text-sm-right" style="margin-bottom: 15px">
      <searchbar class="float-sm-right" [(query)]="searchQuery" (input)="doSearch()"
                 placeholder="{{'search'|translate}} ASaD"></searchbar>
    </div>

  </div>
  <div class="row">
    <div class="col-12">

      <div class="card">
        <div class="table-responsive" *ngIf="filteredAsads.length">
          <table class="table table-hover mb-0">
            <thead>
            <tr>
              <th class="border-top-0" style="width: 25px;"><!--status--></th>
              <th class="border-top-0"><!--card count--></th>
              <th class="border-top-0">{{'name'|translate}}</th>
              <th class="border-top-0 d-none d-md-block">{{'description'|translate}}</th>
              <th class="border-top-0">{{'owner'|translate}}</th>
              <th class="border-top-0">{{'permission'|translate}}</th>
              <th class="border-top-0"><!--actions--></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let asad of filteredAsads" (click)="edit(asad)">
              <td><i class="fa fa-check" aria-hidden="true"
                     [class.fa-check]="asad.userAsad(auth.user).active"
                     [class.text-success]="asad.userAsad(auth.user).active"
                     [class.fa-times]="!asad.userAsad(auth.user).active"
                     [class.text-danger]="!asad.userAsad(auth.user).active">

              </i>
              </td>
              <td class="text-primary-2">
                <i class="fa fa-clone" aria-hidden="true"></i>
                {{getCardCount(asad)}}
              </td>
              <td>{{asad.name}}</td>
              <td class="d-none d-md-block">{{asad.description}}</td>
              <td>{{getOwner(asad)|translate}}</td>
              <td>{{getPermission(asad)|translate}}</td>
              <td>
                <div class="d-flex flex-row justify-content-end">
                  <span class="mr-1" *ngIf="getOwner(asad) == 'me'
                  && (auth.user.role == 'manager' || auth.user.role == 'admin')">
                    {{asad.userAsads.length-1}}
                  </span>

                  <button class="btn btn-link btn-table mr-2"
                          (click)="editUserAsad(asad,$event)"
                          *ngIf="getOwner(asad) == 'me'
                          && (auth.user.role == 'manager' || auth.user.role == 'admin')">
                    <i class="fa fa-users" aria-hidden="true"></i>
                  </button>
                  <button class="btn btn-link btn-table mr-2 text-primary-2" (click)="test(asad,$event)"
                          *ngIf="auth.user.role == 'admin'">
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  </button>
                  <button class="btn btn-link btn-table text-primary-2" (click)="delete(asad,$event)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="!filteredAsads.length">
          <h5 class="card-title text-muted text-center" style="margin-top: 12px">
            {{'empty-search-asad'|translate}}
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="jumbotron jumbotron-fluid jumbotron-small">
  <div class="container-fluid container-limited d-flex flex-row">
    <div class="mr-auto d-flex" *ngIf="module && moduleStat">
      <img [src]="'assets/img/'+module.type+'.png'" class="mr-2" style="height: 35px"/>
      <div class="h2 m-0 text-nowrap">
        {{module.name}}
        <small class="text-muted text-nowrap">- {{moduleStat.cardCount}} {{'cards'|translate}}</small>
      </div>

    </div>
    <button class="btn btn-link p-0 text-primary-2"
            (click)="edit()"
            title="{{'edit-module'|translate}}"
            *ngIf="module">
      <i class="fa fa-pencil" style="font-size: 1.5rem" aria-hidden="true"></i>
    </button>
  </div>
</div>

<div class="container-fluid container-limited">
  <div class="card mt-4 mb-4" *ngIf="moduleStat && moduleStat.cardCount == 0">
    <div class="card-body">
      <h4 class="card-title">{{'module-empty-title'|translate}}</h4>
      <p class="card-text">
        {{'module-empty-text'|translate}}
        <span *ngIf="!isReadonly"><br>{{'module-empty-text2'|translate}}</span>
      </p>
      <button class="btn btn-outline-success"
              *ngIf="!isReadonly"
              (click)="edit()">{{'edit-module'|translate}}
      </button>
    </div>
  </div>

  <div class="row" *ngIf="moduleStat && moduleStat.cardCount > 0">
    <div class="col-sm-7 col-md-6 col-lg-5 mb-4">
      <div class="card">
        <div class="card-header">
          {{'module-stats'|translate}}
        </div>
        <div class="card-body pb-0">
          <p class="card-title text-muted mb-0">{{'phases-dist'|translate}}
            <button class="btn btn-link btn-table fa fa-info-circle text-primary-2" aria-hidden="true"
                    placement="right"
                    data-html="true"
                    [ngbPopover]="popContent"
                    popoverTitle="{{'waiting-period'|translate}}"></button>
          </p>
        </div>
        <div class="card-body d-flex" style="height: 260px">
          <div *ngFor="let phaseCount of moduleStat.cardCountPhases; let idx = index;"
               class="w-25 align-self-end pl-1 pr-1">
            <div class="text-center mt-auto">{{phaseCount}}</div>
            <div style="border-radius: 0.25rem;"
                 [style.background-color]="getColorForPhase(idx)"
                 [style.height]="getHeightForPhase(idx)"></div>
            <div class="text-center mt-3">
              {{idx+1}}
              <i class="fa fa-trophy starred" aria-hidden="true" *ngIf="idx == 5"></i>
            </div>
          </div>
        </div>

        <div class="card-body">
          <table class="table table-sm">
            <tbody>
            <tr>
              <td class="text-muted border-0">{{'lections'|translate}} | {{'cards'|translate}}</td>
              <td class="text-primary-2 border-0 text-right">{{moduleStat.lectionCount}} | {{moduleStat.cardCount}}</td>
            </tr>
            <tr>
              <td class="text-muted">{{'user'|translate}}</td>
              <td class="text-primary-2 text-right">{{moduleStat.userCount}}</td>
            </tr>
            <tr>
              <td class="text-muted">Ø Lernzeit pro Tag</td>
              <td class="text-primary-2 text-right">{{averageLearnTime}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="col-sm-5 col-md-6 col-lg-7">
      <div class="card">
        <div class="card-header">
          {{'your-learn-options'|translate}}
        </div>
        <div class="list-group list-group-flush" *ngIf="this.moduleStat">

          <button class="list-group-item list-group-item-action d-flex"
                  (click)="learn('PHASE')"
                  [disabled]="moduleStat.dueCardCount == 0"
                  [class.list-group-item-success]="moduleStat.dueCardCount > 0"
                  [class.h5]="moduleStat.dueCardCount > 0">
            <div class="mr-auto">
              <i class="fa fa-play" aria-hidden="true" *ngIf="moduleStat.dueCardCount > 0"></i>
              {{'learn-due-cards'|translate}}
            </div>
            <div class="badge badge-default badge-pill" style="line-height: inherit;"
                 *ngIf="!moduleStat.nextLearnDate">
              {{moduleStat.dueCardCount}}
            </div>
            <div class="text-danger" *ngIf="nextTrainingDayCount > 1">
              {{ 'in-days' | translate:{days:nextTrainingDayCount} }}
            </div>
            <div class="text-danger" *ngIf="nextTrainingDayCount == 1">
              {{ 'tomorrow' | translate}}
            </div>
          </button>

          <button class="list-group-item list-group-item-action d-flex"
                  (click)="learn('ALL')"
                  [class.list-group-item-success]="moduleStat.dueCardCount == 0"
                  [class.h5]="moduleStat.dueCardCount == 0">
            <div class="mr-auto">
              <i class="fa fa-play" aria-hidden="true" *ngIf="moduleStat.dueCardCount == 0"></i>
              {{'learn-active-cards'|translate}}
            </div>
            <span class="badge badge-default badge-pill" style="line-height: inherit;">
              {{moduleStat.activeCardCount}}
            </span>
          </button>

          <button class="list-group-item list-group-item-action d-flex"
                  (click)="learn('FAVORITE')"
                  [disabled]="moduleStat.favoriteCardCount == 0">
            <div class="mr-auto">
              {{'learn-favorite-cards'|translate}}
            </div>
            <div class="badge badge-default badge-pill" style="line-height: inherit;">
              <i class="fa fa-star starred" aria-hidden="true"></i>
              {{moduleStat.favoriteCardCount}}
            </div>
          </button>

        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #popContent>
  <div [innerHTML]="phaseDaysText"></div>
</ng-template>

import {Module} from './module.model';

export class CardMcChoice {

  id: string = null;
  text = '';
  correct = false;

  constructor() {
  }

  static createWith(obj: any): CardMcChoice {
    let newObj = new CardMcChoice();
    Object.keys(newObj).forEach(
      key => {
        newObj[key] = obj[key];
      });
    return newObj;
  }

  static sortByCorrect(a: CardMcChoice, b: CardMcChoice): number {
    if (a.correct && b.correct || !a.correct && !b.correct) {
      return 0
    }

    if (a.correct) {
      return -1;
    } else {
      return 1;
    }
  }
}

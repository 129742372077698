import {User} from '../user/user.model';
import {UserModule} from './user-module.model';
import {Lection} from './lection.model';

export enum ModuleKind {
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  TWO_SIDE = 'TWO_SIDE',
  ONE_SIDE = 'ONE_SIDE',
}


export class Module {

  id: string = null;
  // creator of this module
  user: User = null;
  name: string = null;
  description: string = null;
  lang1: string = null;
  lang2: string = null;
  type: ModuleKind = null;
  visibility: string = null;
  created: number = null;
  modified: number = null;
  userModules: UserModule[] = [];
  lections: Lection[] = [];

  constructor() {
  }

  permission(user: User): string {
    let permission = null;
    this.userModules.some(um => {
      if (um.user.id == user.id) {
        permission = um.permission;
        return true;
      }
      return false;
    });
    return permission;
  }

  userModule(user: User): UserModule {
    let userModule = null;
    this.userModules.some(um => {
      if (um.user.id == user.id) {
        userModule = um;
        return true;
      }
      return false;
    });
    return userModule;
  }

  static createWith(obj: any): Module {
    let newObj = new Module();
    Object.keys(newObj).forEach(
      key => {
        if ('userModules' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(um => {
            newObj.userModules.push(UserModule.createWith(um));
          })
        } else if ('lections' === key && obj[key] && Array.isArray(obj[key])) {
          obj[key].forEach(l => {
            newObj.lections.push(Lection.createWith(l));
          })
        } else if ('user' === key && obj[key]) {
          newObj.user = User.createWith(obj[key]);
        } else {
          newObj[key] = obj[key];
        }
      });
    return newObj;
  }

  static sortByName(a: Module, b: Module): number {
    let nameA = a.name.toUpperCase(); // ignore upper and lowercase
    let nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    // names must be equal
    return 0;
  }


}

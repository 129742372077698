import {Component, OnDestroy} from '@angular/core';
import {Module} from '../module.model';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {TooltipService} from '../../util/tooltip/tooltip.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {LoadingService} from '../../util/loading/loading.service';
import {ModuleService} from '../module.service';
import {DeleteConfirmComponent} from '../../util/confirm-modal/delete-confirm.component';
import {SubscriptionHelper} from '../../util/subscription-helper';
import {ModuleUserComponent} from '../module-user/module-user.component';
import {TitleService} from '../../util/title/title.service';
import {StatsService} from '../../stats/stats.service';
import {ModuleStat} from '../../stats/module-stat.model';
import {ModalWrapperService} from '../../util/modal/modal-wrapper.service';
import {Log} from 'ng2-logger';

@Component({
  selector: 'app-module-list',
  templateUrl: './module-list.component.html',
  styleUrls: ['./module-list.component.css']
})
export class ModuleListComponent implements OnDestroy {

  modules: Module[] = [];
  filteredModules: Module[] = [];
  stats: Map<string, ModuleStat> = new Map();
  auth: any;
  searchQuery = '';

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  private readonly log = Log.create('ModuleListComponent');

  constructor(private moduleService: ModuleService,
              private statsService: StatsService,
              public loadingService: LoadingService,
              private modalService: ModalWrapperService,
              private tooltipService: TooltipService,
              public authService: AuthService,
              private router: Router,
              private titleService: TitleService) {
    titleService.title = 'modules';

    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
      }

    });
  }

  _init() {
    this.loadingService.open();
    this._sHelper.sub = this.moduleService.findAllByUser(this.auth.user).subscribe(
      modules => {
        this.modules = modules.sort(Module.sortByName);
        this.doSearch();
        this.loadingService.dismiss();

        // get module stats
        this.statsService.findModuleStatsByUserAndModuleIds(this.auth.user, modules).subscribe(
          stats => stats.forEach(stat => this.stats.set(stat.moduleId, stat)));
      },
      e => {
        this.loadingService.dismiss();
      }
    );
  }

  doSearch() {
    this.filteredModules = this.modules.filter(
      (item: Module) => {

        let match = false;
        if (!this.searchQuery) {
          match = true;
        } else {
          let query = this.searchQuery.toLowerCase();
          if (item.name) {
            match = item.name.toLowerCase().indexOf(query) != -1;
          }
          if (item.description && !match) {
            match = item.description.toLowerCase().indexOf(query) != -1;
          }

        }

        return match;
      });
  }

  getModuleOwner(module: Module) {
    if (module.user.id == this.auth.user.id) {
      return 'me';
    } else {
      return module.user.forename + ' ' + module.user.name
    }
  }

  getModulePermission(module: Module) {
    let permission = '';
    module.userModules.forEach(um => {
      if (um.user.id == this.auth.user.id) {
        permission = um.permission;
      }
    });
    return permission.toLowerCase();
  }

  getModuleCardCount(module: Module) {
    if (this.stats.get(module.id)) {
      return this.stats.get(module.id).cardCount;
    }
    return 0;
  }

  edit(module, event) {
    if (event) {
      event.stopPropagation();
    }
    this.router.navigate(['/modules', module.id]);
  }

  learn(module, event) {
    this.log.info('learn(module, event)')
    if (event) {
      event.stopPropagation();
    }
    this.router.navigate(['/learn', module.id]);
  }

  delete(module, event) {
    event.stopPropagation();
    const modalRef = this.modalService.open(DeleteConfirmComponent, {size: 'sm'});
    modalRef.componentInstance.body = 'delete-text';
    modalRef.componentInstance.params = {name: module.name};
    modalRef.result.then(
      (deleteItem) => {
        if (deleteItem) {
          this._sHelper.sub = this.moduleService.delete(this.auth.user, module.id).subscribe(deleted => {
            if (deleted) {
              // delete item in local array
              let delFn = (item, i, arr) => item.id === module.id ? arr.splice(i, 1) : false;
              this.modules.some(delFn);
              this.doSearch();
              this.tooltipService.show({translateKey: 'delete-success'});
            } else {
              this.tooltipService.show({
                translateKey: 'delete-error',
                type: 'danger',
                stay: true
              });
            }
          });

        }
      });
  }

  editUserModule(module, event) {
    event.stopPropagation();
    let modalRef: NgbModalRef = this.modalService.open(ModuleUserComponent);
    modalRef.componentInstance.module = module;
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }
}

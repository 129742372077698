import {Injectable} from '@angular/core';
import {map, catchError} from 'rxjs/operators';
import {environment} from '../../environments/environment'
import {User} from '../user/user.model';
import {Asad} from './asad.model';
import {UserAsad} from './user-asad.model';
import {Log} from 'ng2-logger'
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class AsadService {
  log = Log.create('AsadService');

  resource = 'users';

  constructor(private http: HttpClient) {
  }

  findAllByUser(user: User): Observable<Asad[]> {
    return this.http.get<Asad[]>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/asads')
      .map(data => {
        this.log.info(<any>data);
        return data.map(item => Asad.createWith(item));
      });
  }

  findAllPublic(): Observable<Asad[]> {
    return this.http.get<Asad[]>(environment.backendApi + '/public/asads')
      .map(data => {
        this.log.info(<any>data);
        return data.map(item => Asad.createWith(item));
      });
  }

  findPublicById(id: string): Observable<Asad> {
    return this.http.get<Asad[]>(environment.backendApi + '/public/asads/' + id)
      .map(data => {
        let asad: Asad = Asad.createWith(data);
        this.log.info(<any>asad);
        return Asad.createWith(data);
      });
  }

  findByUserAndId(user: User, id: string): Observable<Asad> {
    return this.http.get<Asad[]>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/asads/'
      + id)
      .map(data => {
        this.log.info(<any>data);
        return Asad.createWith(data);
      });
  }

  testAsad(user: User, id: string): Observable<Asad> {
    return this.http.get<Asad[]>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/asads/'
      + id, {params: {'test': 'true'}})
      .map(data => {
        this.log.info(<any>data);
        return Asad.createWith(data);
      });
  }

  persist(user: User, asad: Asad): Observable<string> {
    return this.http.post<any>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/asads', asad)
      .map(data => {
        this.log.info(data);
        this.log.info(data.id);
        return data.id;
      });
  }

  update(user: User, asad: Asad): Observable<boolean> {
    return this.http.post<any>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/asads/'
      + asad.id, asad)
      .map(data => {
        this.log.info(data);
        return true;
      });
  }

  updateUserAsad(user: User, asad: Asad, userAsads: UserAsad[]): Observable<boolean> {
    return this.http.post<any>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/asads/'
      + asad.id + '/users'
      , userAsads)
      .map(data => {
        this.log.info(data);
        return true;
      });
  }

  findUserAsads(user: User, asadId: String): Observable<UserAsad[]> {
    return this.http.get<UserAsad[]>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/asads/'
      + asadId + '/users')
      .map(data => {
        this.log.info(<any>data);
        return data.map(item => UserAsad.createWith(item));
      });
  }

  updatePublicUserAsad(asad: Asad, userAsad: UserAsad): Observable<boolean> {
    return this.http.post<any>(environment.backendApi + '/public/asads/' + +asad.id + '/users', userAsad)
      .map(data => {
        this.log.info(data);
        return true;
      });
  }

  delete(user: User, id: string): Observable<boolean> {
    return this.http.delete<any>(environment.backendApi + '/'
      + this.resource + '/'
      + user.id + '/asads/'
      + id)
      .pipe(
        map(data => {
          this.log.info(data);
          return true;
        }),
        catchError((err, obs) => {
          return obs;
        })
      );

  }

}

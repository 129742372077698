import {Component, OnDestroy} from '@angular/core';
import {TitleService} from '../util/title/title.service';
import {SubscriptionHelper} from '../util/subscription-helper';
import {UserStat} from '../stats/user-stat.model';
import {AuthService} from '../auth/auth.service';
import {StatsService} from '../stats/stats.service';
import {LoadingService} from '../util/loading/loading.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnDestroy {

  userStat: UserStat;
  auth: any;

  private _sHelper: SubscriptionHelper = new SubscriptionHelper;

  constructor(private titleService: TitleService,
              private statsService: StatsService,
              public loadingService: LoadingService,
              public authService: AuthService) {
    titleService.title = 'Dashboard';
    this._sHelper.sub = authService.auth.subscribe(auth => {
      this.auth = auth;
      if (auth != null) {
        this._init();
        titleService.title = 'Dashboard - ' + auth.user.name;
      }

    });
  }

  _init() {
    this.loadingService.open();
    // get user stats
    this._sHelper.sub = this.statsService.findUserStatsByUserIds([this.auth.user]).subscribe(
      stats => {
        if (stats && Array.isArray(stats) && stats.length > 0) {
          this.userStat = stats[0];
        }
        this.loadingService.dismiss();
      },
      e => {
        this.loadingService.dismiss();
      });
  }

  ngOnDestroy(): void {
    this._sHelper.unsubscribeAll();
  }

}
